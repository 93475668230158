import * as React from 'react';
import ReactDOMServer from 'react-dom/server';

import { getIsClient } from '../../common/utils';
import InfoWindow from '../components/InfoWindow';
import withSystemProvider from '../../common/hocs/withSystemProvider';
import { PickupStation } from '../../pickup/types';
import { fetchIcon } from '../../../design-system';

type Props = {
  map?: google.maps.Map;
  onClick: (id: string) => void;
  selectedMarker: string;
  stations: PickupStation[];
};

export default function useMarkers({ map, stations, onClick, selectedMarker }: Props) {
  const [markers, setMarkers] = React.useState<{ id: string; mapMarker: google.maps.Marker }[]>([]);
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
  const [svg, setSvg] = React.useState('');

  const loadIcon = async () => {
    const response = await fetchIcon({ name: 'mapMarkerColissimo' });
    if (response) {
      setSvg(response);
    }
  };

  React.useEffect(() => {
    loadIcon();
  }, []);

  const url = getIsClient() ? `data:image/svg+xml;charset=UTF-8;base64, ${window.btoa(svg)}` : '';

  const icon = (isSelected: boolean) => ({
    url,
    scaledSize: isSelected ? new google.maps.Size(44, 44) : new google.maps.Size(24, 24),
  });

  React.useEffect(() => {
    if (!map) {
      return () => {};
    }

    const mapMarkers = stations.map(({ id, displayCoordinate: { latitude, longitude } }) => {
      const mapMarker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        icon: icon(id === selectedMarker),
      });
      mapMarker.addListener('click', () => onClick(id));
      return { id, mapMarker };
    });
    setMarkers(mapMarkers);

    return () => {
      mapMarkers.forEach(({ mapMarker }) => {
        google.maps.event.clearListeners(mapMarker, 'click');
        mapMarker.setMap(null);
      });
    };
  }, [map, stations]);

  React.useEffect(() => {
    markers?.forEach((marker) => marker.mapMarker.setIcon(icon(marker.id === selectedMarker)));
    if (infoWindow) {
      infoWindow.close();
    }

    const selectedStation = stations.find((store) => store.id === selectedMarker);
    if (selectedStation && map) {
      const StyledInfoWindow = withSystemProvider(InfoWindow);
      const content = ReactDOMServer.renderToString(<StyledInfoWindow station={selectedStation} />);
      const mapInfoWindow = new google.maps.InfoWindow({
        content,
      });
      const selectedMapMarker: google.maps.Marker | undefined = (markers ?? []).find(
        (marker) => marker.id === selectedMarker
      )?.mapMarker;

      if (selectedMapMarker) {
        mapInfoWindow.open(map, selectedMapMarker);
        setInfoWindow(mapInfoWindow);
      }
    }
  }, [markers, selectedMarker]);
}
