import { createSelector } from 'reselect';

import { RootState } from '../../store/rootReducer';
import { WishlistItem } from './types';

export const wishlistItemsSelector = (state: RootState) => state.wishlist.wishlist?.items ?? [];

export const numberOfWishlistItemsSelector = createSelector(
  wishlistItemsSelector,
  (items: WishlistItem[]) => (items ?? []).length
);
