export default {
  WISHLIST: 'Favoris',
  ADD_TO_WISHLIST: 'Ajouter à vos favoris',
  ADDED_TO_WISHLIST: 'Ajouté !',
  ADDED_TO_WISHLIST_MOBILE: 'Ajouté à vos favoris !',
  MOVE_TO_CART: 'Ajouter au panier',
  MOVED_TO_CART: 'Ajouté au panier !',
  SELECT_SIZE: 'sélectionner',
  CHANGE_SIZE: 'changer',
  ITEM_REMOVED: 'L’article a été bien supprimé de vos favoris',
  EMPTY_WISHLIST_TITLE: 'Votre liste de favoris est vide',
  CONFIRM_SIZE: 'Confirmer Ma Taille',
} as const;
