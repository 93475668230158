import React from 'react';
import styled from '@emotion/styled';

import { UseRefinementListProps, useRefinementList } from 'react-instantsearch';

import { colors, breakpoints } from '../../../design-system';

const StyledStandardWithIconFilter = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  .filter-item {
    border: unset;
    background: unset;
    outline: none;
    margin-top: 8px;
    cursor: pointer;
    text-align: center;
    color: ${colors.BLACK};
    @media (min-width: ${breakpoints.S}px) {
      margin-top: unset;
    }
  }
  .filter-item:hover,
  .filter-item:focus-visible,
  .filter-item--is-refined {
    .filter-item__text {
      font-weight: bold;
    }
    .filter-item__image {
      border: 1px solid ${colors.BLACK};
    }
  }
  .filter-item__text {
    font-size: 14px;
    margin: 8px 0;
    font-weight: 400;
    transition: all 0.3s;
  }
  .filter-item__image {
    width: 100%;
    padding-bottom: 100%;
    background: ${colors.LIGHT};
    border: 1px solid transparent;
    transition: all 0.3s;
  }
  .filter-item__image--leger {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienLeger.svg') no-repeat center;
  }
  .filter-item__image--normal {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienNormal.svg') no-repeat center;
  }
  .filter-item__image--renforce {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienRenforce.svg') no-repeat
      center;
  }
`;

export const StandardWithIconsFilter: React.FC<UseRefinementListProps> = ({
  attribute,
  ...props
}) => {
  const { items, refine } = useRefinementList({
    ...props,
    attribute,
    sortBy: ['name:asc'],
    limit: 6,
    showMoreLimit: 200,
  });

  return (
    <StyledStandardWithIconFilter>
      {items?.map((item, index) => (
        <button
          className={`filter-item ${item.isRefined ? 'filter-item--is-refined' : ''}`}
          key={`filter-${item.label}-${index}`}
          id={`filter-${attribute}-${item.value}`}
          onClick={() => refine(item.value)}
        >
          <div
            className={`filter-item__image filter-item__image--${item.value
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')}`}
          ></div>
          <p className="filter-item__text filter-item__label">{item.label}</p>
          <p className="filter-item__text filter-item__count">({item.count})</p>
        </button>
      ))}
    </StyledStandardWithIconFilter>
  );
};
