export default {
  DAYS: 'jours',
  HOURS: 'heures',
  MINUTES: 'minutes',
  SECONDS: 'secondes',
  DAY: 'jour',
  HOUR: 'heure',
  MINUTE: 'minute',
  SECOND: 'seconde',
} as const;

export const offers = {
  TITLE: 'Offres en cours',
  LBL_CONDITIONS: 'Conditions',
  LBL_EXCLUSIONS: 'Exclusions',
  CTA_LINK: 'Voir la sélection',
  LBL_NAME: 'Offre',
  LBL_VALIDITY: 'Validité',
  LBL_COUPON_CODE: 'Code',
  TXT_NO_COUPON_CODE: 'Automatique',
  CTA_COPY: 'Copier',
  CTA_COPIED: 'Copié !',
  TOOLTIP_COPY: 'cliquez pour copier',
  EXPIRING_PROMOTION: 'C’est presque fini',
  EXPIRED_PROMOTION: 'L’offre est terminée',
};

export const LBL_CONTACT = 'Vos coordonnées';
export const LBL_HELP_ADVICE = 'Pour mieux vous aider, précisez votre demande';
export const LBL_MESSAGE_SEND_SUCCESS = 'Votre message a bien été envoyé';
export const LBL_CONTACT_FEEDBACK = `Nous vous remercions de l’intérêt que vous portez à Darjeeling collection de lingerie. Nous répondrons à votre question dans les plus brefs délais.`;
export const LBL_CONTINUE_SHOPPING = 'Continuer votre shopping';
export const LBL_OOOPS = 'Ooops';
export const LBL_CONTACT_ERROR = 'Une erreur est survenue. Essayez de recharger la page';
export const LBL_RELOAD = 'Recharger';
