import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';

import { RootState } from 'src/shared/store/rootReducer';
import { Results } from '../../catalog/components/Results';
import { breakpoints } from '../../../../shared/design-system';
import { SearchAutocomplete } from './SearchAutocomplete';
import { CmsSearchBlock } from './CmsSearchBlock';
import { LBL_SEARCH, NO_RESULTS } from '../locale';
import { CatalogProduct } from '../../catalog/types';
import { AnimatedText } from '../../common/components/AnimatedText';

const SearchWrapper = styled.div`
  margin: 0;

  @media (min-width: ${breakpoints.S}px) {
    margin: 24px 24px 0 24px;
  }

  .search-banners-block {
    display: none;

    @media (min-width: ${breakpoints.M}px) {
      display: block;
    }
  }
`;

const TitleWrapper = styled.div<{ isHidden: boolean }>`
  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.M - 1}px) {
    ${({ isHidden }) =>
      isHidden
        ? `
        opacity: 0;
        transform:  scale(0);
        height: 0;
      `
        : `
        opacity: 1;
        transform: scale(1);
        height: calc(3rem + 88px);
      `}
    transition: all 0.3s linear;
    padding: 0 16px;
  }

  h1 {
    font-size: 2.4rem;
    font-family: 'Ogg-text';
    text-align: center;
    padding: 16px 0 0 0;
    margin: 16px 0 24px 0;
    @media (min-width: ${breakpoints.M - 1}px) {
      font-size: 3.2rem;
      padding: 16px 0;
      margin: 16px 0;
    }
  }

  .loading-text {
    display: flex;
    font-family: 'Ogg-text';
    width: 100%;
    justify-content: center;
  }
`;

export const SearchPage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search).get('r') ?? '';
  const [isHidden, setIsHidden] = React.useState(false);
  const { search: cmsSearch } = useSelector((state: RootState) => state.cms);
  const { hits } = useInfiniteHits<CatalogProduct>();
  const { status } = useInstantSearch();

  useEffect(() => {
    const handleScroll = () => {
      setIsHidden(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SearchWrapper>
      <Results
        searchInput={
          <>
            <TitleWrapper isHidden={isHidden}>
              <h1>
                {status === 'stalled' || status === 'loading' ? (
                  <div className="loading-text">
                    {LBL_SEARCH}
                    <AnimatedText
                      text="..."
                      id="search-animated-text-block"
                      fontFamily="Ogg-text"
                      margin="0 0 0 2px"
                      animationSpeed={1}
                      animationDelay={0.1}
                    />
                  </div>
                ) : !hits.length && query ? (
                  NO_RESULTS
                ) : (
                  LBL_SEARCH
                )}
              </h1>
              <SearchAutocomplete />
            </TitleWrapper>
            <div className="search-banners-block">
              <CmsSearchBlock cmsSearch={cmsSearch} isHidden={hits.length > 0 && Boolean(query)} />
            </div>
          </>
        }
      />
    </SearchWrapper>
  );
};
