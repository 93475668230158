import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFound } from './NotFound';
import routes from '../routes';
import accountRoutes from '../../account/routes';
import paths from '../paths';
import Account from '../../account/components/Account';

export const Routing = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={`main-routes-${index}`} path={route.path} element={route.component} />
      ))}
      <Route path={paths.ACCOUNT} element={<Account />}>
        {accountRoutes.map((accountRoute, index) => (
          <Route
            key={`account-routes-${index}`}
            path={accountRoute.path}
            element={accountRoute.component}
          />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
