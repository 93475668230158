import * as React from 'react';

function lockInteraction() {
  document.body.style.pointerEvents = 'none';
}

function unlockInteraction() {
  document.body.style.pointerEvents = 'auto';
}

export default function useInteractionLock(isActive: boolean) {
  React.useEffect(() => {
    if (isActive) {
      lockInteraction();
    } else {
      unlockInteraction();
    }
    return () => {
      unlockInteraction();
    };
  }, [isActive]);
}
