import React from 'react';

type Props = {
  productName: string;
};

export const ProductName = ({ productName }: Props) => {
  const separationOfProductNameCategory = (productName ?? 'Catégorie - Produit').split(' - ');
  const [category, nameWithoutCategory] = [
    separationOfProductNameCategory[1],
    separationOfProductNameCategory[0],
  ];

  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{category ? `${category} -` : ''} </span>
      {nameWithoutCategory ? nameWithoutCategory : ''}
    </>
  );
};
