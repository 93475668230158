import React from 'react';
import Transition from 'react-transition-group/Transition';
import pathOr from 'ramda/src/pathOr';
import styled from '@emotion/styled';

import { zIndex, transitions, heights, boxShadows } from '../../common/constants';
import { MenuTreeItem } from '../types';
import { CmsMenuItem } from '../../cms/types';
import { SecondLevel } from './SecondLevel';
import useScrollLock from '../../common/hooks/useScrollLock';

export const MAX_NUMBER_SECOND_LEVEL = 4;
export const MAX_NUMBER_SECOND_LEVEL_IMAGES = 6;

const transitionStylesContent = {
  entering: {
    maxHeight: 0,
    height: 0,
  },
  entered: {
    height: 'max-content',
    maxHeight: heights.SUBMENU,
  },
  exiting: {
    height: 0,
    maxHeight: 0,
  },
  exited: {
    height: 0,
    maxHeight: 0,
  },
};

type Props = {
  isOpen: boolean;
  selected?: MenuTreeItem;
};

const StyledSubmenu = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% - 8px);
  overflow: hidden;
  padding-top: 24px;
  background-color: #fff;
  z-index: ${zIndex.SUBMENU};
  transition: ${transitions.GENERIC};
  box-shadow: ${boxShadows.HORIZONTAL};
  border-radius: 0 0 8px 8px;
`;

export const SubMenu = ({ isOpen, selected }: Props) => {
  const [ref, setRef] = React.useState<HTMLDivElement>();
  const onRefChange = React.useCallback((node) => {
    setRef(node);
  }, []);
  useScrollLock({ isActive: isOpen, ref });

  const columns: (CmsMenuItem & { rows: CmsMenuItem[] })[] = pathOr(
    [],
    ['columns'],
    selected
  ).slice(0, MAX_NUMBER_SECOND_LEVEL);
  const banner = pathOr(undefined, ['banner'], selected);

  return (
    <Transition in={isOpen} mountOnEnter unmountOnExit timeout={0}>
      {(state) => (
        <StyledSubmenu style={transitionStylesContent[state]} ref={onRefChange}>
          {columns.length > 0 ? <SecondLevel columns={columns} banner={banner} /> : null}
        </StyledSubmenu>
      )}
    </Transition>
  );
};
