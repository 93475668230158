import { getCookie } from '../recommendations/utils';

const env = process.env.DEPLOYMENT_ENV === 'production' ? 'Prod' : 'Staging';
const prefix = `Darjeeling_${env}_`;
const rank = getCookie('t2s-rank');

export const indices = {
  MAIN: `${prefix}Main_Index`,
  T2S: `${prefix}T2S_${rank}_Replica`,
  QUERY_SUGGESTIONS: `${prefix}Main_query_suggestions`,
  SORT_BY_NEWEST: `${prefix}Sort_By_Newest_Replica`,
  SORT_BY_PRICE_DESC: `${prefix}Sort_By_Price_Desc_Replica`,
  SORT_BY_PRICE_ASC: `${prefix}Sort_By_Price_Asc_Replica`,
  BEST_SELLERS: `${prefix}Best_Sellers_Replica`,
};
