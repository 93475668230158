import styled from '@emotion/styled';
import css from '@styled-system/css';

import { getCSSFilterFromHex } from './utils';

type StyledImgProps = {
  id?: string;
  color?: string;
  size?: number;
  theme?: { colors: { [key: string]: string } };
  onClick?: () => void;
};

export const StyledImg = styled.img<StyledImgProps>(
  {
    display: 'flex',
  },
  ({ size = 17 }) =>
    css({
      size,
    }),
  ({ onClick }) =>
    typeof onClick === 'function' && {
      cursor: 'pointer',
    },
  ({ color, theme }) =>
    color && {
      filter: getCSSFilterFromHex({ color, theme }),
    }
);
