import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { useTextReveal } from '../../common/hooks/useTextReveal';
import { useMediaQueries, useMediaQuery } from '../../common/hooks/useMediaQuery';
import { CmsBrandBanner, CmsFontFamily } from '../../cms/types';
import { getOurBrandBannerPosition } from '../utils';
import { breakpoints, colors } from '../../../design-system';
import { hasValidCmsText } from '../../home/utils';
import { heights } from '../../common/constants';
import { getIsClient } from '../../common/utils';

type Props = {
  cmsData: CmsBrandBanner | null;
  index: number;
};

type StyledContainerProps = {
  brand_banner_position_on_page: number;
  brand_banner_margin_bottom: number;
  brand_banner_margin_bottom_small_screen: number;
  brand_banner_text_on_image_block_width: number;
  brand_banner_text_on_image_block_width_small_screen: number;
  isSideBlockPositionAbsolute: boolean;
  isSideBlockActive: boolean;
  text_on_image_font_family: CmsFontFamily;
  text_on_image_font_size: number;
  text_on_image_font_size_small_screen: number;
  text_on_image_font_weight: number;
  text_on_image_color: string;
  side_block_background_color?: string;
  side_title_text_font_family: CmsFontFamily;
  side_title_text_font_size: number;
  side_title_text_font_size_small_screen: number;
  side_title_text_font_weight: number;
  side_title_text_color: string;
  side_text_font_family: CmsFontFamily;
  side_text_font_size: number;
  side_text_font_size_small_screen: number;
  side_text_color: string;
  side_block_position: 'left' | 'right';
};

const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  order: ${(props) => props.brand_banner_position_on_page};
  margin-bottom: ${(props) => props.brand_banner_margin_bottom_small_screen}px;

  @media (min-width: ${breakpoints.M}px) {
    flex-direction: row;
    margin-bottom: ${(props) => props.brand_banner_margin_bottom}px;
  }

  .brand-banner-container-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .brand-banner-image-container {
    position: relative;
    width: 100%;
    max-width: 100vw;
    max-height: calc(100vh - ${heights.HEADER_MOBILE}px);
    order: 1;

    @media (min-width: ${breakpoints.M}px) {
      order: ${(props) => (props.side_block_position === 'left' ? 2 : 1)};
      width: ${(props) =>
        props.isSideBlockPositionAbsolute || !props.isSideBlockActive
          ? '100%'
          : `${props.brand_banner_text_on_image_block_width_small_screen}%`};
      max-height: calc(100vh - ${heights.HEADER_TABLET}px);
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${(props) =>
        props.isSideBlockPositionAbsolute || !props.isSideBlockActive
          ? '100%'
          : `${props.brand_banner_text_on_image_block_width}%`};
      max-height: calc(100vh - ${heights.HEADER}px);
    }
  }

  .brand-banner-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .brand-banner-text-on-image {
    position: absolute;
    bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 24px;
    width: 100%;
    z-index: 2;

    span {
      font-family: ${(props) => props.text_on_image_font_family};
      font-size: ${(props) => props.text_on_image_font_size_small_screen}px;
      font-weight: ${(props) => props.text_on_image_font_weight};
      color: ${(props) => props.text_on_image_color};
      line-height: 35.2px;
    }

    @media (min-width: ${breakpoints.M}px) {
      left: ${(props) =>
        props.isSideBlockPositionAbsolute && props.side_block_position === 'left'
          ? `${100 - props.brand_banner_text_on_image_block_width_small_screen}%`
          : 0};
      padding: 40px;
      width: ${(props) =>
        props.isSideBlockPositionAbsolute
          ? `${props.brand_banner_text_on_image_block_width_small_screen}%`
          : '100%'};
    }

    @media (min-width: ${breakpoints.L}px) {
      left: ${(props) =>
        props.isSideBlockPositionAbsolute && props.side_block_position === 'left'
          ? `${100 - props.brand_banner_text_on_image_block_width}%`
          : 0};
      padding: 56px;
      width: ${(props) =>
        props.isSideBlockPositionAbsolute
          ? `${props.brand_banner_text_on_image_block_width}%`
          : '100%'};

      span {
        font-size: ${(props) => props.text_on_image_font_size}px;
        line-height: 52.8px;
      }
    }
  }

  .brand-banner-side-block {
    order: 2;
    display: ${(props) => (props.isSideBlockActive ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: ${(props) => props.side_block_background_color};
    padding: 32px;
    bottom: 0;

    @media (min-width: ${breakpoints.M}px) {
      order: ${(props) => (props.side_block_position === 'left' ? 1 : 2)};
      position: ${(props) => (props.isSideBlockPositionAbsolute ? 'absolute' : 'initial')};
      width: ${(props) => 100 - props.brand_banner_text_on_image_block_width_small_screen}%;
      min-height: 100%;
      max-height: 100%;
      padding: 20px 40px;
      left: ${(props) =>
        props.isSideBlockPositionAbsolute && props.side_block_position === 'right'
          ? `${props.brand_banner_text_on_image_block_width_small_screen}%`
          : 0};
    }

    @media (min-width: ${breakpoints.L}px) {
      left: ${(props) =>
        props.isSideBlockPositionAbsolute && props.side_block_position === 'right'
          ? `${props.brand_banner_text_on_image_block_width}%`
          : 0};
      width: ${(props) => 100 - props.brand_banner_text_on_image_block_width}%;
    }
  }

  .side-block-title {
    font-size: ${(props) => props.side_title_text_font_size_small_screen}px;
    font-weight: ${(props) => props.side_title_text_font_weight};
    font-family: ${(props) => props.side_title_text_font_family};
    line-height: calc(${(props) => props.side_title_text_font_size_small_screen}px + 6px);
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${(props) => props.side_title_text_color};
    @media (min-width: ${breakpoints.L}px) {
      font-size: ${(props) => props.side_title_text_font_size}px;
      line-height: calc(${(props) => props.side_title_text_font_size}px + 6px);
    }
  }

  .side-block-text {
    font-size: ${(props) => props.side_text_font_size_small_screen}px;
    font-family: ${(props) => props.side_text_font_family};
    line-height: calc(${(props) => props.side_text_font_size_small_screen}px + 6px);
    color: ${(props) => props.side_text_color};
    overflow: hidden;

    @media (min-width: ${breakpoints.L}px) {
      font-size: ${(props) => props.side_text_font_size}px;
      line-height: calc(${(props) => props.side_text_font_size}px + 6px);
    }
    a {
      color: ${(props) => props.side_text_color};
    }
  }

  .side-block-title,
  .side-block-text {
    z-index: 2;
  }
`;

export const BrandBanner = ({ cmsData, index }: Props) => {
  const {
    brand_banner_position_on_page,
    brand_banner_animation_active,
    brand_banner_margin_bottom,
    brand_banner_margin_bottom_small_screen,
    brand_banner_text_on_image_block_width,
    brand_banner_text_on_image_block_width_small_screen,
    brand_banner_image,
    brand_banner_image_mobile,
    brand_banner_container_link,
    brand_banner_container_link_aria_label,
    text_on_image,
    text_on_image_mobile,
    text_on_image_font_family,
    text_on_image_font_size,
    text_on_image_font_size_small_screen,
    text_on_image_font_weight,
    text_on_image_color,
    side_block_position,
    side_block_background_color,
    side_title_text,
    side_title_text_font_family,
    side_title_text_font_size,
    side_title_text_font_size_small_screen,
    side_title_text_font_weight,
    side_title_text_color,
    side_text,
    side_text_font_family,
    side_text_font_size,
    side_text_font_size_small_screen,
    side_text_color,
  } = cmsData?.primary ?? {};

  const containerRef = useRef<HTMLHeadingElement | null>(null);
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.M}px)`);
  const { isMobile } = useMediaQueries();
  const { data } = useTextReveal(
    isMobile && text_on_image_mobile ? text_on_image_mobile : text_on_image,
    brand_banner_animation_active ?? false,
    containerRef
  );

  const isSideBlockActive = Boolean(side_title_text ?? hasValidCmsText(side_text));
  const isSideBlockPositionAbsolute = !side_block_background_color && isSideBlockActive;
  const sideBlockBackgroundColor =
    !side_block_background_color && isSmallScreen ? colors.BLACK : side_block_background_color;
  const sideBlockTitleColor =
    (!sideBlockBackgroundColor && isSmallScreen) || !side_title_text_color
      ? colors.WHITE
      : side_title_text_color;
  const sideBlockTextColor =
    (!sideBlockBackgroundColor && isSmallScreen) || !side_text_color
      ? colors.WHITE
      : side_text_color;

  return (
    <StyledContainer
      id={`brand-banner-block-${index}`}
      brand_banner_position_on_page={getOurBrandBannerPosition(
        brand_banner_position_on_page ?? 'Not activated'
      )}
      brand_banner_margin_bottom={brand_banner_margin_bottom ?? 0}
      brand_banner_margin_bottom_small_screen={brand_banner_margin_bottom_small_screen ?? 0}
      brand_banner_text_on_image_block_width={brand_banner_text_on_image_block_width ?? 75}
      brand_banner_text_on_image_block_width_small_screen={
        brand_banner_text_on_image_block_width_small_screen ?? 66
      }
      isSideBlockPositionAbsolute={isSideBlockPositionAbsolute}
      text_on_image_font_family={text_on_image_font_family ?? 'Ogg-text'}
      text_on_image_font_size={text_on_image_font_size ?? 48}
      text_on_image_font_size_small_screen={text_on_image_font_size_small_screen ?? 32}
      text_on_image_font_weight={text_on_image_font_weight ?? 700}
      text_on_image_color={text_on_image_color ?? colors.WHITE}
      side_block_background_color={sideBlockBackgroundColor}
      side_title_text_font_size={side_title_text_font_size ?? 20}
      side_title_text_font_size_small_screen={side_title_text_font_size_small_screen ?? 20}
      side_title_text_font_weight={side_title_text_font_weight ?? 700}
      side_title_text_font_family={side_title_text_font_family ?? 'Ogg-text'}
      side_title_text_color={sideBlockTitleColor}
      side_text_font_size={side_text_font_size ?? 14}
      side_text_font_size_small_screen={side_text_font_size_small_screen ?? 14}
      side_text_color={sideBlockTextColor}
      side_text_font_family={side_text_font_family ?? 'MaisonNeue'}
      side_block_position={side_block_position ?? 'right'}
      isSideBlockActive={isSideBlockActive}
    >
      {brand_banner_container_link && (
        <Link
          className="brand-banner-container-link"
          data-testid={`brand-banner-link-wrapper-to-${brand_banner_container_link}`}
          to={brand_banner_container_link}
          aria-label={
            brand_banner_container_link_aria_label ?? `Lien vers ${brand_banner_container_link}`
          }
        />
      )}
      <div className="brand-banner-image-container">
        <img
          className="brand-banner-image"
          src={
            isMobile
              ? (brand_banner_image_mobile?.url ?? brand_banner_image?.url)
              : brand_banner_image?.url
          }
          alt={brand_banner_image?.alt ?? ''}
        />
        {text_on_image && getIsClient() && (
          <h2 ref={containerRef} className="brand-banner-text-on-image">
            {data}
          </h2>
        )}
      </div>
      <div className="brand-banner-side-block">
        {side_title_text && <h3 className="side-block-title">{side_title_text}</h3>}
        {hasValidCmsText(side_text) && (
          <div className="side-block-text">
            <RichText render={side_text} />
          </div>
        )}
      </div>
    </StyledContainer>
  );
};
