import * as React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import css from '@styled-system/css';

const dash = (offset: number) => keyframes`
    0% {
        stroke-dashoffset: ${offset};
    }
    50% {
        stroke-dashoffset: ${offset / 4};
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: ${offset};
        transform: rotate(450deg);
    }
`;

const rotator = () => keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
`;

type Props = {
  id?: string;
  size?: number;
  color?: string;
};

const Spinner = ({ id, size = 17, color = 'BLACK' }: Props) => (
  <StyledSpan {...{ id, size, color }}>
    <svg viewBox="0 0 66 66">
      <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span<Props>(({ size = 17, color = 'BLACK' }) =>
  css({
    'svg > circle': {
      fill: 'none',
    },
    'svg, svg > *': {
      fill: color,
      outline: 'none',
      size: `${size}px;`,
    },
    svg: {
      animation: ` ${rotator()} 1.6s linear infinite`,
    },
    circle: {
      animation: `${dash(245)} 1.6s ease-in-out infinite`,
      stroke: color,
      strokeDasharray: 245,
      strokeDashoffset: 0,
      transformOrigin: 'center',
    },
  })
);

export default Spinner;
