import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { getIsClient } from '../common/utils';
import types from './actionTypes';
import { createDataLayer } from './utils';
import { DataLayer, InitParams } from './types';

export const updateDataLayer = (payload: DataLayer) => action(types.UPDATE_DATA_LAYER, payload);

export type TrackingActions = ActionType<typeof updateDataLayer>;

export function initDataLayer({
  pathname,
  search,
  cartDetail,
  cartStep,
  isLoggedIn,
  userInfo,
  userDetail,
  product,
  catalog,
}: InitParams) {
  return async (dispatch: Dispatch<TrackingActions>) => {
    const isClient = getIsClient();
    if (!isClient) {
      return;
    }

    const {
      env_work,
      env_device,
      env_language,
      env_country,
      env_currency,
      page_id,
      page_name,
      page_template,
      page_error,
      page_category1,
      page_category2,
      page_category3,
      page_products,
      page_query,
      page_number,
      page_filters,
      page_sorting,
      page_size,
      user,
      cart,
      order,
    } = createDataLayer({
      pathname,
      search,
      cartDetail,
      cartStep,
      isLoggedIn,
      userInfo,
      userDetail,
      product,
      catalog,
    });

    dispatch(
      updateDataLayer({
        env_work,
        env_device,
        env_language,
        env_country,
        env_currency,
        page_id,
        page_name,
        page_template,
        page_error,
        page_category1,
        page_category2,
        page_category3,
        page_products,
        page_query,
        page_filters,
        page_sorting,
        page_number,
        page_size,
        user,
        cart,
        order,
      })
    );
  };
}
