export const CTA_CONTENT: [string, string, string] = ['Sauvegarder', '', ''];
export const MSG_SAVED = 'Vos modifications ont été sauvegardées.';
export const ERR_NOT_SAVED =
  'Vos modifications n’ont pas pu être sauvegardées. Merci de réessayer.';
export const ERR_TOO_LONG = (length: number): string =>
  `Ce champ ne peut contenir plus de ${length} caractères.`;
export const ERR_REQUIRED = 'Champ obligatoire.';
export const ERR_INVALID_FORMAT = 'Format invalide.';
export const ERR_INVALID_PASSWORD =
  'Votre mot de passe doit contenir au minimum 8 caractères et sans espaces';
export const ERR_INVALID_EMAIL = 'E-mail invalide';
export const ERR_PICKUP_PHONE_NUMBER =
  'Format invalide : ce numéro de téléphone doit commencer par 06 ou 07 et comporter dix chiffres';
export const ERR_INVALID_CHARACTERS =
  'Caractères spéciaux invalides. Veuillez saisir des caractères valides (a,e,é…)';
export const ERR_INVALID_CHARACTERS_FIRST_LAST_NAME = 'Veuillez saisir des lettres (a,e,é…)';
export const ERR_INVALID_FIRST_CHARACTER_FIRST_LAST_NAME =
  'Veuillez commencer par une lettre (a,e,é…)';
export const ERR_ONLY_NUMBER_ALLOWED = 'Veuillez uniquement renseigner des chiffres';
