import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { CmsSliderItem } from '../../cms/types';
import { zIndex } from '../../common/constants';
import { findItemToActivate } from '../utils';

const IMAGE_WIDTH = 132;

type Props = {
  items: CmsSliderItem[];
  slider_with_number_is_active_desktop: boolean | undefined;
  slider_with_number_is_active_mobile: boolean | undefined;
  slider_with_number_text_is_active: boolean | undefined;
};

type StyledContainerProps = {
  slider_with_number_is_active_desktop: boolean | undefined;
  slider_with_number_is_active_mobile: boolean | undefined;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: ${(props) => (props.slider_with_number_is_active_mobile ? 'flex' : 'none')};
  @media (min-width: ${breakpoints.S}px) {
    margin-top: 16px;
    display: ${(props) => (props.slider_with_number_is_active_desktop ? 'flex' : 'none')};
  }
`;

const StyledItemsWrapper = styled.ul`
  display: flex;
  max-width: 1400px;
  padding: 8px 16px;
  margin: 0 auto;
  gap: 8px;
  background: ${colors.WHITE};
  z-index: ${zIndex.STICKY};
  list-style: none;
  overflow-x: auto;

  @media (min-width: ${breakpoints.S}px) {
    padding: 8px 0;
  }

  .slider-with-number-image {
    width: ${IMAGE_WIDTH}px;
    border-radius: 8px;
  }
  .img-active {
    border: solid 1.5px ${colors.BLACK};
  }
`;

type StyledItemAnchorWrapperProps = {
  isClikable: boolean;
};

export const StyledItemAnchorWrapper = styled.a<StyledItemAnchorWrapperProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  pointer-events: ${(props) => (props.isClikable ? 'initial' : 'none')};
  transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
  @media (hover: hover) {
    :hover {
      opacity: ${opacities.HOVERED};
    }
  }
  .slider-with-number-image-container {
    position: relative;
  }
  .slider-with-number__text-on-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.WHITE};
    font-size: 2rem;
    font-family: 'Ogg-text';
    font-weight: 400;
    text-shadow: 0px 0px 6px ${colors.BLACK};
    @media (min-width: ${breakpoints.S}px) {
      font-weight: 700;
    }
  }
  .cms-slider__item-text {
    width: ${IMAGE_WIDTH}px;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 110%;
    @media (min-width: ${breakpoints.S}px) {
      font-weight: 700;
    }
  }
  .paragraph-active {
    font-weight: 700;
  }
`;

export const CmsSliderWithNumber = ({
  items,
  slider_with_number_is_active_desktop,
  slider_with_number_is_active_mobile,
  slider_with_number_text_is_active,
}: Props) => {
  const { pathname, hash } = useLocation();
  const refContainer = useRef<HTMLUListElement | null>(null);
  const refs = useRef<(HTMLAnchorElement | null)[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  let timer: NodeJS.Timeout;

  useEffect(() => {
    const scrollLeft = () => {
      if (refContainer?.current && refs?.current?.[activeIndex]) {
        const containerWidth = refContainer.current.getBoundingClientRect().width;
        const elementWidth = refs.current[activeIndex]?.getBoundingClientRect().width ?? 0;
        const targetLeft =
          (refs.current[activeIndex]?.offsetLeft ?? 0) + (elementWidth - containerWidth) / 2;
        refContainer.current.scrollTo({ behavior: 'smooth', left: targetLeft });
      }
    };

    // timeout 2s to allow vertical scroll on desktop before horizontal scroll if needed
    timer = setTimeout(() => scrollLeft(), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex, refContainer, refs]);

  useEffect(() => {
    if (hash) {
      findItemToActivate({ items, pathname, hash, setActiveIndex });
    } else {
      setActiveIndex(-1);
    }
  }, [hash, items, pathname]);

  return !items?.length ? null : (
    <StyledContainer
      id="catalog-slider-with-number-container"
      slider_with_number_is_active_desktop={slider_with_number_is_active_desktop}
      slider_with_number_is_active_mobile={slider_with_number_is_active_mobile}
    >
      <StyledItemsWrapper ref={refContainer}>
        {items.map((item: CmsSliderItem, index: number) => {
          return (
            <li key={`slider-with-number-${index}`}>
              <StyledItemAnchorWrapper
                data-cy={`catalog-slider-item-${index}-link`}
                href={item.slider_with_number_link?.url ?? ''}
                isClikable={Boolean(item.slider_with_number_link?.url)}
                ref={(el) => {
                  refs.current[index] = el;
                }}
              >
                <div className="slider-with-number-image-container">
                  <img
                    className={`slider-with-number-image ${
                      activeIndex === index ? 'img-active' : ''
                    }`}
                    src={item.slider_with_number_image?.normal?.url}
                    srcSet={`${item.slider_with_number_image?.retina?.url} 2x`}
                    alt={item.slider_with_number_image?.alt ?? `catalogue-slider-image-${index}`}
                  />
                  {item.slider_with_number_with_active_number_on_image && (
                    <span className="slider-with-number__text-on-image">{`n°${index + 1}`}</span>
                  )}
                </div>
                {item.slider_with_number_text && slider_with_number_text_is_active && (
                  <p
                    className={`cms-slider__item-text ${
                      activeIndex === index ? 'paragraph-active' : ''
                    }`}
                  >
                    {item.slider_with_number_text}
                  </p>
                )}
              </StyledItemAnchorWrapper>
            </li>
          );
        })}
      </StyledItemsWrapper>
    </StyledContainer>
  );
};
