import styled from '@emotion/styled';

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none';

type Props = {
  id?: string;
  src: string;
  alt: string;
  isAbsolute?: boolean;
  isLimited?: boolean;
  objectFitValue?: ObjectFit;
};

const Image = styled.img<Props>(
  ({ isAbsolute }) =>
    isAbsolute && {
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',
    },
  ({ isLimited }) =>
    isLimited && {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      margin: 'auto',
    },
  ({ objectFitValue }) =>
    objectFitValue && {
      objectFit: objectFitValue,
    }
);

export default Image;
