import * as React from 'react';
import styled from '@emotion/styled';
import Transition from 'react-transition-group/Transition';
import css from '@styled-system/css';
import { useLocation } from 'react-router-dom';

import { Box, colors, CloseButton } from '../../../design-system';
import withModal from '../hocs/withModal';
import { zIndex, transitions } from '../constants';
import { ModalPreset } from '../types';
import { transitionStylesBackground, Background } from './Sidebar';
import useBodyScrollLock from '../hooks/useBodyScrollLock';
import { useMediaQueries } from '../hooks/useMediaQuery';

export const transitionStylesContent = {
  entering: { opacity: 0, transform: 'translate(-50%, -50%) scale(0)' },
  entered: {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)',
  },
  exiting: { opacity: 0, transform: 'translate(-50%, -50%) scale(0)' },
  exited: { opacity: 0, transform: 'translate(-50%, -50%) scale(0)' },
};

type Props = {
  isOpen: boolean;
  background: boolean;
  content: React.ReactNode;
  preset: ModalPreset;
  closeModal: () => void;
  title?: string;
  onBackgroundClick?: () => void;
};

export const Modal = ({
  isOpen,
  background,
  content,
  preset,
  title,
  closeModal,
  onBackgroundClick,
}: Props) => {
  const { isTablet } = useMediaQueries();

  useBodyScrollLock({ isActive: isOpen, excludeId: 'modal-content' });

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (isOpen) {
      closeModal();
    }
  }, [pathname]);

  const handleBackgroundClick = () => {
    if (onBackgroundClick) {
      onBackgroundClick();
    }
    closeModal();
  };

  return (
    <>
      <Transition in={isOpen && background} mountOnEnter unmountOnExit timeout={0}>
        {(state) => (
          <Background
            onClick={handleBackgroundClick}
            style={{
              ...transitionStylesBackground[state],
            }}
          />
        )}
      </Transition>
      <Transition in={isOpen} mountOnEnter unmountOnExit timeout={0}>
        {(state) => (
          <ModalContent
            id="modal-content"
            preset={preset}
            isTablet={isTablet}
            style={{
              ...transitionStylesContent[state],
            }}
          >
            <Box
              m={title ? (preset === 'l' ? 'xl' : 'm') : 'na'}
              className="modal-content__wrapper"
            >
              {title && (
                <Box display="flex" alignItems="center" justifyContent="space-between" mb="m">
                  <Box fontSize="2rem" lineHeight="110%" fontWeight={700}>
                    {title}
                  </Box>
                  <CloseButton id="modal-close-button" onClick={closeModal} />
                </Box>
              )}
              {content}
            </Box>
          </ModalContent>
        )}
      </Transition>
    </>
  );
};

type ModalContentProps = {
  preset: ModalPreset;
  isPopup?: boolean;
  isTablet?: boolean;
};

export const ModalContent = styled.div<ModalContentProps>`
  position: fixed;
  left: 50%;
  top: 50%;
  overflow-y: auto;
  z-index: ${zIndex.MODAL};
  background: ${colors.WHITE};
  transition: ${transitions.MODAL};
  border-radius: 8px;

  .modal-content__wrapper {
    ${({ preset }) =>
      preset &&
      css({
        height: preset === 'sizeGuide' && 'inherit',
      })};
  }
  ${({ preset, isTablet }) =>
    preset &&
    css({
      maxWidth: '885px',
      width:
        (preset === 'l' || preset === 'large' || preset === 'medium') && isTablet
          ? '95%'
          : (preset === 'l' || preset === 'large' || preset === 'medium') && !isTablet
            ? ['100%', '885px']
            : preset === 'eresaModal' && isTablet
              ? '98%'
              : preset === 'eresaModal' && !isTablet
                ? ['100%', '888px']
                : preset === 'eresaModalResults' && isTablet
                  ? '80%'
                  : preset === 'eresaModalResults' && !isTablet
                    ? ['100%', '600px']
                    : preset === 'nameValidationWarning'
                      ? ['95%', '60%', '370px']
                      : preset === 'small'
                        ? ['95%', '95%', '800px']
                        : preset === 'feedback' || preset === 'feedbackWithoutCrossSell'
                          ? ['100%', '95%', '770px']
                          : preset === 'paypalECS'
                            ? ['95%', '95%', '600px']
                            : preset === 'paymentError'
                              ? ['80%', '70%', 'auto']
                              : preset === 'sizeGuide'
                                ? ['100%', '95%', '885px']
                                : ['95%', '95%', '780px'],

      maxHeight:
        preset === 'l' || preset === 'large'
          ? ['100%', '100%', '885px']
          : preset === 'eresaModal' && isTablet
            ? '622px'
            : preset === 'eresaModal' && !isTablet
              ? ['100%', '622px']
              : preset === 'eresaModalResults' && isTablet
                ? 'auto'
                : preset === 'eresaModalResults' && !isTablet
                  ? ['100%', '412px']
                  : preset === 'sizeGuide'
                    ? ['100%', '780px']
                    : ['95%', '95%', '780px'],
      height:
        preset === 'l' && isTablet
          ? 'auto'
          : preset === 'l' && !isTablet
            ? ['100%', 'auto']
            : preset === 'small' || preset === 'medium'
              ? ['na', '547px', '547px']
              : preset === 'eresaModal' && isTablet
                ? '622px'
                : preset === 'eresaModal' && !isTablet
                  ? ['100%', '622px']
                  : preset === 'eresaModalResults' && isTablet
                    ? 'auto'
                    : preset === 'eresaModalResults' && !isTablet
                      ? ['100%', '412px']
                      : preset === 'large'
                        ? ['na', '587px', '587px']
                        : preset === 'feedback'
                          ? ['na', '656px', '710px']
                          : preset === 'feedbackWithoutCrossSell'
                            ? '286px'
                            : preset === 'paypalECS'
                              ? ['506px', '430px', '430px']
                              : preset === 'paymentError'
                                ? 'auto'
                                : preset === 'sizeGuide'
                                  ? ['100%', '680px']
                                  : 'auto',
      overflow: preset === 'feedback' ? 'hidden' : 'auto',
    })}

  ${({ isPopup }) =>
    isPopup &&
    css`
      z-index: ${zIndex.POPUP};
    `}
`;

export default withModal(Modal);
