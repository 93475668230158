import { useState, useEffect } from 'react';
import { breakpoints } from '../../../design-system/systemprovider/constants';

export const useMediaQueries = () => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.S - 1}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.M - 1}px)`
  );
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.M}px)`);
  const isLargeDesktop = useMediaQuery(`(min-width: ${breakpoints.L}px)`);
  return { isMobile, isTablet, isDesktop, isLargeDesktop };
};

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};
