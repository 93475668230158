import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from 'src/shared/store/rootReducer';
import { Breadcrumbs, breakpoints } from '../../../design-system';
import { Results } from './Results';
import { getCatalogId, getCatalogName } from '../utils';
import { BreadcrumbsItems } from '../types';
import { getBreadcrumbItems } from '../utils';
import { CustomHierarchicalMenu } from './HierarchicalMenu';
import { cmsUspInit } from '../../cms/actions';
import { CmsBlock } from './CmsBlock';
import { CatalogFaq } from './CatalogFaq';
import { CatalogTestimonial } from './CatalogTestimonial';
import UspSection from '../../home/components/UspSection';

type TParams = {
  lvl0: string;
  lvl1: string;
  lvl2: string;
  lvl3: string;
};

const StyledCatalogContainer = styled.div<{
  catalog_usp_is_active_desktop: boolean;
  catalog_usp_is_active_mobile: boolean;
}>`
  overflow: inherit;

  @media (min-width: ${breakpoints.S}px) {
    margin: 0 24px;
  }

  .catalog-breadcrumb-block {
    margin: 8px 16px 0 16px;

    @media (min-width: ${breakpoints.S}px) {
      margin: 8px 0;
    }
  }

  .catalog-usp-section {
    display: ${(props) => (props.catalog_usp_is_active_mobile ? 'block' : 'none')};

    @media (min-width: ${breakpoints.S}px) {
      display: ${(props) => (props.catalog_usp_is_active_desktop ? 'block' : 'none')};
    }
  }
`;

export const Catalog = () => {
  const { lvl0, lvl1, lvl2, lvl3 } = useParams() as TParams;

  useEffect(() => {
    if (!usp?.length) {
      dispatch(cmsUspInit() as unknown as UnknownAction);
    }
  }, []);

  const dispatch = useDispatch();
  const { usp, usp_main_title } = useSelector((state: RootState) => state.cms.usp);
  const { catalog_usp_is_active_desktop, catalog_usp_is_active_mobile } =
    useSelector((state: RootState) => state.cms.catalog) ?? {};
  const uid = getCatalogId([lvl0, lvl1, lvl2, lvl3]);
  const name = getCatalogName([lvl0, lvl1, lvl2, lvl3]);
  const categoryLevels = [lvl0, lvl1, lvl2, lvl3].filter(Boolean);
  const breadcrumbItems: BreadcrumbsItems = getBreadcrumbItems(categoryLevels);
  const uspIsActive = catalog_usp_is_active_desktop || catalog_usp_is_active_mobile;

  if (breadcrumbItems.length > 0) {
    breadcrumbItems[breadcrumbItems.length - 1].readonly = true;
  }

  return (
    <StyledCatalogContainer
      catalog_usp_is_active_desktop={catalog_usp_is_active_desktop ?? false}
      catalog_usp_is_active_mobile={catalog_usp_is_active_mobile ?? false}
    >
      <div className="catalog-breadcrumb-block">
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <CustomHierarchicalMenu />
      <CmsBlock uid={uid} name={name} />
      <Results />
      <CatalogTestimonial />
      <CatalogFaq />
      {uspIsActive && usp?.length && (
        <div className="catalog-usp-section">
          <UspSection content={usp} title={usp_main_title ?? ''} />
        </div>
      )}
    </StyledCatalogContainer>
  );
};
