import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { getProductLink } from '../../product/utils';
import { Color as ColorType } from '../../product/types';
import { Color } from './Color';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import paths from '../../routing/paths';

type Props = {
  productRef: string;
  productName: string;
  colorVariants: ColorType[];
  objectID: string;
  selectedColor: ColorType;
  isDesktopFeedback?: boolean;
  isFeedBack?: boolean;
  reverseToolTip: boolean;
  leftAlignToolTip: boolean;
  handleColorClick: (color: ColorType) => void;
  onLinkClick: () => void;
};

const StyledContainer = styled.div<{ isFeedBack: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: ${(props) => (props.isFeedBack ? '12px' : '16px')};
  height: 16px;

  a {
    text-decoration: none;
    font-size: 1.4rem;
  }
`;

export const ColorPanel = ({
  productRef,
  productName,
  colorVariants = [],
  selectedColor,
  objectID,
  isDesktopFeedback,
  isFeedBack = false,
  reverseToolTip,
  leftAlignToolTip,
  handleColorClick,
  onLinkClick,
}: Props) => {
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const { pathname } = useLocation();
  const selectedColorItem = colorVariants.find(
    (color) => color.colorRef === selectedColor.colorRef
  );
  const colorsWithSelectedFirst = selectedColorItem
    ? [
        selectedColorItem,
        ...colorVariants.filter((color) => color.colorRef !== selectedColor.colorRef),
      ]
    : colorVariants;
  const [numberOfColorsShown, setNumberOfColorsShown] = useState(4);
  const numberOfColors = colorVariants.length;

  const [sortedColors] = useState(colorsWithSelectedFirst);

  const productLink = getProductLink({
    productRef,
    productName,
    colorLabel: selectedColor.colorLabel,
    colorRef: selectedColor.colorRef,
  });

  useEffect(() => {
    if (isFeedBack || isDesktopFeedback) {
      setNumberOfColorsShown(3);
    } else if (pathname.includes(paths.SEARCH_RESULTS) || isMobile || isTablet) {
      setNumberOfColorsShown(4);
    } else {
      setNumberOfColorsShown(5);
    }
  }, [isMobile, isTablet, isDesktop, pathname]);

  return (
    <StyledContainer id={`catalog-item-color-panel-${objectID}`} isFeedBack={isFeedBack}>
      {sortedColors?.slice(0, numberOfColorsShown).map((color, index) => {
        const isSelected = color.colorRef === selectedColor.colorRef;
        return (
          <Color
            key={`${productRef}-${color.colorRef}-${index}`}
            productRef={productRef}
            isSelected={isSelected}
            color={color}
            handleColorClick={handleColorClick}
            reverseToolTip={reverseToolTip}
            leftAlignToolTip={leftAlignToolTip}
          />
        );
      })}
      {numberOfColors > numberOfColorsShown && (
        <Link
          data-testid={`color-panel-more-colors-link-to-pdp-${objectID}`}
          id={`color-panel-more-colors-link-to-pdp-${objectID}`}
          to={productLink}
          onClick={onLinkClick}
          title="Voir + de couleurs"
        >
          {`+${numberOfColors - numberOfColorsShown}`}
        </Link>
      )}
    </StyledContainer>
  );
};
