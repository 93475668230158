import styled from '@emotion/styled';
import css from '@styled-system/css';
import { colors } from '../systemprovider';

type StyledFieldProps = {
  areaHeight?: string;
  isAffiliatePage?: boolean;
};

export const StyledField = styled.div<StyledFieldProps>(
  css({
    '@media(hover: hover)': {
      ':hover': {
        background: colors.LIGHT2,
      },
    },
    position: 'relative',
    alignItems: 'center',
    display: 'inline-flex',
    maxWidth: 'calc(100vw - 16px)',
    minWidth: '96px',
    height: '200px',
    width: '100%',
    background: colors.BACKGROUND,
  }),
  ({ isAffiliatePage }) =>
    isAffiliatePage &&
    css({
      ':hover': {
        bg: '#F9F9F9',
      },
      width: ['343px', '688px', '704px'],
    }),
  ({ areaHeight }) => ({
    height: areaHeight,
  })
);

export const StyledLabel = styled.label({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '16px 8px',
  color: colors.GREY2,
  fontSize: '14px',
  whiteSpace: 'nowrap',
  transition: 'all 0.1s ease-in-out',
});

type StyledInputProps = {
  id: string;
  value: string;
  placeholder?: string;
  hasError?: boolean;
  isAffiliatePage?: boolean;
};

export const StyledInput = styled.textarea<StyledInputProps>(
  {
    ':focus': {
      outline: 'none',
      borderBottom: `2px solid ${colors.LIGHT};`,
    },
    '::placeholder': {
      fontSize: '16px',
    },
    ':focus, :-webkit-autofill': {
      paddingTop: '16px',
      '~ label': {
        fontSize: '12px',
        padding: '8px',
      },
    },
    resize: 'none',
    border: 'none',
    color: colors.GREY,
    fontSize: '16px',
    height: '100%',
    width: '100%',
    background: 'inherit',
    marginTop: '16px',
    paddingLeft: '8px',
  },
  ({ isAffiliatePage }) =>
    isAffiliatePage && {
      lineHeight: '130%',
      alignSelf: 'end',
      height: '85%',
    },
  ({ hasError }) =>
    hasError && {
      borderBottom: `2px solid ${colors.ERROR};`,
      ':focus': {
        borderBottom: `2px solid ${colors.ERROR};`,
      },
    },
  ({ value, placeholder }) =>
    (value || placeholder) && {
      paddingTop: '16px',
      '~ label': {
        fontSize: '12px',
        padding: '8px',
      },
    }
);
