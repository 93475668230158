import * as React from 'react';
import { durations } from '../../common/constants';

const useGoogleMapsAutocomplete = (
  sessionToken,
  autocomplete,
  input
): {
  description: string;
  placeId: string;
}[] => {
  const [predictions, setPredictions] = React.useState<{ description: string; placeId: string }[]>(
    []
  );
  const [debouncedSetState, setDebouncedSetState] = React.useState(null as any);

  React.useEffect(() => {
    if (debouncedSetState) {
      clearTimeout(debouncedSetState);
    }

    setDebouncedSetState(
      setTimeout(() => {
        getPlacePredictions(input);
      }, durations.MAP_SUGGESTIONS_DELAY)
    );
  }, [input]);

  const getPlacePredictions = (input) => {
    if (input.length && autocomplete) {
      autocomplete.getPlacePredictions(
        { input, sessionToken, componentRestrictions: { country: 'fr' } },
        (predictions) => {
          setPredictions(predictions ?? []);
        }
      );
    }
  };

  return predictions;
};

export default useGoogleMapsAutocomplete;
