import { State } from './types';

export const authState: State = {
  user: undefined,
  isLoggedIn: false,
  errLogin: '',
  errSignup: '',
  errSocial: '',
  redirectTo: '',
  userExist: null,
  hasForgotPassword: false,
};
