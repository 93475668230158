import React, { useRef } from 'react';
import { css } from '@emotion/core';
import { RichText } from 'prismic-reactjs';
import Transition from 'react-transition-group/Transition';

import { breakpoints, colors, Icon, opacities } from '../../../design-system';
import { heights, durations } from '../../common/constants';
import { CmsFaqItem } from '../../cms/types';

const transitionStylesContent = {
  entering: { opacity: 0, maxHeight: 0 },
  entered: {
    opacity: 1,
    maxHeight: `${heights.CART_FAQ}px`,
    transition: `max-height ${durations.ENTER}ms ease-in, opacity ${durations.ENTER}ms ease-in`,
  },
  exiting: {
    opacity: 0,
    maxHeight: 0,
  },
  exited: { opacity: 0, maxHeight: 0 },
};

type Props = {
  questionBlockStates: boolean[];
  setQuestionBlockStates: (value: boolean[]) => void;
  questionBlockRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
  index: number;
  item: CmsFaqItem;
  cart_faq_divider_color: string;
  cart_faq_question_color: string;
  cart_faq_answer_color: string;
};

export const CartFaqItem = ({
  index,
  item,
  cart_faq_divider_color,
  questionBlockStates,
  setQuestionBlockStates,
  questionBlockRefs,
  cart_faq_question_color,
  cart_faq_answer_color,
}: Props) => {
  let scrollTimeout: NodeJS.Timeout;

  const ref = useRef<HTMLDivElement>(null);
  const isQuestionBlockOpen = questionBlockStates[index];

  React.useEffect(() => {
    questionBlockRefs.current[index] = ref;
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, []);

  const toggleQuestionBlock = (index: number) => {
    clearTimeout(scrollTimeout);
    const updatedStates = [...questionBlockStates];
    updatedStates[index] = !updatedStates[index];
    setQuestionBlockStates(updatedStates);
    if (updatedStates[index]) {
      const ref = questionBlockRefs.current[index];
      scrollTimeout = setTimeout(
        () => ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        durations.ENTER
      );
    }
  };

  return (
    <div
      css={css`
        border-bottom: solid 1px ${cart_faq_divider_color ?? colors.BLACK};
      `}
      ref={ref}
    >
      <h3 id={`cart-faq-question-${index}`} style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
        <button
          id={`cart-faq-button-${index}`}
          data-testid={`cart-faq-button-${index}-click-to-${isQuestionBlockOpen ? 'close' : 'open'}`}
          type="button"
          onClick={() => toggleQuestionBlock(index)}
          css={css`
            padding-left: inherit;
            padding-right: inherit;
            background: inherit;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            min-height: 56px;
            font-size: 1.4rem;
            line-height: 110%;
            transition: opacity ${durations.ENTER}ms ease-in-out;
            color: ${cart_faq_question_color ?? colors.BLACK};
            &:hover {
              opacity: ${opacities.HOVERED};
            }
          `}
        >
          {item.cart_faq_question}
          <div
            css={css`
              padding-left: 8px;
              @media (min-width: ${breakpoints.S}px) {
                padding-left: 16px;
              }
            `}
          >
            <Icon name={isQuestionBlockOpen ? 'minus' : 'plus'} size={12} />
          </div>
        </button>
      </h3>
      <Transition
        in={isQuestionBlockOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: 0 }}
      >
        {(state) => (
          <div
            id={`cart-faq-answer-${index}`}
            css={css`
              display: flex;
              flex-wrap: wrap;
              max-width: 100%;
              color: ${cart_faq_answer_color ?? colors.BLACK};

              @media (min-width: ${breakpoints.S}px) {
                max-width: 90%;
              }
            `}
            style={{ ...transitionStylesContent[state] }}
          >
            <RichText render={item.cart_faq_answer} />
          </div>
        )}
      </Transition>
    </div>
  );
};
