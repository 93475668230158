import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Icon from '../../icon';
import Box from '../../box';

type Props = {
  id: string;
  iconName: string;
  onClick: () => void;
  totalItems: number;
  activeIndex: number;
  numberOfItemsShown: number;
  direction: Direction;
};
export enum Direction {
  LEFT = 'left',
  RIGHT = 'right',
}
export function ArrowButton({
  id,
  iconName,
  onClick,
  totalItems,
  activeIndex,
  numberOfItemsShown,
  direction,
}: Props) {
  return (
    <StyledArrowButton
      id={id}
      alignItems="center"
      justifyContent="center"
      width="54px"
      height="54px"
      bg="rgba(255, 255, 255, 0.5)"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
      borderRadius="50%"
      onClick={onClick}
      display={
        direction === Direction.LEFT && activeIndex === 0
          ? 'none'
          : direction === Direction.LEFT && activeIndex !== 0
          ? 'flex'
          : totalItems <= numberOfItemsShown || activeIndex + numberOfItemsShown === totalItems
          ? 'none'
          : 'flex'
      }
      transition="all .25s ease-in-out"
    >
      <Icon name={iconName} />
    </StyledArrowButton>
  );
}
const StyledArrowButton = styled(Box)(
  css({
    '@media(hover: hover)': {
      ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    },
  })
);
