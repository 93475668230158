import * as React from 'react';

import { Box, Stars } from '../../../design-system';

type Props = {
  recommendationPercent: number;
  ratingAverage: string;
  ratingCount: number;
};

const Recommendations = ({ recommendationPercent, ratingAverage, ratingCount }: Props) => {
  const hasMoreThanOneReview = ratingCount > 1 ? 's' : '';
  return (
    <div>
      <Box
        p="m"
        fontSize="3rem"
        lineHeight="110%"
        fontWeight={700}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bg="#F9F9F9"
      >
        {Number.parseFloat(ratingAverage)}/5
        <Stars
          rating={Number.parseFloat(ratingAverage)}
          size={18}
          identifier="reviews-header-stars"
        />
        <Box
          width="100%"
          fontSize="1.2rem"
          fontWeight={400}
          lineHeight="110%"
          color="#000"
          textAlign="center"
          mt="m"
        >
          Basée sur {ratingCount} commentaire{hasMoreThanOneReview} vérifié{hasMoreThanOneReview}.
        </Box>
        <Box
          width="100%"
          fontSize="1.2rem"
          fontWeight={400}
          lineHeight="110%"
          color="#5A5A5A"
          textAlign="center"
          mt="s"
        >
          {recommendationPercent}% de nos clients recommandent ce produit.
        </Box>
      </Box>
    </div>
  );
};

export default Recommendations;
