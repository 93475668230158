import React, { useEffect } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Box, Text, Image, ImageContainer } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { CmsCustomTypes } from '../../cms/types';
import { loadCmsContent } from '../../cms/actions';
import { emptyText } from '../../cms/state';
import { getCanonicalUrl } from '../../common/utils';
import paths from '../paths';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

export const NotFound = () => {
  const dispatch = useDispatch();

  const cmsContent = useSelector((state: RootState) => state.cms.notfound) ?? {};
  const { title, subtitle, body, items } = cmsContent;

  useEffect(() => {
    if (!title) {
      dispatch(loadCmsContent(CmsCustomTypes.notfound) as unknown as UnknownAction);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>404 Not Found | Darjeeling</title>
        <link rel="canonical" href={getCanonicalUrl(paths.NOT_FOUND)} />
      </Helmet>
      <Box textAlign="center" m={['m', 'l', 'xl']} display="grid" gridGap="l">
        <Text preset="heading">{title}</Text>
        <Box>
          <RichText render={body || [...emptyText]} />
        </Box>
        <Text preset="heading">{subtitle}</Text>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridGap="m"
          overflow="auto"
          gridAutoColumns={['70vw', '1fr']}
          mr={['-16px', 'na']}
        >
          <Box display={['none', 'none', 'block']}>&nbsp;</Box>
          {(items ?? []).map(({ text, link, image }, index) => (
            <Box key={index} display="grid" gridGap="m">
              <Link
                data-testid={`link-404-recommended-cat-img-${index}`}
                id={`link-404-recommended-cat-img-${index}`}
                to={getFormatedLinkForReactRouterLink(link)}
              >
                <ImageContainer preset="portrait">
                  <Image src={image?.url ?? ''} isAbsolute alt="Recommended Category" />
                </ImageContainer>
              </Link>
              {text && (
                <Box height="xl" fontSize="1.4rem">
                  <Link
                    className="anchor-animated"
                    data-testid={`link-404-recommended-cat-${index}`}
                    id={`link-404-recommended-cat-${index}`}
                    to={getFormatedLinkForReactRouterLink(link)}
                  >
                    {text}
                  </Link>
                </Box>
              )}
            </Box>
          ))}
          <Box display={['none', 'none', 'block']}>&nbsp;</Box>
        </Box>
      </Box>
    </>
  );
};
