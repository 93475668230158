import React, { useRef } from 'react';

import { RecommendationsHeader } from './RecommendationsHeader';
import { RecommendationsContent } from './RecommendationsContent';
import { Product } from '../../product/types';
import { RecoBlockIdTypes, RecommendationTracking } from '../types';

type Props = {
  id: RecoBlockIdTypes;
  title?: string;
  hits: Product[];
  tracking?: RecommendationTracking;
};

export const RecommendationsBlock = ({ id, title, hits, tracking }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <RecommendationsHeader id={id} title={title} hits={hits} containerRef={containerRef} />
      <RecommendationsContent id={id} hits={hits} tracking={tracking} containerRef={containerRef} />
    </>
  );
};
