export type State = {
  pickupStations: PickupStation[];
  totalCount: number;
  token: string;
  errMsg: string;
  isFetching: boolean;
  geoCoordinate?: StationCoordinate;
};

export type Suggestion = {
  name: string;
};

export type PickupStation = {
  id: string;
  name: string;
  line1: string;
  line2: string;
  line3: string;
  postalCode: string;
  city: string;
  accessForPRM: boolean;
  parking: boolean;
  exceptionalClosings: ExceptionalClosings[];
  displayCoordinate: StationCoordinate;
  hours: StationHours;
};

export type ExceptionalClosings = {
  start: number;
  end: number;
};

export type StationHours = {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
};

export type OpeningHours = {
  day: string;
  endDay?: string;
  openingHours: string;
};

export type StationCoordinate = {
  latitude: number;
  longitude: number;
};

export type SearchAddress = {
  address: string;
  postalCode: string;
  city: string;
  countryCode: string;
};

export enum PickupModalMobileSteps {
  LISTING,
  MAP,
}
