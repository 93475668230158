import { RangeBoundaries } from 'instantsearch.js/es/connectors/range/connectRange';
import React, { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import Rheostat from 'rheostat';

import { Box, Text } from '../../../design-system';

type Props = { attribute: string };

export const PriceFilter: React.FC<Props> = ({ attribute }) => {
  const { start, range, refine } = useRange({ attribute });

  const [currentRange, setCurrentRange] = useState<RangeBoundaries>([
    start[0] !== -Infinity ? start[0] : range.min,
    start[1] !== Infinity ? start[1] : range.max,
  ]);

  useEffect(() => {
    setCurrentRange([
      start[0] !== -Infinity ? start[0] : range.min,
      start[1] !== Infinity ? start[1] : range.max,
    ]);
  }, [range]);

  if (range.min === range.max) {
    return null;
  }

  return (
    <Box p="m" mb="m">
      <Box display="flex" justifyContent="center" my="m">
        <Text id="price-range">{currentRange.join(' - ')} EUR</Text>
      </Box>
      {range.min !== range.max && (
        <Rheostat
          min={range.min}
          max={range.max}
          values={currentRange}
          onValuesUpdated={({ values }) => {
            setCurrentRange(values);
          }}
          onSliderDragEnd={() => {
            refine(currentRange);
          }}
        />
      )}
    </Box>
  );
};
