export const ERR_AUTH = 'Une erreur est survenue';
export const LINK_NEW_EMAIL = 'Cliquez ici pour recevoir un nouvel e-mail';
export const ERR_CODE_EXPIRED = 'Le lien de l’e-mail a expiré';
export const ERR_CODE_MISMATCH = 'Le code de vérification est incorrect';
export const MESSAGE_EMAIL_SENT = 'Un e-mail vous a été envoyé';
export const SENDING_A_NEW_EMAIL = 'Envoi d’un nouvel e-mail';
export const ATTEMPT_LIMIT_EXCEEDED =
  'Vous avez atteint la limite de tentatives, veuillez réessayer après un certain temps.';
export const ERR_EMAIL_NOT_FOUND = 'Ce compte n’existe pas';
export const TITLE_SUBMIT_PASSWORD = 'Nouveau mot de passe';
export const FORGOT_EMAIL_TEXT = 'Mot de passe oublié ?';
export const FORGOT_EMAIL_MESSAGE_1 = 'Vérifiez l’adresse mail associée à votre compte.';
export const FORGOT_EMAIL_MESSAGE_2 =
  'Ce bouton va générer un e-mail contenant un lien de réinitialisation de votre mot de passe.';
export const NOTE_PASSWORD =
  'Votre mot de passe doit contenir au minimum 8 caractères et sans espaces';
export const ERR_USER_NOT_FOUND =
  'Cet e-mail n’existe pas. Vous pouvez utiliser cet e-mail pour créer votre compte Darjeeling.';
export const ERR_NOT_AUTHORIZED =
  'Le mot de passe ne correspond pas avec l’e-mail. Vous pouvez demander un nouveau mot de passe ou créer un nouveau compte.';
export const ERR_USER_EXISTS =
  'Cet e-mail existe déjà. Vous pouvez utiliser un nouvel e-mail pour créer votre compte ou demander un nouveau mot de passe.';
export const ERR_ADDRESS_NOT_FOUND = 'Cette adresse e-mail n’existe pas.';
export const ERR_PASSWORD = 'Mot de passe incorrect.';
export const ERR_PARAMS = 'Paramètre invalide.';
export const SUCCESS_EMAIL_SENT =
  'Vérifiez votre boîte mail et cliquez sur le lien de réinitialisation.';
export const ERR_ACCOUNT_CREATION =
  'Nous n’avons pas réussi à finaliser la création de votre compte. Veuillez contacter le service client en cliquant ';
export const ERR_EMAIL_NOT_SEND = "Nous n'avons pas réussi à envoyer l'e-mail de réinitialisation.";
export const CTA_CONTENT_SIGNIN_EMAIL: [string, string, string] = ['Suivant', '', ERR_AUTH];
export const CTA_CONTENT_SIGNIN: [string, string, string] = ['Connexion', 'Connecté', ERR_AUTH];
export const CTA_CONTENT_SIGNUP: [string, string, string] = [
  'Créer un compte',
  'Connecté',
  ERR_AUTH,
];
export const CTA_CONTENT_FORGOT_PASSWORD: [string, string, string] = [
  'Envoyer le lien de réinitialisation',
  MESSAGE_EMAIL_SENT,
  ERR_AUTH,
];
export const CTA_CONTENT_SUBMIT_PASSWORD: [string, string, string] = [
  'Confirmer le mot de passe',
  'Votre mot de passe a été modifié',
  ERR_AUTH,
];

export const AUTH_BAR_MAIN_TITLE = 'Compte';
export const AUTH_BAR_TITLE = 'Connectez-vous ou inscrivez-vous';
export const AUTH_BAR_SIGNIN_TITLE = 'Connexion';
export const AUTH_BAR_SIGNUP_TITLE = 'Inscription';
export const AUTH_BAR_FORGOT_PASSWORD_TITLE = 'Réinitiliser votre mot de passe';
export const MSG_NEWSLETTER_CHECKBOX =
  'Oui, je souhaite recevoir par mail les actualités et les offres personnalisées Darjeeling';
export const MSG_ONGOING_LOGIN = 'Connexion en cours… Patientez SVP';
export const MSG_STUCK_LOGIN = 'Connexion en cours… Patientez ou actualisez la page SVP';

export const PREFILL_HASH = '#prefill';
export const MSG_PASSWORD_UPDATED = 'Votre mot de passe est mis à jour';
export const MSG_CONNECT_WITH_NEW_CREDENTIALS = 'Connectez-vous avec vos nouveaux identifiants.';
export const MSG_MODIFY = 'Modifier';
export const MSG_HERE = 'ici';
export const MSG_ERROR = 'Erreur';
export const MSG_OR_SUBSCRIBE_WITH = 'ou inscrivez-vous avec';
export const MSG_EMAIL_SEND = 'E-mail envoyé';
export const MSG_SEND = 'Envoi';
