import React from 'react';
import Box from '../box';
type Props = {
  progressionPercentage: number;
};

export const ProgressBar = ({ progressionPercentage }: Props) => {
  return (
    <Box id="progress-bar-block" width="100%" display="flex">
      <Box width={`${progressionPercentage}%`} height="s" bg="#000" />
      <Box width={`${100 - progressionPercentage}%`} height="s" bg="#E6E6E6" />
    </Box>
  );
};
