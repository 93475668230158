import * as React from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { getIsClient } from '../utils';

export default function useScript({
  id,
  src,
  position,
}: {
  id: string;
  src: string;
  position?: 'head' | 'body';
}) {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    const isClient = getIsClient();
    if (!isClient) {
      return () => {};
    }

    const hasAlreadyLoaded = Boolean(document.getElementById(id));
    if (hasAlreadyLoaded) {
      setHasLoaded(true);
      return () => {};
    }

    let script: HTMLScriptElement | HTMLLinkElement;
    const extension = src.split('.').pop();

    if (extension === 'css') {
      script = document.createElement('link');
      script.rel = 'stylesheet';
      script.href = src;
    } else {
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
    }
    script.id = id;

    const onScriptLoad = () => {
      setHasLoaded(true);
    };

    const onScriptError = () => {
      script.remove();
      setHasLoaded(false);
      const err = new Error();
      err.name = 'Script Loading Error';
      err.message = `error loading ${
        extension === 'css' ? (script as HTMLLinkElement).href : (script as HTMLScriptElement).src
      }`;
      datadogLogs.logger.error(err.name, err);
    };

    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    if (position === 'head') {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src]);

  return hasLoaded;
}
