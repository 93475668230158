import { Wishlist, State } from './types';

export const emptyWishlist: Wishlist = {
  wishlistId: '',
  items: [],
};

export const wishlistState: State = {
  wishlist: { ...emptyWishlist },
  errMsg: '',
  isFetching: true,
};
