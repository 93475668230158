import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import { Box, Button, Text, Picture, opacities, colors } from '../../../design-system';
import { transitions, zIndex } from '../constants';

type TransitionProps = {
  isOpen: boolean;
};

export const SecondDrawer = styled(Box)<TransitionProps>(
  css({
    px: ['m', 'l'],
    bg: 'WHITE',
  }),
  {
    height: '100%',
    width: '100%',
    top: 0,
    position: 'fixed',
    overflow: 'auto',
    right: '-100vw',
    transition: transitions.GENERIC,
  },
  ({ isOpen }) =>
    isOpen && {
      right: 0,
    }
);

export const LineThroughText = styled(Text)({
  textDecoration: 'line-through',
  opacity: opacities.DISABLED,
});

type HorizontalSliderProps = {
  activeIndex: number;
  length: number;
  width: string;
  gridGap: string;
};

export const HorizontalSlider = styled(Box)<HorizontalSliderProps>(
  ({ activeIndex, length, width, gridGap }) => ({
    transform: `translate3d(calc(-1 * ${activeIndex} * (${width} + ${gridGap})), 0px, 0px)`,
    width: `calc(${length} * ${width})`,
    transition: 'transform 0.3s ease-in-out',
  })
);

type ColorTextProps = {
  cmsColor?: string;
};

export const ColorText = styled(Text)<ColorTextProps>(
  ({ cmsColor }) =>
    cmsColor && {
      color: cmsColor,
    }
);

type ColorButtonProps = {
  cmsColor?: string;
  fontColor?: string;
};

export const ColorButton = styled(Button)<ColorButtonProps>(
  ({ cmsColor }) =>
    cmsColor && {
      background: cmsColor,
    },
  ({ fontColor }) =>
    fontColor && {
      color: fontColor,
    }
);

export const ImageWrapperLink = styled(Link)({
  textDecoration: 'none',
  ':hover': {
    opacity: 1,
  },
});

const fragmentCMSMargin = {
  margin: 0,
  '*': { margin: 0 },
  p: {
    marginBottom: 'm',
  },
  a: {
    ':hover': {
      opacity: 0.7,
    },
  },
};

export const H1 = styled(Text.withComponent('h1'))(css(fragmentCMSMargin));
export const H2 = styled(Text.withComponent('h2'))(css(fragmentCMSMargin));

export const H1Affiliate = styled(H1)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['30px', '40px', '50px'],
    lineHeight: ['130%', '110%', '110%'],
  })
);

export const H1Catalog = styled(H1)(
  css({
    fontSize: ['20px', '30px'],
    fontFamily: '"Ogg-text", serif',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '1.1',
    textAlign: 'center',
  })
);

export const Title = styled(H2)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['l', 'l', 'xl'],
    lineHeight: ['l', 'l', 'xl'],
  })
);

export const SubTitle = styled(Text)(
  css({
    fontFamily: '"Ogg-text", serif',
    fontWeight: '700',
    fontSize: ['m', 'm', 'l'],
    lineHeight: ['m', 'm', 'l'],
  })
);

type ToolTipProps = {
  reverse?: boolean;
  isLeftAligned?: boolean;
};

export const ToolTip = styled(Box)<ToolTipProps>(
  {
    position: 'relative',
    '> div': {
      display: 'none',
      position: 'absolute',
      top: '28px',
      left: '50%',
      background: colors.BACKGROUND,
      border: `1px solid ${colors.LIGHT}`,
      borderRadius: '3px',
      padding: '4px 8px',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      zIndex: zIndex.TOOLTIP,
    },
    '@media (hover: hover)': {
      ':hover': {
        '> div': {
          display: 'block',
          ':before': {
            zIndex: zIndex.TOOLTIP,
            top: '-11px',
            content: '""',
            position: 'absolute',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${colors.LIGHT}`,
          },
          ':after': {
            zIndex: zIndex.TOOLTIP + 1,
            content: '""',
            position: 'absolute',
            top: '-10px',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${colors.BACKGROUND}`,
          },
        },
      },
    },
  },
  ({ reverse }) =>
    reverse && {
      '> div': {
        top: '-36px',
      },
      '@media (hover: hover)': {
        ':hover': {
          '> div': {
            ':before': {
              transform: 'rotateX(180deg)',
              top: '24px',
            },
            ':after': {
              transform: 'rotateX(180deg)',
              top: '23px',
            },
          },
        },
      },
    },
  ({ isLeftAligned }) =>
    isLeftAligned && {
      '>div': {
        left: '0',
        transform: 'translateX(0%)',
      },
      '@media (hover: hover)': {
        ':hover': {
          '> div': {
            ':before': {
              left: '0',
              marginLeft: '4px',
            },
            ':after': {
              left: '0',
              marginLeft: '4px',
            },
          },
        },
      },
    }
);

type HoverPictureProps = {
  isHovered: boolean;
};

export const HoverPicture = styled(Picture)<HoverPictureProps>(
  css({
    display: ['none', 'block'],
    'source, img': {
      opacity: 0,
      zIndex: zIndex.PRODUCT_ALT_IMAGE,
      transition: transitions.PRODUCT_ALT_IMAGE,
    },
  }),
  ({ isHovered }) =>
    isHovered && {
      'source, img': {
        opacity: 1,
      },
    }
);
