import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { CmsSliderItem } from '../../cms/types';
import { CmsSliderStickyItem } from './CmsSliderStickyItem';
import { breakpoints, colors } from '../../../design-system/systemprovider';
import { heights, zIndex } from '../../common/constants';
import { useLocation } from 'react-router-dom';
import { findItemToActivate } from '../utils';

type Props = {
  items: CmsSliderItem[];
};

const StyledItemsWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  position: sticky;
  top: ${heights.HEADER_MOBILE}px;
  left: 0;
  gap: 8px;
  padding: 8px 16px 16px 16px;
  margin: 0 auto;
  background: ${colors.WHITE};
  z-index: ${zIndex.SIDEBAR - 1};
  overflow-x: auto;

  @media (min-width: ${breakpoints.S}px) {
    padding: 8px 0;
    display: flex;
  }
`;

export const CmsSliderSticky = ({ items }: Props) => {
  const { pathname, hash } = useLocation();
  const ref = useRef<HTMLUListElement | null>(null);
  const [activeIndex, setActiveIndex] = useState(-1);

  const isDisabled =
    !items?.length ||
    items?.some((item) => !item.slider_with_number_text || !item.slider_with_number_text.length);

  useEffect(() => {
    if (hash) {
      findItemToActivate({ items, pathname, hash, setActiveIndex });
    } else {
      setActiveIndex(-1);
    }
  }, [hash, items, pathname]);

  return isDisabled ? null : (
    <StyledItemsWrapper ref={ref}>
      {items?.map((item: CmsSliderItem, index: number) => {
        return (
          <CmsSliderStickyItem
            key={`slider-with-number-item-${index}`}
            index={index}
            activeIndex={activeIndex}
            item={item}
            refContainer={ref}
          />
        );
      })}
    </StyledItemsWrapper>
  );
};
