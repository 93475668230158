import * as React from 'react';
import Icon from '../../icon/Icon';
import {
  StyledIcon,
  StyledContent,
  StyledHeader,
  StyledLine,
  StyledTitle,
} from '../accordion.style';
import { Box } from '../../../design-system';
export type AccordionItemType = {
  title: React.ReactNode;
  content: React.ReactNode;
  index: number;
  iconUp?: string;
  iconDown?: string;
  iconSize?: number;
  separateLineColor?: string;
};
type Props = {
  index: number;
  id: string;
  item: AccordionItemType;
  isOpen: boolean;
  isTitleCentered?: boolean;
  isPLPFilter?: boolean;
  noCollapse?: boolean;
  onClick: () => void;
};
const AccordionItem = ({
  id,
  item: { title, content, iconUp = 'chevronDown', iconDown = 'chevronDown', iconSize = 17 },
  isTitleCentered,
  isOpen,
  isPLPFilter,
  noCollapse = false,
  onClick,
}: Props) => (
  <>
    {noCollapse && (
      <Box mt="l" textAlign={isTitleCentered ? 'center' : 'left'}>
        <StyledTitle id={`${id}-title`}>{title}</StyledTitle>
      </Box>
    )}
    {!noCollapse && (
      <StyledHeader id={`${id}-header`} onClick={onClick} isTitleCentered={isTitleCentered}>
        <StyledTitle id={`${id}-title`}>{title}</StyledTitle>
        <StyledIcon isOpen={isOpen}>
          <Icon name={isOpen ? iconDown : iconUp} onClick={onClick} size={iconSize} />
        </StyledIcon>
      </StyledHeader>
    )}
    <StyledContent id={`${id}-content`} isOpen={isOpen}>
      {content}
    </StyledContent>
    <StyledLine isPLPFilter={isPLPFilter} />
  </>
);
export default AccordionItem;
