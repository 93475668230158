import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { makeMenuTreeMobile } from '../utils';
import { MobileMenuHeader } from './MobileMenuHeader';
import { MobileMenuItemList } from './MobileMenuItemList';
import { MobileMenuTreeItem } from '../types';
import { closeSidebar } from '../../common/actions';

type Props = MobileMenuTreeItem & {
  onMenuClick?: (subMenu: MobileMenuTreeItem[], name: string) => void;
};
type MenuState = {
  menu: Props[] | null;
  name: string;
};

const StyledMenuMobile = styled.div`
  margin: 0 -16px;
`;

export const MenuMobile = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState<Props[] | null>(null);
  const [menuName, setMenuName] = useState('MENU');
  const [menuStack, setMenuStack] = useState<MenuState[]>([]);
  const { items } = useSelector((state: RootState) => state.cms.menu);
  const tree = makeMenuTreeMobile(items);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const onMenuClick = (subMenu: MobileMenuTreeItem[], name: string) => {
    setIsFadingOut(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setTimeout(() => {
      setIsFadingOut(false);
      setIsFadingIn(true);
      setMenuStack((prevStack) => [...prevStack, { menu: activeMenu, name: menuName }]);
      setActiveMenu(subMenu);
      setMenuName(name);
    }, 300);
  };

  const onClickBack = () => {
    setIsFadingOut(true);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setTimeout(() => {
      setIsFadingOut(false);
      setIsFadingIn(true);
      const previousMenu = menuStack.pop();
      setMenuStack([...menuStack]);
      if (previousMenu) {
        setActiveMenu(previousMenu.menu);
        setMenuName(previousMenu.name);
      } else {
        setActiveMenu(null);
        setMenuName('MENU');
      }
    }, 300);
  };

  const onClickClose = () => {
    dispatch(closeSidebar());
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <StyledMenuMobile>
      <MobileMenuHeader
        name={menuName}
        onClickClose={onClickClose}
        onClickBack={onClickBack}
        haveSubMenu={activeMenu !== null}
        isFadingIn={isFadingIn}
        isFadingOut={isFadingOut}
      />
      <MobileMenuItemList
        items={activeMenu || tree}
        onMenuClick={onMenuClick}
        isFadingIn={isFadingIn}
        isFadingOut={isFadingOut}
        onClickClose={onClickClose}
      />
    </StyledMenuMobile>
  );
};
