import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store/rootReducer';
import { setFormValues, setFormValidation, setFocus, setFeedback, setCtaState } from '../actions';
import { Forms } from '../types';

export default function useForm(formType: Forms) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form[formType]);
  const actions = {
    setFormValues: bindActionCreators(setFormValues, dispatch),
    setFormValidation: bindActionCreators(setFormValidation, dispatch),
    setFocus: bindActionCreators(setFocus, dispatch),
    setFeedback: bindActionCreators(setFeedback, dispatch),
    setCtaState: bindActionCreators(setCtaState, dispatch),
  };

  return { form, actions };
}
