import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { checkEmail, login, logout, signup, relogin } from '../actions';
import { User } from '../types';

export type WithAuthActions = {
  checkEmail: (email: string) => void;
  login: (email: string, password: string) => void;
  signup: (email: string, password: string) => void;
  relogin: () => void;
  logout: () => void;
};

export type WithAuthProps = {
  isLoggedIn: boolean;
  errLogin: string;
  errSignup: string;
  errSocial: string;
  user?: User;
} & WithAuthActions;

export default function withAuth(WrappedComponent) {
  const Auth = (props) => <WrappedComponent {...props} />;

  Auth.displayName = `withAuth(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ ...state.auth }), {
    checkEmail,
    login,
    logout,
    signup,
    relogin,
  })(Auth);
}
