import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../../../design-system';

export type Item = {
  _highlightResult: {
    query?: { value: string };
    label?: { value: string };
  };
};

const StyledSpan = styled.span`
  color: ${colors.MAIN_GREY};
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  mark {
    color: ${colors.BLACK};
    background-color: unset;
    font-weight: bold;
  }
`;

export const CustomHighlight = ({ hit }: { hit: Item }) => {
  const displayItem =
    (hit._highlightResult.query?.value
      ? hit._highlightResult.query?.value
      : hit._highlightResult.label?.value
    )
      ?.replace(/__aa-highlight__/g, '<mark>')
      .replace(/__\/aa-highlight__/g, '</mark>') ?? '';
  return <StyledSpan dangerouslySetInnerHTML={{ __html: displayItem }} />;
};
