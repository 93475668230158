import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { css } from '@emotion/core';

import { CmsHomepageThinBanner } from '../../cms/types';
import { Box, Image } from '../../../design-system';
import { emptyText } from '../../cms/state';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  id: string;
  content: CmsHomepageThinBanner;
};

export function ThinBanner({ id, content }: Props) {
  const { isMobile } = useMediaQueries();

  const {
    background_color,
    title,
    title_mobile,
    promo_description,
    promo_description_mobile,
    promo,
    promo_mobile,
    image,
    image_title,
    image_title_mobile,
    cta_name,
    cta_link,
  } = content ?? {};

  const withImage = image && image.url;
  const height = isMobile ? (withImage ? 135 : 142) : 204;

  return (
    <Box
      position={'relative'}
      id={id}
      backgroundColor={background_color ?? '#FBE8D5'}
      width={'100%'}
      height={height}
      marginX={'auto'}
      display={'grid'}
      gridTemplateColumns={withImage ? '1fr 1fr' : '1fr'}
      gridGap={isMobile ? 0 : 64}
    >
      <Box position={'relative'} display={withImage ? 'block' : 'none'} height={'inherit'}>
        <Image
          alt={image?.alt ?? ''}
          src={isMobile ? (image?.mobile?.url ?? '') : (image?.desktop?.url ?? '')}
          objectFitValue={'cover'}
          width={'100%'}
          height={height}
        />
        <Box
          position={'absolute'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}
          left={0}
          top={0}
        >
          <Box opacity={0.4}>
            <RichText render={(isMobile ? image_title_mobile : image_title) ?? [...emptyText]} />
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={withImage ? 'flex-start' : 'center'}
        justifyContent={'space-between'}
        paddingLeft={isMobile ? (withImage ? 24 : 0) : 0}
        paddingRight={isMobile ? 8 : 0}
        paddingY={isMobile ? 16 : 32}
      >
        <Box textAlign={'left'}>
          <RichText render={(isMobile ? title_mobile : title) ?? [...emptyText]} />
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <RichText
            render={(isMobile ? promo_description_mobile : promo_description) ?? [...emptyText]}
          />
          <Box ml={isMobile ? (withImage ? 4 : 8) : 8}>
            <RichText render={(isMobile ? promo_mobile : promo) ?? [...emptyText]} />
          </Box>
        </Box>
        <Link
          className="anchor-animated"
          id={`thin-banner-${id}-cta`}
          data-testid="home-page-thin-banner-link"
          to={getFormatedLinkForReactRouterLink(cta_link)}
          css={css`
            font-size: 1.4rem;
          `}
        >
          {cta_name}
        </Link>
      </Box>
    </Box>
  );
}
