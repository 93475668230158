export default {
  LBL_RECYCLE: 'Recyclez. Recevez des €',
  TXT_MORE_INFO: 'Plus d‘informations',
  TXT_DETAILS:
    'Lorsque vous êtes prêt à les recycler, renvoyez-nous vos articles Chantelle One et recevez des crédits à valoir sur un prochain achat.',
  TXT_STEP_1: 'Étape 1',
  TXT_STEP_2: 'Étape 2',
  TXT_STEP_3: 'Étape 3',
  TXT_STEP_1_DETAILS: 'Renvoyez vos articles Chantelle One',
  TXT_STEP_2_DETAILS: 'Recevez des € *',
  TXT_STEP_3_DETAILS: 'Utilisez vos crédits sur un prochain achat',
  TXT_PAYBACK: '* Vous recevez :',
} as const;
