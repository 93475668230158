import { emptyProduct } from '../product/state';
import { ECart, State } from './types';

export const emptyECart: ECart = {
  items: [],
  total: 0,
  storeInfo: undefined,
};

export const ecartState: State = {
  ecart: { ...emptyECart },
  additionnals_products: [],
  main_product: emptyProduct,
  isLoading: false,
  isError: false,
};
