import { ShippingTypes } from '../cart/types';
import { Address as AddressType } from '../account/types';
import { durations } from '../../design-system';
import { Forms } from '../form/types';

export function calculateDeliveryDates(
  id: ShippingTypes,
  home_delivery_min_day = 5,
  home_delivery_max_day = 7,
  store_delivery_min_day = 3,
  store_delivery_max_day = 6,
  pickup_delivery_min_day = 5,
  pickup_delivery_max_day = 7
) {
  // function for dates interval set on prismic < 6 days
  const currentDate = new Date();
  const deliveryIntervalStart = new Date();
  const deliveryIntervalEnd = new Date();
  const formatter = new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'long' });
  const DaysToTypeMap = {
    [ShippingTypes.HOME]: { from: home_delivery_min_day, to: home_delivery_max_day },
    [ShippingTypes.STORE]: { from: store_delivery_min_day, to: store_delivery_max_day },
    [ShippingTypes.PICKUP]: { from: pickup_delivery_min_day, to: pickup_delivery_max_day },
  };
  const { from, to } = DaysToTypeMap[id];

  deliveryIntervalStart.setDate(currentDate.getDate() + from);
  deliveryIntervalEnd.setDate(currentDate.getDate() + to);

  function hasSundayInRange(startDate: Date, endDate: Date) {
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    while (currentDate <= lastDate) {
      if (currentDate.getDay() === 0) {
        return true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return false;
  }

  if (hasSundayInRange(deliveryIntervalStart, deliveryIntervalEnd)) {
    if (deliveryIntervalStart.getDay() === 0) {
      deliveryIntervalStart.setDate(deliveryIntervalStart.getDate() + 1);
    }
    deliveryIntervalEnd.setDate(deliveryIntervalEnd.getDate() + 1);
  }

  const dateStart = formatter.format(deliveryIntervalStart);
  const dateEnd = formatter.format(deliveryIntervalEnd);

  return {
    dateStart,
    dateEnd,
  };
}

export const deliveryMethodsDesktopWidth = 582;

export const emptyAddress: AddressType = {
  id: '',
  name: '',
  company: '',
  idTva: '',
  siret: '',
  firstName: '',
  lastName: '',
  street: '',
  streetAdditional: '',
  postal: '',
  city: '',
  phone: '',
  country: 'FR',
};

export const applyScroll = ({
  type,
  billingSelectorRef,
  shippingSelectorRef,
}: {
  type: Forms;
  billingSelectorRef: React.RefObject<HTMLDivElement>;
  shippingSelectorRef: React.RefObject<HTMLDivElement>;
}) => {
  if (type === Forms.billing) {
    return setTimeout(
      () => billingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' }),
      durations.FOCUS_DELAY
    );
  }
  return setTimeout(
    () => shippingSelectorRef.current?.scrollIntoView({ behavior: 'smooth' }),
    durations.FOCUS_DELAY
  );
};
