import * as React from 'react';
import { connect } from 'react-redux';

import { openPopup, closePopup, OpenPopupParams } from '../actions';
import { RootState } from '../../../store/rootReducer';

export type WithPopupActions = {
  closePopup?: () => void;
  openPopup: (options: OpenPopupParams) => void;
};

function withPopup(WrappedComponent) {
  const Popup = (props) => <WrappedComponent {...props} />;

  Popup.displayName = `withPopup(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ ...state.popup }), { openPopup, closePopup })(Popup);
}

export default withPopup;
