import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { Box } from '../../../design-system';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

// check with seo if needed (WIP)
export const Links = () => {
  const items = useSelector((state: RootState) => state.cms.menu?.items) ?? [];
  const uniqueLinks = items.filter(
    (item, index, self) => self.findIndex((s) => s.link === item.link) === index
  );

  return (
    <Box display="none" maxWidth="mws" m="auto" p="l">
      {uniqueLinks.map((item, index) => (
        <Link key={index} id={`links-${index}`} to={getFormatedLinkForReactRouterLink(item.link)}>
          {item.name ?? ''}
        </Link>
      ))}
    </Box>
  );
};
