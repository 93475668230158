import React, { useEffect, useState } from 'react';

import { getBFFData, Queries } from '../../api';
import { Product } from '../../product/types';
import { ProductModal } from './ProductModal';

type Props = {
  productRef: string;
  colorRef: string;
  originalSelectedColor?: string;
  onClose?: () => void;
};

export const QuickAdd = ({ productRef, colorRef, originalSelectedColor, onClose }: Props) => {
  const [hits, setHits] = useState<Product[]>([]);
  const [selectedColorRef, setSelectedColorRef] = useState(colorRef);
  const [cache, setCache] = useState<{ [key: string]: Product }>({});
  const [selectedImagePosition, setSelectedImagePosition] = React.useState(0);
  const onColorClick = (colorRef: string) => setSelectedColorRef(colorRef);

  useEffect(() => {
    async function fetch() {
      const res = await getBFFData(Queries.getProduct, { productRef, colorRef: selectedColorRef });
      const product = res.data.product as Product;
      const filteredProduct = {
        ...product,
        colorVariants: product.colorVariants.filter((color) => color.status),
      };
      setCache((prev) => ({ ...prev, [selectedColorRef]: filteredProduct }));
      setHits([filteredProduct]);
    }
    setSelectedImagePosition(0);
    if (cache[selectedColorRef]) {
      setHits([cache[selectedColorRef]]);
    } else {
      fetch();
    }
  }, [selectedColorRef]);

  return !hits.length ? null : (
    <ProductModal
      onColorClick={onColorClick}
      onClose={onClose}
      selectedColorRef={selectedColorRef}
      selectedImagePosition={selectedImagePosition}
      setSelectedImagePosition={setSelectedImagePosition}
      originalSelectedColor={originalSelectedColor}
      hits={hits}
    />
  );
};
