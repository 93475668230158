import types from './actionTypes';
import { TrackingActions } from './actions';

import { State } from './types';
import { trackingState } from './state';

export default function tracking(state = trackingState, action: TrackingActions): State {
  switch (action.type) {
    case types.UPDATE_DATA_LAYER:
      return {
        ...state,
        dataLayer: action.payload,
      };
    default:
      return state;
  }
}
