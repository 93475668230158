import React, { useEffect, useRef, useState } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Transition from 'react-transition-group/Transition';

import { RootState } from 'src/shared/store/rootReducer';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Box, Icon } from '../../../design-system';
import { durations } from '../../common/constants';
import { closeModal, openModal } from '../../common/actions';
import { Product } from '../../product/types';
import { BackButton } from './../../product/components/BackButton';
import EresaMainProduct from './EresaMainProduct';
import { EresaBreadcrumb } from './EresaBreadcrumb';
import { EresaStoreLocator } from './EresaStoreLocator';
import { EresaSummary } from './EresaSummary';
import { ProductEresa, bulkRemoveEreservation, setEresaAdditionnalList } from '../actions';
import { ERESA_MODAL_TITLE } from '../locale';
import { EresaStep } from '../utils';
import { EresaResults } from './EresaResults';
import { ECartItem } from '../types';

type Props = {
  product: Product;
};

const transitionStylesContent = {
  entering: { opacity: 0, transition: `opacity ${durations.MAP_SEARCH_DELAY}ms ease-in-out` },
  entered: { opacity: 1, transition: `opacity ${durations.MAP_SEARCH_DELAY}ms ease-in-out` },
  exiting: { opacity: 0, transition: `opacity ${durations.MAP_SEARCH_DELAY}ms ease-in-out` },
  exited: { opacity: 0, transition: `opacity ${durations.MAP_SEARCH_DELAY}ms ease-in-out` },
};

export const Ereservation = ({ product }: Props) => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useMediaQueries();
  const {
    additionnals_products,
    main_product,
    isLoading,
    isError,
    ecart: { items },
  } = useSelector((state: RootState) => state.ecart);
  const [step, setStep] = useState(EresaStep.PRODUCT);
  const [stepToDisplay, setStepToDisplay] = useState(EresaStep.PRODUCT);
  const ref = useRef<HTMLDivElement>(null);
  const storesAvailable = main_product?.sizeVariants[0]?.storesAvailable;

  const removeEresaProductsFromBE = () => {
    const productsToRemove: ProductEresa[] = [];
    items.map((item: ECartItem, index: number) => {
      productsToRemove.push({
        productRef: item.productRef,
        colorRef: item.colorRef,
        sku: item.sku,
      });
      if (index + 1 < items.length) {
        return null;
      }
      return dispatch(bulkRemoveEreservation(productsToRemove) as unknown as UnknownAction);
    });
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMobile && ref?.current) {
      timeoutId = setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView();
        }
      }, 300);
    }
    return () => {
      clearTimeout(timeoutId);
      if (additionnals_products.length > 0) {
        dispatch(setEresaAdditionnalList([]));
      }
      if (items.length > 0) {
        removeEresaProductsFromBE();
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMobile && ref.current) {
      ref.current.scrollIntoView();
    }
    if (step < EresaStep.SUMMARY) {
      if (additionnals_products.length > 0) {
        dispatch(setEresaAdditionnalList([]));
      }
      if (items.length > 0) {
        removeEresaProductsFromBE();
      }
    }
    const timer = setTimeout(() => {
      setStepToDisplay(step);
    }, durations.MAP_SEARCH_DELAY);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timer);
    };
  }, [step]);

  useEffect(() => {
    if (isError) {
      dispatch(
        openModal({
          content: <EresaResults isError />,
          preset: 'eresaModalResults',
        })
      );
    }
  }, [isError]);

  return (
    <Box
      id="eresa-modal"
      display="flex"
      minHeight={isMobile ? '100vh' : '622px'}
      height={isMobile ? '100vh' : '622px'}
      maxHeight={isMobile ? 'auto' : '622px'}
      alignItems="center"
      pt={isMobile ? 'm' : 'xl'}
      pb={isMobile ? 'na' : 'xl'}
      px={isMobile || isTablet ? 'm' : 'xl'}
      ref={ref}
      flexDirection="column"
    >
      {isMobile && (
        <Box id="eresa-modal-header">
          {step > EresaStep.PRODUCT && (
            <BackButton onClick={() => (isLoading ? null : setStep(step - 1))} isEreservation />
          )}
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top="m"
            right="m"
            height="30px"
          >
            <Icon
              data-testid="ereservation-icon-close-mobile"
              name="closeSmall"
              size={16}
              onClick={() => dispatch(closeModal())}
            />
          </Box>
        </Box>
      )}
      <Box
        id="eresa-modal-title"
        width="100%"
        display="flex"
        justifyContent="space-between"
        mt={isMobile ? '57px' : 'na'}
      >
        {!isMobile && (
          <Box position="relative" mt="-22px" ml="-16px" width="94px" minWidth="94px">
            {step > EresaStep.PRODUCT && (
              <BackButton onClick={() => (isLoading ? null : setStep(step - 1))} isEreservation />
            )}
          </Box>
        )}
        <Box fontSize="20px" fontWeight={700}>
          {ERESA_MODAL_TITLE}
        </Box>
        {!isMobile && (
          <Box width="72px" display="flex" justifyContent="end">
            <Icon
              data-testid="ereservation-icon-close-desktop-tablet"
              name="closeSmall"
              size={18}
              onClick={() => dispatch(closeModal())}
            />
          </Box>
        )}
      </Box>
      <EresaBreadcrumb step={step} setStep={setStep} navigationIsLock={isLoading} />
      {stepToDisplay === EresaStep.PRODUCT && (
        <Transition
          in={step === EresaStep.PRODUCT}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 0, exit: durations.MAP_SEARCH_DELAY }}
        >
          {(state) => (
            <Box
              id="eresa-modal-product"
              mt="l"
              alignSelf="start"
              width="100%"
              display="flex"
              flexDirection="column"
              height="100%"
              style={{
                ...transitionStylesContent[state],
              }}
            >
              <EresaMainProduct
                originalProduct={{
                  ...product,
                  colorVariants: product.colorVariants.filter((color) => color.status),
                }}
                setStepEresaModal={setStep}
              />
            </Box>
          )}
        </Transition>
      )}
      {stepToDisplay === EresaStep.STORES && (
        <Transition
          in={step === EresaStep.STORES}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 0, exit: durations.MAP_SEARCH_DELAY }}
        >
          {(state) => (
            <Box
              id="eresa-modal-store-locator"
              mt="l"
              alignSelf="start"
              width="100%"
              style={{
                ...transitionStylesContent[state],
              }}
            >
              <EresaStoreLocator storesAvailable={storesAvailable} setStepEresaModal={setStep} />
            </Box>
          )}
        </Transition>
      )}
      {stepToDisplay > EresaStep.STORES && (
        <Transition
          in={step > EresaStep.STORES}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 0, exit: durations.MAP_SEARCH_DELAY }}
        >
          {(state) => (
            <Box
              id="eresa-modal-summary"
              mt="l"
              alignSelf="start"
              width="100%"
              style={{
                ...transitionStylesContent[state],
              }}
            >
              <EresaSummary step={step} setStep={setStep} />
            </Box>
          )}
        </Transition>
      )}
    </Box>
  );
};
