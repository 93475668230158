import * as React from 'react';
import Hammer from 'react-hammerjs';

import Box from '../../box';
import { Icon } from '../..';
import Text from '../../text';

type Props = {
  pos: number;
  maxScale: number;
  onPan: (e) => void;
  onPanEnd: () => void;
};

const ZoomSlider = ({ pos, onPan, onPanEnd, maxScale }: Props) => (
  <Box
    display="grid"
    alignItems="center"
    justifyContent="left"
    gridGap="s"
    gridTemplateColumns="auto 216px"
    gridAutoFlow="column"
    mx="auto"
  >
    <Box>
      <Icon name="zoom" />
    </Box>

    <Box position="relative">
      <Hammer {...{ onPan, onPanEnd }}>
        <Box
          size="m"
          borderRadius="50%"
          bg="BLACK"
          position="absolute"
          top="-7.5px"
          left={`${pos}px`}
        />
      </Hammer>
      <Box width="216px" height="1px" bg="BLACK" position="absolute" />
      <Box width="1px" height="6px" bg="BLACK" position="absolute" right={0} top="-2.5px" />
      <Text preset="caption" position="absolute" right="-12px" top="s">
        {`${maxScale * 100}%`}
      </Text>
    </Box>
  </Box>
);

export default ZoomSlider;
