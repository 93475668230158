import React, { useEffect, useState } from 'react';
import { UnknownAction } from 'redux';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { Spinner, colors } from '../../../design-system';
import { BrandBanner } from './BrandBanner';
import { FullTitleBanner } from './FullTitleBanner';
import { TagLineBanner } from './TagLineBanner';
import { GalleryBanner } from './GalleryBanner';
import { AnimatedPictures } from './AnimatedPictures';
import { PicturesSlider } from './PicturesSlider';
import { SeoDescription } from '../../common/components/SeoDescription';
import UspSection from '../../home/components/UspSection';
import { cmsOurBrandInit, cmsUspInit } from '../../cms/actions';
import { getSliceBody } from '../utils';
import { hasValidCmsText } from '../../home/utils';
import { getIsClient } from '../../common/utils';
import { RootState } from 'src/shared/store/rootReducer';
import { emptyText } from '../../cms/state';
import {
  CmsAnimatedPictures,
  CmsBrandBanner,
  CmsCategorieBanner,
  CmsFullSlider,
  CmsFullTitle,
  CmsGalleryBanner,
  CmsPicturesSlider,
  CmsTabsCategorieBanner,
  CmsTagLine,
} from '../../cms/types';
import { CategorieBanner } from './CategorieBanner';
import { TabsCategorie } from './TabsCategorie';
import { FullSlider } from './FullSlider';

const BrandPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BrandPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const uid = pathname.split('/')?.[2];
  const { ourbrand } = useSelector((state: RootState) => state.cms);
  const [isLoading, setIsLoading] = useState(true);
  const { usp, usp_main_title } = useSelector((state: RootState) => state.cms.usp) ?? {};

  const fullTitleBannerCms = getSliceBody(ourbrand, 'full_title');
  const brandBannerCms = getSliceBody(ourbrand, 'brand_banner');
  const tagLineCms = getSliceBody(ourbrand, 'tag_line');
  const galleryBannerCms = getSliceBody(ourbrand, 'gallery_banner');
  const animatedPicturesCms = getSliceBody(ourbrand, 'animated_picture');
  const picturesSliderCms = getSliceBody(ourbrand, 'pictures_slider');
  const categorieBannerCms = getSliceBody(ourbrand, 'categorie_banner');
  const tabsCategorieCms = getSliceBody(ourbrand, 'tabs_categorie');
  const fullSliderCms = getSliceBody(ourbrand, 'full_slider');

  const {
    ourbrand_bottom_seo_description,
    ourbrand_bottom_seo_description_alignment,
    ourbrand_meta_description,
    ourbrand_meta_title,
  } = ourbrand;

  useEffect(() => {
    if (!usp.length || !usp_main_title) {
      dispatch(cmsUspInit() as unknown as UnknownAction);
    }
    if (Object.values(ourbrand).every((arr) => arr?.length === 0)) {
      dispatch(cmsOurBrandInit(uid) as unknown as UnknownAction);
    }
    if (getIsClient()) {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {(ourbrand_meta_title || ourbrand_meta_description) && (
        <Helmet>
          {ourbrand_meta_title && (
            <>
              <title>{ourbrand_meta_title}</title>
              <meta property="og:title" content={ourbrand_meta_title} />
            </>
          )}
          {ourbrand_meta_description && (
            <>
              <meta name="description" content={ourbrand_meta_description} />
              <meta property="og:description" content={ourbrand_meta_description} />
            </>
          )}
        </Helmet>
      )}
      {isLoading ? (
        <div
          style={{
            width: '100vw',
            maxWidth: '1920px',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={colors.BLACK} id="brand-page-loader" size={56} />
        </div>
      ) : (
        <BrandPageContainer id="brand-page-block">
          {fullTitleBannerCms?.map((el, index) => {
            if ((el as CmsFullTitle)?.primary?.full_title_position_on_page === 'Not activated') {
              return null;
            }
            return (
              <FullTitleBanner
                key={`full-title-banner-${index}`}
                index={index}
                cmsData={el as CmsFullTitle}
              />
            );
          })}
          {brandBannerCms?.map((el, index) => {
            if (
              (el as CmsBrandBanner)?.primary?.brand_banner_position_on_page === 'Not activated'
            ) {
              return null;
            }
            return (
              <BrandBanner
                key={`brand-banner-${index}`}
                index={index}
                cmsData={el as CmsBrandBanner}
              />
            );
          })}
          {tagLineCms?.map((el, index) => {
            if ((el as CmsTagLine)?.primary?.tag_line_position_on_page === 'Not activated') {
              return null;
            }
            return (
              <TagLineBanner
                key={`tag-line-banner-${index}`}
                index={index}
                cmsData={el as CmsTagLine}
              />
            );
          })}
          {galleryBannerCms?.map((el, index) => {
            if (
              (el as CmsGalleryBanner)?.primary?.gallery_banner_position_on_page === 'Not activated'
            ) {
              return null;
            }
            return (
              <GalleryBanner
                key={`gallery-banner-${index}`}
                index={index}
                cmsData={el as CmsGalleryBanner}
              />
            );
          })}
          {animatedPicturesCms?.map((el, index) => {
            if (
              (el as CmsAnimatedPictures)?.primary?.animated_pictures_position_on_page ===
              'Not activated'
            ) {
              return null;
            }
            return (
              <AnimatedPictures
                key={`animated-banner-${index}`}
                index={index}
                cmsData={el as CmsAnimatedPictures}
              />
            );
          })}
          {picturesSliderCms?.map((el, index) => {
            if (
              (el as CmsPicturesSlider)?.primary?.pictures_slider_position_on_page ===
              'Not activated'
            ) {
              return null;
            }
            return (
              <PicturesSlider
                key={`pictures-slider-banner-${index}`}
                index={index}
                cmsData={el as CmsPicturesSlider}
              />
            );
          })}
          {categorieBannerCms?.map((el, index) => {
            if (
              (el as CmsCategorieBanner)?.primary?.categorie_banner_position_on_page ===
              'Not activated'
            ) {
              return null;
            }
            return (
              <CategorieBanner
                key={`categorie-banner-${index}`}
                index={index}
                cmsData={el as CmsCategorieBanner}
              />
            );
          })}
          {tabsCategorieCms?.map((el, index) => {
            if (
              (el as CmsTabsCategorieBanner)?.primary?.tabs_categorie_position_on_page ===
              'Not activated'
            ) {
              return null;
            }
            return (
              <TabsCategorie
                key={`tabs-categorie-banner-${index}`}
                cmsData={el as CmsTabsCategorieBanner}
                index={index}
              />
            );
          })}
          {fullSliderCms?.map((el, index) => {
            if ((el as CmsFullSlider)?.primary?.full_slider_position_on_page === 'Not activated') {
              return null;
            }
            return (
              <FullSlider
                key={`full-slider-banner-${index}`}
                cmsData={el as CmsFullSlider}
                index={index}
              />
            );
          })}
        </BrandPageContainer>
      )}
      {hasValidCmsText(ourbrand_bottom_seo_description) && (
        <SeoDescription
          seo_description={ourbrand_bottom_seo_description || [...emptyText]}
          alignment={ourbrand_bottom_seo_description_alignment ?? 'left'}
        />
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <UspSection content={usp ?? []} title={usp_main_title ?? ''} />
      </div>
    </>
  );
};
