import { CDN_S3_BUCKET, PRISMIC_IMAGE_DOMAIN, PRISMIC_STATICS_DOMAIN } from './constants';

export function getCmsFilename(type: string, uid?: string): string {
  return `https://${CDN_S3_BUCKET}/prismic/${type}-${uid || type}.json`;
}

export async function getCmsData(type: string, uid?: string) {
  const filename = getCmsFilename(type, uid);
  try {
    const response = await fetch(filename);
    const json = await response.json();
    const updatedJson = JSON.stringify(json)
      .replace(new RegExp(PRISMIC_IMAGE_DOMAIN, 'g'), CDN_S3_BUCKET)
      .replace(new RegExp(PRISMIC_STATICS_DOMAIN, 'g'), CDN_S3_BUCKET);
    return JSON.parse(updatedJson);
  } catch (error) {
    return null;
  }
}
