import styled from '@emotion/styled';
import * as React from 'react';

import Icon, { Spinner } from '../../icon';
import { colors, opacities } from '../../systemprovider';
import Button, { Props as ButtonProps } from '../Button';
import Box from '../../box';

type Props = {
  data: [string, string, string];
  feedback: Status;
  icon?: string;
  isActive?: boolean;
} & ButtonProps;

export enum Status {
  Default,
  Success,
  Error,
  Loading,
}

const DynamicButton = ({
  feedback,
  data,
  id,
  disabled,
  icon,
  isActive,
  fontWeight = '400',
  ...props
}: React.ComponentPropsWithRef<'button'> & Props) => (
  <StyledButton
    showAltBackground={[Status.Loading, Status.Success].includes(feedback)}
    disabled={feedback === Status.Loading || disabled}
    isActive={isActive}
    {...{ id, ...props }}
  >
    <Box display="flex" justifyContent="center">
      <Box mr={icon || feedback === Status.Success ? 's' : 'na'}>
        {feedback === Status.Success ? (
          <Icon
            name="checkmark"
            size={18}
            color={props.preset && props.preset === 'subtle' ? colors.BLACK : colors.WHITE}
          />
        ) : feedback === Status.Loading ? (
          <Spinner color={props.preset && props.preset === 'subtle' ? 'BLACK' : 'WHITE'} />
        ) : icon ? (
          <Icon
            name={icon}
            size={18}
            color={props.preset && props.preset === 'subtle' ? colors.BLACK : colors.WHITE}
          />
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" fontWeight={fontWeight}>
        {feedback === Status.Loading ? null : data[feedback]}
      </Box>
    </Box>
  </StyledButton>
);

type StyledProps = {
  showAltBackground: boolean;
  isActive: boolean | undefined;
};

const StyledButton = styled(Button)<StyledProps>(({ showAltBackground, isActive }) =>
  showAltBackground && isActive
    ? {
        opacity: opacities.HOVERED,
      }
    : !isActive &&
      isActive !== undefined && {
        opacity: 0.4,
        pointerEvents: 'none',
      }
);

export default DynamicButton;
