import React from 'react';
import { connect } from 'react-redux';

import { openModalNew, closeModalNew, OpenModalNewParams, closeModal } from '../actions';
import { RootState } from '../../../store/rootReducer';

export type WithModalActions = {
  closeModal?: () => void;
  openModal: (options: OpenModalNewParams) => void;
};

function withModalNew(WrappedComponent) {
  const ModalNew = (props) => <WrappedComponent {...props} />;

  ModalNew.displayName = `withModalNew(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ ...state.modalNew }), {
    openModalNew,
    closeModalNew,
    closeModal,
  })(ModalNew);
}

export { withModalNew };
