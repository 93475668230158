import { heights } from '../common/constants';

const getNavHeight = ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }): number => {
  if (isMobile) {
    return heights.HEADER_MOBILE;
  }
  if (isTablet) {
    return heights.HEADER_TABLET;
  }
  return heights.HEADER;
};

export const scrollToFormAffiliate = ({
  isMobile,
  isTablet,
}: {
  isMobile: boolean;
  isTablet: boolean;
}): void => {
  if (typeof window !== 'undefined') {
    const target = document.getElementById('form-block-affiliate')?.offsetTop;
    const adjustedTarget = target && target - getNavHeight({ isMobile, isTablet });
    window.scrollTo({ top: adjustedTarget, left: 0, behavior: 'smooth' });
  }
};
