import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { setFormValues, setFormValidation, setFocus, setFeedback, setCtaState } from '../actions';
import { Callback, Forms, FocusCallback, FeedbackCallback, CtaStateCallback } from '../types';

export type WithFormActions = {
  setFormValidation: ({ form, values }: Callback) => void;
  setFormValues: ({ form, values }: Callback) => void;
  setFocus: ({ form, key }: FocusCallback) => void;
  setFeedback: ({ form, ok, message }: FeedbackCallback) => void;
  setCtaState: ({ form, ctaState }: CtaStateCallback) => void;
};

export default function withForm(WrappedComponent, form: Forms) {
  const Form = (props) => <WrappedComponent {...props} />;

  Form.displayName = `withForm(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ form: state.form[form] }), {
    setFormValues,
    setFormValidation,
    setFocus,
    setFeedback,
    setCtaState,
  })(Form);
}
