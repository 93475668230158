import * as React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../design-system';
import StepIndicator from './StepIndicator';
import { CartStep } from '../types';
import { getIconUrl } from '../../../design-system/icon/utils';
import { breakpoints, colors, opacities } from '../../../design-system/systemprovider';
import { css } from '@emotion/core';
import { transitions } from '../../common/constants';

type Props = {
  step: CartStep;
  handleClickBack?: () => void;
  isLoading: boolean;
};

export const Header = ({ step, handleClickBack = () => {}, isLoading }: Props) => {
  const customBreakPoint = step === CartStep.PAYMENT ? breakpoints.L + 120 : breakpoints.L + 60;

  return (
    <div
      id="cart-header-container"
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        position: relative;
        max-width: ${breakpoints.XL}px;
        @media (max-width: ${customBreakPoint - 1}px) {
          padding-top: 16px;
          flex-direction: column;
        }
        @media (min-width: ${customBreakPoint}px) {
          flex-direction: row;
          min-height: 66px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          @media (max-width: ${customBreakPoint - 1}px) {
            position: initial;
            margin-bottom: 8px;
            position: relative;
            width: 100%;
            justify-content: center;
          }
          @media (min-width: ${customBreakPoint}px) {
            position: absolute;
            top: 23px;
            left: 72px;
          }
        `}
      >
        <button
          type="button"
          data-cy="cart-page-back-button"
          onClick={handleClickBack}
          css={css`
            border: initial;
            background: initial;
            padding: 0 0;
            cursor: pointer;
            margin-right: 24px;
            @media (max-width: ${customBreakPoint - 1}px) {
              position: absolute;
              left: 16px;
              top: 2px;
            }
          `}
        >
          <Icon name="chevronLeft" size={14} color="#202020" />
        </button>
        <Link
          to="/"
          data-cy="cart-main-logo-link-to-home"
          css={css`
            @media (hover: hover) {
              transition: ${transitions.GENERIC};
              :hover {
                opacity: ${opacities.HOVERED};
              }
            }
          `}
        >
          <img
            src={getIconUrl('logo')}
            css={css`
              width: 135px;
              @media (min-width: ${customBreakPoint}px) {
                width: 150px;
              }
            `}
            alt="logo Darjeeling"
          />
        </Link>
      </div>
      {!isLoading && (
        <div
          id="cart-step-indicator-container"
          css={css`
            padding: 8px;
            @media (max-width: ${customBreakPoint - 1}px) {
              height: 50px;
              background-color: ${colors.BACKGROUND};
              display: flex;
              justify-content: center;
              width: 100%;
            }
          `}
        >
          <StepIndicator step={step} />
        </div>
      )}
    </div>
  );
};
