import styled from '@emotion/styled';

const Main = styled.main({
  position: 'relative',
  height: '100%',
  width: '100%',
  touchAction: 'manipulation',
});

export default Main;
