export const TOP_TEN_PRODUCT_CARD_MIN_WIDTH = 268;
export const TOP_TEN_PAGE_MAX_WIDTH = 1440;
export const TOP_TEN_DOT_WIDTH = 16;
export const TOP_TEN_DOT_HEIGHT = 16;
export const TOP_TEN_DOT_TRANSPARENT_GAP = 8;
export const TOP_TEN_SUGGESTIONS_CTA_TABLET_WIDTH = 186;
export const TOP_TEN_CTA_BLOCK_TEXT = 'VOIR LA COLLECTION';

export const getParagraphNumberText = (index: number): string => {
  return `\u2116${index + 1}`;
};

export const getFormatedLinkForReactRouterLink = (link: string | undefined | null): string => {
  if (link) {
    if (link.startsWith('/') || link.startsWith('http')) {
      return link;
    }
    return `/${link}`;
  }
  return '';
};
