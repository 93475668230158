import * as React from 'react';

import { Box, LoadingBox } from '../../../design-system';

const LoadingStore = () => {
  return (
    <Box
      display="grid"
      gridTemplateAreas={[
        "'name' 'image' 'infos' 'services' 'map' ",
        "'name  name' 'image  infos' 'image services' 'map map'",
      ]}
      gridTemplateColumns={['auto', 'auto auto']}
      gridGap={['s', 'm']}
    >
      <LoadingBox gridArea="name" height={['80px', '100px']} />
      <LoadingBox gridArea="image" height={['250px', '500px']} />
      <LoadingBox gridArea="infos" height={['200px', '350px']} />
      <LoadingBox gridArea="services" height="150px" />
      <LoadingBox gridArea="map" height="300px" />
    </Box>
  );
};

export default LoadingStore;
