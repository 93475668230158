import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Icon from '../Icon';
import Box from '../../box';

type Props = {
  size?: number;
  color?: string;
  stroke?: number;
  iconName?: string;
  id?: string;
};

const SpinnerAndIconLinear = ({ size = 17, color = 'BLACK', stroke = 22, id, iconName }: Props) => (
  <Box position="relative" width={`${size}px`} height={`${size}px`} id={id ? id : 'spinner-linear'}>
    <StyledSpan {...{ size, color }}>
      <svg viewBox="0 0 600 600" width={`${size}px`} height={`${size}px`}>
        <defs>
          <linearGradient id="Gradient1" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor={color} stopOpacity={1} />
            <stop offset="100%" stopColor={color} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient id="Gradient2" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="rgba(246, 246, 246, 0)" stopOpacity="0" />
            <stop offset="90%" stopColor={color} stopOpacity={0.5} />
            <stop offset="100%" stopColor={color} stopOpacity={0.65} />
          </linearGradient>
          <pattern id="Pattern" x="0" y="0" width="600" height="600" patternUnits="userSpaceOnUse">
            <g transform="rotate(0, 300, 300)">
              <rect
                shapeRendering="crispEdges"
                x="0"
                y="0"
                width="300"
                height="600"
                fill="url(#Gradient1)"
              />
              <rect
                shapeRendering="crispEdges"
                x="300"
                y="0"
                width="300"
                height="600"
                fill="url(#Gradient2)"
              />
            </g>
          </pattern>
        </defs>
        <path
          className="rotating"
          style={{ stroke: 'url(#Pattern)' }}
          fill="transparent"
          transform="rotate(0, 300, 300)"
          strokeWidth={stroke > 0 && stroke < 80 ? stroke : 22}
          d="M 364 58 A 250 250 0 1 1 235 58"
        />
      </svg>
    </StyledSpan>
    {iconName && (
      <Box position="absolute" top={`${size / 2 - size / 6}px`} left={`${size / 2 - size / 6}px`}>
        <Icon name={iconName} size={size / 3} />
      </Box>
    )}
  </Box>
);

const StyledSpan = styled.span(() =>
  css({
    '@keyframes rotate': {
      from: { transform: 'rotate(105deg)' },
      to: { transform: 'rotate(465deg)' },
    },
    '.rotating': {
      animationName: `rotate`,
      animationDuration: '1600ms',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      transformOrigin: '300px 300px',
    },
  })
);

export default SpinnerAndIconLinear;
