import * as React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { scrollToTop } from '../utils';

export default function useScrollLock({
  isActive,
  ref,
  withScrollToTop,
}: {
  isActive: boolean;
  ref?: HTMLElement | null;
  withScrollToTop?: boolean;
}) {
  React.useEffect(() => {
    if (ref) {
      if (isActive) {
        disableBodyScroll(ref);
        if (withScrollToTop) {
          scrollToTop();
        }
      } else {
        enableBodyScroll(ref);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isActive, ref]);
}
