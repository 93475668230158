import * as React from 'react';

import { Box } from '../../../design-system';
import { BannerVideo } from '../../banners/components/BannerVideo';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  column: string;
  video: string;
  isVideoWideFormat?: boolean;
};

const BrandVideo = ({ column, video, isVideoWideFormat }: Props) => {
  const { isDesktop } = useMediaQueries();

  const width = !isDesktop
    ? column === 'quarter'
      ? 'calc(calc(100vw - 32px) /2)'
      : '100vw'
    : column === 'full'
      ? '100vw'
      : column === 'half'
        ? 'calc(calc(100vw - 48px) / 2)'
        : column === 'one-third'
          ? 'calc(calc(100vw - 64px) / 3)'
          : 'calc(calc(100vw - 80px) / 4)';

  const ratio = isVideoWideFormat ? 9 / 16 : 3 / 4;
  const height = `calc(${width} * ${ratio})`;

  return (
    <Box mx="auto" {...{ width, height }}>
      <BannerVideo video={video} />
    </Box>
  );
};

export default BrandVideo;
