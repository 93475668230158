import * as React from 'react';

import Box from '../../box';
import Icon from '../../icon/Icon';

import { StyledPaginator } from '../pagination.style';

type Props = {
  pages: number[];
  goToPage: (page: number) => void;
  currentPage: number;
  totalPages: number;
};

const PaginationBox = ({ pages, goToPage, currentPage, totalPages }: Props) => (
  <Box display="flex">
    <Box
      opacity={currentPage === 1 ? 0.5 : 1}
      display="flex"
      alignItems="center"
      onClick={() => currentPage > 1 && goToPage(currentPage - 1)}
    >
      <Icon name="chevronLeft" size={14} />
      <StyledPaginator>Prec</StyledPaginator>
    </Box>
    {pages.map((page, index) => (
      <Box key={index} display="flex">
        {page - pages[index - 1] > 1 && (
          <StyledPaginator isCurrentPage preset="body">
            ..
          </StyledPaginator>
        )}
        <StyledPaginator
          isCurrentPage={currentPage === page}
          preset="body"
          onClick={() => goToPage(page)}
        >
          {page}
        </StyledPaginator>
      </Box>
    ))}
    <Box
      opacity={currentPage === totalPages ? 0.5 : 1}
      display="flex"
      alignItems="center"
      onClick={() => currentPage < totalPages && goToPage(currentPage + 1)}
    >
      <StyledPaginator>Suiv</StyledPaginator>
      <Icon name="chevronRight" size={14} />
    </Box>
  </Box>
);

export default PaginationBox;
