import React from 'react';
import { css } from '@emotion/core';

import { Box, Stars } from '../../../design-system';

type Props = {
  ratingAverage: string;
  ratingCount: string;
  reviewsRef: React.RefObject<HTMLDivElement>;
};
export const Rating = ({ ratingAverage, ratingCount, reviewsRef }: Props) => {
  const onClick = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const rating = Number.parseFloat(ratingAverage);
  const ratingCountToNumber = parseInt(ratingCount, 10) ?? 0;

  return ratingCountToNumber > 0 ? (
    <Box display="flex" alignItems="center">
      <Stars size={14} rating={rating} identifier="product-stars-block" />
      <Box ml="s">
        <button
          type="button"
          className="anchor-animated"
          data-testid="btn-pdp-scroll-to-reviews"
          id="btn-pdp-scroll-to-reviews"
          onClick={onClick}
          css={css`
            font-size: 1.2rem;
          `}
        >
          {`${ratingCount} avis`}
        </button>
      </Box>
    </Box>
  ) : null;
};
