import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { breakpoints, Checkbox, colors, Divider } from '../../../design-system';
import { CartStep, ShippingTypes } from '../types';
import { Generic } from '../../cms';
import { RootState } from '../../../store/rootReducer';
import { openModal } from '../../common/actions';
import { Coupon } from './Coupon';
import { TotalAmount } from './TotalAmount';
import {
  LBL_DELIVERY,
  LBL_DELIVERY_CHOICE,
  LBL_DELIVERY_TITLE_SUMMARY,
  LBL_FREE,
  LBL_PROMO_CODE,
  LBL_SUMMARY_TITLE,
  SHIPPING_AMOUNT_FREE,
  LBL_SUBTOTAL,
  LBL_DISCOUNTS,
  LBL_OPTIN_NEWS_LETTER,
} from '../locale';
import { formatPrice } from '../../common/utils';
import { CartIemsListSummary } from './CartItemsListSummary';
import { deliveryMethodsDesktopWidth } from '../../shipping/utils';
import NewsLetterContainer from '../../account/components/Newsletter';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  onIsConditionsAcceptedClick: () => void;
};

export const Summary = ({ onIsConditionsAcceptedClick }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQueries();
  const { focus, values, validation } = useSelector((state: RootState) => state.form.delivery);
  const { step, cart, shippingTypes } = useSelector((state: RootState) => state.cart);
  const {
    totalDiscount,
    originalBaseTotal,
    isDigital,
    couponAccepted,
    totalCouponAmount,
    shippingAmount,
    total,
  } = cart ?? {};
  const cmsContent = useSelector((state: RootState) => state.cms.cart);
  const conditionsCheckboxRef = useRef<HTMLDivElement>(null);
  const requiredAmountHome = cmsContent?.shipping_promo_amount ?? 85;
  const requiredAmountPickUp = cmsContent?.pickup_shipping_promo_amount ?? 85;

  useEffect(() => {
    if (focus === 'isConditionsAccepted' && isMobile) {
      const node = conditionsCheckboxRef.current;
      if (node) {
        node.focus();
      }
    }
  }, [focus]);

  const onConditionsClick = () => {
    dispatch(
      openModal({
        title: 'Conditions générales',
        content: (
          <>
            <Divider />
            <Generic />
          </>
        ),
      })
    );
  };

  const homeShippingPrice =
    shippingTypes.find((item) => item.label === ShippingTypes.HOME)?.amount ?? 4.9;
  const pickUpShippingPrice =
    shippingTypes.find((item) => item.label === ShippingTypes.PICKUP)?.amount ?? 4.5;

  return (
    <div
      id="cart-summary-block"
      css={css`
        background: ${colors.BACKGROUND};
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: ${step !== CartStep.DELIVERY ? '16px' : 0};
        margin: 0 -16px;
        @media (min-width: ${breakpoints.M}px) {
          margin: ${step === CartStep.LISTING && isDigital ? '0 0 16px 0' : 0};
          background: none;
          padding: 0;
        }
        @media (min-width: ${breakpoints.L}px) {
          margin: ${step === CartStep.LISTING && isDigital ? '0 0 24px 0' : 0};
        }
      `}
    >
      <h2
        id="cart-summary-title"
        css={css`
          font-size: 1.6rem;
          line-height: 110%;
          font-weight: 700;
          margin-block-start: 0;
          margin-block-end: 0;
          padding: 24px 0 16px 0;
          display: none;
          @media (min-width: ${breakpoints.S}px) {
            display: block;
            padding: 8px 0 16px 0;
          }
          @media (min-width: ${breakpoints.M}px) {
            padding: 24px 0 16px 0;
          }
        `}
      >
        {LBL_SUMMARY_TITLE}
      </h2>
      {step > CartStep.LISTING ? (
        <div
          css={css`
            display: none;
            @media (min-width: ${breakpoints.S}px) {
              display: block;
            }
          `}
        >
          <CartIemsListSummary step={step} />
        </div>
      ) : null}
      {step < CartStep.PAYMENT ? (
        <>
          <Coupon />
          <Divider my="l" color={colors.LIGHT} />
        </>
      ) : (
        <div
          css={css`
            display: none;
            @media (min-width: ${breakpoints.M}px) {
              display: block;
            }
          `}
        >
          <Divider my="l" color={colors.LIGHT} />
        </div>
      )}

      <div id="cart-total-block">
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
          `}
        >
          <p
            css={css`
              font-size: 1.4rem;
              line-height: 1.8rem;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {LBL_SUBTOTAL}
          </p>
          <p
            css={css`
              font-size: 1.4rem;
              line-height: 1.8rem;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {formatPrice(originalBaseTotal)}
          </p>
        </div>
        {totalDiscount > 0 ? (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {LBL_DISCOUNTS}
            </p>
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: ${colors.MAIN_SUCCESS};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {`-${formatPrice(totalDiscount)}`}
            </p>
          </div>
        ) : null}
        {couponAccepted ? (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {LBL_PROMO_CODE}
            </p>
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: ${colors.MAIN_SUCCESS};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {`-${formatPrice(totalCouponAmount)}`}
            </p>
          </div>
        ) : null}
        {step > CartStep.LISTING && !isDigital ? (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {LBL_DELIVERY}
            </p>
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: ${shippingAmount === 0 ? colors.MAIN_SUCCESS : colors.BLACK};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {shippingAmount === 0 ? SHIPPING_AMOUNT_FREE : formatPrice(shippingAmount)}
            </p>
          </div>
        ) : null}
        <TotalAmount step={step} />
        {(isDigital && step === CartStep.DELIVERY) || (!isDigital && step < CartStep.PAYMENT) ? (
          <Divider my="l" color={colors.LIGHT} />
        ) : null}
      </div>
      {step === CartStep.LISTING && !isDigital ? (
        <div
          id="cart-delivery-method-list"
          css={css`
            @media (min-width: ${breakpoints.M}px) {
              padding-bottom: 24px;
            }
          `}
        >
          <p
            css={css`
              font-size: 1.4rem;
              line-height: 110%;
              font-weight: 700;
              padding-bottom: 16px;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {LBL_DELIVERY_TITLE_SUMMARY}
          </p>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 8px;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 110%;
                color: ${colors.GREY};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {LBL_DELIVERY_CHOICE[0]}
            </p>
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 110%;
                color: ${colors.MAIN_SUCCESS};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {LBL_FREE}
            </p>
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 8px;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 110%;
                color: ${colors.GREY};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {`${LBL_DELIVERY_CHOICE[1]} `}
              {requiredAmountHome !== 0 && homeShippingPrice > 0 ? (
                <span
                  style={{ fontSize: '1.4rem', lineHeight: '110%', color: colors.MAIN_SUCCESS }}
                >
                  {`(gratuite à partir de ${requiredAmountHome}€)`}
                </span>
              ) : (
                <span style={{ fontSize: '1.4rem', lineHeight: '110%', color: colors.GREY }}>
                  {`(sans minimum d'achat)`}
                </span>
              )}
            </p>
            {total - shippingAmount >= requiredAmountHome || homeShippingPrice === 0 ? (
              <p
                css={css`
                  font-size: 1.4rem;
                  line-height: 100%;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  color: ${colors.MAIN_SUCCESS};
                `}
              >
                {LBL_FREE}
              </p>
            ) : (
              <p
                css={css`
                  font-size: 1.4rem;
                  line-height: 100%;
                  margin-block-start: 0;
                  margin-block-end: 0;
                `}
              >
                {formatPrice(homeShippingPrice)}
              </p>
            )}
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: space-between;
            `}
          >
            <p
              css={css`
                font-size: 1.4rem;
                line-height: 110%;
                color: ${colors.GREY};
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              {`${LBL_DELIVERY_CHOICE[2]} `}
              {requiredAmountPickUp !== 0 && pickUpShippingPrice > 0 ? (
                <span
                  style={{ fontSize: '1.4rem', lineHeight: '110%', color: colors.MAIN_SUCCESS }}
                >
                  {`(gratuite à partir de ${requiredAmountPickUp}€)`}
                </span>
              ) : (
                <span style={{ fontSize: '1.4rem', lineHeight: '110%', color: colors.GREY }}>
                  {`(sans minimum d'achat)`}
                </span>
              )}
            </p>
            {total - shippingAmount >= requiredAmountPickUp || pickUpShippingPrice === 0 ? (
              <p
                css={css`
                  font-size: 1.4rem;
                  line-height: 110%;
                  color: ${colors.MAIN_SUCCESS};
                  margin-block-start: 0;
                  margin-block-end: 0;
                `}
              >
                {LBL_FREE}
              </p>
            ) : (
              <p
                css={css`
                  font-size: 1.4rem;
                  line-height: 110%;
                  margin-block-start: 0;
                  margin-block-end: 0;
                `}
              >
                {formatPrice(pickUpShippingPrice)}
              </p>
            )}
          </div>
        </div>
      ) : null}
      {step === CartStep.DELIVERY && (
        <>
          <div
            css={css`
              display: inline-flex;
              padding-bottom: 24px;
            `}
            id="cart-cgv-block"
          >
            <Checkbox
              id="checkbox-terms-conditions"
              ref={conditionsCheckboxRef}
              onChange={onIsConditionsAcceptedClick}
              checked={values.isConditionsAccepted}
              errMsg={validation.isConditionsAccepted}
            >
              <div
                css={css`
                  display: inline-flex;
                  align-items: center;
                  gap: 4px;
                  flex-wrap: wrap;
                `}
              >
                <p
                  css={css`
                    font-size: 1.2rem;
                    margin-block-start: 0;
                    margin-block-end: 0;
                  `}
                >
                  J’accepte et je reconnais avoir pris connaissance des
                </p>
                <button
                  className="anchor-animated"
                  data-testid="cart-btn-terms-conditions"
                  id="cart-btn-terms-conditions"
                  type="button"
                  onClick={onConditionsClick}
                  css={css`
                    font-size: 1.2rem;
                  `}
                >
                  <span>conditions générales de vente.</span>
                </button>
              </div>
            </Checkbox>
          </div>

          <div
            css={css`
              display: block;
              @media (min-width: ${breakpoints.S}px) {
                display: none;
              }
              @media (min-width: ${breakpoints.L}px) {
                width: ${step === CartStep.DELIVERY ? `${deliveryMethodsDesktopWidth}px` : 'auto'};
              }
            `}
          >
            <p
              css={css`
                background: ${colors.WHITE};
                margin-left: -16px;
                margin-right: -16px;
                padding: 16px 16px 0 16px;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: left;
                font-weight: 700;
                margin-block-start: 0;
                margin-block-end: 0;
                @media (min-width: ${breakpoints.S}px) {
                  margin-top: 0;
                }
              `}
            >
              {LBL_OPTIN_NEWS_LETTER}
            </p>
            <NewsLetterContainer isOnCartPage />
          </div>
        </>
      )}
    </div>
  );
};
