import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import Box from '../box';
import Button from '../button';
import Text from '../text';
import Icon from '../icon';
import { Picture, ImageContainer } from '../image';
import ZoomImage from './components/ZoomImage';
import { ProductImageList } from '../../modules/product/utils';
import { BannerVideo } from '../../modules/banners/components/BannerVideo';

type Props = {
  images: ProductImageList;
  thumbnailHeight?: number;
  numberOfThumbnailsShown?: number;
  children?: React.ReactNode;
  video?: {
    url?: string;
    thumbnail?: string;
  };
};

const THUMBNAIL_MARGIN = 16;
const BUTTON_HEIGHT = 32;

const Slider = ({
  children,
  images,
  video,
  thumbnailHeight = 80,
  numberOfThumbnailsShown = 6,
}: Props) => {
  const THUMBNAIL_TOTAL_HEIGHT = thumbnailHeight + THUMBNAIL_MARGIN;
  const MIN_IMAGE_WIDTH =
    ((THUMBNAIL_TOTAL_HEIGHT * numberOfThumbnailsShown + BUTTON_HEIGHT) * 3) / 4;
  const THUMBNAIL_WIDTH = (thumbnailHeight * 3) / 4;

  const items = [...images, video?.url ? video : undefined].filter(Boolean);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [showUpButton, setShowUpButton] = React.useState(false);
  const [showDownButton, setShowDownButton] = React.useState(
    items.length > numberOfThumbnailsShown
  );

  const videoIndex = items.length - 1;
  const showVideo = video?.url && activeIndex === videoIndex;

  const divRef = React.useRef<HTMLDivElement>(null);

  const handleDownClick = () => {
    if (divRef.current) {
      const newTop = divRef.current.scrollTop + THUMBNAIL_TOTAL_HEIGHT;
      divRef.current.scrollTo({ behavior: 'smooth', top: newTop });
      if (newTop >= THUMBNAIL_TOTAL_HEIGHT * (items.length - numberOfThumbnailsShown)) {
        setShowDownButton(false);
      }
      setShowUpButton(true);
    }
  };

  const handleUpClick = () => {
    if (divRef.current) {
      const newTop = divRef.current.scrollTop - THUMBNAIL_TOTAL_HEIGHT;
      divRef.current.scrollTo({ behavior: 'smooth', top: newTop });
      if (newTop <= 0) {
        setShowUpButton(false);
      }
      setShowDownButton(true);
    }
  };

  return (
    <Box display="grid" gridGap="m" gridAutoFlow="column" justifyContent="center">
      <Box display="grid" gridGap="m" gridAutoFlow="row" alignSelf="start">
        <Box position="relative">
          {showUpButton && (
            <Box position="absolute" zIndex="absolute" top={0} width="100%">
              <StyledButton id="btn-slider-up" preset="primaryLight" onClick={handleUpClick}>
                <Icon name="chevronUp" />
              </StyledButton>
            </Box>
          )}
          <Box
            display="grid"
            gridGap="m"
            gridAutoFlow="row"
            alignSelf="start"
            maxHeight="560px"
            width={`${THUMBNAIL_WIDTH}px`}
            overflow="hidden"
            ref={divRef}
          >
            {images.map((image, index) => (
              <Box
                key={index}
                width={`${THUMBNAIL_WIDTH}px`}
                height={`${thumbnailHeight}px`}
                onClick={() => setActiveIndex(index)}
                border={index === activeIndex ? '1px solid' : ''}
                borderColor={index === activeIndex ? 'BLACK' : ''}
              >
                <ImageContainer>
                  <Picture>
                    <source srcSet={image.thumbnail.webp} type="image/webp" />
                    <source srcSet={image.thumbnail.jpg} type="image/jpeg" />
                    <img src={image.thumbnail.jpg} alt={`Thumbnail ${image.thumbnail.alt}`} />
                  </Picture>
                </ImageContainer>
              </Box>
            ))}
            {video?.url && (
              <Box
                width={`${THUMBNAIL_WIDTH}px`}
                height={`${thumbnailHeight}px`}
                onClick={() => setActiveIndex(videoIndex)}
                border={showVideo ? '1px solid' : ''}
                borderColor={showVideo ? 'BLACK' : ''}
              >
                <ImageContainer>
                  <Picture>
                    <img src={video?.thumbnail} />
                  </Picture>
                </ImageContainer>
              </Box>
            )}
          </Box>
        </Box>
        {showDownButton && (
          <StyledButton id="btn-slider-down" preset="primaryLight" onClick={handleDownClick}>
            <Icon name="chevronDown" />
          </StyledButton>
        )}
      </Box>
      <Box position="relative">
        {!showVideo && (
          <>
            {children}
            <Box position="absolute" m="m" zIndex="absolute">
              <Text preset="caption" color="GREY">{`${activeIndex + 1} sur ${
                images.length
              } `}</Text>
            </Box>
          </>
        )}
        <Box minWidth={MIN_IMAGE_WIDTH} height="100%">
          {showVideo ? (
            <BannerVideo video={video?.url ?? ''} />
          ) : (
            <ZoomImage
              src={images[activeIndex].default}
              srcLarge={images[activeIndex].zoomed}
              srcThumbnail={images[activeIndex].thumbnail}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Button)(
  css({
    height: [`${BUTTON_HEIGHT}px`, `${BUTTON_HEIGHT}px`],
    ':hover': {
      bg: 'BACKGROUND',
      opacity: 1,
    },
  })
);

export default Slider;
