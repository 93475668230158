import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import UspSection from '../../home/components/UspSection';
import { RootState } from 'src/shared/store/rootReducer';
import { SHIPUP_KEY } from '../../api/constants';
import useScript from '../../common/hooks/useScript';
import { breakpoints } from '../../../design-system';

const StyledOrderTrackingContainer = styled.div`
  margin: 32px 16px 0 16px;

  @media (min-width: ${breakpoints.S}px) {
    margin: 32px 64px 0 64px;
  }

  form.shipup-tracker-search {
    margin: 100px 16px 0 16px;
    @media (min-width: ${breakpoints.S}px) {
      margin: 150px 64px 0 64px;
    }
  }

  #shipup-container {
    min-height: 450px;

    @media (min-width: ${breakpoints.S}px) {
      min-height: 500px;
    }
  }
`;

export const OrderTracking = () => {
  const { usp, usp_main_title } = useSelector((state: RootState) => state.cms.usp);

  const hasScriptLoaded = useScript({
    id: 'shipup-js',
    src: 'https://cdn.shipup.co/latest_v2/shipup-js.js',
    position: 'head',
  });

  const hasStylesLoaded = useScript({
    id: 'shipup-jcss',
    src: 'https://cdn.shipup.co/latest_v2/shipup.css',
    position: 'head',
  });

  useEffect(() => {
    if (hasScriptLoaded && hasStylesLoaded && window.ShipupJS) {
      const shipupInstance = new window.ShipupJS.default(SHIPUP_KEY);
      const elementToRender = document.getElementById('shipup-container');
      shipupInstance.render(elementToRender);
    }
  }, [hasScriptLoaded, hasStylesLoaded]);

  return (
    <StyledOrderTrackingContainer>
      <div id="shipup-container" />
      <UspSection content={usp} title={usp_main_title ?? ''} />
    </StyledOrderTrackingContainer>
  );
};
