import React, { useEffect, useRef, useState } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { RootState } from 'src/shared/store/rootReducer';
import { CmsSliderSticky } from '../../catalog/components/CmsSliderSticky';
import { CmsSliderWithNumber } from '../../catalog/components/CmsSliderWithNumber';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes, CmsSlider, CmsToptenSlice } from '../../cms/types';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { TopTenBlock } from './TopTenBlock';
import { breakpoints, colors } from '../../../design-system';
import { TOP_TEN_PAGE_MAX_WIDTH, TOP_TEN_SUGGESTIONS_CTA_TABLET_WIDTH } from '../utils';
import { SeoDescription } from '../../common/components/SeoDescription';
import { hasValidCmsText } from '../../home/utils';
import { emptyText } from '../../cms/state';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { useStickySliderObserver } from '../../common/hooks/useStickySliderObserver';

const StyledTopTenContainer = styled.div`
  width: 100vw;
  overflow: hidden;
  padding-top: 8px;
  max-width: ${TOP_TEN_PAGE_MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  text-align: center;

  @media (min-width: ${breakpoints.M}px) {
    padding: 16px 24px 0 24px;
  }

  .top-ten__title {
    font-family: 'Ogg-Text';
    font-size: 3.2rem;
    line-height: 110%;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  h1.top-ten__title {
    padding: 0 16px;

    @media (min-width: ${breakpoints.M}px) {
      padding: 0 80px;
    }
  }
  h2.top-ten__title {
    width: calc(100% - 16px);

    @media (min-width: ${breakpoints.M}px) {
      width: 100%;
    }
  }
  .top-ten__subtitle {
    font-size: 1.6rem;
    line-height: 24px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0 16px;

    @media (min-width: ${breakpoints.M}px) {
      padding: 0 80px;
    }
  }
  .top-ten-testimonial {
    padding: 16px;
    margin-bottom: 40px;
    text-align: left;
    background: ${colors.BACKGROUND};
    display: block;
    @media (min-width: ${breakpoints.L}px) {
      display: none;
    }
  }
  .icon-div {
    filter: invert();
    margin-bottom: 16px;
  }
  .top-ten-page-testimonial__title {
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: 'Ogg-text';
    font-size: 2rem;
    font-weight: 700;
    line-height: 130%;
    @media (min-width: ${breakpoints.L}px) {
      font-size: 2.4rem;
      margin-bottom: 16px;
    }
  }
  .top-ten-page-testimonial__text {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.6rem;
    line-height: 24px;
    color: ${colors.MAIN_GREY};
  }
  .top-ten-page-testimonial__author {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 700;
  }
  .top-ten-suggestion-link {
    margin: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    background: ${colors.BLACK};
    color: ${colors.WHITE};
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    @media (min-width: ${breakpoints.S}px) {
      width: ${TOP_TEN_SUGGESTIONS_CTA_TABLET_WIDTH}px;
    }
  }
`;

export const TopTenPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const uid = pathname.split('/')?.[2];
  const { isMobile } = useMediaQueries();
  const sliderWithNumberRef = useRef<HTMLDivElement | null>(null);
  const { isLoading } = useSelector((state: RootState) => state.cms);
  const {
    body,
    body1,
    slider_with_number_is_active_desktop,
    slider_with_number_is_active_mobile,
    slider_with_number_is_sticky_mobile,
    slider_with_number_text_is_active,
    top_ten_bottom_seo_description,
    top_ten_meta_description,
    top_ten_meta_title,
    top_ten_bottom_description_alignment,
  } = useSelector((state: RootState) => state.cms.bestsellers);
  const sliderItems =
    body?.find((el: CmsSlider) => el.slice_type === 'slider_with_number')?.items ?? [];
  const { primary, items: topTenItems } = body1?.find(
    (el: CmsToptenSlice) => el.slice_type === 'bestsellers-block'
  ) ?? { primary: undefined, items: [] };
  const { top_ten_page_title, top_ten_page_subtitle } = primary ?? {
    top_ten_page_subtitle: undefined,
    top_ten_page_title: undefined,
  };
  const [stickySliderIsActive, setStickySliderIsActive] = useState(
    !slider_with_number_is_active_mobile && slider_with_number_is_sticky_mobile
  );
  const [allBlocksLoaded, setAllBlocksLoaded] = useState(false);
  const [shouldDisplayStickySlider, setShouldDisplayStickySlider] = useState(false);
  const alignmentValue = top_ten_bottom_description_alignment?.toLocaleLowerCase();
  const alignment: 'left' | 'center' | 'right' =
    alignmentValue === 'left' || alignmentValue === 'center' || alignmentValue === 'right'
      ? alignmentValue
      : 'left';

  useEffect(() => {
    dispatch(
      loadCmsContent(CmsCustomTypes.bestsellers, {
        isControlled: true,
        uid,
      }) as unknown as UnknownAction
    );
  }, [pathname]);

  useEffect(() => {
    setShouldDisplayStickySlider(
      Boolean(
        !isLoading &&
          isMobile &&
          slider_with_number_is_sticky_mobile &&
          (stickySliderIsActive || !slider_with_number_is_active_mobile)
      )
    );
  }, [
    isLoading,
    isMobile,
    slider_with_number_is_sticky_mobile,
    stickySliderIsActive,
    slider_with_number_is_active_mobile,
  ]);

  useStickySliderObserver({
    sliderElement: sliderWithNumberRef?.current,
    isDataLoaded: allBlocksLoaded && !isLoading,
    isMobile,
    slider_with_number_is_active_mobile,
    setStickySliderIsActive,
  });

  return isLoading || !topTenItems ? null : (
    <>
      {!allBlocksLoaded && <LoadingScreen />}
      {shouldDisplayStickySlider && <CmsSliderSticky items={sliderItems} />}
      {(top_ten_meta_title || top_ten_meta_description) && (
        <Helmet>
          {top_ten_meta_title && (
            <>
              <title>{top_ten_meta_title}</title>
              <meta property="og:title" content={top_ten_meta_title} />
            </>
          )}
          {top_ten_meta_description && (
            <>
              <meta name="description" content={top_ten_meta_description} />
              <meta property="og:description" content={top_ten_meta_description} />
            </>
          )}
        </Helmet>
      )}
      <StyledTopTenContainer>
        {top_ten_page_title && <h1 className="top-ten__title">{top_ten_page_title}</h1>}
        {top_ten_page_subtitle && <p className="top-ten__subtitle">{top_ten_page_subtitle}</p>}
        {sliderItems?.length > 0 && (
          <div ref={sliderWithNumberRef}>
            <CmsSliderWithNumber
              items={sliderItems}
              slider_with_number_is_active_desktop={slider_with_number_is_active_desktop}
              slider_with_number_is_active_mobile={slider_with_number_is_active_mobile}
              slider_with_number_text_is_active={slider_with_number_text_is_active}
            />
          </div>
        )}
        {topTenItems.map((item, index) => {
          return (
            <TopTenBlock
              item={item}
              itemsLength={topTenItems.length}
              index={index}
              key={`top-ten-block-${index}`}
              stickySliderIsActive={stickySliderIsActive ?? false}
              allBlocksLoaded={allBlocksLoaded}
              setAllBlocksLoaded={setAllBlocksLoaded}
            />
          );
        })}
        {allBlocksLoaded && hasValidCmsText(top_ten_bottom_seo_description) && (
          <SeoDescription
            seo_description={top_ten_bottom_seo_description || [...emptyText]}
            alignment={alignment || 'left'}
          />
        )}
      </StyledTopTenContainer>
    </>
  );
};
