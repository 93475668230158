import types from './actionTypes';
import { PaymentActions } from './actions';

import { paymentState } from './state';
import { State } from './types';

export default function payment(state = paymentState, action: PaymentActions): State {
  switch (action.type) {
    case types.SET_CARD_DELETED:
      return {
        ...state,
        cardDeleted: state.cardDeleted.concat(action.payload),
      };
    case types.SET_FORTER_TOKEN:
      return {
        ...state,
        forterToken: action.payload,
      };
    default:
      return state;
  }
}
