import { action, ActionType } from 'typesafe-actions';
import types from './actionTypes';

export const updateLimit = (payload) => action(types.UPDATE_LIMIT, payload);
export const resetLimits = () => action(types.RESET_LIMITS);

export const updateCurrentRefinementsGroup = (payload) =>
  action(types.UPDATE_CURRENT_REFINEMENTS_GROUP, payload);
export const updateCurrentRefinementsItems = (payload) =>
  action(types.UPDATE_CURRENT_REFINEMENTS_ITEMS, payload);

export const updateRefinementsItems = (payload) => action(types.UPDATE_REFINEMENTS_ITEMS, payload);

export const updateStep = (payload) => action(types.UPDATE_STEP, payload);
export const updateOptions = (payload: { key: string; value: number }) =>
  action(types.UPDATE_OPTIONS, payload);
export const updateSelectedItems = (payload: { key: string; value: string[] | [] }) =>
  action(types.UPDATE_SELECTED_ITEMS, payload);
export const setIsLoading = (payload) => action(types.SET_IS_LOADING, payload);
export type FilterActions = ActionType<
  | typeof updateLimit
  | typeof resetLimits
  | typeof updateStep
  | typeof updateCurrentRefinementsGroup
  | typeof updateCurrentRefinementsItems
  | typeof updateRefinementsItems
  | typeof updateOptions
  | typeof updateSelectedItems
  | typeof setIsLoading
>;
