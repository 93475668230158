import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationType, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Box, Button, Divider, Icon, Text } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { getAllStores } from '../actions';
import { BASE_LOC, isValidStoreId } from '..//utils';
import StoreAddress from './StoreAddress';
import BackButton from '../../common/components/BackButton';
import StorePhotoGallery from './StorePhotoGallery';
import OpeningHours from './OpeningHours';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import StoreServices from './StoreServices';
import StoreMap from './StoreMap';
import StorePromotions from './StorePromotions';
import StoreAssistances from './StoreAssistances';
import StoreContact from './StoreContact';
import paths from '../../routing/paths';
import LoadingStore from './LoadingStore';
import {
  ACCEPTED_PAYMENT_METHODS,
  BACK_TO_STORE_LIST,
  CASH,
  CHEQUE,
  CREDIT_CARDS,
  GIFTS_CARDS,
  ITINERARY,
  STORE_NOT_FOUND,
  STORE_PAGE_DESCRIPTION,
  STORE_PAGE_TITLE,
} from '../locale';
import { PaymentOptions, StoreService } from '../types';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

const StoreDetails = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const paymentOptionsRef = React.useRef<HTMLDivElement>(null);

  const [showPaymentOptionsTitle, setShowPaymentOptionsTitle] = React.useState<boolean>(false);

  const { isMobile } = useMediaQueries();

  const { storeId } = useParams<{ storeId?: string }>();
  const { allStores, isFetching } = useSelector((state: RootState) => state.stores) ?? [];

  React.useEffect(() => {
    if (storeId && isValidStoreId(storeId) && allStores.length === 0) {
      dispatch(getAllStores({}) as unknown as UnknownAction);
      dispatch(loadCmsContent(CmsCustomTypes.storelocator) as unknown as UnknownAction);
    }
  }, [storeId]);

  React.useEffect(() => {
    setShowPaymentOptionsTitle((paymentOptionsRef.current?.children ?? []).length > 0);
  }, [paymentOptionsRef.current?.children.length]);

  if (isFetching) {
    return (
      <Box my="l" mx={['na', 'm']}>
        <LoadingStore />
      </Box>
    );
  }
  const store = allStores.find((store) => store.id === storeId);

  if (!store) {
    return <Text>{STORE_NOT_FOUND}</Text>;
  }

  const name = store?.name ?? '';
  const phone = store?.mainPhone ?? '';
  const address = store?.address ?? {};
  const { latitude, longitude } = store?.displayCoordinate ?? {
    latitude: BASE_LOC.lat,
    longitude: BASE_LOC.lng,
  };
  const paymentOptions: PaymentOptions[] = store.paymentOptions ?? [];
  const services: StoreService[] = store.services ?? [];
  const storeImageUrl = store?.photoGallery?.[0]?.url ?? '';

  const handleClickBack = () => {
    if (
      navigationType === NavigationType.Push ||
      (navigationType === NavigationType.Pop && length > 1)
    ) {
      navigate(-1);
    } else {
      navigate(paths.STORE_LOCATOR);
    }
  };

  const openGoogleMaps = () => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`);
  };

  return (
    <>
      <Helmet>
        <title>{STORE_PAGE_TITLE(name)}</title>
        <meta property="og:title" content={STORE_PAGE_TITLE(name)} />
        {storeImageUrl && <meta property="og:image" content={storeImageUrl} />}
        <meta name="description" content={STORE_PAGE_DESCRIPTION(name)} />
        <meta property="og:description" content={STORE_PAGE_DESCRIPTION(name)} />
      </Helmet>

      <Box position="relative" pb="l">
        <Box m="auto">
          <Box display="flex" flexDirection={['column-reverse', 'column']} mx={['m', 'na']}>
            <Box mt="l">
              <StoreAddress name={name} address={address} />
            </Box>
            <Box>
              <BackButton
                id="store-page-back"
                onClick={handleClickBack}
                backText={BACK_TO_STORE_LIST}
              />
              <Divider my="s" color="BLACK" />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={['column', 'row']}
            width="100%"
            mt={['m', 's']}
            minHeight={['100%', '440px']}
          >
            <Box width={['100%', '50%']} mr={['na', 'm']}>
              <StorePhotoGallery photoGallery={store.photoGallery} />
            </Box>
            <Box width={['100%', '50%']} mx={['na', 'm']} p={['m', 'na']}>
              <OpeningHours hours={store.hours ?? {}} />
              <Box mt="m" display="flex">
                <Box mr="xs">
                  <Icon name="phone" />
                </Box>
                <Text textDecoration="underline">{store?.mainPhone ?? ''}</Text>
              </Box>
              <Box my="l" ml="m">
                {showPaymentOptionsTitle && (
                  <Text fontWeight="bold" textTransform="uppercase" mb="m">
                    {ACCEPTED_PAYMENT_METHODS}
                  </Text>
                )}
                <Box ref={paymentOptionsRef}>
                  {paymentOptions.some((method) =>
                    [PaymentOptions.VISA, PaymentOptions.MASTERCARD].includes(method)
                  ) && (
                    <Box my="-45px" display="flex" alignItems="center">
                      <Icon name="paymentCards" size={134} />
                      <Text ml="s">{CREDIT_CARDS}</Text>
                    </Box>
                  )}
                  {paymentOptions.includes(PaymentOptions.GIFTSCARD) && (
                    <Box display="flex" alignItems="center">
                      <Icon name="darjeelingCard" size={38} />
                      <Text ml="s">{GIFTS_CARDS}</Text>
                    </Box>
                  )}
                  {paymentOptions.includes(PaymentOptions.CHECK) && (
                    <Box display="flex" alignItems="center">
                      <Icon name="cheque" size={38} />
                      <Text ml="s">{CHEQUE}</Text>
                    </Box>
                  )}
                  {paymentOptions.includes(PaymentOptions.CASH) && (
                    <Box display="flex" alignItems="center">
                      <Icon name="cash" size={38} />
                      <Text ml="s">{CASH}</Text>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box mt="l">
                <StoreServices services={services} />
              </Box>
            </Box>
          </Box>

          <Box position="relative" width="100vw" pt={['80%', '30%']} my="m">
            <StoreMap {...{ latitude, longitude, openGoogleMaps }} />
          </Box>
        </Box>

        <Box mt={['xl', 'xxxl']}>
          <StorePromotions />
        </Box>

        <Box mt={['xl', 'xxxl']}>
          <StoreAssistances />
        </Box>

        <Box mt={['xl', 'xxxl']} maxWidth="mws" m="auto">
          <StoreContact {...{ name, phone }} />
        </Box>

        {isMobile && (
          <Box position="sticky" left="0" right="0" bottom="0" p="s">
            <Button id="store-map-directions-btn" onClick={openGoogleMaps}>
              {ITINERARY}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StoreDetails;
