import { cdnBaseImageUrl } from '../common/constants';

export const sizingGuideFittingAdvice = [
  {
    image: `${cdnBaseImageUrl.production}9663e892-99b2-453a-841b-3736fdf45f85_fitting-advice-1.jpg`,
    title: 'Régler le dos',
    description:
      'Le dos doit être positionné au même niveau que l’avant, il ne doit pas être trop serré ni trop lâche (on doit pouvoir passer un doigt entre le soutien-gorge et le dos), il doit idéalement être réglé au plus large.',
  },
  {
    image: `${cdnBaseImageUrl.production}d3cafd94-8004-4059-93d0-e07cb99ce916_fitting-advice-2.jpg`,
    title: 'Régler les bretelles',
    description:
      'Ajuster les bretelles et vérifier l’élasticité au niveau de l’épaule en glissant votre doigt sous la bretelle, vous devez sentir une légère pression et la bretelle ne doit pas glisser sur l’épaule.',
  },
  {
    image: `${cdnBaseImageUrl.production}67a5e087-3fd5-4020-918c-84b94512af31_fitting-advice-3.jpg`,
    title: 'Repositionner la poitrine',
    description:
      'Tenir la base du soutien-gorge d’une main et plonger l’autre main à l’intérieur du bonnet, puis remonter la poitrine vers le centre et vers le haut et tirer sur la partie supérieure du bonnet. Un geste à refaire chez soi tous les matins !',
  },
  {
    image: `${cdnBaseImageUrl.production}8f821a6b-d320-401d-b70c-70ed4dbeb7b3_fitting-advice-4.jpg`,
    title: 'Positionner les armatures',
    description:
      'S’assurer que l’armature est bien positionnée : l’armature doit bien englober le sein sans blesser. Elle ne doit pas être complètement sous le bras ni “rentrer” dans le sein, elle ne doit pas se faire sentir.',
  },
  {
    image: `${cdnBaseImageUrl.production}dfe0f804-fbb6-4187-9a0e-90b663f9997f_fitting-advice-5.jpg`,
    title: 'Appliquer l’entre-bonnet',
    description:
      'S’assurer que l’entre-bonnet est confortable et bien plaqué sur la peau (on ne doit pas pouvoir passer de doigt en dessous).',
  },
  {
    image: `${cdnBaseImageUrl.production}7cd9943d-ef13-4aae-a63b-48c869ce07a0_fitting-advice-6.jpg`,
    title: 'Vérifier le remplissage du bonnet',
    description:
      'Vérifier que la poitrine est bien contenue dans le bonnet et qu’elle n’est pas coupée sur les bords.',
  },
  {
    image: `${cdnBaseImageUrl.production}731e7f9e-46a3-4267-ac44-132dc79af13d_fitting-advice-7.jpg`,
    title: 'Lever les bras',
    description:
      'Les armatures doivent rester bien en place, plaquées sur la peau, elles ne doivent pas se soulever.',
  },
];
