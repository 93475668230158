import * as React from 'react';
import styled from '@emotion/styled';

import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { CmsCart } from '../../cms/types';

type Props = {
  cmsContent: CmsCart;
};

type StyledContainerProps = {
  text_color: string;
  background_color: string;
  isClickable: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  a.cart-banner-anchor-block {
    display: block;
    background-color: ${(props) => props.background_color};
    padding: 8px 16px;
    text-decoration: none;
    text-align: center;
    pointer-events: ${(props) => (props.isClickable ? 'initial' : 'none')};
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (min-width: ${breakpoints.S}px) {
      padding: 16px;
    }

    @media (hover: hover) {
      :hover {
        opacity: ${(props) => (props.isClickable ? opacities.HOVERED : 1)};
      }
    }
  }

  .cart-banner-title {
    font-size: 1.4rem;
    line-height: 1.8rem;

    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  .cart-banner-text {
    font-size: 1.2rem;
    line-height: 1.6rem;

    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  .cart-banner-title,
  .cart-banner-text {
    margin: 0;
    color: ${(props) => props.text_color};
    font-weight: 700;
  }
`;

export const CmsBlock = ({ cmsContent }: Props) => {
  const {
    activate_banner,
    title,
    block: text,
    link,
    text_color,
    background_color,
  } = cmsContent ?? {};

  return !cmsContent || !(title || text) || !activate_banner ? null : (
    <StyledContainer
      id="cart-banner-block"
      text_color={text_color ?? colors.BLACK}
      background_color={background_color ?? colors.BACKGROUND}
      isClickable={Boolean(link)}
    >
      <a className="cart-banner-anchor-block" data-testid="cart-banner-anchor" href={link}>
        {title && <h2 className="cart-banner-title">{title}</h2>}
        {text && <p className="cart-banner-text">{text}</p>}
      </a>
    </StyledContainer>
  );
};
