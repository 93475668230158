import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Icon, breakpoints, durations, opacities } from '../../../design-system';
export const StyledLogoIcon = styled(Icon)`
  width: 137px;
  height: 18px;
  @media (min-width: ${breakpoints.S}px) {
    width: 128px;
    height: 17px;
  }
`;

export const Logo = () => (
  <Link
    id="darjeeling-logo-link"
    to="/"
    data-testid="darjeeling-logo-link"
    css={css`
      transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
      @media (hover: hover) {
        :hover {
          opacity: ${opacities.HOVERED};
        }
      }
    `}
  >
    <StyledLogoIcon name="logo" className="header__logo" />
  </Link>
);
