import { action, ActionType } from 'typesafe-actions';

import types from './actionTypes';
import { CatalogProduct, AlgoliaQuery } from './types';

export const updateCatalog = (payload: { hits: CatalogProduct[]; indexName?: string }) =>
  action(types.UPDATE_CATALOG, payload);

export const setLastPositionId = (payload: string) => action(types.SET_LAST_POSITION_ID, payload);

export const setAlgoliaQuery = (payload: AlgoliaQuery | undefined) =>
  action(types.SET_ALGOLIA_QUERY, payload);

export const setIsBannerOnScreen = (payload: boolean) =>
  action(types.SET_IS_BANNER_ON_SCREEN, payload);

export type CatalogActions = ActionType<
  | typeof updateCatalog
  | typeof setLastPositionId
  | typeof setAlgoliaQuery
  | typeof setIsBannerOnScreen
>;
