import { createSelector } from 'reselect';

import { RootState } from '../../store/rootReducer';
import { checkCartValidity, checkCartItemAvailability } from './utils';
import { CartItem } from './types';

const cartItemsSelector = (state: RootState) => state.cart.cart?.items ?? [];

export const cartValiditySelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  checkCartValidity(items)
);

export const numberOfItemsSelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  (items ?? []).reduce((accumulator, item) => accumulator + item.quantity, 0)
);

export const orderedCartItemsSelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  (items ?? []).sort((a, b) => (a.freeProduct && b.freeProduct ? 0 : a.freeProduct ? -1 : 1))
);

export const giftMessagesSelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  (items ?? []).map((item) => (item.giftMessage ? item.giftMessage : '')).filter(Boolean)
);

export const unavailableItemsSelector = createSelector(
  orderedCartItemsSelector,
  (items: CartItem[]) => (items ?? []).filter((item) => !checkCartItemAvailability(item))
);

export const availableItemsSelector = createSelector(
  orderedCartItemsSelector,
  (items: CartItem[]) => (items ?? []).filter((item) => checkCartItemAvailability(item))
);

const giftItemsSelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  (items ?? []).filter((item) => item.freeProduct)
);

export const numberOfGiftItemsSelector = createSelector(giftItemsSelector, (items: CartItem[]) =>
  (items ?? []).reduce((accumulator, item) => accumulator + item.quantity, 0)
);

export const hasGiftSelector = createSelector(
  giftItemsSelector,
  (items: CartItem[]) => (items ?? []).length > 0
);

export const hasDigitalSelector = createSelector(cartItemsSelector, (items: CartItem[]) =>
  (items ?? []).some((item) => item.isDigital)
);
