import React from 'react';
import styled from '@emotion/styled';

import { Icon, breakpoints, colors } from '../../../design-system';
import { Color as ColorType } from '../../product/types';
import { Color } from '../../catalog/components/Color';
import { LBL_COLOR } from '../locale';

type Props = {
  objectID: string;
  detailedColorLabel: string;
  colorRef: string;
  productRef: string;
  colorVariants: ColorType[];
  isStickyCta?: boolean;
  reverseToolTip?: boolean;
  leftAlignToolTip?: boolean;
  setColorBlockIsOpen?: (value: boolean) => void;
  onColorClick: (colorRef: string) => void;
};

const StyledColorsContainer = styled.div<{ isStickyCta: boolean }>`
  display: ${(props) => (props.isStickyCta ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.isStickyCta ? 'column' : 'unset')};
  position: ${(props) => (props.isStickyCta ? 'relative' : 'initial')};
  width: 100%;
  align-items: left;
  justify-content: center;

  @media (min-width: ${breakpoints.S}px) {
    padding: ${(props) => (props.isStickyCta ? '16px' : 0)};
  }

  .product-color-label,
  .product-detailed-color-label {
    margin: 0;
    font-size: 1.4rem;
    color: ${colors.BLACK};
  }

  .product-color-label {
    margin-bottom: 16px;
  }

  .product-detailed-color-label {
    font-weight: 700;
  }

  .product-colors-list {
    display: grid;
    align-items: center;
    grid-gap: 16px;
    grid-template-columns: repeat(9, min-content);
    grid-auto-rows: min-content;
  }

  .pdp-sticky-cta-close-button {
    border: none;
    background-color: unset;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: ${breakpoints.S}px) {
      top: 16px;
      right: 16px;
    }
  }
`;

export const Colors = ({
  objectID,
  detailedColorLabel,
  productRef,
  colorRef,
  colorVariants,
  isStickyCta = false,
  reverseToolTip = false,
  leftAlignToolTip = false,
  setColorBlockIsOpen = () => null,
  onColorClick,
}: Props) => {
  return (
    <StyledColorsContainer
      isStickyCta={isStickyCta}
      id={`product-${objectID}-colors-block-${isStickyCta ? 'sticky-cta' : 'plp'}`}
    >
      <p className="product-color-label">
        {`${LBL_COLOR} : `}
        <span className="product-detailed-color-label">{detailedColorLabel}</span>
      </p>
      {!colorVariants?.length ? null : (
        <div className="product-colors-list">
          {colorVariants.map((color, index) => {
            const isSelected = color.colorRef === colorRef;
            return (
              <Color
                key={`${productRef}-${color.colorRef}-${index}`}
                productRef={productRef}
                isSelected={isSelected}
                color={color}
                handleColorClick={(color) => onColorClick(color.colorRef)}
                reverseToolTip={reverseToolTip}
                leftAlignToolTip={leftAlignToolTip}
              />
            );
          })}
        </div>
      )}
      {isStickyCta && (
        <button className="pdp-sticky-cta-close-button" data-testid="pdp-sticky-cta-close-button">
          <Icon name="close" size={16} onClick={() => setColorBlockIsOpen(false)} />
        </button>
      )}
    </StyledColorsContainer>
  );
};
