export type State = {
  user?: User;
  isLoggedIn: boolean;
  errLogin: string;
  errSignup: string;
  errSocial: string;
  redirectTo: string;
  userExist: boolean | null;
  hasForgotPassword: boolean;
};

export type User = {
  aud: string;
  auth_time: number;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  sub: string;
  token_use: string;
  website: string;
};

export type ParamsLogin = {
  email: string;
  password: string;
};

export type ParamsSignUp = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  optInEmail: boolean;
};

export enum ErrorCodes {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  ExpiredCodeException = 'ExpiredCodeException',
  LimitExceededException = 'LimitExceededException',
  UsernameExistsException = 'UsernameExistsException',
  InvalidParameterException = 'InvalidParameterException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  CodeMismatchException = 'CodeMismatchException',
}

export enum SocialProviders {
  facebook = 'facebook',
  google = 'google',
  apple = 'apple',
}
