import React from 'react';
import styled from '@emotion/styled';

import { Icon, colors } from '../../../design-system';

type Props = {
  cleaningAdvice: string;
  productComposition: string;
};

const StyledContainer = styled.div`
  width: 100%;

  .composition-ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 24px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${colors.MAIN_GREY};
  }

  .composition-section {
    list-style: none;
    padding: 4px 0;
    margin-left: -24px;
  }

  .composition-li {
    &:marker {
      padding-left: 16px;
    }
  }

  .cleaning-advice-block {
    margin-top: 16px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: ${colors.BACKGROUND};
    display: flex;
    align-items: center;
  }

  .cleaning-advice-text {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${colors.MAIN_GREY};
    margin-left: 12px;
  }
`;

export const ProductComposition = ({ cleaningAdvice, productComposition }: Props) => {
  const partsWithDot = productComposition?.split('.'); // sizes separator
  const data: { [key: string]: string }[] = [];
  partsWithDot?.forEach((part) => {
    const result: { [key: string]: string } = {};
    const partsWithDash = part?.split('-'); // product part separator
    partsWithDash?.forEach((part) => {
      const [category, details, thirdPart] = part ? part.split(':') : []; // product designation separator
      if (thirdPart) {
        result[category?.trim()] = '';
        result[details?.trim()] = thirdPart?.trim();
      } else {
        result[category?.trim()] = details?.trim();
      }
    });
    data.push(result);
  });

  return !data?.length ? null : (
    <StyledContainer>
      <ul className="composition-ul">
        {data.map((section, sectionIndex) => (
          <React.Fragment key={`product-composition-${sectionIndex}`}>
            {Object.entries(section)?.map(([key, value], entryIndex) => {
              if (key) {
                const partWithSlash = value?.split('/'); // materials separator
                return (
                  <React.Fragment key={entryIndex}>
                    <li className="composition-section">{`${key} :`}</li>
                    {partWithSlash?.map((el, index) => {
                      const isSection = !el.includes('%');
                      return el ? (
                        <li
                          className={isSection ? 'composition-section' : 'composition-li'}
                          key={`${entryIndex}-${index}`}
                        >
                          {el.trim()}
                          {isSection ? ' :' : ''}
                        </li>
                      ) : null;
                    })}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </React.Fragment>
        ))}
      </ul>
      {cleaningAdvice && (
        <div className="cleaning-advice-block">
          <Icon name="washingMachine" size={24} />
          <p className="cleaning-advice-text">{cleaningAdvice}</p>
        </div>
      )}
    </StyledContainer>
  );
};
