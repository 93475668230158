import { DynamicButtonStatus } from '../../design-system';
import { State } from './types';

const formState: State = {
  personal: {
    validation: {
      prefix: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      password: '',
    },
    values: {
      prefix: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      password: '',
    },
    focus: '',
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  affiliate: {
    validation: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },
    values: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },
    focus: '',
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  delivery: {
    validation: {
      giftMessage: '',
      isConditionsAccepted: '',
      pickupPhone: '',
    },
    values: {
      showBilling: false,
      showGiftMessage: false,
      isConditionsAccepted: false,
      pickupPhone: '',
      giftMessage: '',
    },
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
    focus: '',
  },
  shipping: {
    validation: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: '',
    },
    values: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: 'FR',
    },
    focus: '',
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
  },
  billing: {
    validation: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: '',
    },
    values: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: 'FR',
    },
    focus: '',
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
  },
  address: {
    validation: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: '',
    },
    values: {
      id: '',
      name: '',
      company: '',
      idTva: '',
      siret: '',
      firstName: '',
      lastName: '',
      street: '',
      streetAdditional: '',
      postal: '',
      city: '',
      phone: '',
      country: '',
    },
    focus: '',
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  payment: {
    validation: {},
    values: {},
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  newsletter: {
    validation: {},
    values: {
      optInEmail: false,
      optInSMS: false,
    },
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
  },
  contact: {
    validation: {
      prefix: '',
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      theme: '',
      orderNumber: '',
      address: '',
      postal: '',
      city: '',
      phone: '',
      country: '',
      message: '',
    },
    values: {
      prefix: '',
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      theme: '',
      orderNumber: '',
      address: '',
      postal: '',
      city: '',
      phone: '',
      country: 'FR',
      message: '',
    },
    focus: '',
    ctaState: DynamicButtonStatus.Default,
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
  },
  signInEmail: {
    validation: {
      email: '',
    },
    values: {
      email: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  signInPassword: {
    validation: {
      password: '',
    },
    values: {
      password: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  signIn: {
    validation: {
      email: '',
      password: '',
    },
    values: {
      email: '',
      password: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  signUp: {
    validation: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      optInEmail: true,
    },
    values: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      optInEmail: false,
    },
    ctaState: DynamicButtonStatus.Default,
  },
  forgotPassword: {
    validation: {
      email: '',
      password: '',
    },
    values: {
      email: '',
      password: '',
    },
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  socialLogin: {
    feedback: {
      ok: false,
      isDirty: false,
      message: '',
    },
    validation: {},
    values: {},
  },
  footerNewsletter: {
    validation: {
      email: '',
    },
    values: {
      email: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
  eReservation: {
    validation: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    values: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    focus: '',
    ctaState: DynamicButtonStatus.Default,
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
  },
  eResaRequest: {
    validation: {
      isConditionsAccepted: '',
    },
    values: {
      isConditionsAccepted: false,
    },
    focus: '',
    feedback: {
      ok: true,
      isDirty: false,
      message: '',
    },
    ctaState: DynamicButtonStatus.Default,
  },
};

export default formState;
