import React from 'react';
import { useSelector } from 'react-redux';
import { RichText } from 'prismic-reactjs';

import { RootState } from '../../../store/rootReducer';
import { Box, Divider, Icon, Text } from '../../../design-system';
import { StoreService } from '../types';
import { hasValidCmsText } from '../../home/utils';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  withoutTitle?: boolean;
  services?: StoreService[];
};

const StoreServices = ({ withoutTitle, services: storeServices }: Props) => {
  const { services: cmsServices } = useSelector((state: RootState) => state.cms.storelocator);
  const { isMobile } = useMediaQueries();

  const services = storeServices
    ? cmsServices.filter((item) => storeServices.includes(StoreService[item.icon]))
    : cmsServices;

  if (!services.length) {
    return null;
  }

  return (
    <Box>
      {!withoutTitle && (
        <Box display="flex" alignItems="center">
          <Divider />
          <Text color="GREY" whiteSpace="nowrap" mx="s">
            Services en boutique
          </Text>
          <Divider />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-start"
        mx={['m', 'na']}
        my="m"
        flexDirection={['column', 'row']}
      >
        {services.map(({ icon, service_title, service_content }, index) => {
          return (
            <Box
              display="flex"
              flexDirection={['row', 'column']}
              alignItems="center"
              key={index}
              maxWidth={['100%', `${100 / services.length}%`]}
              my={['s', 'na']}
            >
              <Icon name={icon} size={isMobile ? 28 : 50} />
              {!isMobile && (
                <Text fontWeight="bold" mt="xs">
                  {service_title}
                </Text>
              )}
              {hasValidCmsText(service_content) && (
                <Box
                  textAlign={['start', 'center']}
                  mt={['na', 's']}
                  ml={['s', 'na']}
                  maxWidth={['100%', '200px']}
                  px={['16px', '16px']}
                >
                  <RichText render={service_content} />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StoreServices;
