import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import { Button, Box } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  link: string;
  column: string;
  ctaText: string;
  ctaType?: string;
  ctaTypeMobile?: string;
};

const BrandCta = ({ ctaText, ctaType, ctaTypeMobile, link, column }: Props) => {
  const { isDesktop } = useMediaQueries();

  const mt = column === 'full' || column === 'half' ? 'xxl' : isDesktop ? 'xxl' : 'm';
  const px = isDesktop || column !== 'quarter' ? 'na' : 's';
  const width = isDesktop || column !== 'quarter' ? '240px' : 'auto';
  const maxWidth = isDesktop || column !== 'quarter' ? '240px' : '160px';

  const type = !isDesktop && ctaTypeMobile ? ctaTypeMobile : ctaType;

  return (
    <Box {...{ mt, px, width, maxWidth }} mx="auto" textAlign="center">
      <Link
        className={type === 'link' ? 'anchor-animated' : ''}
        data-testid={`link-cms-brand-${ctaText}`}
        id="link-cms-brand"
        to={getFormatedLinkForReactRouterLink(link)}
        css={css`
          text-decoration: none;
          font-size: 1.6rem;
        `}
      >
        {type !== 'link' ? (
          <Button
            id="btn-cms-brand"
            preset={type === 'light' ? 'primaryLight' : type === 'subtle' ? 'subtle' : 'primary'}
          >
            {ctaText}
          </Button>
        ) : (
          ctaText
        )}
      </Link>
    </Box>
  );
};

export default BrandCta;
