import { indices } from '../search/constants';

export const sortIndices = [
  { value: indices.MAIN, label: 'Default' },
  { value: indices.SORT_BY_NEWEST, label: 'Nouveautés' },
  { value: indices.SORT_BY_PRICE_DESC, label: 'Prix décroissants' },
  { value: indices.SORT_BY_PRICE_ASC, label: 'Prix croissants' },
  { value: indices.BEST_SELLERS, label: 'Meilleures ventes' },
  { value: indices.T2S, label: 'Default index for catalogue page only' },
];
