import { State } from './types';

export const recommendationsState: State = {
  blocks: {
    crossSell: {
      filter: '',
      recommendedProducts: [],
    },
    upSell: {
      filter: '',
      recommendedProducts: [],
    },
    home: {
      filter: '',
      recommendedProducts: [],
    },
    cart: {
      filter: '',
      recommendedProducts: [],
    },
    search: {
      filter: '',
      recommendedProducts: [],
    },
  },
  errMsg: '',
  isFetching: true,
};
