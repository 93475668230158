import * as React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { ColorText } from '../../common/components/Custom';

type Props = {
  objectID: string;
  promotionLongLabel: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
};

const PromotionLabel = ({
  promotionLongLabel,
  promotionTextColor,
  promotionBackgroundColor,
  objectID,
}: Props) => {
  const { label_text_color: defaultTextColor, label_background_color: defaultBackgroundColor } =
    useSelector((state: RootState) => state.cms.promotion) ?? {};

  const textColor = promotionTextColor || defaultTextColor;
  const backgroundColor = promotionBackgroundColor || defaultBackgroundColor;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={backgroundColor}
      width="100%"
      p="s"
      mt={['s', 'na']}
    >
      <ColorText
        cmsColor={textColor}
        preset="caption"
        fontWeight="bold"
        id={`pdp-item-promotion-label-${objectID}`}
      >
        {promotionLongLabel}
      </ColorText>
    </Box>
  );
};

export default PromotionLabel;
