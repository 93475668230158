import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import Transition from 'react-transition-group/Transition';
import { durations, transitions, heights } from '../../common/constants';
import { Icon, Divider, breakpoints, colors } from '../../../design-system';

import styled from '@emotion/styled';
import { CartItem, CartStep } from '../types';
import { getProductImageLink, productImageWidths, isItGiftCard } from '../../product/utils';
import { RootState } from '../../../store/rootReducer';
import { LBL_COLOR, LBL_QUANTITY, LBL_SIZE } from '../../product/locale';
import { availableItemsSelector } from '../selectors';
import { getProductPrice } from '../../product/utils';
import { formatPrice } from '../../common/utils';
import {
  LBL_GIFT_CART_DATE,
  LBL_GIFT_CART_RECIPIENT,
  TXT_DROPDOWN_CART,
  LBL_GIFT_ITEM,
} from '../locale';

const transitionStylesContent = {
  entering: { opacity: 0, maxHeight: 0 },
  entered: { opacity: 1, maxHeight: `${heights.PRODUCT_SELECTOR_MOBILE}px` },
  exiting: { opacity: 0, maxHeight: 0 },
  exited: { opacity: 0, maxHeight: 0 },
};

const ScrollbarWrapper = styled('div')(
  css({
    overflowY: 'auto',
    '@media only screen and (min-width: 768px)': {
      '&::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: '#E6E6E6',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#000',
        borderRadius: '99px',
      },
      '&::-moz-scrollbar': {
        width: '8px',
        backgroundColor: '#E6E6E6',
      },
      '&::-moz-scrollbar-thumb': {
        backgroundColor: '#000',
        borderRadius: '99px',
      },
      '&::-moz-scrollbar-track': {
        backgroundColor: '#E6E6E6',
      },
      scrollbarColor: '#000 #E6E6E6',
      scrollbarWidth: 'thin',
    },
  })
);

type Props = {
  step: CartStep;
  isItemListBlockOpen?: boolean;
  setIsItemListBlockOpen?: (value: boolean) => void;
};

export const CartIemsListSummary = ({
  step,
  isItemListBlockOpen = false,
  setIsItemListBlockOpen = () => {},
}: Props) => {
  const { isMobile } = useMediaQueries();
  const availableItems = useSelector((state: RootState) => availableItemsSelector(state));
  const giftItemTextColor =
    useSelector((state: RootState) => state.cms.cart?.gift_text_color) ?? 'BLACK';
  const { total } = useSelector((state: RootState) => state.cart.cart);
  const productsBlockRef = React.useRef<HTMLDivElement>(null);
  const content = (
    <ScrollbarWrapper
      id="cart-items-list-summary-block"
      ref={productsBlockRef}
      css={css`
        height: auto;
        max-height: ${heights.PRODUCT_SELECTOR_MOBILE}px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: ${colors.WHITE};
        padding: 16px;
        @media (min-width: ${breakpoints.S}px) {
          max-height: 176px;
          margin-bottom: 16px;
        }
        @media (min-width: ${breakpoints.M}px) {
          padding: 16px 0;
        }
      `}
    >
      {availableItems?.map((item: CartItem, index: number) => {
        const {
          productRef,
          colorRef,
          colorLabel,
          productName,
          detailedColorLabel,
          rowBaseTotal,
          rowTotal,
          quantity,
          freeProduct,
          size,
          bandSize,
          cupSize,
          gcFields,
        } = item ?? {};
        const isGiftCard = isItGiftCard(productRef);
        const objectID = `${productRef}-${colorRef}-${index}`;
        const thumbnail = getProductImageLink({
          productRef,
          colorRef,
          productName,
          position: 1,
          width: productImageWidths.THUMBNAIL,
        });
        const price = getProductPrice({ storePrice: rowTotal, originalPrice: rowBaseTotal });
        return (
          <div
            key={`cart-item-summary-list-product-${objectID}`}
            id={`cart-item-summary-list-step-${
              step === CartStep.DELIVERY ? 'delivery' : 'payment'
            }}`}
          >
            <div
              css={css`
                display: flex;
                width: 100%;
                margin-bottom: ${index + 1 < availableItems.length ? '13px' : 0};
              `}
            >
              <div
                id={`cart-item-list-summary-picture-product-${objectID}`}
                css={css`
                  min-width: 72px;
                  height: 72px;
                  width: 72px;
                  background-image: ${thumbnail ? `url(${thumbnail})` : ''};
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                `}
              />
              <div
                id={`cart-item-list-summary-product-info-${objectID}`}
                css={css`
                  display: flex;
                  width: 100%;
                  height: 72px;
                  justify-content: space-between;
                  padding-left: 8px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                  `}
                >
                  <p
                    id={`cart-item-list-summary-product-${objectID}-name`}
                    css={css`
                      font-size: 1.2rem;
                      line-height: 1.4rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {productName}
                  </p>
                  {!isGiftCard ? (
                    <div
                      id={`cart-item-list-summary-product-${objectID}-color-size-qty-block`}
                      css={css`
                        margin-top: 12px;
                        font-size: 1.1rem;
                        line-height: 1.4rem;
                      `}
                    >
                      <p
                        css={css`
                          margin-block-start: 0;
                          margin-block-end: 0;
                        `}
                      >
                        {`${LBL_COLOR} : `}
                        <span style={{ fontWeight: 700 }}>{`${
                          detailedColorLabel ?? colorLabel
                        } | `}</span>
                        {`${LBL_SIZE} : `}
                        <span style={{ fontWeight: 700 }}> {size || `${bandSize} ${cupSize}`}</span>
                      </p>
                      <p
                        css={css`
                          margin-top: 4px;
                          margin-block-start: 0;
                          margin-block-end: 0;
                        `}
                      >
                        {`${LBL_QUANTITY} : `}
                        <span style={{ fontWeight: 700 }}>{quantity}</span>
                      </p>
                    </div>
                  ) : (
                    <div
                      id={`cart-item-list-summary-product-gift-card-${objectID}-email-date-block`}
                      css={css`
                        margin-top: 12px;
                        font-size: 1.1rem;
                        line-height: 1.4rem;
                      `}
                    >
                      <p
                        css={css`
                          margin-block-start: 0;
                          margin-block-end: 0;
                        `}
                      >
                        {LBL_GIFT_CART_DATE}
                        <span style={{ fontWeight: 700 }}>
                          {gcFields?.date && new Date(gcFields.date).toLocaleDateString('fr-FR')}
                        </span>
                      </p>
                      <p
                        css={css`
                          margin-top: 4px;
                          margin-block-start: 0;
                          margin-block-end: 0;
                        `}
                      >
                        {LBL_GIFT_CART_RECIPIENT}
                        <span style={{ fontWeight: 700 }}>{gcFields?.email ?? ''}</span>
                      </p>
                    </div>
                  )}
                </div>
                {!freeProduct ? (
                  <div
                    id={`cart-item-list-summary-product-${objectID}-price`}
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                      padding-left: 16px;
                      padding-right: 0;
                      @media (min-width: ${breakpoints.M}px) {
                        padding-right: 8px;
                      }
                    `}
                  >
                    {price.hasPromotion && (
                      <p
                        css={css`
                          font-size: 1.4rem;
                          font-weight: 500;
                          color: ${colors.GREY2};
                          text-decoration: line-through;
                          margin-block-start: 0;
                          margin-block-end: 0;
                          padding-bottom: 4px;
                        `}
                      >
                        {price.originalPrice}
                      </p>
                    )}
                    <p
                      css={css`
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: ${price.hasPromotion ? colors.MAIN_PROMO : colors.BLACK};
                        margin-block-start: 0;
                        margin-block-end: 0;
                      `}
                    >
                      {price.storePrice}
                    </p>
                  </div>
                ) : (
                  <div
                    id={`cart-item-list-summary-product-${objectID}-price`}
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                      justify-content: space-between;
                      padding-left: 16px;
                      padding-right: 0;
                      @media (min-width: ${breakpoints.M}px) {
                        padding-right: 8px;
                      }
                    `}
                  >
                    <p
                      css={css`
                        color: ${giftItemTextColor};
                        margin-block-start: 0;
                        margin-block-end: 0;
                      `}
                    >
                      {LBL_GIFT_ITEM}
                    </p>
                    <Icon name="gift" color={giftItemTextColor} />
                  </div>
                )}
              </div>
            </div>
            {index + 1 < availableItems.length && (
              <div
                css={css`
                  margin-bottom: 16px;
                  width: 100%;
                  padding-right: 0;
                  @media (min-width: ${breakpoints.M}px) {
                    padding-right: 8px;
                  }
                `}
              >
                <Divider color={colors.LIGHT} />
              </div>
            )}
          </div>
        );
      })}
    </ScrollbarWrapper>
  );
  const mobileContent = (
    <div
      id="cart-item-list-summary-block-mobile"
      css={css`
        background: ${colors.BACKGROUND};
      `}
    >
      <button
        css={css`
          display: flex;
          width: 100%;
          border: none;
          background: none;
          color: ${colors.BLACK};
          justify-content: space-between;
          margin-bottom: ${step === CartStep.DELIVERY ? '16px' : 0};
          padding: 4px 0;
        `}
        onClick={() => setIsItemListBlockOpen(!isItemListBlockOpen)}
        type="button"
        id={`cart-item-list-summary-block-button-to-${
          isItemListBlockOpen ? 'close' : 'open'
        }-mobile`}
        data-cy={`cart-item-list-summary-block-button-to-${
          isItemListBlockOpen ? 'close' : 'open'
        }-mobile`}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <p
            css={css`
              font-size: 1.4rem;
              font-weight: 700;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {TXT_DROPDOWN_CART}
          </p>
          <div
            css={css`
              margin-left: 12px;
              transform: rotate(${isItemListBlockOpen ? 180 : 0}deg);
              transition: all 0.25s ease-in-out;
            `}
          >
            <Icon name="chevronUp" size={12} />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            font-weight: 700;
          `}
        >
          {formatPrice(total)}
        </div>
      </button>
      <Transition
        in={isItemListBlockOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <div
            id="cart-item-list-block-with-scroll-auto"
            css={css`
              overflow: auto;
              margin-top: ${step === CartStep.PAYMENT ? '16px' : 0};
              margin-bottom: ${step === CartStep.PAYMENT ? 0 : '16px'};
              transition: ${transitions.GENERIC};
            `}
            style={{
              ...transitionStylesContent[state],
            }}
          >
            {content}
          </div>
        )}
      </Transition>
    </div>
  );

  React.useEffect(() => {
    if (productsBlockRef.current && !isMobile) {
      productsBlockRef.current.scrollTo(0, 0);
    }
    if (isItemListBlockOpen && isMobile) {
      setIsItemListBlockOpen(false);
    }
  }, [step]);

  return isMobile ? mobileContent : content;
};
