import React, { useEffect, useRef, useState } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { RootState } from '../../../store/rootReducer';
import { Box, Field, Button, Checkbox, Spinner } from '../../../design-system';
import { FieldRefs, FieldType, FormFieldCallback, Forms } from '../../form/types';
import {
  format,
  validate,
  isPhoneOnChangeValid,
  formatedPhoneValue,
  validateName,
  addSpacesForPhoneNumber,
  removeSpaces,
} from '../../form/utils';
import { setFormValidation, setFormValues } from '../../form/actions';
import { openModal } from '../../common/actions';
import { populateForm } from '../../form/utils';
import { subscribeNewsletter } from '../../footer/utils';
import { formatPrice } from '../../common/utils';
import { EresaResults } from './EresaResults';
import {
  ERESA_ADD_DETAILS,
  ERESA_ALLOW_OPTIN,
  FINALIZE_ERESERVATION,
  MANDATORY_FIELDS,
  ERESA_TOTAL,
  PAY_IN_STORE,
} from '../locale';
import { ERR_TERMS_OF_SALES } from '../../cart/locale';
import { getBFFData, Mutations } from '../../api';
import paths from '../../routing/paths';
import { ScrollbarWrapper } from './EresaSummary';
import { pushToGTM, getCatalog } from '../../tracking';
import { Events } from '../../tracking/types';
import { updateNewsletter } from '../../account/actions';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

export const EresaForm = () => {
  const dispatch = useDispatch();
  const [formIsOK, setFormIsOK] = useState(true);
  const [cgvChecked, setCgvChecked] = useState('');
  const [optinAccepted, setOptinAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile, isTablet } = useMediaQueries();
  const { eReservation, newsletter } = useSelector((state: RootState) => state.form);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { total, items } = useSelector((state: RootState) => state.ecart.ecart);

  const { email, firstName, lastName, phone } =
    useSelector((state: RootState) => state.account.user) ?? {};

  const refs: FieldRefs = {};
  Object.keys(eReservation.values).forEach((key) => {
    refs[key] = useRef(null);
  });

  useEffect(() => {
    if (
      eReservation.validation.email === '' &&
      eReservation.validation.firstName === '' &&
      eReservation.validation.lastName === '' &&
      eReservation.validation.phone === ''
    ) {
      setFormIsOK(true);
    }
    if (
      eReservation.validation.email !== '' ||
      eReservation.validation.firstName !== '' ||
      eReservation.validation.lastName !== '' ||
      eReservation.validation.phone !== ''
    ) {
      setFormIsOK(false);
    }
  }, [eReservation.validation]);

  useEffect(() => {
    if (eReservation.validation.email !== '') {
      dispatch(
        setFormValidation({
          form: Forms.eReservation,
          values: { email: '' },
        })
      );
    }
    if (eReservation.validation.firstName !== '') {
      dispatch(
        setFormValidation({
          form: Forms.eReservation,
          values: { firstName: '' },
        })
      );
    }
    if (eReservation.validation.lastName !== '') {
      dispatch(
        setFormValidation({
          form: Forms.eReservation,
          values: { lastName: '' },
        })
      );
    }
    if (eReservation.validation.phone !== '') {
      dispatch(
        setFormValidation({
          form: Forms.eReservation,
          values: { phone: '' },
        })
      );
    }
    if (isLoggedIn && (email || firstName || lastName || phone)) {
      const userinfos = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      };
      const keys = Object.keys(userinfos);
      dispatch(
        setFormValues({
          form: Forms.eReservation,
          values: populateForm(userinfos, keys),
        })
      );
    }
  }, []);

  const validateField = (key: string, value: FieldType): string => {
    return validate({ value: String(value), key, isRequired: true });
  };

  const handleFieldBlur = ({ form, key, value }: FormFieldCallback) => {
    const errMsg = validateField(key, value);
    dispatch(setFormValidation({ form, values: { [key]: errMsg } }));
  };
  const getField = ({
    key,
    label,
    autoComplete,
    inputType,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    inputType?: string;
  }) => (
    <Box gridArea={key}>
      <Field
        id={`eReservation-form-${key}`}
        onChange={(value: string) =>
          handleFieldChangeCGV({
            form: Forms.eReservation,
            key,
            value: key === 'phone' ? removeSpaces(value) : value,
          })
        }
        onBlur={(value: string) =>
          handleFieldBlur({
            form: Forms.eReservation,
            key,
            value: key === 'phone' ? removeSpaces(value) : value,
          })
        }
        value={
          key === 'phone'
            ? addSpacesForPhoneNumber(eReservation.values[key])
            : eReservation.values[key]
        }
        errMsg={eReservation.validation[key]}
        ref={refs[key]}
        label={label}
        autoComplete={autoComplete}
        type={inputType}
        color="#9B9B9B"
        isEresaModal
      />
    </Box>
  );

  const ereservationCall = async () => {
    const response = await getBFFData(Mutations.eReservation, { ...eReservation.values });
    if (response.ok) {
      const { reservationId } = response.data.eReservation;
      pushToGTM(Events.completeReservation, {
        eresa_id: reservationId,
        amount: total,
        products: getCatalog({ products: items, isCart: true }),
      });
      dispatch(
        openModal({
          content: <EresaResults reservationId={reservationId} />,
          preset: 'eresaModalResults',
        })
      );
    } else {
      dispatch(
        openModal({
          content: <EresaResults isError />,
          preset: 'eresaModalResults',
        })
      );
    }
  };

  const validateEmail = (value: string): string =>
    validate({
      value: String(value),
      key: 'email',
      isRequired: true,
    });

  const validateFirstName = (value: string): string =>
    validate({
      value: String(value),
      key: 'firstName',
      isRequired: true,
    });

  const validateLastName = (value: string): string =>
    validate({
      value: String(value),
      key: 'lastName',
      isRequired: true,
    });

  const validatePhone = (value: string): string =>
    validate({
      value: String(value),
      key: 'phone',
      isRequired: true,
    });

  const onSubmitForm = async () => {
    if (!formIsOK) {
      setFormIsOK(true);
    }
    const errMsgEmail = validateEmail(eReservation.values.email);
    dispatch(
      setFormValidation({
        form: Forms.eReservation,
        values: { email: errMsgEmail },
      })
    );
    const errMsgFirstName = validateFirstName(eReservation.values.firstName);
    dispatch(
      setFormValidation({
        form: Forms.eReservation,
        values: { firstName: errMsgFirstName },
      })
    );
    const errMsgLastName = validateLastName(eReservation.values.lastName);
    dispatch(
      setFormValidation({
        form: Forms.eReservation,
        values: { lastName: errMsgLastName },
      })
    );
    const errMsgPhone = validatePhone(eReservation.values.phone);
    dispatch(
      setFormValidation({
        form: Forms.eReservation,
        values: { phone: errMsgPhone },
      })
    );
    if (!errMsgEmail && !errMsgFirstName && !errMsgLastName && !errMsgPhone) {
      if (cgvChecked === 'true') {
        setIsLoading(true);
        if (optinAccepted) {
          const newsLetterResponse = await subscribeNewsletter({
            mail: eReservation.values.email,
            origin: 'ereservation',
          });
          if (
            isLoggedIn &&
            newsLetterResponse &&
            email === eReservation.values.email &&
            !newsletter.values.optInEmail
          ) {
            dispatch(updateNewsletter({ optInEmail: true }) as unknown as UnknownAction);
          }
          ereservationCall();
        } else {
          ereservationCall();
        }
      } else {
        setCgvChecked('false');
        setFormIsOK(false);
      }
    } else {
      setFormIsOK(false);
    }
  };

  const handleFieldChangeCGV = ({ form, key, value }: FormFieldCallback) => {
    let newValue = value;
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      typeof value === 'string' &&
      !isPhoneOnChangeValid({ value, dispatch, formType: Forms.eReservation, form: eReservation })
    ) {
      return null;
    }

    newValue = key === 'phone' && typeof value === 'string' ? formatedPhoneValue(value) : value;
    if (eReservation.validation[key] && key !== 'phone') {
      dispatch(setFormValidation({ form, values: { [key]: validateField(key, newValue) } }));
    }

    return dispatch(
      setFormValues({
        form,
        values: {
          [key]:
            typeof newValue === 'boolean' || key === 'phone'
              ? newValue
              : format({ value: newValue, key }),
        },
      })
    );
  };

  return (
    <>
      <ScrollbarWrapper
        overflow={isTablet ? 'scroll' : 'auto'}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box>
          <Box width="100%" height="34px" py="s">
            <Box fontSize="16px" fontWeight={700}>
              {ERESA_ADD_DETAILS}
            </Box>
          </Box>
          <Box display="flex" width="100%" flexDirection="column">
            <Box display="flex" flexDirection="row" width="100%">
              <Box width="calc(50% - 4px)" mr="xs">
                {getField({
                  key: 'lastName',
                  label: 'Nom*',
                  autoComplete: 'family-name',
                  inputType: 'lastName',
                })}
              </Box>
              <Box width="calc(50% - 4px)" ml="xs">
                {getField({
                  key: 'firstName',
                  label: 'Prénom*',
                  autoComplete: 'given-name',
                  inputType: 'firstName',
                })}
              </Box>
            </Box>
            <Box my="s">
              {getField({
                key: 'email',
                label: 'Votre adresse mail*',
                autoComplete: 'email',
                inputType: 'email',
              })}
            </Box>
            <Box>
              {getField({
                key: 'phone',
                label: 'Votre numéro de téléphone*',
                autoComplete: 'tel',
                inputType: 'tel',
              })}
            </Box>
            <Box my="8px">
              <Checkbox
                id="ereservation-form-checkbox-terms-conditions"
                data-cy="ereservation-form-checkbox-terms-conditions"
                onChange={() =>
                  setCgvChecked(cgvChecked === 'false' || cgvChecked === '' ? 'true' : '')
                }
                checked={cgvChecked === 'true' ? true : false}
                errMsg={cgvChecked === 'false' ? ERR_TERMS_OF_SALES : ''}
              >
                <div>
                  <p
                    className="checkbox-content__description"
                    css={css`
                      font-size: 1.2rem;
                      line-height: 1.6rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    J&apos;accepte les{' '}
                    <a
                      className="anchor-animated"
                      id="ereservation-form-btn-terms-conditions"
                      data-testid="ereservation-form-terms-conditions-link"
                      href={paths.GENERIC[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={css`
                        font-size: 1.2rem;
                      `}
                    >
                      conditions générales d&apos;utilisation
                    </a>
                  </p>
                </div>
              </Checkbox>
            </Box>
            <Box my="8px">
              <Checkbox
                id="ereservation-form-optin-checkbox"
                data-cy="ereservation-form-optin-checkbox"
                onChange={() => setOptinAccepted(!optinAccepted)}
                checked={optinAccepted}
              >
                <div className="checkbox-content__container">
                  <p
                    className="checkbox-content__description"
                    css={css`
                      font-size: 1.2rem;
                      line-height: 1.6rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {ERESA_ALLOW_OPTIN}
                  </p>
                </div>
              </Checkbox>
            </Box>
            <Box fontSize="12px" color="#5A5A5A" mb="s">
              {MANDATORY_FIELDS}
            </Box>
          </Box>
        </Box>
      </ScrollbarWrapper>
      <Box mt={isMobile ? 'm' : 'na'} mb={isMobile ? 'm' : 'na'}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
          pt="m"
          borderTop="solid 1px #E6E6E6"
        >
          <Box fontSize="16px" fontWeight={700}>
            {ERESA_TOTAL}
          </Box>
          <Box fontSize="16px" fontWeight={700}>
            {formatPrice(total)}
          </Box>
        </Box>
        <Box fontSize="12px" color="#545454" pt="s" pb="m">
          {PAY_IN_STORE}
        </Box>
        {!isLoading && (
          <Button
            id="eresa-modal-form-submit-button"
            data-cy="ereservation-form-submit-button"
            fontWeight="700"
            onClick={() => onSubmitForm()}
            disabled={!formIsOK || cgvChecked === 'false'}
          >
            {FINALIZE_ERESERVATION}
          </Button>
        )}
        {isLoading && (
          <Box bg="black" height="44px" display="flex" alignItems="center" justifyContent="center">
            <Spinner color="white" />
          </Box>
        )}
      </Box>
    </>
  );
};
