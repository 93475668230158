import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { getAllStores } from '../actions';
import { createMap, BASE_LOC } from '../utils';
import { ERR_SELECT_STORE, MODAL_TITLE, STORE_DELIVERY_SEARCH_PLACEHOLDER } from '../locale';
import Search from './Search';
import { StoreAutoComplete } from './StoreAutoComplete';
import SelectedStore from './SelectedStore';
import { openModal } from '../../common/actions';
import { Suggestion } from '../types';
import useGoogleMapsAutocomplete from '../../common/hooks/useGoogleMapsAutocomplete';
import { StoreLocator } from './StoreLocator';
import { setFocus } from '../../form/actions';
import { Forms } from '../../form/types';

const StoreDelivery = () => {
  const dispatch = useDispatch();

  const stores = useSelector((state: RootState) => state.stores.allStores) ?? [];
  const { focus } = useSelector((state: RootState) => state.form.delivery);
  const { storeId } = useSelector((state: RootState) => state.cart.cart);

  const [searchText, setSearchText] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  const [mapState, setMapState] = React.useState<{
    loading: boolean;
    sessionToken?: google.maps.places.AutocompleteSessionToken;
    autocomplete?: google.maps.places.AutocompleteService;
  }>({
    loading: true,
  });

  const mapRef = React.useRef<HTMLDivElement>(null);
  const divRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    dispatch(getAllStores({ isClickAndCollect: true }) as unknown as UnknownAction);
  }, []);

  React.useEffect(() => {
    const initMap = async (element: HTMLDivElement) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const { sessionToken, autocomplete } = await createMap({
        center: { ...BASE_LOC },
        element,
      });
      setMapState({ loading: false, sessionToken, autocomplete });
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
  }, [mapRef]);

  const selectedStore = stores.find((store) => store.id === storeId);

  const handleChange = (value: string) => {
    setSearchText(value);
    if (errMsg) {
      setErrMsg('');
    }
  };

  const openMap = ({
    search,
    toBePositioned,
    selectedStoreId,
  }: {
    search?: string;
    toBePositioned?: boolean;
    selectedStoreId?: string;
  }) => {
    dispatch(
      openModal({
        content: (
          <StoreLocator
            isDelivery
            selectedStoreId={selectedStoreId}
            search={search}
            toBePositioned={toBePositioned}
          />
        ),
        preset: 'l',
        title: MODAL_TITLE,
      })
    );
  };

  const handleLocate = () => {
    openMap({ toBePositioned: true });
  };

  const handleSearch = () => {
    openMap({ search: searchText });
  };

  const handleAutoComplete = (search: string) => {
    openMap({ search });
  };

  const handleChangeStoreClick = () => {
    openMap({ selectedStoreId: selectedStore?.id });
  };

  React.useEffect(() => {
    if (focus === 'search') {
      const node = divRef.current;
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrMsg(ERR_SELECT_STORE);
      }
    }
    if (focus === '' && errMsg === ERR_SELECT_STORE) {
      setErrMsg('');
    }
  }, [focus]);

  React.useEffect(() => {
    if (focus === 'search' && selectedStore) {
      dispatch(setFocus({ form: Forms.delivery, key: '' }));
      setErrMsg('');
    }
  }, [selectedStore]);

  const predictions = useGoogleMapsAutocomplete(
    mapState.sessionToken,
    mapState.autocomplete,
    searchText
  );

  const suggestionsList: Suggestion[] = predictions.map((prediction) => {
    return { name: prediction.description };
  });

  if (selectedStore) {
    return <SelectedStore store={selectedStore} onClick={handleChangeStoreClick} />;
  }

  return (
    <Box ref={divRef} position="relative">
      <Search
        onIconClick={handleLocate}
        onChange={handleChange}
        onClick={handleSearch}
        value={searchText}
        errMsg={errMsg}
        placeholder={STORE_DELIVERY_SEARCH_PLACEHOLDER}
        isDelivery
      />
      <StoreAutoComplete
        suggestionsList={suggestionsList}
        query={searchText}
        onClick={handleAutoComplete}
      />
      <div ref={mapRef} />
    </Box>
  );
};

export default StoreDelivery;
