import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { CmsFontFamily, CmsTabsCategorieBanner } from '../../cms/types';
import {
  TABS_CATEGORIE_TEXT_DESCRIPTION_WIDTH,
  TABS_CATEGORIE_TILE_HEIGHT,
  TABS_CATEGORIE_TILE_WIDTH,
  getOurBrandBannerPosition,
} from '../utils';
import { Spinner, Tabs, breakpoints, colors } from '../../../design-system';
import { ProductCard } from '../../catalog/components/ProductCard';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { hasValidCmsText } from '../../home/utils';
import { CatalogProduct } from '../../catalog/types';
import { cdnBaseImageUrl } from '../../common/constants';
import { isValidProduct } from '../../ecart/utils';

type Props = {
  cmsData: CmsTabsCategorieBanner | null;
  index: number;
};

type StyledcontainerProps = {
  tabs_categorie_position_on_page: number;
  tabs_categorie_margin_bottom: number;
  tabs_categorie_margin_bottom_small_screen: number;
  tabs_categorie_title_color: string;
  tabs_categorie_title_font_family: CmsFontFamily;
  tabs_categorie_title_font_size: number;
  tabs_categorie_title_font_size_small_screen: number;
  tabs_categorie_title_font_weight: number;
};

const StyledContainer = styled.div<StyledcontainerProps>`
  display: block;
  order: ${(props) => props.tabs_categorie_position_on_page};
  margin-bottom: ${(props) => props.tabs_categorie_margin_bottom_small_screen}px;
  width: 100%;
  max-width: 1440px;

  @media (min-width: ${breakpoints.M}px) {
    margin: ${(props) => `0 auto ${props.tabs_categorie_margin_bottom}px auto`};
  }

  .tabs-categorie-title {
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 0 16px 24px 16px;
    text-align: center;
    font-size: ${(props) => props.tabs_categorie_title_font_size_small_screen}px;
    font-family: ${(props) => props.tabs_categorie_title_font_family};
    font-weight: ${(props) => props.tabs_categorie_title_font_weight};

    @media (min-width: ${breakpoints.M}px) {
      font-size: ${(props) => props.tabs_categorie_title_font_size}px;
      margin: 0 24px 32px 24px;
    }

    @media (min-width: ${breakpoints.L}px) {
      margin: 0 0 32px 0;
    }
  }

  .tab-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;

    @media (min-width: ${breakpoints.S}px) {
      gap: 24px;
    }

    @media (min-width: ${breakpoints.L}px) {
      flex-direction: row;
      max-height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
      gap: 0;
    }
  }

  .tab-content-left-side {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.S}px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: 45%;
    }
  }

  .tab-content-right-side {
    display: flex;
    gap: 16px;
    margin: 0 16px;
    overflow-x: auto;

    @media (min-width: ${breakpoints.S}px) {
      overflow: visible;
      margin: 0;
      gap: 0;
      justify-content: space-evenly;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: 55%;
      justify-content: center;
      gap: 16px;
    }
  }

  .tabs-image {
    width: calc(100vw - 32px);
    border-radius: 8px;
    object-fit: cover;
    display: block;
    margin: 0 16px;

    @media (min-width: ${breakpoints.S}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
      height: ${TABS_CATEGORIE_TILE_WIDTH * (4 / 3)}px;
      margin: 0;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
      height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
    }
  }

  .tabs-gif {
    height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
    width: calc(100% - 32px);
    object-fit: cover;
    display: block;
    border-radius: 8px;
    margin: 0 16px;

    @media (min-width: ${breakpoints.S}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
      margin: 0;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
    }
  }

  .tabs-video {
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) * (4 / 3));
    margin: 0 16px;

    @media (min-width: ${breakpoints.S}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
      height: ${TABS_CATEGORIE_TILE_WIDTH * (4 / 3)}px;
      margin: 0;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${TABS_CATEGORIE_TILE_HEIGHT * (3 / 4)}px;
      height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
    }
  }

  .text-description {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.BLACK};
    padding: 0 16px;

    @media (min-width: ${breakpoints.S}px) {
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
      padding: 0;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: 40%;
      padding-left: 32px;
    }

    p {
      @media (min-width: ${breakpoints.S}px) {
        width: ${TABS_CATEGORIE_TEXT_DESCRIPTION_WIDTH}px;
      }
    }
  }

  .tabs-categorie-product-card {
    min-height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
    min-width: ${TABS_CATEGORIE_TILE_WIDTH}px;

    @media (min-width: ${breakpoints.S}px) {
      height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
      width: ${TABS_CATEGORIE_TILE_WIDTH}px;
    }
  }

  .loading-block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${TABS_CATEGORIE_TILE_HEIGHT}px;
  }
`;

export const TabsCategorie = ({ cmsData, index }: Props) => {
  const {
    tabs_categorie_position_on_page,
    tabs_categorie_margin_bottom,
    tabs_categorie_margin_bottom_small_screen,
    tabs_categorie_title,
    tabs_categorie_title_color,
    tabs_categorie_title_font_family,
    tabs_categorie_title_font_size,
    tabs_categorie_title_font_size_small_screen,
    tabs_categorie_title_font_weight,
  } = cmsData?.primary ?? {};

  const { items = [] } = cmsData ?? {};

  const { hits: firstTabsProducts } = useProductsInfos([
    items?.[0]?.tabs_categorie_product_ref_one ?? '',
    items?.[0]?.tabs_categorie_product_ref_two ?? '',
  ]);

  const { hits: secondTabsProducts } = useProductsInfos([
    items?.[1]?.tabs_categorie_product_ref_one ?? '',
    items?.[1]?.tabs_categorie_product_ref_two ?? '',
  ]);

  const { hits: thirdTabsProducts } = useProductsInfos([
    items?.[2]?.tabs_categorie_product_ref_one ?? '',
    items?.[2]?.tabs_categorie_product_ref_two ?? '',
  ]);

  const memorizedFirstTabs = useMemo(() => {
    return firstTabsProducts;
  }, [firstTabsProducts]);

  const memorizedSecondProducts = useMemo(() => {
    return secondTabsProducts;
  }, [secondTabsProducts]);

  const memorizedThirdProducts = useMemo(() => {
    return thirdTabsProducts;
  }, [thirdTabsProducts]);

  const allProducts = {
    0: memorizedFirstTabs,
    1: memorizedSecondProducts,
    2: memorizedThirdProducts,
  };

  const getTabsConfiguration = () => {
    return [
      ...items.slice(0, 3).map((item, itemIndex) => {
        return {
          title: item.tabs_categorie_section_text,
          content: (
            <div className="tab-wrapper">
              <div className="tab-content-left-side">
                {item.tabs_categorie_gif?.id && item.tabs_categorie_gif?.name ? (
                  <img
                    className="tabs-gif"
                    src={`${
                      process.env.DEPLOYMENT_ENV === 'production'
                        ? cdnBaseImageUrl.production
                        : cdnBaseImageUrl.development
                    }${item.tabs_categorie_gif.id}_${item.tabs_categorie_gif.name}`}
                    alt={item.tabs_categorie_gif.name ?? ''}
                  />
                ) : item.tabs_categorie_video_link ? (
                  <div className="tabs-video">
                    <iframe
                      src={item.tabs_categorie_video_link}
                      style={{
                        border: 'none',
                        borderRadius: '8px',
                        height: '100%',
                        width: '100%',
                      }}
                      frameBorder="0"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                ) : (
                  <img
                    className="tabs-image"
                    src={item.tabs_categorie_image?.url ?? ''}
                    alt={item.tabs_categorie_image?.alt ?? ''}
                  />
                )}
                {hasValidCmsText(item.tabs_categorie_text_description) && (
                  <div className="text-description">
                    <RichText render={item.tabs_categorie_text_description} />
                  </div>
                )}
              </div>
              <div className="tab-content-right-side">
                {allProducts[itemIndex].map((hit, hitIndex) => {
                  const product = {
                    ...hit,
                    objectID: `${hit.productRef}/${hit.colorRef}`,
                    rcFilter: `${hit.productRef}-${hit.colorRef}`,
                  };
                  return isValidProduct(product) ? (
                    <div
                      key={`tabs-categorie-product-card-${hitIndex}`}
                      className="tabs-categorie-product-card"
                    >
                      <ProductCard hit={product as unknown as CatalogProduct} />
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          ),
        };
      }),
    ];
  };

  return (
    <StyledContainer
      id={`tabs-categorie-banner-block-${index}`}
      tabs_categorie_position_on_page={getOurBrandBannerPosition(
        tabs_categorie_position_on_page ?? 'Not activated'
      )}
      tabs_categorie_margin_bottom={tabs_categorie_margin_bottom ?? 0}
      tabs_categorie_margin_bottom_small_screen={tabs_categorie_margin_bottom_small_screen ?? 0}
      tabs_categorie_title_color={tabs_categorie_title_color ?? colors.BLACK}
      tabs_categorie_title_font_family={tabs_categorie_title_font_family ?? 'Ogg-text'}
      tabs_categorie_title_font_size={tabs_categorie_title_font_size ?? 40}
      tabs_categorie_title_font_size_small_screen={
        tabs_categorie_title_font_size_small_screen ?? 32
      }
      tabs_categorie_title_font_weight={tabs_categorie_title_font_weight ?? 700}
    >
      {tabs_categorie_title && <h2 className="tabs-categorie-title">{tabs_categorie_title}</h2>}
      {(items?.length ?? 0) < 3 ||
      memorizedFirstTabs?.length !== 2 ||
      memorizedSecondProducts?.length !== 2 ||
      memorizedThirdProducts?.length !== 2 ? (
        <div id={`tabs-categorie-banner-loading-block-${index}`} className="loading-block">
          <Spinner size={40} />
        </div>
      ) : (
        <Tabs id={`tabs-banner-section-${index}`} items={getTabsConfiguration()} isOurBrandPage />
      )}
    </StyledContainer>
  );
};
