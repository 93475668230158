import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';

import { Box, Text, Icon } from '../../../design-system';
import { socialLogin } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { Forms } from '../../form/types';
import { setFeedback } from '../../form/actions';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import useTimeout from '../../common/hooks/useTimeout';
import useSocial from '../hooks/useSocial';
import { MSG_STUCK_LOGIN } from '../locale';
import { SocialProviders } from '../types';
import { getIconUrl } from '../../../design-system/icon/utils';

const TIMEOUT_MS = 10000;

type Props = {
  title: string;
};

export const SocialLogin = ({ title }: Props) => {
  const dispatch = useDispatch();

  const { ok } = useSelector((state: RootState) => state.form.socialLogin.feedback);

  const { hasFacebookInitialized, hasAppleInitizalied } = useSocial();

  const [fbOpacity, setFbOpacity] = React.useState(1);
  const [googleOpacity, setGoogleOpacity] = React.useState(1);
  const [appleOpacity, setAppleOpacity] = React.useState(1);
  const [displaySocialButtons, setDisplaySocialbuttons] = React.useState(false);

  useInteractionLock(ok);

  const hasTimedOut = useTimeout(TIMEOUT_MS);

  React.useEffect(() => {
    if (process.env.DEPLOYMENT_ENV !== 'development') {
      observe();
    }
    if (process.env.DEPLOYMENT_ENV === 'development') {
      setDisplaySocialbuttons(true);
    }
    return () => {
      if (process.env.DEPLOYMENT_ENV !== 'development') {
        observer.disconnect();
        dispatch(setFeedback({ form: Forms.socialLogin, ok: false, message: '' }));
      }
      if (process.env.DEPLOYMENT_ENV === 'development') {
        dispatch(setFeedback({ form: Forms.socialLogin, ok: false, message: '' }));
      }
    };
  }, []);

  React.useEffect(() => {
    if (ok && hasTimedOut) {
      dispatch(setFeedback({ form: Forms.socialLogin, ok: true, message: MSG_STUCK_LOGIN }));
    }
  }, [ok, hasTimedOut]);

  const handleSocialClick = (provider: SocialProviders) =>
    dispatch(socialLogin({ provider }) as unknown as UnknownAction);
  const googleDivRef = React.useRef<HTMLDivElement>(null);

  const observer = new MutationObserver(function (mutations) {
    if (mutations.length === 1) {
      setTimeout(() => setDisplaySocialbuttons(true), 500);
    }
  });

  const observe = () =>
    observer.observe(googleDivRef.current as HTMLDivElement, {
      childList: true,
      subtree: true,
    });

  return (
    <Box id="grid-social-login" display="grid" gridGap="m">
      <Box textAlign="center">
        <Text preset="caption" color="GREY">
          {title}
        </Text>
      </Box>
      {!displaySocialButtons && (
        <Box
          display="grid"
          gridGap="l"
          gridAutoFlow="column"
          gridAutoColumns="70px"
          justifyContent="center"
        >
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="s"
            justifyContent="center"
            bg="#F2F2F2"
            alignItems="center"
            borderRadius="8px"
            width="70px"
            height="60px"
            opacity={fbOpacity}
            onMouseEnter={() => setFbOpacity(0.7)}
            onMouseLeave={() => setFbOpacity(1)}
            transition="all ease-in-out 0.3s"
          >
            <Box display="grid" alignItems="end" justifyContent="center" size="20px">
              <Icon name="facebook" color="black" size={20} />
            </Box>
          </Box>
          <Box
            height="60px"
            width="70px"
            display="grid"
            alignItems="center"
            justifyContent="center"
            bg="#F2F2F2"
            borderRadius="8px"
            opacity={googleOpacity}
            onMouseEnter={() => setGoogleOpacity(0.7)}
            onMouseLeave={() => setGoogleOpacity(1)}
            transition="all ease-in-out 0.3s"
            backgroundImage={`url(${getIconUrl('googleSignIn')})`}
            backgroundSize="18px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
          <Box
            display="grid"
            alignItems="center"
            justifyContent="center"
            bg="#F2F2F2"
            borderRadius="8px"
            height="60px"
            width="70px"
            opacity={appleOpacity}
            onMouseEnter={() => setAppleOpacity(0.7)}
            onMouseLeave={() => setAppleOpacity(1)}
            transition="all ease-in-out 0.3s"
          >
            <Icon name="apple" size={19} />
          </Box>
        </Box>
      )}
      <Box
        id="grid-social-login-buttons"
        display="flex"
        flexWrap="wrap"
        gridGap="l"
        opacity={!displaySocialButtons ? 0 : 1}
        gridAutoFlow="column"
        gridAutoColumns="70px"
        justifyContent="center"
      >
        {hasFacebookInitialized && (
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="s"
            justifyContent="center"
            bg="#F2F2F2"
            alignItems="center"
            borderRadius="8px"
            onClick={() => handleSocialClick(SocialProviders.facebook)}
            width="70px"
            height="60px"
            opacity={fbOpacity}
            onMouseEnter={() => setFbOpacity(0.7)}
            onMouseLeave={() => setFbOpacity(1)}
            transition="all ease-in-out 0.3s"
          >
            <Box display="grid" alignItems="end" justifyContent="center" size="20px">
              <Icon name="facebook" id="btn-facebook-login" color="black" size={20} />
            </Box>
          </Box>
        )}
        <Box
          id="div-google-login"
          ref={googleDivRef}
          height="60px"
          width="70px"
          display="grid"
          alignItems="center"
          justifyContent="center"
          bg="#F2F2F2"
          borderRadius="8px"
          opacity={googleOpacity}
          onMouseEnter={() => setGoogleOpacity(0.7)}
          onMouseLeave={() => setGoogleOpacity(1)}
          transition="all ease-in-out 0.3s"
          backgroundImage={`url(${getIconUrl('googleSignIn')})`}
          backgroundSize="18px"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
        {hasAppleInitizalied && (
          <Box
            display="grid"
            alignItems="center"
            justifyContent="center"
            bg="#F2F2F2"
            borderRadius="8px"
            onClick={() => handleSocialClick(SocialProviders.apple)}
            height="60px"
            width="70px"
            opacity={appleOpacity}
            onMouseEnter={() => setAppleOpacity(0.7)}
            onMouseLeave={() => setAppleOpacity(1)}
            transition="all ease-in-out 0.3s"
          >
            <Icon name="apple" id="btn-apple-login" size={19} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SocialLogin;
