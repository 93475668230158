import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import {
  Picture,
  ImageContainer,
  mq,
  Icon,
  colors,
  durations,
  opacities,
} from '../../../design-system';
import { getProductLink, getCatalogImageList, isItGiftCard } from '../../product/utils';
import { zIndex } from '../../common/constants';
import { PromotionLabel } from './PromotionLabel';
import { Color } from '../../product/types';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { HoverPicture } from '../../common/components/Custom';
import { CmsImage } from '../../cms/types';

type Props = {
  productRef: string;
  productName: string;
  selectedColor: Color;
  objectID: string;
  isFeedback?: boolean;
  isDesktopFeedback?: boolean;
  onLinkClick: () => void;
  onQuickAddClick: () => void;
  alternativeSource?: CmsImage;
};

const StyledButton = styled.button`
  position: absolute;
  padding: 0;
  bottom: 8px;
  right: 8px;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.LIGHT};
  z-index: ${zIndex.PRODUCT_QUICK_ADD};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      opacity: ${opacities.HOVERED};
    }
  }
`;

export const Images = ({
  productRef,
  selectedColor,
  productName,
  objectID,
  isFeedback,
  isDesktopFeedback,
  onLinkClick,
  onQuickAddClick,
  alternativeSource,
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [firstSelectedColorInfos] = React.useState({
    ...selectedColor,
  });

  const {
    promotionLabel,
    promotionPercentage,
    promoBColor: promotionBackgroundColor,
    promoTColor: promotionTextColor,
    isOutlet,
  } = selectedColor.colorRef === firstSelectedColorInfos.colorRef
    ? firstSelectedColorInfos
    : selectedColor;

  const { isMobile, isDesktop } = useMediaQueries();

  const [srcFront, srcBack] = getCatalogImageList({
    productRef,
    productName,
    colorRef: selectedColor.colorRef,
    colorLabel: selectedColor.colorLabel,
  });

  const productLink = getProductLink({
    productRef,
    colorLabel: selectedColor.colorLabel,
    colorRef: selectedColor.colorRef,
    productName,
  });

  const isGiftCard = isItGiftCard(productRef);

  return (
    <ImageContainer
      id={`catalog-item-wrapper-${objectID}`}
      onMouseEnter={() => setIsHovered(isGiftCard ? false : true)}
      onMouseLeave={() => setIsHovered(false)}
      isDesktopFeedback={isDesktopFeedback}
    >
      <Link
        data-testid={`link-product-card-image-${objectID}`}
        id={`link-product-card-image-${objectID}`}
        to={productLink}
        onClick={onLinkClick}
      >
        {(promotionLabel || promotionPercentage) && !isFeedback && !isDesktopFeedback && (
          <PromotionLabel
            promotionLabel={promotionLabel}
            promotionPercentage={promotionPercentage}
            promotionBackgroundColor={promotionBackgroundColor}
            promotionTextColor={promotionTextColor}
            isOutlet={isOutlet}
            objectID={objectID}
            isPositionAbsolute
          />
        )}
        {isDesktop && (
          <HoverPicture isHovered={isHovered}>
            {alternativeSource ? (
              <>
                <source
                  data-srcset={srcFront.largeDesktop.webp}
                  type="image/webp"
                  media={mq.LARGE}
                />
                <source
                  data-srcset={srcFront.largeDesktop.jpg}
                  type="image/jpeg"
                  media={mq.LARGE}
                />
                <source data-srcset={srcFront.desktop.webp} type="image/webp" media={mq.DESKTOP} />
                <source data-srcset={srcFront.desktop.jpg} type="image/jpeg" media={mq.DESKTOP} />
                <source data-srcset={srcFront.tablet.webp} type="image/webp" media={mq.TABLET} />
                <source data-srcset={srcFront.tablet.jpg} type="image/jpeg" media={mq.TABLET} />
                <source data-srcset={srcFront.mobile.webp} type="image/webp" media={mq.MOBILE} />
                <source data-srcset={srcFront.mobile.jpg} type="image/jpeg" media={mq.MOBILE} />
              </>
            ) : (
              <>
                <source
                  data-srcset={srcBack.largeDesktop.webp}
                  type="image/webp"
                  media={mq.LARGE}
                />
                <source data-srcset={srcBack.largeDesktop.jpg} type="image/jpeg" media={mq.LARGE} />
                <source data-srcset={srcBack.desktop.webp} type="image/webp" media={mq.DESKTOP} />
                <source data-srcset={srcBack.desktop.jpg} type="image/jpeg" media={mq.DESKTOP} />
                <source data-srcset={srcBack.tablet.webp} type="image/webp" media={mq.TABLET} />
                <source data-srcset={srcBack.tablet.jpg} type="image/jpeg" media={mq.TABLET} />
                <source data-srcset={srcBack.mobile.webp} type="image/webp" media={mq.MOBILE} />
                <source data-srcset={srcBack.mobile.jpg} type="image/jpeg" media={mq.MOBILE} />
              </>
            )}
            <img
              src={srcBack.placeholder.jpg}
              data-src={alternativeSource ? srcFront.largeDesktop.jpg : srcBack.largeDesktop.jpg}
              alt={srcBack.largeDesktop.alt}
              className="lazyload"
              id={`catalog-item-alt-image-${objectID}`}
            />
          </HoverPicture>
        )}

        <Picture>
          {alternativeSource ? (
            <>
              <source data-srcset={alternativeSource.url} type="image/jpeg" media={mq.DESKTOP} />
              <source
                data-srcset={`${alternativeSource?.retina?.url} 2x`}
                type="image/jpeg"
                media={'(-webkit-min-device-pixel-ratio: 2)'}
              />
            </>
          ) : (
            <>
              <source data-srcset={srcFront.largeDesktop.webp} type="image/webp" media={mq.LARGE} />
              <source data-srcset={srcFront.largeDesktop.jpg} type="image/jpeg" media={mq.LARGE} />
              <source data-srcset={srcFront.desktop.webp} type="image/webp" media={mq.DESKTOP} />
              <source data-srcset={srcFront.desktop.jpg} type="image/jpeg" media={mq.DESKTOP} />
              <source data-srcset={srcFront.tablet.webp} type="image/webp" media={mq.TABLET} />
              <source data-srcset={srcFront.tablet.jpg} type="image/jpeg" media={mq.TABLET} />
              <source data-srcset={srcFront.mobile.webp} type="image/webp" media={mq.MOBILE} />
              <source data-srcset={srcFront.mobile.jpg} type="image/jpeg" media={mq.MOBILE} />
            </>
          )}
          <img
            src={srcFront.placeholder.jpg}
            data-src={alternativeSource ? alternativeSource.url : srcFront.largeDesktop.jpg}
            alt={srcFront.largeDesktop.alt}
            className="lazyload"
            id={`catalog-item-image-${objectID}`}
          />
        </Picture>
      </Link>
      {!isGiftCard && !(isMobile && isFeedback) && (
        <StyledButton
          data-testid={`product-card-${objectID}-quick-add-button`}
          id={`product-card-${objectID}-quick-add-button`}
          onClick={onQuickAddClick}
        >
          <Icon name="eyeOn" size={20} />
        </StyledButton>
      )}
    </ImageContainer>
  );
};
