import { css } from '@emotion/core';
import * as React from 'react';
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch';

import { Box, Button, Icon, Text } from '../../../design-system';
import { MobileFilterBar } from './MobileFilterBar';
import { SecondDrawer } from '../../common/components/Custom';
import useScrollLock from '../../common/hooks/useScrollLock';
import { zIndex } from '../../common/constants';
import { HIERARCHICAL_ITEMS } from '../../catalog/components/HierarchicalMenu';
import { MobileSortSelector } from '../../sorting/components/MobileSortSelector';
import { useLocation } from 'react-router-dom';
import paths from '../../routing/paths';

enum DrawerContent {
  EMPTY,
  FILTERS,
  SORT_BY,
  FILTERS_SORT_BY,
}

export const MobileFilters: React.FC = () => {
  const { pathname } = useLocation();
  const { items } = useCurrentRefinements({ excludedAttributes: [...HIERARCHICAL_ITEMS, 'query'] });
  const { results } = useInstantSearch();
  const [drawerContent, setDrawerContent] = React.useState(DrawerContent.EMPTY);
  const [filtersRef, setFiltersRef] = React.useState<HTMLDivElement>();
  useScrollLock({ ref: filtersRef, isActive: drawerContent === DrawerContent.FILTERS });
  const [sortByRef, setSortByRef] = React.useState<HTMLDivElement>();
  useScrollLock({ ref: sortByRef, isActive: drawerContent === DrawerContent.SORT_BY });
  const nbHits = pathname.startsWith(paths.SELECTION)
    ? (Object.values(results._rawResults[0].facets?.['categories.lvl0'] ?? {}).reduce(
        (acc, curr) => acc + curr,
        0
      ) ?? 0)
    : results.nbHits;

  const onFiltersRefChange = React.useCallback((node) => {
    setFiltersRef(node);
  }, []);

  const onSortByRefChange = React.useCallback((node) => {
    setSortByRef(node);
  }, []);

  const handleFiltersClick = () => {
    setDrawerContent(DrawerContent.FILTERS);
  };

  const handleSortByClick = () => {
    setDrawerContent(DrawerContent.SORT_BY);
  };

  const handleClose = () => {
    setDrawerContent(DrawerContent.EMPTY);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-around"
        position="relative"
        padding="8px 16px"
        css={css`
          gap: 16px;
        `}
      >
        <Button id="btn-filter-bar" onClick={handleFiltersClick} preset="filter_sort_by">
          <Box
            display="grid"
            gridGap="s"
            gridAutoFlow="column"
            alignItems="center"
            justifyContent="center"
          >
            {items.length > 0 ? (
              <Text
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="WHITE"
                color="BLACK"
                size="l"
                preset="caption"
                borderRadius="50%"
                fontWeight="bold"
                id="filter-bar-number-of-filters-applied"
              >
                {items.length}
              </Text>
            ) : (
              <Icon name="filters" size={26} color={'WHITE'} />
            )}
            FILTRES
          </Box>
        </Button>
        <Button id="btn-sort-by-bar" onClick={handleSortByClick} preset="filter_sort_by">
          <Box
            display="grid"
            gridGap="s"
            gridAutoFlow="column"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="sortBy" size={26} color={'WHITE'} />
            TRIER PAR
          </Box>
        </Button>
      </Box>
      <SecondDrawer
        isOpen={drawerContent === DrawerContent.FILTERS}
        ref={onFiltersRefChange}
        zIndex={zIndex.SIDEBAR}
      >
        <MobileFilterBar onClose={handleClose} nbHits={nbHits} />
      </SecondDrawer>
      <SecondDrawer
        isOpen={drawerContent === DrawerContent.SORT_BY}
        ref={onSortByRefChange}
        zIndex={zIndex.SIDEBAR}
      >
        <MobileSortSelector onClose={handleClose} />
      </SecondDrawer>
    </>
  );
};
