import React from 'react';
import styled from '@emotion/styled';
import { Divider, Icon } from '../../../design-system';
import {
  LBL_GIFT_CARD_DESCRIPTION_ONLINE,
  LBL_GIFT_CARD_DESCRIPTION_STORE,
  LBL_GIFT_CARD_DESCRIPTION_TITLE,
  LBL_GIFT_CARD_ONLINE,
  LBL_GIFT_CARD_STORE,
} from '../locale';

const GiftCardDescriptionContainer = styled.div`
  margin-top: 32px;

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-block-start: 0;
    margin-block-end: 0;
    &.ogg-text-paragraph {
      font-family: 'Ogg-text';
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &.bold-paragraph {
      font-weight: 700;
      margin-left: 16px;
    }
  }

  > div {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }
`;

export const GiftCardDescription = () => {
  return (
    <GiftCardDescriptionContainer>
      <p className="ogg-text-paragraph">{LBL_GIFT_CARD_DESCRIPTION_TITLE}</p>
      <div>
        <Icon name="clickCollect" size={40} />
        <p className="bold-paragraph">{LBL_GIFT_CARD_ONLINE}</p>
      </div>
      <p>{LBL_GIFT_CARD_DESCRIPTION_ONLINE}</p>
      <Divider my="16px" />
      <div>
        <Icon name="shop" size={40} />
        <p className="bold-paragraph">{LBL_GIFT_CARD_STORE}</p>
      </div>
      <p>{LBL_GIFT_CARD_DESCRIPTION_STORE}</p>
    </GiftCardDescriptionContainer>
  );
};
