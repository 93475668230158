import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { colors, durations } from '../../systemprovider';
import Box from '../Box';

const colorAnimation = () => keyframes`
  0% {
    background-color: ${colors.BACKGROUND};
  }
  50% {
    background-color: ${colors.LIGHT};
  }
  100 {
    background-color: ${colors.BACKGROUND};
  }
`;

const LoadingBox = styled(Box)`
  animation: ${() => colorAnimation()} ${durations.LOADING_BACKGROUND}ms infinite;
  background-color: ${colors.BACKGROUND};
`;

export default LoadingBox;
