import * as React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { RichText } from 'prismic-reactjs';
import { css } from '@emotion/core';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Icon, colors, opacities } from '../../../design-system';
import { CmsText } from '../../cms/types';
import { heights, durations } from '../../common/constants';
import Transition from 'react-transition-group/Transition';

const transitionStylesContent = {
  entering: { opacity: 0, maxHeight: 0, paddingBottom: 0 },
  entered: {
    opacity: 1,
    maxHeight: `${heights.CART_FAQ}px`,
    paddingBottom: '19px',
    transition: `max-height ${durations.ENTER}ms ease-in, opacity ${
      durations.ENTER
    }ms ease-in, padding-bottom ${durations.ENTER / 2}ms ease-in`,
  },
  exiting: {
    opacity: 0,
    maxHeight: 0,
  },
  exited: { opacity: 0, maxHeight: 0 },
};

type Props = {
  dividerColor: string | undefined;
  questionColor: string | undefined;
  questionFontFamily: string | undefined;
  question: string;
  answerColor: string | undefined;
  answerFontFamily: string | undefined;
  answer: CmsText;
  index: number;
};

export const CatalogFaqItem = ({
  dividerColor,
  question,
  answer,
  index,
  answerColor,
  questionColor,
  answerFontFamily,
  questionFontFamily,
}: Props) => {
  const [isQuestionBlockOpen, setIsQuestionBlockOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useMediaQueries();

  React.useEffect(() => {
    if (isMobile && ref.current && isQuestionBlockOpen) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (!isQuestionBlockOpen && hash === `#catalog-faq-question-${index + 1}`) {
      navigate(pathname);
    }
  }, [isQuestionBlockOpen]);

  React.useEffect(() => {
    if (hash) {
      if (hash === `#catalog-faq-question-${index + 1}` && !isQuestionBlockOpen) {
        setIsQuestionBlockOpen(true);
      } else if (hash !== `#catalog-faq-question-${index + 1}` && isQuestionBlockOpen) {
        setIsQuestionBlockOpen(false);
      }
    }
  }, [hash]);

  return (
    <div
      id={`catalog-faq-question-${index}`}
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        display: flex;
        align-self: center;
        justify-content: space-between;
        width: 100%;
        max-width: 934px;
        padding: 0 16px;
        border-bottom: ${`solid 1px ${dividerColor ?? colors.BLACK}`};
        scroll-margin-top: 40vh;
      `}
    >
      <h3
        css={css`
          margin-block-start: 0;
          margin-block-end: 0;
        `}
      >
        <button
          data-cy={`catalog-faq-button-to${
            isQuestionBlockOpen ? 'close' : 'open'
          }-question-answer-block-${index}`}
          id={`catalog-faq-button-to${
            isQuestionBlockOpen ? 'close' : 'open'
          }-question-answer-block-${index}`}
          onClick={() => setIsQuestionBlockOpen(!isQuestionBlockOpen)}
          css={css`
            min-height: 56px;
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: space-between;
            width: 100%;
            max-width: 934px;
            background: none;
            border: none;
            font-size: 1.6rem;
            gap: 24px;
            font-weight: 700;
            font-family: ${questionFontFamily ?? 'MaisonNeue'};
            color: ${questionColor ?? colors.BLACK};
            padding: 19px 0 19px 0;
            text-align: left;
            cursor: pointer;
            :active {
              -webkit-tap-highlight-color: transparent;
            }
            @media (hover: hover) {
              :hover {
                opacity: ${opacities.HOVERED};
                transition: opacity 0.3s ease-in-out;
              }
            }
          `}
        >
          {question}
          <Icon name={isQuestionBlockOpen ? 'minus' : 'plus'} size={16} />
        </button>
      </h3>
      <Transition
        in={isQuestionBlockOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: 0 }}
      >
        {(state) => (
          <div
            id={`catalog-faq-answer-${index}`}
            style={{ ...transitionStylesContent[state] }}
            css={css`
              color: ${answerColor};
              font-family: ${answerFontFamily};
            `}
          >
            <RichText render={answer} />
          </div>
        )}
      </Transition>
    </div>
  );
};
