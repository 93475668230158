import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { RichText } from 'prismic-reactjs';

import {
  Box,
  Field,
  PasswordField,
  DateField,
  Text,
  RadioGroup,
  DynamicButton,
} from '../../../design-system';
import { NOTE_PASSWORD } from '../../auth/locale';
import { Forms, FieldCallback, FieldType, FieldRefs } from '../../form/types';
import NewsletterContainer from './Newsletter';
import { RootState } from '../../../store/rootReducer';
import { setFormValues, setFormValidation, setFeedback } from '../../form/actions';
import { loadUserDetails, updateUserInfo } from '../actions';
import {
  format,
  validate,
  isCtaActive,
  isPhoneOnChangeValid,
  formatedPhoneValue,
  validateName,
  removeSpaces,
  addSpacesForPhoneNumber,
} from '../../form/utils';
import { CTA_CONTENT } from '../../form/locale';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import { emptyText } from '../../cms/state';

export const PersonalDetails = () => {
  const dispatch = useDispatch();

  const { body } = useSelector((state: RootState) => state.cms.account) ?? {};

  const form = useSelector((state: RootState) => state.form.personal);

  const refs: FieldRefs = {};
  Object.keys(form.values).forEach((key) => {
    refs[key] = React.useRef(null);
  });

  React.useEffect(() => {
    if (!body?.[0]?.text) {
      dispatch(loadCmsContent(CmsCustomTypes.account) as unknown as UnknownAction);
    }
    dispatch(loadUserDetails() as unknown as UnknownAction);
  }, []);

  const validateField = (key: string, value: FieldType): string => {
    let errMsg = '';

    if (key === 'password') {
      if (value) {
        errMsg = validate({ value: String(value), key });
      }
    } else {
      errMsg = validate({ value: String(value), key, isRequired: !['dob', 'phone'].includes(key) });
    }

    return errMsg;
  };

  const handleFieldChange = ({ key, value }: FieldCallback) => {
    let newValue = value;
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      typeof value === 'string' &&
      !isPhoneOnChangeValid({ value, dispatch, formType: Forms.personal, form })
    ) {
      return null;
    }

    newValue = key === 'phone' && typeof value === 'string' ? formatedPhoneValue(value) : value;

    if (form.validation[key] && key !== 'phone') {
      dispatch(
        setFormValidation({ form: Forms.personal, values: { [key]: validateField(key, newValue) } })
      );
    }

    if (key === 'dob') {
      newValue = format({ value: String(newValue), key });
    }

    dispatch(setFormValues({ form: Forms.personal, values: { [key]: newValue } }));
    if (!form.feedback.isDirty) {
      dispatch(
        setFeedback({
          form: Forms.personal,
          ok: false,
          message: '',
          isDirty: true,
        })
      );
    }
    return null;
  };

  const handleFieldBlur = ({ key, value }: FieldCallback) => {
    const errMsg = validateField(key, value);
    dispatch(setFormValidation({ form: Forms.personal, values: { [key]: errMsg } }));
  };

  const getField = ({
    key,
    label,
    autoComplete,
    type,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    type?: string;
  }) => (
    <Box gridArea={key}>
      <Field
        id={`${Forms.personal}-${key}`}
        onChange={(value: string) =>
          handleFieldChange({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        onBlur={(value: string) =>
          handleFieldBlur({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        value={key === 'phone' ? addSpacesForPhoneNumber(form.values[key]) : form.values[key]}
        errMsg={form.validation[key]}
        label={label}
        ref={refs[key]}
        autoComplete={autoComplete}
        type={type}
      />
    </Box>
  );

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    let fieldToFocus = '';
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField(key, values[key]);
      newErrMsgs[key] = errMsg;
      dispatch(setFormValidation({ form: Forms.personal, values: { [key]: errMsg } }));
      if (errMsg && !fieldToFocus) {
        fieldToFocus = key;
      }
      return errMsg;
    });
    if (fieldToFocus) {
      const node = refs[fieldToFocus].current;
      if (node) {
        node.focus();
      }
    }
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      dispatch(updateUserInfo({ ...form.values }) as unknown as UnknownAction);
    }
  };

  return (
    <form>
      <Box maxWidth="mws">
        <Box
          display="grid"
          gridColumnGap="s"
          gridRowGap="l"
          gridTemplateAreas={[
            "'newsletter' 'title' 'prefix' 'firstName' 'lastName' 'email' 'phone' 'dob' 'password' 'submitbutton' 'message' 'service'",
            "'newsletter newsletter' 'message message' 'title title' 'prefix prefix' 'firstName lastName' 'email email' 'phone dob' 'password password' 'submitbutton .' 'service service'",
          ]}
        >
          <Box gridArea="message" textAlign="center">
            {form.feedback.message && (
              <Text
                color={form.feedback.ok ? 'SUCCESS' : 'ERROR'}
                id={`account-personal-form-feedback-${form.feedback.ok ? 'success' : 'error'}`}
              >
                {form.feedback.message}
              </Text>
            )}
          </Box>
          <Box gridArea="title" my="s">
            <Text preset="heading">Vos informations</Text>
          </Box>

          <Box gridArea="prefix">
            <RadioGroup
              id="radio-personal-details-prefix"
              inline
              ref={refs.prefix}
              checked={form.values.prefix}
              errMsg={form.validation.prefix}
              onChange={(value) => handleFieldChange({ key: 'prefix', value: String(value) })}
              options={[
                {
                  id: 'Madame',
                  value: 'Madame',
                },
                {
                  id: 'Monsieur',
                  value: 'Monsieur',
                },
              ]}
            />
          </Box>
          {getField({ key: 'firstName', label: 'Prénom*', autoComplete: 'given-name' })}
          {getField({ key: 'lastName', label: 'Nom*', autoComplete: 'family-name' })}
          {getField({ key: 'email', label: 'Email*', autoComplete: 'email' })}
          {getField({ key: 'phone', label: 'Téléphone', autoComplete: 'tel', type: 'tel' })}
          <Box gridArea="dob">
            <DateField
              id="personal-info-form-dob"
              key="dob"
              value={form.values.dob}
              errMsg={form.validation.dob}
              label="Anniversaire"
              onChange={(value: string) => handleFieldChange({ key: 'dob', value })}
              onBlur={(value: string) => handleFieldBlur({ key: 'dob', value })}
            />
          </Box>
          <Box gridArea="password">
            <PasswordField
              id="personal-info-form-password"
              key="password"
              value={form.values.password}
              errMsg={form.validation.password}
              label="Nouveau mot de passe"
              onChange={(value: string) => handleFieldChange({ key: 'password', value })}
              onBlur={(value: string) => handleFieldBlur({ key: 'password', value })}
              autoComplete="new-password"
              note={NOTE_PASSWORD}
            />
          </Box>
          <Box gridArea="submitbutton" my="l">
            <Box width={['100%', '256px']}>
              <DynamicButton
                type="button"
                id="btn-submit-personal-details"
                onClick={handleSubmit}
                disabled={!form.feedback.isDirty}
                data={CTA_CONTENT}
                feedback={form.ctaState}
                isActive={isCtaActive(form)}
              />
            </Box>
          </Box>
          <Box gridArea="newsletter">
            <Text preset="heading">Recevez nos actualités</Text>
            <Box mx={['-16px', 'na']}>
              <NewsletterContainer />
            </Box>
          </Box>
          <Box gridArea="service" my="m">
            <RichText render={body || [...emptyText]} />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default PersonalDetails;
