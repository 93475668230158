import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { breakpoints, colors } from './../../../design-system/systemprovider';
import { CmsSliderItem } from '../../cms/types';
import { StyledItemAnchorWrapper } from './CmsSliderWithNumber';

type Props = {
  index: number;
  activeIndex: number;
  item: CmsSliderItem;
  isSticky?: boolean;
  refContainer: React.RefObject<HTMLUListElement>;
};

type StyledParagraphProps = {
  isActive: boolean;
};

const StyledParagraph = styled.p<StyledParagraphProps>`
  align-self: center;
  width: max-content;
  padding: 4px 16px;
  border: ${(props) =>
    props.isActive ? `solid 1px ${colors.BLACK}` : `solid 1px ${colors.LIGHT}`};
  border-radius: 16px;
  background: ${colors.WHITE};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: unset;
  margin: 0;
  @media (min-width: ${breakpoints.S}px) {
    font-weight: 700;
  }
`;

export const CmsSliderStickyItem = ({ index, activeIndex, item, refContainer }: Props) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  let timer: NodeJS.Timeout;

  useEffect(() => {
    const scrollLeft = () => {
      if (ref?.current && refContainer?.current && activeIndex === index) {
        const containerWidth = refContainer.current.getBoundingClientRect().width;
        const elementWidth = ref.current.getBoundingClientRect().width;
        const targetLeft = ref.current.offsetLeft + (elementWidth - containerWidth) / 2;
        refContainer.current.scrollTo({ behavior: 'smooth', left: targetLeft });
      }
    };

    // timeout 1s to allow vertical scroll before horizontal scroll
    timer = setTimeout(() => scrollLeft(), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [ref?.current, refContainer?.current, activeIndex]);

  return (
    <li>
      <StyledItemAnchorWrapper
        ref={ref}
        data-cy={`catalog-slider-item-${index}-link`}
        href={item.slider_with_number_link?.url ?? ''}
        isClikable={Boolean(item.slider_with_number_link?.url)}
      >
        <StyledParagraph isActive={activeIndex === index}>
          {item.slider_with_number_text}
        </StyledParagraph>
      </StyledItemAnchorWrapper>
    </li>
  );
};
