import * as React from 'react';

import { Box, Text } from '../../../design-system';
import { LineThroughText } from '../../common/components/Custom';
import { Price } from '../../product/types';

type Props = {
  price: Price;
  isEresaModal?: boolean;
};

const ProductPrice = ({ price, isEresaModal }: Props) => (
  <Box display="grid" gridAutoFlow="column" alignItems="center" gridGap="s">
    {price.hasPromotion && (
      <LineThroughText fontWeight={isEresaModal ? 700 : 400} id="pdp-original-price">
        {price.originalPrice}
      </LineThroughText>
    )}
    <Text
      id="pdp-price"
      preset={['body', 'subheading']}
      color={
        price.hasPromotion && isEresaModal ? '#E31D29' : price.hasPromotion ? 'PROMO' : 'BLACK'
      }
      fontWeight={isEresaModal ? 700 : 'bold'}
    >
      {price.storePrice}
    </Text>
  </Box>
);

export default ProductPrice;
