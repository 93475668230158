import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { CmsSearchBannerTiles } from '../../cms/types';
import { breakpoints, colors, durations, opacities } from '../../../design-system';

type Props = {
  data: CmsSearchBannerTiles | undefined;
};

const IMAGE_WIDTH = 220;
const IMAGE_GAP = 16;

const StyledContainer = styled.div<{ height_position_one: number; height_position_two: number }>`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  gap: ${IMAGE_GAP}px;

  @media (min-width: ${breakpoints.L}px) {
    flex: 0 0 auto;
    padding-top: 45px;
  }

  .banner-tile-image {
    width: calc(50vw - 24px);
    height: 100%;
    object-fit: cover;
    border-radius: 8px;

    @media (min-width: ${breakpoints.S}px) {
      width: calc(50vw - 48px);
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${IMAGE_WIDTH}px;
    }
  }

  .image-wrapper,
  .link-wrapper {
    position: relative;
  }

  .link-wrapper {
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
      }
    }
  }

  .text-one,
  .text-two {
    position: absolute;
    width: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: 'Ogg-text';
    font-size: 3.2rem;
    line-height: 110%;
    font-weight: 700;
    color: ${colors.WHITE};
    padding: 0 8px;
    text-align: center;
  }

  .text-one {
    top: ${(props) => props.height_position_one}%;
  }

  .text-two {
    top: ${(props) => props.height_position_two}%;
  }
`;

export const SearchBannerTiles = ({ data }: Props) => {
  if (!data) {
    return null;
  }

  const {
    banner_tile_image_one,
    banner_tile_image_one_link,
    banner_tile_image_one_text,
    banner_tile_image_one_text_height_position,
    banner_tile_image_two,
    banner_tile_image_two_link,
    banner_tile_image_two_text,
    banner_tile_image_two_text_height_position,
  } = data.primary;

  const banners = [
    {
      image: banner_tile_image_one,
      link: banner_tile_image_one_link,
      text: banner_tile_image_one_text,
      textHeightPosition: banner_tile_image_one_text_height_position ?? 50,
      textClass: 'text-one',
    },
    {
      image: banner_tile_image_two,
      link: banner_tile_image_two_link,
      text: banner_tile_image_two_text,
      textHeightPosition: banner_tile_image_two_text_height_position ?? 50,
      textClass: 'text-two',
    },
  ];

  return (
    <StyledContainer
      id="search-banner-tiles-block"
      height_position_one={banner_tile_image_one_text_height_position ?? 50}
      height_position_two={banner_tile_image_two_text_height_position ?? 50}
    >
      {banners.map((banner, index) => {
        const content = (
          <>
            <img
              className="banner-tile-image"
              src={banner.image?.url}
              alt={banner.image?.alt ?? `image ${index + 1} de la page recherche`}
            />
            {banner.text && <h3 className={banner.textClass}>{banner.text}</h3>}
          </>
        );
        return banner.link ? (
          <Link
            key={`search-banner-tile-${index}-${banner.text}`}
            className="link-wrapper"
            to={banner.link}
            data-testid={`banner-tile-${index + 1}-link-to-${banner.link}`}
            aria-label={`Lien vers la page ${banner.link}`}
          >
            <div className="image-wrapper">{content}</div>
          </Link>
        ) : (
          <div key={`search-banner-tile-${index}-${banner.text}`} className="image-wrapper">
            {content}
          </div>
        );
      })}
    </StyledContainer>
  );
};
