import { StoreState, emptyStoreList } from './state';
import { State } from './types';
import { StoresActions } from './actions';
import types from './actionTypes';

export default function stores(state = StoreState, action: StoresActions): State {
  switch (action.type) {
    case types.REQUEST_STORES_BY_LOCATION:
      return {
        ...state,
        isFetching: true,
      };
    case types.REQUEST_STORES_BY_LOCATION_SUCCESS:
      return {
        ...state,
        storesByLocation: action.payload,
        isFetching: false,
      };
    case types.REQUEST_STORES_BY_LOCATION_FAILURE:
      return {
        ...state,
        storesByLocation: emptyStoreList,
        isFetching: false,
      };

    case types.REQUEST_ALL_STORES:
      return {
        ...state,
        storesByLocation: emptyStoreList,
        isFetching: true,
      };
    case types.REQUEST_ALL_STORES_SUCCESS:
      return {
        ...state,
        storesByLocation: action.payload,
        isFetching: false,
        allStores: action.payload.stores,
      };
    case types.REQUEST_ALL_STORES_FAILURE:
      return {
        ...state,
        storesByLocation: emptyStoreList,
        errMsg: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}
