import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { zIndex } from '../../common/constants';
import { getPromotionColors } from '../utils';
import { breakpoints } from '../../../design-system';

type Props = {
  objectID: string;
  promotionPercentage?: string;
  promotionLabel?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  isOutlet?: boolean;
  isPositionAbsolute?: boolean;
};

type StyledSpanProps = {
  isPositionAbsolute: boolean;
  backgroundColor: string;
  promotionLabelColor: string;
};

const StyledSpan = styled.span<StyledSpanProps>`
  width: fit-content;
  position: ${(props) => (props.isPositionAbsolute ? 'absolute' : 'initial')};
  top: 8px;
  right: 8px;
  background-color: ${(props) => props.backgroundColor};
  z-index: ${zIndex.PRODUCT_PROMOTION};
  padding: 4px 8px;
  margin-bottom: ${(props) => (props.isPositionAbsolute ? '16px' : 0)};
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  border-radius: 4px;
  color: ${(props) => props.promotionLabelColor};

  @media (min-width: ${breakpoints.S}px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const PromotionLabel = ({
  promotionPercentage,
  promotionLabel,
  promotionTextColor,
  promotionBackgroundColor,
  isOutlet,
  objectID,
  isPositionAbsolute = false,
}: Props) => {
  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  if (!(promotionPercentage || promotionLabel)) {
    return null;
  }

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
    promotionTextColor,
    promotionBackgroundColor,
    isOutlet,
  });

  return (
    <StyledSpan
      id={`catalog-item-promotion-label-${objectID}`}
      isPositionAbsolute={isPositionAbsolute}
      backgroundColor={backgroundColor}
      promotionLabelColor={textColor}
    >
      {promotionPercentage && !isOutlet ? `-${promotionPercentage}` : promotionLabel}
    </StyledSpan>
  );
};
