import { action, ActionType } from 'typesafe-actions';

import types from './actionTypes';
import { ModalPreset, ModalPresetNew } from './types';
import { findDevice } from './utils';

export type OpenSidebarParams = {
  content: React.ReactNode;
  title?: string;
};

export function openSidebar({ title, content }: OpenSidebarParams) {
  return {
    type: types.OPEN_SIDEBAR,
    title,
    content,
  };
}

export function closeSidebar() {
  return {
    type: types.CLOSE_SIDEBAR,
  };
}

export function changeTitle(title: string) {
  return {
    type: types.CHANGE_TITLE,
    title,
  };
}
export type OpenSidebarNewParams = {
  content: React.ReactNode;
  title?: React.ReactNode | string;
  direction: 'left' | 'right';
};

export const openSidebarNew = ({ title, content, direction }: OpenSidebarNewParams) => ({
  type: types.OPEN_SIDEBAR_NEW,
  title,
  content,
  direction,
});

export function closeSidebarNew() {
  return {
    type: types.CLOSE_SIDEBAR_NEW,
  };
}

export function changeTitleNew(title: React.ReactNode | string) {
  return {
    type: types.CHANGE_TITLE_NEW,
    title,
  };
}

export type OpenModalParams = {
  content: React.ReactNode;
  background?: boolean;
  title?: string;
  preset?: ModalPreset;
  onBackgroundClick?: () => void;
};

export function openModal({
  content,
  title,
  background = true,
  preset = 's',
  onBackgroundClick,
}: OpenModalParams) {
  return {
    type: types.OPEN_MODAL,
    content,
    background,
    title,
    preset,
    onBackgroundClick,
  };
}

export function closeModal() {
  return {
    type: types.CLOSE_MODAL,
  };
}

export type OpenModalNewParams = {
  content: React.ReactNode;
  preset?: ModalPresetNew;
};

export const openModalNew = ({ content, preset }: OpenModalNewParams) => ({
  type: types.OPEN_MODAL_NEW,
  content,
  preset,
});

export function closeModalNew() {
  return {
    type: types.CLOSE_MODAL_NEW,
  };
}

export type OpenPopupParams = {
  content: React.ReactNode;
  title?: string;
  preset?: ModalPreset;
};

export function openPopup({ content, title, preset = 's' }: OpenPopupParams) {
  return {
    type: types.OPEN_POPUP,
    content,
    title,
    preset,
  };
}

export function closePopup() {
  return {
    type: types.CLOSE_POPUP,
  };
}

export function updateDevice(userAgent: string) {
  const payload = findDevice(userAgent);
  return action(types.UPDATE_DEVICE, payload);
}

export function setIsOutdated(payload: boolean) {
  return action(types.SET_IS_OUTDATED, payload);
}

export type CommonActions = ActionType<typeof updateDevice | typeof setIsOutdated>;
