import { State } from './types';

export const filtersState: State = {
  limits: {
    promotionPercentage: 5,
    colorLabel: 5,
    typology: 5,
    collectionName: 5,
    storesAvailable: 5,
    maintien: 6,
    layer: 5,
    wire: 2,
  },
  currentRefinementsGroup: {},
  currentRefinementsItems: [],
  refinementsItems: [],
  mobile: {
    step: -1,
  },
  options: {
    colorLabel: 0,
    'sizeVariants.sizeFilter': 0,
    typology: 0,
    collectionName: 0,
    promotionPercentage: 0,
    price: 0,
    storesAvailable: 0,
    maintien: 0,
    layer: 0,
    wire: 0,
  },
  filters: {
    colors: [],
    sizes: [],
    forms: [],
    collection: [],
    promotions: [],
    isLoading: false,
  },
};
