import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import Search from './Search';
import { StoreAutoComplete } from '../../store-locator/components/StoreAutoComplete';
import { PickupModal } from './PickupModal';
import { openModal } from '../../common/actions';
import { getPickUpStations, getToken } from '../actions';
import SelectedStation from './SelectedStation';
import { Suggestion } from '../types';
import { ERR_SELECT_PICKUP_STATION, MODAL_TITLE } from '../locale';
import useGoogleMapsAutocomplete from '../../common/hooks/useGoogleMapsAutocomplete';
import { setFocus } from '../../form/actions';
import { Forms } from '../../form/types';
import { STORE_DELIVERY_SEARCH_PLACEHOLDER } from '../../store-locator/locale';

type Props = {
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  autocomplete?: google.maps.places.AutocompleteService;
};
export const PickupDelivery = ({ sessionToken, autocomplete }: Props) => {
  const dispatch = useDispatch();
  const { pickupStation } = useSelector((state: RootState) => state.cart.cart);
  const { focus } = useSelector((state: RootState) => state.form.delivery);
  const pickupStations = useSelector((state: RootState) => state.pickup.pickupStations) ?? [];
  const token = useSelector((state: RootState) => state.pickup.token);

  const [searchText, setSearchText] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  const divRef = React.useRef<HTMLInputElement>(null);

  const pickupStationId = pickupStation?.id;
  const selectedStation = pickupStations.find((station) => station.id === pickupStationId);

  React.useEffect(() => {
    if (token) {
      dispatch(getToken() as unknown as UnknownAction);
    }
  }, []);

  React.useEffect(() => {
    if (!token) {
      dispatch(getToken() as unknown as UnknownAction);
    }
  }, [token]);

  const getPickUpStationsList = (search: string) => {
    dispatch(getPickUpStations(true, search) as unknown as UnknownAction);
  };

  const handleChange = (value: string) => {
    setSearchText(value);

    if (errMsg) {
      setErrMsg('');
    }
  };

  const openMap = ({ search, toBePositioned }: { search?: string; toBePositioned?: boolean }) => {
    dispatch(
      openModal({
        content: (
          <PickupModal
            search={search}
            toBePositioned={toBePositioned}
            sessionToken={sessionToken}
            autocomplete={autocomplete}
          />
        ),
        preset: 'l',
        title: MODAL_TITLE,
      })
    );
  };

  const handleLocate = () => {
    openMap({ toBePositioned: true });
  };

  const handleSearch = () => {
    getPickUpStationsList(searchText);
    openMap({ search: searchText });
  };

  const handleAutoComplete = (search: string) => {
    getPickUpStationsList(search);
    openMap({ search });
  };

  const handleChangeStationClick = () => {
    openMap({});
  };

  React.useEffect(() => {
    if (focus === 'search') {
      const node = divRef.current;
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrMsg(ERR_SELECT_PICKUP_STATION);
      }
    }
  }, [focus]);

  React.useEffect(() => {
    if (focus === 'search' && selectedStation) {
      dispatch(setFocus({ form: Forms.delivery, key: '' }));
    }
  }, [selectedStation]);

  const predictions = useGoogleMapsAutocomplete(sessionToken, autocomplete, searchText);
  const suggestionsList: Suggestion[] = predictions.map((prediction) => {
    return { name: prediction.description, placeId: prediction.placeId };
  });
  if (selectedStation) {
    return <SelectedStation station={selectedStation} onClick={handleChangeStationClick} />;
  }

  return (
    <Box ref={divRef}>
      <Search
        onIconClick={handleSearch}
        onChange={handleChange}
        onClick={handleLocate}
        value={searchText}
        errMsg={errMsg}
        placeholder={STORE_DELIVERY_SEARCH_PLACEHOLDER}
        isPickupDelivery
      />
      <StoreAutoComplete
        suggestionsList={suggestionsList}
        query={searchText}
        onClick={handleAutoComplete}
      />
    </Box>
  );
};
