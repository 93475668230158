import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Field, MultilineField, breakpoints } from '../../../design-system';
import { TGiftCardErrors } from '../utils';
import { TGiftCardInfos } from './Product';
import { MAX_LENGTH_GIFT_CARD_MESSAGE } from '../locale';
import styled from '@emotion/styled';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type TGiftCardSelector = {
  onGiftCardInfo: ({
    isAnotherAmount,
    giftCardAmount,
    senderName,
    receiverName,
    receiverEmail,
    dateOfSending,
    message,
  }: TGiftCardInfos) => void;
  isCustomAmountDeselected: boolean;
  giftCardErrMsg: TGiftCardErrors;
  giftCardInfos: TGiftCardInfos;
};

const StyledDiv = styled.div`
  .checkbox-content__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-block-start: 0;
    margin-block-end: 0;
    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
  .gift-card-input__container {
    margin-top: 12px;
  }
`;

export function GiftCardCustomInfos({
  onGiftCardInfo,
  isCustomAmountDeselected,
  giftCardErrMsg,
  giftCardInfos,
}: TGiftCardSelector) {
  const [isAnotherAmount, setIsAnotherAmount] = useState(false);
  const [giftCardCustomAmount, setGiftCardCustomAmount] = useState('');
  const [senderName, setSenderName] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [dateOfSending, setDateOfSending] = useState(new Date().toISOString().slice(0, 10));
  const [message, setMessage] = useState('');
  const { isMobile } = useMediaQueries();
  const reggexForCustomAmount = /^[0-9]+([.,][0-9]{1,2})?$/;

  const CustomSetGiftCardCustomAmount = (value: string) => {
    if (!reggexForCustomAmount.test(value) && value.length !== 0) {
      return;
    }
    if (giftCardCustomAmount === '' && value === '0') {
      return;
    }
    if (value.length > 6) {
      return;
    }
    setGiftCardCustomAmount(value);
  };

  useEffect(() => {
    onGiftCardInfo({
      ...giftCardInfos,
      isAnotherAmount,
      giftCardAmount: isAnotherAmount ? giftCardCustomAmount : giftCardInfos.giftCardAmount,
      senderName,
      receiverName,
      receiverEmail,
      dateOfSending,
      message,
    });
  }, [
    isAnotherAmount,
    senderName,
    receiverName,
    receiverEmail,
    dateOfSending,
    message,
    giftCardCustomAmount,
  ]);

  useEffect(() => {
    if (isCustomAmountDeselected) {
      setIsAnotherAmount(false);
    }
  }, [isCustomAmountDeselected]);

  return (
    <StyledDiv id="gift-card-input-fields">
      {!isMobile && (
        <Checkbox
          id="other-amount-checkbox"
          onChange={() => setIsAnotherAmount((check) => !check)}
          checked={isAnotherAmount}
        >
          <div className="checkbox-content__container">
            <p className="checkbox-content__description">Choisissez votre montant</p>
          </div>
        </Checkbox>
      )}
      <div className="gift-card-input__container">
        {!isMobile && isAnotherAmount && (
          <Box mb={'xs'} flex={1}>
            <Field
              id="quantity"
              type="number"
              min="1"
              max="300"
              step="0.01"
              label="Votre montant ex. 52,50€"
              isAPrice
              value={giftCardCustomAmount}
              onChange={(e) => CustomSetGiftCardCustomAmount(e)}
              errMsg={giftCardErrMsg?.customAmount ?? ''}
            />
          </Box>
        )}
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gridColumnGap={'1rem'} mb={'xs'}>
          <Field
            id="sender"
            type="text"
            maxLength={60}
            label="De la part de*"
            value={senderName}
            onChange={(e) => setSenderName(e)}
            errMsg={giftCardErrMsg?.senderName ?? ''}
          />

          <Field
            id="receiver"
            type="text"
            maxLength={60}
            label="Pour*"
            value={receiverName}
            onChange={(e) => setReceiverName(e)}
            errMsg={giftCardErrMsg?.receiverName ?? ''}
          />
        </Box>
        <Box mb={'xs'}>
          <Field
            id="email"
            type="email"
            maxLength={80}
            label="Email du destinataire*"
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e)}
            errMsg={giftCardErrMsg?.receiverEmail ?? ''}
          />
        </Box>
        <Box mb={'xs'}>
          <Field
            id="date-of-sending"
            type="date"
            label="Date d'envoi*"
            required
            value={dateOfSending}
            onChange={(e) => setDateOfSending(e)}
            errMsg={giftCardErrMsg?.date ?? ''}
          />
        </Box>
        <Box mb={'16px'}>
          <MultilineField
            id="message"
            maxLength={MAX_LENGTH_GIFT_CARD_MESSAGE}
            label="Écrire un message personnalisé"
            value={message}
            onChange={(e) => setMessage(e)}
            errMsg={giftCardErrMsg?.message ?? ''}
            areaHeight={'95px'}
            note={`${message.length}/${MAX_LENGTH_GIFT_CARD_MESSAGE} caractères maximum`}
          />
        </Box>
      </div>
    </StyledDiv>
  );
}
