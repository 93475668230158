export default {
  UPDATE_LIMIT: 'FILTERS / UPDATE LIMIT',
  RESET_LIMITS: 'FILTERS / RESET LIMITS',
  UPDATE_CURRENT_REFINEMENTS_GROUP: 'FILTERS / UPDATE CURRENT REFINEMENTS GROUP',
  UPDATE_CURRENT_REFINEMENTS_ITEMS: 'FILTERS / UPDATE CURRENT REFINEMENTS ITEMS',
  UPDATE_REFINEMENTS_ITEMS: 'FILTERS / UPDATE REFINEMENTS ITEMS',
  UPDATE_STEP: 'FILTERS / UPDATE STEP',
  UPDATE_STORE_STOCK: 'FILTERS / UPDATE_STORE_STOCK',
  UPDATE_OPTIONS: 'FILTERS / UPDATE OPTIONS',
  UPDATE_SELECTED_ITEMS: 'FILTERS / UPDATE SELECTED ITEMS',
  SET_IS_LOADING: 'FILTERS / SET IS LOADING',
} as const;
