import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { loadRecommendations } from '../../recommendations/actions';
import { Recommendation, RecommendationTypes } from '../../recommendations/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { Product } from '../../product/types';
import { RecommendationsHeader } from '../../recommendations/components/RecommendationsHeader';
import { RecommendationsContent } from '../../recommendations/components/RecommendationsContent';
import { breakpoints, colors, durations, opacities } from '../../../design-system';

const StyledRecommendationsTabsBannerContainer = styled.div`
  margin: 0 16px;

  @media (min-width: ${breakpoints.S}px) {
    margin: 0;
  }

  .recommendations-tabs-buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    padding-bottom: 24px;
    width: 100%;

    @media (min-width: ${breakpoints.S}px) {
      width: fit-content;
      margin: auto;
    }
  }

  .recommendations-tabs-button {
    border: none;
    border-bottom: 1px solid ${colors.LIGHT};
    color: ${colors.BLACK};
    padding: 0;
    background-color: unset;
    cursor: pointer;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (min-width: ${breakpoints.S}px) {
      padding: 0 24px;
    }

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .button-selected {
    border-bottom: 1px solid ${colors.BLACK};
    pointer-events: none;
  }

  .button-name {
    margin: 0;
    padding: 16px 0;
    font-size: 1.4rem;
    color: ${colors.GREY2};

    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.6rem;
    }
  }

  .button-name-selected {
    font-weight: 700;
    color: ${colors.BLACK};
  }
`;

type Props = {
  title: string;
  tabs?: { name?: string; t2s_id?: string }[];
};

export const RecommendationsTabsBanner = ({ title, tabs }: Props) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const recommendationBlocks = useSelector((state: RootState) => state.recommendations.blocks);

  const [selectedFilter, setSelectedFilter] = useState(0);

  const filteredTabs = tabs?.filter((tab) => tab.t2s_id && tab.name) ?? [];
  const t2sIds = filteredTabs.map((tab) => tab.t2s_id);

  const isRecommendationsNotLoaded =
    t2sIds.map((t2sId) => recommendationBlocks[`homeTab${t2sId}`]).filter((el) => el !== undefined)
      .length !== t2sIds.length;

  useEffect(() => {
    if (isRecommendationsNotLoaded) {
      dispatch(loadRecommendations(RecommendationTypes.home, t2sIds) as unknown as UnknownAction);
    }
  }, []);

  useEffect(() => {
    const currentRef = containerRef?.current;
    if (currentRef) {
      currentRef.scrollTo({ left: 0, behavior: 'instant' });
    }
  }, [selectedFilter]);

  const recommendations = t2sIds.map(
    (t2sId) => recommendationBlocks[`homeTab${t2sId}`]
  ) as Recommendation[];

  const allRecommendedProducts = recommendations
    .map((recommendation) => recommendation?.recommendedProducts)
    .reduce((acc, val) => acc.concat(val), []); // use to flatten the array}

  const { hits: unorderedHits } = useProductsInfos(allRecommendedProducts);

  const hits: Product[][] = [[], [], []];

  unorderedHits.forEach((hit) => {
    recommendations.forEach((recommendation, i) => {
      recommendation.recommendedProducts.forEach((product) => {
        if (`${hit.productRef}-${hit.colorRef}` === product) {
          hits[i].push(hit);
        }
      });
    });
  });

  if (!hits.length || isRecommendationsNotLoaded) {
    return null;
  }

  return (
    <StyledRecommendationsTabsBannerContainer>
      <RecommendationsHeader
        id="home-page-tabs"
        title={title}
        hits={hits[selectedFilter]}
        containerRef={containerRef}
      />
      <div className="recommendations-tabs-buttons-wrapper">
        {filteredTabs.map((tab, index) => {
          return (
            <button
              key={tab.t2s_id}
              type="button"
              onClick={() => setSelectedFilter(index)}
              className={`recommendations-tabs-button ${
                selectedFilter === index ? 'button-selected' : ''
              }`}
              data-testid={`hp-tabs-${tab.name}-recommendations-button`}
            >
              <p
                className={`button-name ${selectedFilter === index ? 'button-name-selected' : ''}`}
              >
                {tab.name}
              </p>
            </button>
          );
        })}
      </div>
      <RecommendationsContent
        id="home-page-tabs"
        hits={hits[selectedFilter]}
        containerRef={containerRef}
        tracking={recommendations[selectedFilter].tracking}
      />
    </StyledRecommendationsTabsBannerContainer>
  );
};
