import styled from '@emotion/styled';

import { colors } from '../../systemprovider';

type Props = {
  preset?: 'portrait' | 'landscape' | 'rectangle' | 'custom';
  ratioHeight?: number;
  ratioWidth?: number;
  isLimited?: boolean;
  isDesktopFeedback?: boolean;
};

const ImageContainer = styled.div<Props>(
  {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    '::before': {
      display: 'block',
      content: '""',
      width: '100%',
    },
  },
  ({ preset = 'portrait', ratioHeight = 3, ratioWidth = 4 }) => ({
    '::before': {
      paddingTop: `${
        preset === 'landscape'
          ? 75
          : preset === 'rectangle'
          ? 100
          : preset === 'custom'
          ? Math.round((ratioHeight / ratioWidth) * 100)
          : 133
      }%`,
    },
  }),
  ({ isLimited }) =>
    !isLimited && {
      backgroundColor: colors.BACKGROUND,
    },
  ({ isDesktopFeedback }) =>
    isDesktopFeedback && {
      height: '191px',
      width: '143px',
    }
);

export default ImageContainer;
