import * as qs from 'qs';
import pathOr from 'ramda/src/pathOr';
import AlgoliaAnalytics from 'search-insights';
import { IndexUiState, UiState } from 'node_modules/instantsearch.js/cjs/types/ui-state';

import { indices } from './constants';
import { AnalyticsEventParams, AnalyticsEventTypes } from './types';
import paths from '../routing/paths';
import { AWS_IDENTITY_POOL_ID } from '../api/constants';
import { getCookie } from '../recommendations/utils';

const sortByIndices = [
  { name: indices.SORT_BY_NEWEST, searchOrder: 'nouveautes' },
  { name: indices.SORT_BY_PRICE_DESC, searchOrder: 'prix-decroissants' },
  { name: indices.SORT_BY_PRICE_ASC, searchOrder: 'prix-croissants' },
  { name: indices.BEST_SELLERS, searchOrder: 'meilleures-ventes' },
];

declare global {
  interface Window {
    ABTESTT2SORGANIZE: string;
  }
}

export const getIndexName = (pathName: string, search?: string): string => {
  if (isSelectionPage(pathName)) {
    if (!search) {
      return indices.MAIN;
    }
  }

  let t2sInUse = getCookie('ABTESTT2SORGANIZE') === '1';

  if (
    typeof process === 'undefined' && // to unbreak the server
    typeof window !== 'undefined' &&
    window.ABTESTT2SORGANIZE === 'VARIANT'
  ) {
    t2sInUse = true;
  }

  const acceptedRankOfT2s = 'rank1,rank2,rank3,rank4,rank5,rank6';
  const rank = getCookie('t2s-rank');
  if (rank === undefined || !acceptedRankOfT2s.includes(rank)) {
    t2sInUse = false;
  }

  if (!search && !t2sInUse) {
    return indices.MAIN;
  }

  if (!search) {
    return indices.T2S;
  }

  const targetSort = sortByIndices.find((index) => search.includes(index.searchOrder));

  if (!targetSort) {
    return indices.MAIN;
  }

  return targetSort.name;
};

const getIndexUrl = (name: string) =>
  sortByIndices.find((index) => index.name === name)?.searchOrder;

export const isCatalogPage = (pathname: string) => pathname.startsWith(paths.CATALOG);
export const isSelectionPage = (pathname: string) => pathname.startsWith(paths.SELECTION);
export const isSelectionOrCatalogPage = (pathname: string) =>
  isCatalogPage(pathname) || isSelectionPage(pathname);

export const hasAlgoliaInPage = (pathname: string) => {
  return (
    isCatalogPage(pathname) ||
    isSelectionPage(pathname) ||
    pathname.startsWith(paths.SEARCH_RESULTS)
  );
};

export const getCategoryName = (slug: string) => slug.replace(/\+/g, ' ');

export const getCategories = (pathname: string) => {
  return pathname.split('/').filter(Boolean).slice(1);
};

export const createHierarchicalMenu = (pathname: string) => {
  if (!isCatalogPage(pathname)) {
    return undefined;
  }
  const categories = getCategories(pathname);

  return categories.length
    ? `${categories.reduce(
        (accumulator, lvl, index) =>
          `${accumulator}${index !== 0 ? ' > ' : ''}${getCategoryName(lvl)}`,
        ''
      )}`
    : undefined;
};

export const createURL = (uiState: UiState, indice: string) => {
  const state = uiState[indice];
  const page = pathOr(1, ['page'], state);
  const priceRange = pathOr('', ['range', 'storePrice'], state);
  const refinementList = pathOr({}, ['refinementList'], state);
  const sortBy = pathOr(indices.MAIN, ['sortBy'], state);
  const colorLabel = pathOr([], ['colorLabel'], refinementList);
  const size = pathOr([], ['sizeVariants.sizeFilter'], refinementList);
  const typology = pathOr([], ['typology'], refinementList);
  const promotionPercentage = pathOr([], ['promotionPercentage'], refinementList);
  const collectionName = pathOr([], ['collectionName'], refinementList);
  const maintien = pathOr([], ['maintien'], refinementList);
  const layer = pathOr([], ['layer'], refinementList);
  const wire = pathOr([], ['wire'], refinementList);
  const storesAvailable = pathOr([], ['storesAvailable'], refinementList);
  const [minPrix, maxPrix] = priceRange.split(':');
  const query = pathOr(undefined, ['query'], state);

  const queryParameters = {
    page: page > 1 ? page : undefined,
    remise:
      promotionPercentage.length > 0 ? promotionPercentage.map(encodeURIComponent) : undefined,
    taille: size.length > 0 ? size.map(encodeURIComponent) : undefined,
    couleur: colorLabel.length > 0 ? colorLabel.map(encodeURIComponent) : undefined,
    forme: typology.length > 0 ? typology.map(encodeURIComponent) : undefined,
    collection: collectionName.length > 0 ? collectionName.map(encodeURIComponent) : undefined,
    maintien: maintien.length > 0 ? maintien.map(encodeURIComponent) : undefined,
    matiere: layer.length > 0 ? layer.map(encodeURIComponent) : undefined,
    armature: wire.length > 0 ? wire.map(encodeURIComponent) : undefined,
    boutique: storesAvailable.length > 0 ? storesAvailable.map(encodeURIComponent) : undefined,
    minPrix: minPrix ? minPrix : undefined,
    maxPrix: maxPrix ? maxPrix : undefined,
    trierPar: sortBy === indices.MAIN ? undefined : getIndexUrl(sortBy),
    r: query,
  };

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return queryString;
};

const isRefinementList = (key: string) => {
  return (
    key === 'couleur' ||
    key === 'taille' ||
    key === 'forme' ||
    key === 'remise' ||
    key === 'collection' ||
    key === 'maintien' ||
    key === 'matiere' ||
    key === 'boutique' ||
    key === 'armature'
  );
};

export const urlToSearchState = (pathname: string, search: string): IndexUiState => {
  const queryState = qs.parse(search.slice(1));

  const {
    couleur,
    taille,
    forme,
    remise,
    collection,
    maintien,
    matiere,
    armature,
    boutique,
    minPrix,
    maxPrix,
    r: query,
  } = queryState;
  const selection = pathname.includes('selection')
    ? pathname.split('/').filter(Boolean).slice(1)
    : undefined;
  const hierarchicalMenu = createHierarchicalMenu(pathname);

  const initialUiState = {
    page: 1,
    hierarchicalMenu: hierarchicalMenu
      ? {
          'categories.lvl0': [hierarchicalMenu],
        }
      : undefined,
    range:
      minPrix || maxPrix
        ? {
            storePrice: `${minPrix ?? ''}:${maxPrix ?? ''}`,
          }
        : undefined,
    configure: {
      clickAnalytics: true,
      attributesToRetrieve: ['*', '-storesAvailable'],
      ruleContexts: selection ? selection : undefined,
    },
    query,
  };

  for (const key of Object.keys(queryState)) {
    if (isRefinementList(key) && !initialUiState['refinementList']) {
      initialUiState['refinementList'] = {};
    }
    if (key === 'trierPar') {
      initialUiState['sortBy'] = getIndexName(pathname, search);
    }
    if (key === 'page') {
      initialUiState['page'] = Number(queryState[key]);
    }
    if (key === 'couleur') {
      initialUiState['refinementList']['colorLabel'] = (
        Array.isArray(couleur) ? couleur : [couleur].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'taille') {
      initialUiState['refinementList']['sizeVariants.sizeFilter'] = (
        Array.isArray(taille) ? taille : [taille].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'forme') {
      initialUiState['refinementList']['typology'] = (
        Array.isArray(forme) ? forme : [forme].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'remise') {
      initialUiState['refinementList']['promotionPercentage'] = (
        Array.isArray(remise) ? remise : [remise].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'collection') {
      initialUiState['refinementList']['collectionName'] = (
        Array.isArray(collection) ? collection : [collection].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'maintien') {
      initialUiState['refinementList']['maintien'] = (
        Array.isArray(maintien) ? maintien : [maintien].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'armature') {
      initialUiState['refinementList']['wire'] = (
        Array.isArray(armature) ? armature : [armature].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'matiere') {
      initialUiState['refinementList']['layer'] = (
        Array.isArray(matiere) ? matiere : [matiere].filter(Boolean)
      ).map(decodeURIComponent);
    }
    if (key === 'boutique') {
      initialUiState['refinementList']['storesAvailable'] = (
        Array.isArray(boutique) ? boutique : [boutique].filter(Boolean)
      ).map(decodeURIComponent);
    }
  }

  return initialUiState;
};

export const analyticsEvent = ({
  index,
  eventName,
  queryID,
  objectID,
  position,
  type,
}: AnalyticsEventParams) => {
  const params = {
    objectIDs: [objectID],
    queryID,
    eventName,
    index,
  };
  try {
    if (type === AnalyticsEventTypes.convert) {
      AlgoliaAnalytics('convertedObjectIDsAfterSearch', params);
    } else {
      AlgoliaAnalytics('clickedObjectIDsAfterSearch', { ...params, positions: [position ?? 0] });
    }
  } catch {
    //
  }
};
export function getUserTokenForAlgoliaAnalytics() {
  const identityId = localStorage.getItem(`CognitoIdentityId-${AWS_IDENTITY_POOL_ID}`);
  return identityId?.split(':')?.[1] || 'unknown';
}

function debouncePromise(fn: (...args: any[]) => void, time: number) {
  let timerId: NodeJS.Timeout | null = null;

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}

export const debounced = debouncePromise((items) => Promise.resolve(items), 300);
