import styled from '@emotion/styled';

import { colors } from '../systemprovider';

export const StyledAccordion = styled.div`
  max-width: calc(100vw - 16px);
`;

type StyledHRProps = {
  isPLPFilter?: boolean;
};
export const StyledLine = styled.hr<StyledHRProps>`
  margin: ${(props) => (props.isPLPFilter ? '8px 0' : '0')};
  border: none;
  height: 0.5px;
  width: 100%;
  background-color: ${colors.LIGHT};
`;

type StyledHeaderProps = {
  isTitleCentered?: boolean;
};
export const StyledHeader = styled.div<StyledHeaderProps>`
  display: flex;
  justify-content: ${(props) => (props.isTitleCentered ? 'center' : 'space-between')};
  cursor: pointer;
  align-items: center;
  padding: 16px 0;
`;

export const StyledTitle = styled.span`
  color: ${colors.BLACK};
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  margin-right: 8px;
`;

type StyledIconProps = {
  isOpen: boolean;
};

export const StyledIcon = styled.span<StyledIconProps>`
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.25s ease-in-out;
`;

type StyledContentProps = {
  isOpen: boolean;
};

export const StyledContent = styled.div<StyledContentProps>`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '100%' : '0')};
`;
