import { useHierarchicalMenu } from 'react-instantsearch';

export const HIERARCHICAL_ITEMS = [
  'categories.lvl0',
  'categories.lvl1',
  'categories.lvl2',
  'categories.lvl3',
];

export const CustomHierarchicalMenu: React.FC = () => {
  useHierarchicalMenu({
    attributes: HIERARCHICAL_ITEMS,
  });
  return null;
};
