import * as React from 'react';
import { connect } from 'react-redux';

import { openSidebar, closeSidebar, OpenSidebarParams, changeTitle } from '../actions';
import { RootState } from '../../../store/rootReducer';

export type WithSidebarActions = {
  closeSidebar: () => void;
  openSidebar: (options: OpenSidebarParams) => void;
  changeTitle?: (title: string) => void;
};

function withSidebar(WrappedComponent) {
  const Sidebar = (props) => <WrappedComponent {...props} />;

  Sidebar.displayName = `withSidebar(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ ...state.sidebar }), {
    openSidebar,
    closeSidebar,
    changeTitle,
  })(Sidebar);
}

export default withSidebar;
