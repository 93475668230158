import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import types from './actionTypes';
import { getCmsData } from '../api';
import { CmsCustomTypes } from './types';

export const setCmsContent = (payload) => action(types.SET_CONTENT, payload);

export const setMasterList = (payload: string[]) => action(types.SET_MASTER_LIST, payload);
export const setIsLoading = (payload: boolean) => action(types.SET_IS_LOADING, payload);
export const setUid = (payload: string) => action(types.SET_UID, payload);

export type CmsActions = ActionType<
  typeof setCmsContent | typeof setMasterList | typeof setIsLoading | typeof setUid
>;

export function loadCmsContent(
  type: CmsCustomTypes,
  options?: {
    uid?: string;
    isControlled?: boolean;
    fallbackValue?: any;
  }
) {
  return async (dispatch: Dispatch<CmsActions>) => {
    if (options?.isControlled) {
      dispatch(setIsLoading(true));
    }

    const data = await getCmsData(type, options?.uid);
    if (data || options?.fallbackValue) {
      dispatch(
        setCmsContent({
          type: CmsCustomTypes[type],
          content: data || options?.fallbackValue,
        })
      );
    }

    if (options?.isControlled) {
      dispatch(setIsLoading(false));
      if (options.uid) {
        dispatch(setUid(options.uid));
      }
    }
  };
}

export function loadMasterList() {
  return async (dispatch: Dispatch<ActionType<typeof setMasterList>>) => {
    const data = await getCmsData('catalog', 'master-list');
    if (data && data.uids && Array.isArray(data.uids)) {
      dispatch(setMasterList(data.uids));
    }
  };
}

function loadBulkCmsContent(types: CmsCustomTypes[]) {
  return async (dispatch) => {
    await Promise.all(types.map((type) => dispatch(loadCmsContent(type))));
  };
}

export function cmsInit() {
  return loadBulkCmsContent([
    CmsCustomTypes.banner,
    CmsCustomTypes.menu,
    CmsCustomTypes.search,
    CmsCustomTypes.footer,
    CmsCustomTypes.promotion,
    CmsCustomTypes.delivery,
    CmsCustomTypes.cart,
    CmsCustomTypes.ecart,
  ]);
}

export function cmsHomepageInit() {
  return loadBulkCmsContent([CmsCustomTypes.homepage, CmsCustomTypes.usp]);
}

export function cmsProductInit() {
  return loadBulkCmsContent([CmsCustomTypes.product, CmsCustomTypes.usp]);
}

export function cmsCatalogInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.catalog, { uid, isControlled: true });
}

export function cmsGenericInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.generic, { uid });
}

export function cmsBrandInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.grid, { uid, fallbackValue: { notFound: true } });
}

export function cmsOffersInit() {
  return loadCmsContent(CmsCustomTypes.offers);
}

export function cmsAffiliateInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.affiliation, { uid });
}

export function cmsUspInit() {
  return loadCmsContent(CmsCustomTypes.usp);
}

export function cmsTopTenInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.bestsellers, { uid, isControlled: true });
}

export function cmsOurBrandInit(uid: string) {
  return loadCmsContent(CmsCustomTypes.ourbrand, { uid, isControlled: true });
}
