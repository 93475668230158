import * as React from 'react';

import { StyledSelect, StyledDiv } from './selector.style';
import Text from '../text';
import Icon from '../icon';
import { Handles } from '../field/Field';
import { durations } from '../systemprovider';

type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = {
  id: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  placeholder?: string;
  note?: string;
  errMsg?: string;
  hasError?: boolean;
  mini?: boolean;
  isEresaModal?: boolean;
};

const Selector: React.ForwardRefRenderFunction<Handles, Props> = (
  {
    id,
    options = [],
    value,
    placeholder,
    note,
    errMsg,
    hasError,
    mini,
    isEresaModal,
    onChange,
  }: Props,
  ref
) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setTimeout(
        () =>
          divRef.current
            ? divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            : undefined,
        durations.FOCUS_DELAY
      );
    },
  }));

  return (
    <div ref={divRef}>
      <StyledSelect
        id={id}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value)}
        hasError={hasError || Boolean(errMsg)}
        value={value}
        mini={mini}
        isEresaModal={isEresaModal}
      >
        {(placeholder
          ? [{ value: '', label: placeholder, disabled: true }, ...options]
          : options
        ).map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {!mini && !isEresaModal && (errMsg || note) && (
        <StyledDiv>
          {errMsg ? (
            <Text preset="caption" color="ERROR" id={`error-message-for-${id}`}>
              {errMsg}
            </Text>
          ) : (
            note && (
              <div className="additional-note__container">
                <Icon id={`info-icon-for-${id}`} name="infoCircle" size={15} />
                <Text preset="caption" color="GREY" id={`note-for-${id}`}>
                  {note}
                </Text>
              </div>
            )
          )}
        </StyledDiv>
      )}
    </div>
  );
};

export default React.forwardRef(Selector);
