import * as React from 'react';
import { Icon, breakpoints } from '../../../design-system';
import styled from '@emotion/styled';
import { heights, widths } from '../../common/constants';

type TVisual = {
  image: string;
  title: string;
  description?: string;
  stepIndicator?: string | number;
};

export type TSizeGuideVisual = {
  arrayOfVisual?: Array<TVisual>;
  id: string;
  isPopup?: boolean;
};

const SizeGuideVisualsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  padding: 0 16px 16px 16px;
  margin-bottom: 16px;
  gap: 16px;

  @media (min-width: ${breakpoints.S - 1}px) {
    padding: 0;
    grid-auto-flow: initial;
    overflow-x: unset;
    margin: 0;
    position: sticky;
    top: ${heights.HEADER + 24 + 'px'};
    &.size-guide-visual-list--is-single {
      grid-template-columns: ${widths.VISUAL_SIZE_GUIDE_ITEM}px;
    }
    &.size-guide-visual-list--is-multiple {
      grid-template-columns: repeat(1, minmax(auto, ${widths.VISUAL_SIZE_GUIDE_ITEM}px));
    }
  }
  @media (min-width: ${breakpoints.M - 1}px) {
    &.size-guide-visual-list--is-multiple {
      grid-template-columns: repeat(2, minmax(auto, ${widths.VISUAL_SIZE_GUIDE_ITEM}px));
    }
  }

  &.size-guide-visual-list--is-single {
    .size-guide-visual-list__item {
      width: 100%;
    }
  }
  &.size-guide-visual-list--is-multiple {
    .size-guide-visual-list__item {
      width: 67vw;
    }
  }
  @media (min-width: ${breakpoints.S - 1}px) {
    &.size-guide-visual-list--is-multiple,
    &.size-guide-visual-list--is-single {
      .size-guide-visual-list__item {
        width: unset;
      }
    }
  }
  .size-guide-visual-list__item {
    display: inline-block;
    position: relative;
  }
  .size-guide-visual-list__step-indicator {
    position: absolute;
    left: 16px;
    top: 16px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .size-guide-visual-list__image {
    width: 100%;
  }
  .size-guide-visual-list__description-wrapper {
    width: 0;
    min-width: 100%;
  }
  .size-guide-visual-list__title {
    font-size: 14px;
    line-height: 150%;
    font-weight: 700;
    margin: 16px 0 4px;
  }
  .size-guide-visual-list__subtitle {
    font-size: 12px;
    line-height: 110%;
  }
`;

const SizeGuideVisual = ({ arrayOfVisual, id }: TSizeGuideVisual) => {
  if (!arrayOfVisual?.length) {
    return null;
  }

  return (
    <SizeGuideVisualsWrapper
      className={`size-guide-visual-list ${
        arrayOfVisual.length === 1
          ? 'size-guide-visual-list--is-single'
          : 'size-guide-visual-list--is-multiple'
      }`}
    >
      {arrayOfVisual.map((cell, index) => {
        return (
          <div className="size-guide-visual-list__item" key={`${id}-content-${index}`}>
            <div className="size-guide-visual-list__image-wrapper">
              {cell.stepIndicator && (
                <span className="size-guide-visual-list__step-indicator">
                  {typeof cell.stepIndicator === 'string' ? (
                    <Icon name={cell.stepIndicator} size={24} />
                  ) : (
                    cell.stepIndicator
                  )}
                </span>
              )}
              <img className="size-guide-visual-list__image" src={cell.image} alt="" />
            </div>
            <div className="size-guide-visual-list__description-wrapper">
              <p className="size-guide-visual-list__title">{cell.title}</p>
              {cell.description && (
                <span className="size-guide-visual-list__subtitle">{cell.description}</span>
              )}
            </div>
          </div>
        );
      })}
    </SizeGuideVisualsWrapper>
  );
};

export { SizeGuideVisual };
