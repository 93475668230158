import * as React from 'react';
import styled from '@emotion/styled';

import { Box } from '../../../design-system';
import { ColorText } from '../../common/components/Custom';

type Props = {
  value: string;
  label: string;
  textColor?: string;
  preset:
    | 'bannerLarge'
    | 'bannerSmall'
    | 'heading'
    | 'subheading'
    | 'promoBanner'
    | 'promoBannerMobile'
    | 'subheadingPromoBanner';
};

const TimerItem = ({ value, label, textColor, preset }: Props) => (
  <Box
    display="grid"
    alignItems="center"
    justifyContent="center"
    gridGap={preset === 'subheadingPromoBanner' ? '4px' : '8px'}
  >
    <StyledText cmsColor={textColor} preset={preset} fontWeight="bold" suppressHydrationWarning>
      {value}
    </StyledText>
    <StyledText
      cmsColor={textColor}
      preset={
        preset === 'bannerLarge'
          ? 'body'
          : preset === 'promoBanner'
          ? 'subheadingPromoBanner'
          : 'caption'
      }
    >
      {label}
    </StyledText>
  </Box>
);

const StyledText = styled(ColorText)(
  ({ preset }) =>
    preset === 'subheading' && {
      lineHeight: '1rem',
    }
);

export default TimerItem;
