export const SEARCH_PLACEHOLDER = "Insérer l'emplacement";
export const MSG_NO_PICKUP_STATION_FOUND =
  'Désolé, nous n’avons pas trouvé de boutique correspondant à votre recherche.';
export const SEE_ON_THE_MAP = 'Voir sur la carte';
export const SELECT_PICKUP_STATION = 'Choisir';
export const SELECTED_PICKUP_STATION = 'Sélectionnée';
export const MODAL_TITLE = 'Trouver un point relais';
export const ERR_LOCATION = 'Geolocation is not supported';
export const ERR_SELECT_PICKUP_STATION = 'Sélectionnez un point relais';
export const PH_PICKUP_PHONE_PLACEHOLDER = 'Téléphone*';
