import types from './actionTypes';
import { Devices } from './types';

export function sidebar(
  state = {
    isOpen: false,
    content: null,
    title: null,
  },
  action
) {
  switch (action.type) {
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        isOpen: true,
        title: action.title,
        content: action.content,
      };
    case types.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };
    case types.CHANGE_TITLE:
      return {
        ...state,
        title: action.title,
      };
    default:
      return state;
  }
}
export function sidebarNew(
  state = {
    isOpen: false,
    content: null,
    title: null,
    direction: 'right',
  },
  action
) {
  switch (action.type) {
    case types.OPEN_SIDEBAR_NEW:
      return {
        ...state,
        isOpen: true,
        title: action.title,
        content: action.content,
        direction: action.direction,
      };
    case types.CLOSE_SIDEBAR_NEW:
      return {
        ...state,
        isOpen: false,
      };
    case types.CHANGE_TITLE_NEW:
      return {
        ...state,
        title: action.title,
      };
    default:
      return state;
  }
}

export function modal(
  state = {
    isOpen: false,
    content: null,
    background: true,
    title: null,
    preset: 'm',
    onBackgroundClick: null,
  },
  action
) {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        content: action.content,
        background: action.background,
        title: action.title,
        preset: action.preset,
        onBackgroundClick: action.onBackgroundClick,
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: null,
        title: null,
        preset: 'm',
      };
    default:
      return state;
  }
}
export function modalNew(
  state = {
    isOpen: false,
    content: null,
    preset: 'medium',
  },
  action
) {
  switch (action.type) {
    case types.OPEN_MODAL_NEW:
      return {
        ...state,
        isOpen: true,
        content: action.content,
        preset: action.preset,
      };
    case types.CLOSE_MODAL_NEW:
      return {
        ...state,
        isOpen: false,
        content: null,
        preset: 'medium',
      };
    default:
      return state;
  }
}

export function popup(
  state = {
    isOpen: false,
    content: null,
    title: null,
  },
  action
) {
  switch (action.type) {
    case types.OPEN_POPUP:
      return {
        ...state,
        isOpen: true,
        content: action.content,
        title: action.title,
      };
    case types.CLOSE_POPUP:
      return {
        ...state,
        isOpen: false,
        content: null,
        title: null,
      };
    default:
      return state;
  }
}

export function common(
  state = {
    device: Devices.desktop,
    isOutdated: false,
  },
  action
) {
  switch (action.type) {
    case types.UPDATE_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case types.SET_IS_OUTDATED:
      return {
        ...state,
        isOutdated: action.payload,
      };
    default:
      return state;
  }
}
