import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useScrollToHash = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const listOfHashToRemain = ['#reviews', '#prefill'];
  const hashPattern = /^top-ten-block-\d*$/;

  useEffect(() => {
    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView();
      }
      if (!listOfHashToRemain.includes(hash) && !hashPattern.test(elementId)) {
        navigate(pathname, { replace: true });
      }
    }
  }, [hash]);
};
