import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import types from './actionTypes';
import { Bounds, StoreList, StoreService } from './types';
import { getBFFData, getStoresData, Queries } from '../api';
import { BASE_LOC, searchStores } from './utils';

export const requestStoresByLocation = () => action(types.REQUEST_STORES_BY_LOCATION, null);
export const requestStoresByLocationSuccess = (payload: StoreList) =>
  action(types.REQUEST_STORES_BY_LOCATION_SUCCESS, payload);
export const requestStoresByLocationFailure = (payload: string) =>
  action(types.REQUEST_STORES_BY_LOCATION_FAILURE, payload);

export const requestAllStores = () => action(types.REQUEST_ALL_STORES, null);
export const requestAllStoresSuccess = (payload: StoreList) =>
  action(types.REQUEST_ALL_STORES_SUCCESS, payload);
export const requestAllStoresFailure = (payload: string) =>
  action(types.REQUEST_ALL_STORES_FAILURE, payload);

export type StoresActions = ActionType<
  | typeof requestStoresByLocation
  | typeof requestStoresByLocationSuccess
  | typeof requestStoresByLocationFailure
  | typeof requestAllStores
  | typeof requestAllStoresSuccess
  | typeof requestAllStoresFailure
>;

const DEFAULT_GEOCOORDINATE = { latitude: BASE_LOC.lat, longitude: BASE_LOC.lng };

export function getAllStores({
  offlineMode,
  isClickAndCollect,
  isReservation,
}: {
  offlineMode?: boolean;
  isClickAndCollect?: boolean;
  isReservation?: boolean;
}) {
  return async (dispatch: Dispatch<StoresActions>, getState) => {
    dispatch(requestAllStores());
    const geoCoordinate = DEFAULT_GEOCOORDINATE;
    const { allStores } = getState().stores;
    let response: StoreList;

    if (allStores.length && offlineMode) {
      response = {
        stores: [...allStores],
        geoCoordinate,
        totalCount: allStores.length,
      };
    } else {
      response = await getStoresData();
    }

    if (response) {
      if (isClickAndCollect) {
        response.stores = response.stores.filter((store) =>
          store?.services.includes(StoreService.clickCollect)
        );
      } else if (isReservation) {
        response.stores = response.stores.filter((store) =>
          store?.services.includes(StoreService.eReservation)
        );
      }
      dispatch(
        requestAllStoresSuccess({
          ...response,
          geoCoordinate,
        })
      );
    } else {
      dispatch(requestAllStoresFailure('Error loading stores from CDN'));
    }
  };
}

export function getStoresByLocation({
  params,
  isClickAndCollect,
  isReservation,
}: {
  params: {
    bounds?: Bounds;
    lat?: number;
    lng?: number;
    address: string;
  };
  isClickAndCollect?: boolean;
  isReservation?: boolean;
}) {
  return async (dispatch: Dispatch<StoresActions>, getState) => {
    dispatch(requestStoresByLocation());
    const response = params.address
      ? getBFFData(Queries.getStoresByText, { text: params.address })
      : getBFFData(Queries.getStoresByPosition, {
          lat: params.lat,
          lng: params.lng,
          bounds: params.bounds,
        });
    const { ok, data } = await response;

    if (ok) {
      const { allStores } = getState().stores;
      const responseData = data[params.address ? 'storeSearchText' : 'storeSearchPosition'];
      const storesId = responseData.stores.map((item) => item.id);
      const geoCoordinate = responseData?.geoCoordinate;
      const response = searchStores({
        allStores,
        storesId,
        geoCoordinate,
        isClickAndCollect,
        isReservation,
      });
      dispatch(requestStoresByLocationSuccess(response));
    } else {
      dispatch(requestStoresByLocationFailure('Error getting stores list'));
    }
  };
}
