import * as React from 'react';

import { CmsHomepageBanner } from '../../../cms/types';
import { SliderBanner } from './SliderBanner/SliderBanner';
import { HomeBanner } from './HomeBanner';
import { Box } from '../../../../design-system';
import { useMediaQueries } from '../../../common/hooks/useMediaQuery';

type Props = {
  banner?: {
    type: 'slider' | 'full' | 'half';
    banners: CmsHomepageBanner[];
  };
  index: number;
};

export const HomeBanners = ({ banner, index }: Props) => {
  const { isDesktop } = useMediaQueries();

  if (!banner) {
    return null;
  }

  return (
    <Box>
      {banner.type === 'slider' ? (
        <SliderBanner banners={banner?.banners ?? []} index={`${index}`} />
      ) : banner.type === 'full' ? (
        <HomeBanner banner={banner?.banners?.[0]} index={`${index}`} />
      ) : banner.type === 'half' ? (
        <Box
          display="grid"
          gridGap={isDesktop ? 'm' : 'na'}
          gridAutoFlow={isDesktop ? 'column' : 'row'}
          gridTemplateColumns={isDesktop ? '1fr 1fr' : '1fr'}
        >
          <HomeBanner banner={banner?.banners?.[0]} index={`${index}`} isHalf />
          <HomeBanner banner={banner?.banners?.[1]} index={`${index}`} isHalf />
        </Box>
      ) : null}
    </Box>
  );
};

export default HomeBanners;
