import * as React from 'react';
import { connect } from 'react-redux';

import { openSidebarNew, closeSidebarNew, OpenSidebarNewParams, changeTitleNew } from '../actions';
import { RootState } from '../../../store/rootReducer';

export type WithSidebarNewActions = {
  closeSidebarNew: () => void;
  openSidebarNew: (options: OpenSidebarNewParams) => void;
  changeTitleNew?: (title: string) => void;
};

function withSidebarNew(WrappedComponent) {
  const SidebarNew = (props) => <WrappedComponent {...props} />;

  SidebarNew.displayName = `withSidebarNew(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return connect((state: RootState) => ({ ...state.sidebarNew }), {
    openSidebarNew,
    closeSidebarNew,
    changeTitleNew,
  })(SidebarNew);
}

export default withSidebarNew;
