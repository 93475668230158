import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { PickupStation } from './types';
import { getBFFData, Queries } from '../api';
import types from './actionTypes';
import { isFrenchZipCode, removeDiacritics } from '../store-locator/utils';
import { RootState } from '../../store/rootReducer';

export const requestToken = () => action(types.REQUEST_TOKEN, null);
export const requestTokenSuccess = (payload: string) =>
  action(types.REQUEST_TOKEN_SUCCESS, payload);
export const requestTokenFailure = (payload: string) =>
  action(types.REQUEST_TOKEN_FAILURE, payload);

export const requestPickupStations = () => action(types.REQUEST_PICKUP_STATIONS, null);
export const requestPickupStationsSuccess = (payload: PickupStation[]) =>
  action(types.REQUEST_PICKUP_STATIONS_SUCCESS, payload);
export const requestPickupStationsFailure = (payload: string) =>
  action(types.REQUEST_PICKUP_STATIONS_FAILURE, payload);

export const resetToken = () => action(types.RESET_TOKEN);

export const setGeoLocationPosition = (payload: { latitude: number; longitude: number }) =>
  action(types.SET_GEO_LOCATION_POSITION, payload);

export type PickupActions = ActionType<
  | typeof resetToken
  | typeof requestToken
  | typeof requestTokenSuccess
  | typeof requestTokenFailure
  | typeof requestPickupStations
  | typeof requestPickupStationsSuccess
  | typeof requestPickupStationsFailure
  | typeof setGeoLocationPosition
>;

export function getToken() {
  return async (dispatch: Dispatch<PickupActions>) => {
    dispatch(requestToken());
    const { ok, data } = await getBFFData(Queries.getColissimoToken, {});
    if (ok && data?.colissimoToken?.success) {
      dispatch(requestTokenSuccess(data.colissimoToken.message));
    } else {
      dispatch(requestTokenFailure('Error getting token'));
    }
  };
}

export function getPickUpStations(
  isSearchByText: boolean,
  textToSearch = '',
  position?: { lat: number; lng: number }
) {
  return async (dispatch: Dispatch<PickupActions>, getState: () => RootState) => {
    dispatch(requestPickupStations());
    const { token } = getState().pickup;
    const searchText = isFrenchZipCode(textToSearch)
      ? `${textToSearch} France`
      : removeDiacritics(textToSearch);
    const response = await getBFFData(Queries.getPickupStations, {
      token,
      isSearchByText,
      searchText,
      position,
    });

    if (response.ok) {
      const { pickupStations } = response.data;
      if (isSearchByText) {
        pickupStations?.length > 0 &&
          dispatch(setGeoLocationPosition(pickupStations[0].displayCoordinate));
      }
      dispatch(requestPickupStationsSuccess(pickupStations));
    } else {
      dispatch(resetToken());
      dispatch(requestPickupStationsFailure('Error loading pickup stations'));
    }
  };
}
