import * as React from 'react';

import { colors, opacities } from '../systemprovider';
import { Handles } from '../field/Field';
import { durations } from '../systemprovider/constants';
import styled from '@emotion/styled';

type Props = InputProps & {
  id: string;
  onChange?: (id: string) => void;
  children?: React.ReactNode;
  errMsg?: string;
};

type InputProps = {
  isFilterCheckBox?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

const StyledCheckboxContainer = styled.div<InputProps>`
  opacity: ${(props) => props.disabled && opacities.DISABLED};
  height: inherit;
  .checkbox {
    display: flex;
    gap: 8px;
    position: relative;
    cursor: pointer;
    height: inherit;
    align-items: center;
  }
  .checkbox__input {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    margin: 0;
    appearance: none;
    border: 1px solid ${colors.BLACK};
    cursor: pointer;
    margin: 4px 4px 4px 0;
    background: ${colors.WHITE};
    border-radius: unset;
    &:checked {
      background: ${colors.BLACK};
    }
  }
  .checkbox--with-error {
    .checkbox__input {
      border: 1px solid ${colors.ERROR};
    }
  }
  .checkbox__icon {
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0.4rem;
    fill: ${colors.WHITE};
  }
  .checkbox__content {
    padding: 3px 0;
    width: 100%;
  }
  .checkbox-error__container {
    padding-top: 4px;
  }
  .check-error__description {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${colors.ERROR};
  }
`;
const Checkbox: React.ForwardRefRenderFunction<Handles, Props> = (
  { id, checked, children, onChange = () => null, errMsg, disabled, isFilterCheckBox }: Props,
  ref
) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setTimeout(
        () =>
          divRef.current
            ? divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            : undefined,
        durations.FOCUS_DELAY
      );
    },
  }));

  const handleChange = (event) => {
    if (event.target.type !== 'button' && !disabled) {
      onChange(id);
    }
  };

  return (
    <StyledCheckboxContainer
      id={id}
      ref={divRef}
      isFilterCheckBox={isFilterCheckBox}
      disabled={disabled}
    >
      <label className={`checkbox ${errMsg ? 'checkbox--with-error' : ''}`}>
        <input
          id={id}
          className="checkbox__input"
          type="checkbox"
          name={checked ? 'checkboxOn' : 'checkboxOff'}
          checked={checked ?? false}
          onChange={handleChange}
          data-testid={id}
        />
        {checked && (
          <svg width="9" height="7" viewBox="0 0 9 7" className="checkbox__icon">
            <path d="M8.71414 0.707102L3.30708 6.11423L0 2.80711L0.707111 2.10001L3.30708 4.70001L8.00703 0L8.71414 0.707102Z" />
          </svg>
        )}
        <div className="checkbox__content">{children}</div>
      </label>
      {errMsg && (
        <div className="checkbox-error__container">
          {errMsg && <p className="check-error__description">{errMsg}</p>}
        </div>
      )}
    </StyledCheckboxContainer>
  );
};

export default React.forwardRef(Checkbox);
