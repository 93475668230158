import * as React from 'react';

import { Box, Text } from '../../../design-system';
import { EXCEPTIONAL_CLOSINGS, EXCEPTIONAL_OPENINGS, REOPENING_DATE, WEEK_DAYS } from '../locale';
import { Range, StoreHours, StoreHolidayHoursDescription } from '../types';
import {
  formatDateToLocale,
  getExceptionalClosings,
  getExceptionalOpenings,
  mergeClosingDays,
  getOpeningHours,
  isSameDay,
  isWorkingHours,
  getFormattedOpeningBounds,
} from '../utils';

type Props = {
  hours: StoreHours;
  isShortenedVersion?: boolean;
  isBigSize?: boolean;
};

const OpeningHours = ({ hours, isShortenedVersion, isBigSize }: Props) => {
  const reopenDate = hours?.reopenDate;
  const holidayHours = hours?.holidayHours ?? [];

  const exceptionalOpenings: StoreHolidayHoursDescription[] = getExceptionalOpenings(holidayHours);
  const exceptionalClosings: Range[] = mergeClosingDays(getExceptionalClosings(holidayHours));

  if (isShortenedVersion) {
    const openingsToday = getOpeningHours(hours).filter((item) => isSameDay(item.day));
    const { openings, startTime, endTime } = getFormattedOpeningBounds(openingsToday[0]);
    let openingInfos;

    if (openingsToday.length) {
      openingInfos = isWorkingHours({ startTime, endTime });
    }

    return (
      <Box display="flex" alignItems="center">
        <Box
          width="s"
          height="s"
          borderRadius="50%"
          backgroundColor={
            openingInfos?.isOpenNow
              ? openingInfos?.isClosingSoon
                ? '#FFA500'
                : 'SUCCESS'
              : 'PROMO'
          }
          mr="xs"
        />
        <Text preset={isBigSize ? 'body' : 'caption'} color={isBigSize ? 'BLACK' : 'GREY'} py="xxs">
          {openingInfos?.isOpenNow
            ? openingInfos?.isClosingSoon
              ? 'Ferme bientôt'
              : `Ouvert ${openings}`
            : 'Fermé'}
        </Text>
      </Box>
    );
  }

  return (
    <>
      {getOpeningHours(hours).map((value, index) => {
        const { openings, startTime, endTime } = getFormattedOpeningBounds(value);
        return (
          <Box display="flex" alignItems="center" key={index}>
            <Box
              width="s"
              height="s"
              borderRadius="50%"
              backgroundColor={
                isSameDay(value?.day)
                  ? isWorkingHours({ startTime, endTime })?.isOpenNow
                    ? 'SUCCESS'
                    : 'PROMO'
                  : 'WHITE'
              }
              mr="s"
            />

            <Text
              key={index}
              color={isSameDay(value?.day) ? 'BLACK' : 'GREY'}
              textTransform="capitalize"
              py="xxs"
            >{`${WEEK_DAYS[value?.day ?? '']}:
           ${openings}`}</Text>
          </Box>
        );
      })}
      {reopenDate && (
        <Text color="GREY" my="s">
          {REOPENING_DATE}: {formatDateToLocale(reopenDate)}
        </Text>
      )}
      {exceptionalOpenings.length > 0 && (
        <Box ml="m">
          <Text my="xs" color="GREY">
            {EXCEPTIONAL_OPENINGS}:
          </Text>

          {exceptionalOpenings.map((item, index) => {
            return (
              <Text key={index} color="ERROR">
                {formatDateToLocale(item?.date ?? '')}: {item?.openIntervals?.[0]?.start} -{' '}
                {item?.openIntervals?.[0]?.end}
              </Text>
            );
          })}
        </Box>
      )}
      {exceptionalClosings.length > 0 && (
        <Box ml="m">
          <Text my="xs" color="GREY">
            {EXCEPTIONAL_CLOSINGS}:
          </Text>

          {exceptionalClosings.map((item, index) => {
            return (
              <Text key={index} color="ERROR">
                {formatDateToLocale(item?.from ?? '')}
                {item.to ? ` jusqu'au ${formatDateToLocale(item.to)}` : ''}
              </Text>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default OpeningHours;
