import * as React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '../../../design-system';
import { CmsCart } from '../../cms/types';
import { ColorText } from '../../common/components/Custom';
import {
  MSG_GIFT_BANNER_TITLE,
  MSG_GIFT_BANNER_BODY_MULTIPLE,
  MSG_GIFT_BANNER_BODY,
} from '../locale';
import { RootState } from '../../../store/rootReducer';
import { giftMessagesSelector, numberOfGiftItemsSelector } from '../selectors';

type Props = {
  cmsContent: CmsCart;
};

const GiftBlock = ({ cmsContent }: Props) => {
  const textColor = cmsContent?.gift_text_color ?? 'BLACK';
  const backgroundColor = cmsContent?.gift_background_color ?? 'BACKGROUND';
  const image = cmsContent?.gift_image;

  const giftMessages = useSelector((state: RootState) => giftMessagesSelector(state));
  const numberOfGiftItems = useSelector((state: RootState) => numberOfGiftItemsSelector(state));

  const message =
    numberOfGiftItems > 1
      ? MSG_GIFT_BANNER_BODY_MULTIPLE
      : giftMessages?.[0]
      ? giftMessages[0]
      : MSG_GIFT_BANNER_BODY;

  const imageRatio = (image?.dimensions?.height ?? 0) / (image?.dimensions?.width ?? 1);

  return (
    <Box
      p="m"
      mb="m"
      bg={backgroundColor}
      display="grid"
      alignItems="center"
      justifyContent="space-between"
      gridGap={['s', 'l', 'xl']}
      gridAutoFlow="column"
      id="box-cart-gift-banner"
    >
      <Box textAlign={['left', 'left', 'center']}>
        <ColorText preset="subheading" cmsColor={textColor} fontWeight="bold">
          {MSG_GIFT_BANNER_TITLE}
        </ColorText>

        <ColorText cmsColor={textColor} fontWeight="bold">
          {message}
        </ColorText>
      </Box>

      <Box
        background={`url(${image?.url})`}
        width={image?.dimensions?.width}
        height={image?.dimensions?.height}
        maxWidth="20vw"
        maxHeight={`calc(20vw * ${imageRatio})`}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
      />
    </Box>
  );
};

export default GiftBlock;
