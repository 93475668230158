import styled from '@emotion/styled';
import css from '@styled-system/css';

type Props = {
  my?: string;
  color?: string;
};

const Divider = styled.hr<Props>(
  css({
    border: 'none',
    height: '1px',
    width: '100%',
  }),
  ({ my = 'na' }) =>
    css({
      my,
    }),
  ({ color = 'LIGHT' }) =>
    css({
      backgroundColor: color,
    })
);

export default Divider;
