import React, { useState } from 'react';
import { Box, Field, MultilineField, DynamicButton } from '../../../design-system';
import { TGiftCardErrors, verifyGiftCardInfos } from '../../product/utils';
import { updateGiftCard } from '../actions';
import { CartItem as CartItemType } from '../types';
import { useDispatch } from 'react-redux';
import { MAX_LENGTH_GIFT_CARD_MESSAGE } from '../../product/locale';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type GiftCardFields = {
  itemId: string;
  price: number;
  from: string;
  to: string;
  email: string;
  date: string;
  message?: string;
};

type Props = {
  item: CartItemType;
  gcFields: GiftCardFields;
  onCloseEdit: () => void;
};

export default function CartGiftCardEdit({ gcFields, item, onCloseEdit }: Props) {
  const { isMobile } = useMediaQueries();
  const [senderName, setSenderName] = useState(gcFields.from);
  const [receiverName, setReceiverName] = useState(gcFields.to);
  const [receiverEmail, setReceiverEmail] = useState(gcFields.email);
  const [dateOfSending, setDateOfSending] = useState(gcFields.date);
  const [message, setMessage] = useState(gcFields.message ?? '');
  const [giftCardErrMsg, setGiftCardErrMsg] = useState<TGiftCardErrors>({});
  const [feedback, setFeedback] = useState<number>(0);
  const dispatch = useDispatch();

  const giftCardInfos = {
    senderName,
    receiverName,
    receiverEmail,
    dateOfSending,
    message,
  };

  const handleCtaClick = async () => {
    if (!gcFields) {
      return;
    }

    const errMsg = verifyGiftCardInfos(giftCardInfos);
    setGiftCardErrMsg(errMsg);

    if (Object.keys(errMsg).length === 0 && errMsg.constructor === Object) {
      const giftCard = {
        productRef: item.productRef,
        colorRef: item.colorRef,
        sku: item.sku,
        price: gcFields.price,
        from: giftCardInfos.senderName,
        to: giftCardInfos.receiverName,
        email: giftCardInfos.receiverEmail,
        date: giftCardInfos.dateOfSending,
        message: giftCardInfos.message,
      };
      setFeedback(3);
      const response = await dispatch(updateGiftCard(gcFields.itemId, giftCard));
      if (!response.ok) {
        setGiftCardErrMsg({ receiverEmail: response.data });
        setFeedback(0);
        return;
      }
      onCloseEdit();
    }
  };

  if (!isMobile) {
    return (
      <Box display="flex" flexDirection={'column'}>
        <Box padding="8px 8px" mb={['s', 'm']} width={['100%', '70%']}>
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gridColumnGap={'1rem'} mb={'xs'}>
            <Field
              id="sender"
              type="text"
              maxLength={60}
              label="De la part de*"
              value={senderName}
              onChange={(e) => setSenderName(e)}
              errMsg={giftCardErrMsg?.senderName ?? ''}
            />

            <Field
              id="receiver"
              type="text"
              maxLength={60}
              label="Pour*"
              value={receiverName}
              onChange={(e) => setReceiverName(e)}
              errMsg={giftCardErrMsg?.receiverName ?? ''}
            />
          </Box>
          <Box mb={'xs'}>
            <Field
              id="email"
              type="email"
              maxLength={80}
              label="Email du destinataire*"
              value={receiverEmail}
              onChange={(e) => setReceiverEmail(e)}
              errMsg={giftCardErrMsg?.receiverEmail ?? ''}
            />
          </Box>

          <Box mb={'xs'}>
            <Field
              id="date-of-sending"
              type="date"
              label="Date d'envoi*"
              value={dateOfSending}
              onChange={(e) => setDateOfSending(e)}
              errMsg={giftCardErrMsg?.date ?? ''}
            />
          </Box>

          <Box>
            <MultilineField
              id="message"
              maxLength={MAX_LENGTH_GIFT_CARD_MESSAGE}
              label="Écrire un message personnalisé"
              value={message}
              onChange={(e) => setMessage(e)}
              errMsg={''}
              areaHeight={'95px'}
              note={`${message.length}/${MAX_LENGTH_GIFT_CARD_MESSAGE} caractères maximum`}
            />
          </Box>
        </Box>
        <Box width={['100%', '70%']} mb={['s', 'm']}>
          <DynamicButton
            id={`btn-update-gift-card-${item.gcFields?.itemId}`}
            onClick={handleCtaClick}
            data={['Sauvegarder', '', '']}
            feedback={feedback}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      padding="8px 8px"
      mb={['s', 'm']}
      mt={window.location.pathname !== '/cart' ? ['s', 'm'] : 'na'}
      width={window.location.pathname === '/cart' ? ['100%', '423px'] : '100%'}
    >
      <Box padding="8px 8px" mb={['s', 'm']} width={['100%', '70%']}>
        <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gridColumnGap={'1rem'} mb={'xs'}>
          <Field
            id="sender"
            type="text"
            maxLength={60}
            label="De la part de*"
            value={senderName}
            onChange={(e) => setSenderName(e)}
            errMsg={giftCardErrMsg?.senderName ?? ''}
          />

          <Field
            id="receiver"
            type="text"
            maxLength={60}
            label="Pour*"
            value={receiverName}
            onChange={(e) => setReceiverName(e)}
            errMsg={giftCardErrMsg?.receiverName ?? ''}
          />
        </Box>
        <Box mb={'xs'}>
          <Field
            id="email"
            type="email"
            maxLength={80}
            label="Email du destinataire*"
            value={receiverEmail}
            onChange={(e) => setReceiverEmail(e)}
            errMsg={giftCardErrMsg?.receiverEmail ?? ''}
          />
        </Box>

        <Box mb={'xs'}>
          <Field
            id="date-of-sending"
            type="date"
            label="Date d'envoi*"
            value={dateOfSending}
            onChange={(e) => setDateOfSending(e)}
            errMsg={giftCardErrMsg?.date ?? ''}
          />
        </Box>

        <Box>
          <MultilineField
            id="message"
            maxLength={300}
            label="Écrire un message personnalisé"
            value={message}
            onChange={(e) => setMessage(e)}
            errMsg={''}
            areaHeight={'95px'}
          />
        </Box>
      </Box>
      <Box width={['100%', '70%']} mb={['s', 'm']}>
        <DynamicButton
          id={`btn-update-gift-card-${item.gcFields?.itemId}`}
          onClick={handleCtaClick}
          data={['Sauvegarder', '', '']}
          feedback={feedback}
        />
      </Box>
    </Box>
  );
}
