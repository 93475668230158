import * as React from 'react';

import { Box } from '../../../design-system';

type Props = {
  mapRef: React.RefObject<HTMLDivElement>;
};

export default function Map({ mapRef }: Props) {
  return <Box ref={mapRef} className="map-ref" width="100%" height="100%" />;
}
