import React from 'react';
import { css } from '@emotion/core';

import { Box, colors, Divider, Text } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';

export const GiftCardMiniDescription = () => {
  return (
    <Box>
      <Text fontSize="s" color={colors.GREY}>
        Notre carte cadeau est une carte virtuelle qui peut être utilisée aussi bien&nbsp;
        <ToolTip display={'inline'}>
          <span
            className="anchor-animated"
            id={'en-ligne'}
            css={css`
              font-size: 1.4rem;
              color: ${colors.GREY};
            `}
          >
            en ligne&nbsp;
          </span>
          <Box
            width={324}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            p={'1.6rem !important'}
          >
            <Text fontWeight={'700'}>En ligne</Text>
            <Divider my={'16px'} color={colors.BLACK} />
            <Text>
              Lors de l’étape du paiement, saisissez les 19 numéros inscrits sur votre carte cadeau
              dans le champ correspondant ainsi que votre code PIN.
            </Text>
          </Box>
        </ToolTip>
        &nbsp;qu&apos;
        <ToolTip display={'inline'}>
          <span
            className="anchor-animated"
            id={'en-boutique'}
            css={css`
              font-size: 1.4rem;
              color: ${colors.GREY};
            `}
          >
            &nbsp;en boutique
          </span>
          <Box
            width={324}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            p={'1.6rem !important'}
          >
            <Text fontWeight={'700'}>En boutique</Text>
            <Divider my={'16px'} color={colors.BLACK} />
            <Text>Imprimez-la et réglez avec directement en caisse.</Text>
          </Box>
        </ToolTip>
        .
      </Text>
    </Box>
  );
};
