import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { cmsProductInit } from '../../cms/actions';
import { setCrossSellLoading } from '../actions';
import { RecommendationsBlock } from '../../recommendations/components/RecommendationsBlock';

const StyledCrossSellContainer = styled.div`
  overflow: hidden;
  margin: 0 16px;
`;

export const CrossSell = () => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.crossSell
  );
  const { cross_sell_title: title } = useSelector((state: RootState) => state.cms.product);
  const { isFetching } = useSelector((state: RootState) => state.recommendations);
  useEffect(() => {
    if (!title) {
      dispatch(cmsProductInit() as unknown as UnknownAction);
    }
  }, []);

  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (
      (!isFetching &&
        recommendedProducts.length > 0 &&
        hits.length === recommendedProducts.length) ||
      (!isFetching && recommendedProducts.length === 0)
    ) {
      dispatch(setCrossSellLoading(false));
    }
    if (!recommendedProducts.length) {
      if (typeof window !== 'undefined') {
        const target = document.getElementById('modal-content');
        if (target) {
          target.classList.remove('modal--large-variant');
        }
      }
    }
  }, [recommendedProducts, hits, isFetching]);

  if (!recommendedProducts || isFetching) {
    return null;
  }

  return (
    <StyledCrossSellContainer>
      <RecommendationsBlock id="cross-sell" title={title} hits={hits} tracking={tracking} />
    </StyledCrossSellContainer>
  );
};
