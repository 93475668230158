import * as React from 'react';

import { Text, Box, ImageContainer, Image, Divider } from '../../../design-system';
import { getProductImageLink, productImageWidths } from '../../product/utils';
import { OrderItem as OrderItemType } from '../types';
import { formatPrice } from '../../common/utils';
import { LineThroughText } from '../../common/components/Custom';

type Props = {
  item: OrderItemType;
};

const OrderItem = ({
  item: {
    productRef,
    colorRef,
    productName,
    detailedColorLabel,
    size,
    quantity,
    rowBaseTotal,
    rowTotal,
    bandSize,
    cupSize,
  },
}: Props) => {
  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });
  return (
    <Box display="grid" gridTemplateColumns="100px auto 50px" gridColumnGap="m">
      <Box gridColumn="1 / 4">
        <Divider my="s" />
      </Box>
      <ImageContainer>
        <Image isAbsolute src={thumbnail} alt="Product Image" />
      </ImageContainer>
      <div>
        <Box my="xs">
          <Text>{productName}</Text>
        </Box>
        <Text>Couleur : {detailedColorLabel}</Text>
        <Text>Taille : {size ? size : bandSize ? `${bandSize} ${cupSize}` : ''}</Text>
        <Text>Quantité : {quantity}</Text>
      </div>
      <Box my="xs" justifySelf="center" alignSelf={['end', 'start']}>
        {rowBaseTotal !== rowTotal && (
          <LineThroughText>{formatPrice(rowBaseTotal)}</LineThroughText>
        )}
        <Text>{formatPrice(rowTotal)}</Text>
      </Box>
    </Box>
  );
};

export default OrderItem;
