import { State, StoreList } from './types';

export const emptyStoreList: StoreList = {
  geoCoordinate: { latitude: 0, longitude: 0 },
  stores: [],
  totalCount: 0,
};

export const StoreState: State = {
  storesByLocation: emptyStoreList,
  errMsg: '',
  isFetching: true,
  allStores: [],
};
