import * as React from 'react';

import CommonUI from '../wrappers/CommonUI';

function withCommonUI(WrappedComponent) {
  const WithCommonUI = (props) => (
    <CommonUI isActive hasFooter>
      <WrappedComponent {...props} />
    </CommonUI>
  );

  WithCommonUI.displayName = `WithCommonUI(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WithCommonUI;
}

export default withCommonUI;
