import { CmsActions } from './actions';
import cmsState from './state';
import types from './actionTypes';
import { State } from './types';

export default function cms(state = cmsState, action: CmsActions): State {
  switch (action.type) {
    case types.SET_CONTENT:
      return {
        ...state,
        [action.payload.type]: action.payload.content,
      };
    case types.SET_MASTER_LIST:
      return {
        ...state,
        masterList: action.payload,
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SET_UID:
      return {
        ...state,
        uid: action.payload,
      };
    default:
      return state;
  }
}
