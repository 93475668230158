import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Box, Icon } from '../../../design-system';
import { ProductUsp as ProductUspType } from '../../cms/types';
import ProductUspModal from './ProductUspModal';
import { openModal } from '../../common/actions';
import { ToolTip } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  item: ProductUspType;
};

export const ProductUspItem = ({ item }: Props) => {
  const dispatch = useDispatch();

  const { isDesktop } = useMediaQueries();

  const usp_icon = item?.usp_icon ?? '';
  const usp_title = item?.usp_title ?? '';

  const handleClick = () => {
    dispatch(
      openModal({
        content: <ProductUspModal item={item} />,
        title: ' ',
      })
    );
  };

  const fragments = {
    link: (
      <>
        <Icon name={usp_icon} />
        <span className="anchor-animated" id={`pdp-usp-${usp_title}`}>
          {usp_title}
        </span>
      </>
    ),
  };

  return isDesktop ? (
    <StyledToolTip
      display="grid"
      gridAutoFlow="column"
      gridGap="xs"
      alignItems="center"
      justifyContent="center"
      pb="m"
      px="l"
    >
      {fragments.link}
      <Box>
        <ProductUspModal item={item} />
      </Box>
    </StyledToolTip>
  ) : (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridGap="xs"
      alignItems="center"
      justifyContent="center"
      onClick={handleClick}
    >
      {fragments.link}
    </Box>
  );
};

export const StyledToolTip = styled(ToolTip)({
  ':hover': {
    '> div': {
      width: '324px',
      ':before': {
        bottom: 'initial',
        top: '-11px',
      },
      ':after': {
        bottom: 'initial',
        top: '-10px',
      },
    },
  },
  span: {
    fontSize: '1.4rem',
  },
});
