import * as React from 'react';
import ReactCursorPosition from 'react-cursor-position';
import styled from '@emotion/styled';

import { Picture, ImageContainer } from '../../image';
import { getIsTouch } from '../utils';
import { ProductImage } from '../../../modules/product/utils';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import './blur-effect.css';

type Props = {
  src: ProductImage;
  srcLarge: ProductImage;
  srcThumbnail: ProductImage;
  position?: {
    x: number;
    y: number;
  };
  isActive?: boolean;
  zoom?: number;
  isMozaicPictures?: boolean;
};

const DEFAULT_ZOOM_MULTIPLIER = 2.5;

const TouchHandler = ({ src, srcLarge, srcThumbnail, zoom, isMozaicPictures }: Props) => (
  <ReactCursorPosition>
    <ZoomImage
      src={src}
      srcLarge={srcLarge}
      srcThumbnail={srcThumbnail}
      zoom={zoom}
      isMozaicPictures={isMozaicPictures}
    />
  </ReactCursorPosition>
);

const ZoomImage = ({
  src,
  srcLarge,
  srcThumbnail,
  zoom = DEFAULT_ZOOM_MULTIPLIER,
  position = { x: 0, y: 0 },
  isActive,
  isMozaicPictures = false,
}: Props) => {
  const [isZooming, setIsZooming] = React.useState(false);

  React.useEffect(() => {
    if (!isActive) {
      setIsZooming(false);
    }
  }, [isActive]);

  const handleClick = () => {
    const isTouch = getIsTouch();
    if (!isTouch) {
      setIsZooming(!isZooming);
    }
  };

  const cursor = isZooming ? 'zoom-out' : isActive ? 'zoom-in' : 'pointer';

  return (
    <CursorImageContainer
      onClick={handleClick}
      cursor={cursor}
      isMozaicPictures={isMozaicPictures}
      data-testid="zoom-into-picture-button"
    >
      <Picture>
        <source srcSet={srcThumbnail.webp} data-srcset={src.webp} type="image/webp" />
        <source srcSet={srcThumbnail.jpg} data-srcset={src.jpg} type="image/jpeg" />
        <img src={srcThumbnail.jpg} data-src={src.jpg} alt={src.alt} className="lazyload blur-up" />
      </Picture>
      {isZooming && isActive && (
        <ZoomedPicture x={position.x} y={position.y} zoom={zoom}>
          <source srcSet={src.webp} data-srcset={srcLarge.webp} type="image/webp" />
          <source srcSet={src.jpg} data-srcset={srcLarge.jpg} type="image/jpeg" />
          <img
            src={src.jpg}
            data-src={srcLarge.jpg}
            alt={`zoomed ${srcLarge.alt}`}
            className="lazyload"
          />
        </ZoomedPicture>
      )}
    </CursorImageContainer>
  );
};

type CursorImageContainerProps = {
  cursor: string;
  isMozaicPictures?: boolean;
};

const CursorImageContainer = styled(ImageContainer)<CursorImageContainerProps>(
  ({ cursor, isMozaicPictures }) => ({
    cursor,
    ...(isMozaicPictures && {
      borderRadius: '10px',
    }),
  })
);

type ZoomedPictureProps = {
  x: number;
  y: number;
  zoom: number;
};

const ZoomedPicture = styled(Picture)<ZoomedPictureProps>(({ x, y, zoom }) => ({
  'source, img': {
    transform: `scale(${zoom}) translate(${-x * (1 - 1 / zoom)}px, ${-y * (1 - 1 / zoom)}px)`,
    transformOrigin: '0 0 0',
  },
}));

export default TouchHandler;
