import React from 'react';

import paths from './paths';
import PersonalDetailsContainer from './components/PersonalDetails';
import OrdersContainer from './components/Orders';
import OrderDetailContainer from './components/OrderDetail';
import AddressesContainer from './components/Addresses';
import OrderTracking from './components/OrderTracking';

export default [
  {
    component: <PersonalDetailsContainer />,
    exact: true,
    path: `${paths.ACCOUNT}${paths.INFO}`,
  },
  {
    component: <OrdersContainer />,
    exact: true,
    path: `${paths.ACCOUNT}${paths.ORDERS}`,
  },
  {
    component: <OrderDetailContainer />,
    exact: true,
    path: `${paths.ACCOUNT}${paths.ORDERS}/:uid`,
  },
  {
    component: <AddressesContainer />,
    exact: true,
    path: `${paths.ACCOUNT}${paths.ADDRESSES}`,
  },
  {
    component: <OrderTracking />,
    exact: true,
    path: `${paths.ACCOUNT}${paths.TRACKING}/:uid`,
  },
];
