import React from 'react';
import { useRange, useRefinementList } from 'react-instantsearch';
import styled from '@emotion/styled';

import { Accordion } from '../../../design-system';
import { FilterListType, FilterList } from './FilterList';

const StyledH3 = styled.h3`
  font-size: 1.6rem;
  line-height: 110%;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const FilterItem: React.FC<{ index: number; filter: FilterListType }> = ({ index, filter }) => {
  const { items } = useRefinementList({
    attribute: filter.attribute === 'storePrice' ? '_' : filter.attribute,
  });
  const { range } = useRange({ attribute: 'storePrice' });
  return (
    <div
      hidden={
        (filter.attribute === 'storePrice' && range.min === range.max) ||
        (!items.length && filter.attribute !== 'storePrice')
      }
    >
      <Accordion
        id={`desktop-filter-${index}`}
        focus={0}
        isPLPFilter
        items={[
          {
            title: <StyledH3>{filter.title}</StyledH3>,
            content: filter.component,
            index,
          },
        ]}
      />
    </div>
  );
};

export const Filters = () => {
  return (
    <div>
      {FilterList.map((filter, index) => (
        <div key={`desktop-filter-${filter.attribute}`}>
          <FilterItem filter={filter} index={index} />
        </div>
      ))}
    </div>
  );
};
