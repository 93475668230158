import { cdnBaseImageUrl } from '../common/constants';

export const sizingGuideBraVisual = [
  {
    image: `${cdnBaseImageUrl.production}3424ba6d-a67e-4ae8-ac06-68f55ae7d1fc_fitting-bra-1.jpg`,
    stepIndicator: 1,
    title: 'Mesurer le tour de poitrine (cm)',
    description:
      'Placer le mettre ruban bien à plat sur la poitrine, à l’endroit le plus large et rester bien droite.',
  },
  {
    image: `${cdnBaseImageUrl.production}c11c73af-697a-4699-afd6-51d65933d00e_fitting-bra-2.jpg`,
    stepIndicator: 2,
    title: 'Mesurer le tour de dos (cm)',
    description: 'Placer le mettre ruban sous la poitrine et rester bien droite.',
  },
  {
    image: `${cdnBaseImageUrl.production}3a7ce46d-5a2c-4067-a71b-9bdb315cd18e_fitting-bra-3.jpg`,
    stepIndicator: 'interfaceCheckmarkGreen',
    title: 'Oui !',
  },
  {
    image: `${cdnBaseImageUrl.production}6dad434a-f6b3-49e2-b2f1-3cb138e57d4d_fitting-bra-4.jpg`,
    stepIndicator: 'interfaceCloseRed',
    title: 'Non !',
  },
];
export const sizingGuidePantyVisual = [
  {
    image: `${cdnBaseImageUrl.production}f7c50956-fbf1-485d-8e34-7f07b3fea902_fitting-panty-1.jpg`,
    title: 'Mesurer son tour de hanches (cm)',
    description:
      "Tenez vous droite devant un miroir, placez le mètre ruban bien à plat sur les hanches, à l'endroit le plus fort. Le ruban ne doit être ni trop serré, ni trop lâche.",
  },
];
