import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, Text } from '../../../design-system';
import { opacities } from '../../../design-system/systemprovider';
import { CartStep } from '../types';
import { setStep } from '../actions';
import styled from '@emotion/styled';
import { RootState } from 'src/shared/store/rootReducer';
import { Status as CtaStatus } from '../../../design-system/button/components/DynamicButton';

type Props = {
  step: CartStep;
};

const StepIndicator = ({ step }: Props) => {
  const dispatch = useDispatch();
  const { ctaState } = useSelector((state: RootState) => state.form.payment);
  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gridGap="m"
      alignItems="center"
      justifyContent="center"
      id="box-cart-steps"
      bg={['BACKGROUND', 'initial']}
    >
      {['Panier', 'Livraison', 'Paiement'].map((text, i) => (
        <StyledButton
          key={i}
          data-cy={`cart-step-indicator-${i}-link`}
          id={`box-cart-step-${i}`}
          onClick={() => (step > i ? dispatch(setStep(i)) : null)}
          isClickable={step > i && ctaState !== CtaStatus.Loading}
          disabled={ctaState === CtaStatus.Loading}
        >
          <Text id={`box-cart-step-text-${i}`} preset="caption" display="flex" alignItems="center">
            <Box
              border="1px solid"
              borderColor={step >= i ? 'BLACK' : 'GREY2'}
              borderRadius="50%"
              size="18px"
              mr="s"
              id={`box-cart-step-indicator-${i}`}
              display="grid"
              justifyContent="center"
              alignItems="center"
              bg={step === i ? 'BLACK' : 'transparent'}
            >
              {step > i ? (
                <Box id={`box-cart-step-checkmark-${i}`}>
                  <Icon name="checkmark" color="#000" size={8} />
                </Box>
              ) : (
                <Text
                  preset="caption"
                  color={step === i ? 'WHITE' : 'BLACK'}
                  opacity={step === i ? 1 : 0.4}
                  id={`box-cart-step-number-${i}`}
                >
                  {i + 1}
                </Text>
              )}
            </Box>
            <Box opacity={step >= i ? 1 : 0.4} fontSize="14px" fontWeight={400}>
              {text}
            </Box>
          </Text>
        </StyledButton>
      ))}
    </Box>
  );
};

type StyledButtonProps = {
  isClickable: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  background: none;
  padding: 0;
  // for disable bg color on click for iphone
  -webkit-tap-highlight-color: ${(props) => (props.isClickable ? 'initial' : 'transparent')};
  @media (hover: hover) {
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'initial')};
    opacity: ${opacities.HOVERED};
  }
`;

export default StepIndicator;
