import * as React from 'react';

import { Box, Icon, Text } from '../../../design-system';
import { PickupStation } from '../types';
import OpeningHours from './OpeningHours';

export type Props = {
  station: PickupStation;
};

const InfoWindow = ({ station }: Props) => {
  const { accessForPRM, parking, name, id, line1, line2, postalCode, city } = station;

  return (
    <Box display="grid" gridGap="xs" gridAutoRows="min-content">
      <Text
        id={`pickup-station-info-window-${id}-name`}
        textTransform="uppercase"
        fontWeight="bold"
        preset="caption"
      >
        {name}
      </Text>
      <Text id={`pickup-station-info-window-${id}-address`} py="s" preset="caption" color="GREY">
        {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
      </Text>
      {(parking || accessForPRM) && (
        <Box display="flex" mb="s">
          {accessForPRM && <Icon name="wheelchair" />}
          {parking && <Icon name="parkingColissimo" />}
        </Box>
      )}
      <OpeningHours station={station} />
    </Box>
  );
};

export default InfoWindow;
