import React, { useState } from 'react';
import { Box, colors, Text } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';
import { patternVariants } from '../../product/components/PatternSelector';

type Props = {
  objectID: string;
};

export default function PatternPanel({ objectID }: Props) {
  const [selectedPattern, setSelectedPattern] = useState(patternVariants[0]);

  return (
    <>
      <Box
        display="grid"
        alignItems="center"
        gridGap="m"
        gridTemplateColumns="repeat(9, min-content)"
        gridAutoRows="min-content"
      >
        {(patternVariants ?? []).map((pattern, index) => {
          const isSelected = pattern.name === selectedPattern.name;

          return (
            <Box
              key={index}
              id={`catalog-item-color-${objectID}-${index}`}
              border={isSelected ? `1px solid ${colors.BLACK}` : ''}
              borderRadius="50%"
              onClick={() => setSelectedPattern(pattern)}
            >
              <ToolTip
                background={`url(${pattern.url})`}
                backgroundPosition="center"
                backgroundSize="cover"
                border={isSelected ? '2px solid WHITE' : ''}
                borderRadius="50%"
                size={['20px', '24px', '20px']}
              >
                <Box>
                  <Text preset="caption" color="GREY" withEllipsis textTransform="uppercase">
                    {pattern.name}
                  </Text>
                </Box>
              </ToolTip>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
