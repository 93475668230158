import React from 'react';
import styled from '@emotion/styled';

import { CmsSearchLinks } from '../../cms/types';
import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { Link } from 'react-router-dom';

type Props = {
  data: CmsSearchLinks | undefined;
};

const StyledContainer = styled.div`
  width: 50%;

  @media (min-width: ${breakpoints.M}px) {
    margin: 48px 0 32px 0;
  }

  .search-links-title {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 8px;
    margin-right: 16px;
    font-size: 1.4rem;
    line-height: 21px;
    color: ${colors.BLACK};
    font-weight: 700;
  }

  .search-links-items-block {
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.S}px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .search-links-item {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 8px;
    margin-right: 16px;
    color: ${colors.MAIN_GREY};
    font-size: 1.4rem;
    line-height: 21px;
    font-weight: 400;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
      }
    }
  }
`;

export const SearchLinks = ({ data }: Props) => {
  if (!data) {
    return null;
  }

  const {
    primary: { search_link_title },
    items,
    slice_type,
  } = data;

  return (
    <StyledContainer id={`search-links-${slice_type}-block`}>
      {search_link_title && <h2 className="search-links-title">{search_link_title}</h2>}
      <div className="search-links-items-block">
        {items?.map((item, index) => {
          if (!item.search_link_url || !item.search_link_name) {
            return null;
          }
          return (
            <Link
              className="search-links-item"
              key={`${search_link_title}-${item.search_link_name}`}
              to={item.search_link_url}
              data-testid={`search-link-${index}-${item.search_link_name}-on-${data.slice_type}`}
              aria-label={`Lien vers la page ${item.search_link_url}`}
            >
              {item.search_link_name}
            </Link>
          );
        })}
      </div>
    </StyledContainer>
  );
};
