import React from 'react';
import { css } from '@emotion/core';

import { breakpoints } from './../../../design-system';
import { Notifications } from './Notifications';
import { Shipping } from './../../shipping';
import NewsletterContainer from './../../account/components/Newsletter';
import { CartNotification, CartStep, ShippingTypes } from '../types';
import { deliveryMethodsDesktopWidth } from '../../shipping/utils';
import { FormFieldCallback, Forms } from '../../form/types';
import { LBL_OPTIN_NEWS_LETTER } from '../locale';

type Props = {
  couponAccepted: boolean;
  notifications: CartNotification[];
  step: CartStep;
  handleShowBillingClick: () => void;
  handleShowGiftMessageClick: () => void;
  handleShippingMethodClick: (method: ShippingTypes) => void;
  handleFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  handleFieldChange: ({ form, key, value }: FormFieldCallback) => void;
  formErrorType: Forms | null;
  setFormErrorType: (value: Forms | null) => void;
  setOpenedFormType: (value: Forms | null) => void;
};

export const CartDeliveryStep = ({
  couponAccepted,
  notifications,
  step,
  handleShowBillingClick,
  handleShowGiftMessageClick,
  handleShippingMethodClick,
  handleFieldBlur,
  handleFieldChange,
  formErrorType,
  setFormErrorType,
  setOpenedFormType,
}: Props) => {
  return (
    <>
      <div
        css={css`
          @media (min-width: ${breakpoints.S}px) {
            margin: 0 16px;
          }
          @media (min-width: ${breakpoints.L}px) {
            margin: 0 48px;
          }
        `}
      >
        <Notifications couponAccepted={couponAccepted} notifications={notifications} step={step} />
        <Shipping
          onShowBillingClick={handleShowBillingClick}
          onShowGiftMessageClick={handleShowGiftMessageClick}
          onShippingMethodClick={handleShippingMethodClick}
          onFieldBlur={handleFieldBlur}
          onFieldChange={handleFieldChange}
          formErrorType={formErrorType}
          setFormErrorType={setFormErrorType}
          setOpenedFormType={setOpenedFormType}
        />
        <div
          css={css`
            @media (max-width: ${breakpoints.S - 1}px) {
              display: none;
            }
            @media (min-width: ${breakpoints.L}px) {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          `}
        >
          <div
            css={css`
              @media (min-width: ${breakpoints.L}px) {
                width: ${step === CartStep.DELIVERY ? `${deliveryMethodsDesktopWidth}px` : 'auto'};
              }
            `}
          >
            <p
              css={css`
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: left;
                font-weight: 700;
                margin-top: 16px;
                margin-block-start: 0;
                margin-block-end: 0;
                @media (min-width: ${breakpoints.S}px) {
                  margin-top: 0;
                }
              `}
            >
              {LBL_OPTIN_NEWS_LETTER}
            </p>
            <NewsletterContainer isOnCartPage />
          </div>
        </div>
      </div>
    </>
  );
};
