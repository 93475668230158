import * as React from 'react';

import { Box, Text, Icon } from '../../../design-system';
import { getShouldRotate } from '../utils';
import { MSG_ROTATE } from '../locale';

type Props = {
  children: React.ReactNode;
};

export const OrientationController = ({ children }: Props) => {
  const [shouldRotate, setShouldRotate] = React.useState(false);
  const updateShouldRotate = () => setShouldRotate(getShouldRotate());

  React.useEffect(() => {
    updateShouldRotate();
    window.addEventListener('orientationchange', updateShouldRotate);
    return () => {
      window.removeEventListener('orientationchange', updateShouldRotate);
    };
  }, []);

  if (shouldRotate) {
    return (
      <Box
        id="box-rotation-warning"
        bg="BACKGROUND"
        height="100vh"
        display="grid"
        alignItems="center"
        justifyContent="center"
      >
        <Box display="grid" alignItems="center" justifyContent="center" mb="h">
          <Box justifySelf="center">
            <Icon id="icon-rotation-warning" name="rotate" size={300} />
          </Box>
          <Text id="text-rotation-warning" preset="subheading" color="GREY">
            {MSG_ROTATE}
          </Text>
        </Box>
      </Box>
    );
  }
  return <>{children}</>;
};

export default OrientationController;
