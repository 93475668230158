import { FormActions } from './actions';
import formState from './state';
import types from './actionTypes';
import { State } from './types';

export default function form(state = formState, action: FormActions): State {
  switch (action.type) {
    case types.SET_FORM_VALUES:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          values: {
            ...state[action.payload.form].values,
            ...action.payload.values,
          },
        },
      };
    case types.SET_FORM_VALIDATION:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          validation: {
            ...state[action.payload.form].validation,
            ...action.payload.values,
          },
        },
      };
    case types.SET_FOCUS:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          focus: action.payload.key,
        },
      };
    case types.SET_FEEDBACK:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          feedback: {
            ok: action.payload.ok,
            message: action.payload.message,
            isDirty: action.payload.isDirty,
          },
        },
      };
    case types.SET_CTA_STATE:
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          ctaState: action.payload.ctaState,
        },
      };
    default:
      return state;
  }
}
