import types from './actionTypes';
import { AccountActions } from './actions';
import { accountState } from './state';
import { State } from './types';

export default function account(state = accountState, action: AccountActions): State {
  switch (action.type) {
    case types.REQUEST_ORDERS:
      return {
        ...state,
        orders: [],
        errMsg: '',
        isFetching: true,
      };
    case types.ERROR_ORDERS:
      return {
        ...state,
        orders: [],
        errMsg: action.payload,
        isFetching: false,
      };
    case types.SUCCESS_ORDERS:
      return {
        ...state,
        errMsg: '',
        isFetching: false,
        orders: action.payload,
      };
    case types.REQUEST_ORDER:
      return {
        ...state,
        errMsg: '',
        isFetching: true,
      };
    case types.ERROR_ORDER:
      return {
        ...state,
        errMsg: action.payload,
        isFetching: false,
      };
    case types.SUCCESS_ORDER:
      return {
        ...state,
        errMsg: '',
        isFetching: false,
        order: action.payload,
      };
    case types.SUCCESS_USER:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case types.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    default:
      return state;
  }
}
