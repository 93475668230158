import * as React from 'react';

import { StyledField, StyledInput, StyledLabel } from './field.style';
import Text from '../text';
import Box from '../box';
import Icon from '../icon';
import { colors, durations } from '../systemprovider/constants';

export type Handles = {
  focus(): void;
};

export type Props = {
  id: string;
  value: string;
  required?: boolean;
  onChange: (value: string) => void;
  label?: string;
  errMsg?: string;
  placeholder?: string;
  type?: string;
  icon?: string;
  iconSize?: number;
  iconMargin?: string;
  iconOpacity?: number;
  autoComplete?: string;
  note?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  hasBorder?: boolean;
  min?: string;
  max?: string;
  step?: string;
  isAPrice?: boolean;
  maxLength?: number;
  color?: string;
  height?: string;
  isFooterInput?: boolean;
  isDelivery?: boolean;
  onBlur?: (value: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onIconClick?: () => void;
  isEresaModal?: boolean;
  customClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
};

const Field: React.RefForwardingComponent<Handles, Props> = (
  {
    id,
    label,
    errMsg,
    note,
    icon,
    onChange,
    onBlur,
    onKeyPress,
    onIconClick,
    iconSize = 18,
    iconMargin = 'xs',
    iconOpacity = 1,
    hasBorder = false,
    isAPrice = false,
    color,
    height,
    readOnly,
    type,
    isEresaModal,
    isDelivery,
    customClick = () => null,
    isFooterInput,
    ...rest
  }: Props,
  ref
) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setTimeout(() => inputRef.current?.focus(), durations.FOCUS_DELAY);
    },
    scrollIntoView: () => {
      setTimeout(
        () => inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
        durations.SCROLL_DELAY
      );
    },
  }));

  return (
    <div>
      <StyledField
        hasError={Boolean(errMsg)}
        hasBorder={hasBorder}
        height={height}
        readOnly={readOnly}
        isDelivery={isDelivery}
      >
        {type === 'date' && (
          <Box marginX={16} position={'absolute'} right={0} pointerEvents={'none'}>
            <Icon name={'calendar'} />
          </Box>
        )}
        <StyledInput
          id={id}
          onKeyPress={(event) => (onKeyPress ? onKeyPress(event) : undefined)}
          onChange={(event) => onChange(event.target.value)}
          onBlur={(event) => (onBlur ? onBlur(event.target.value) : undefined)}
          hasLabel={Boolean(label)}
          ref={inputRef}
          isAPrice={isAPrice}
          type={type}
          isEresaModal={isEresaModal}
          isDelivery={isDelivery}
          isFooterInput={isFooterInput}
          {...rest}
        />
        {icon ? (
          <Box m={iconMargin} opacity={iconOpacity} bg={colors.BACKGROUND}>
            {!isEresaModal && !isDelivery ? (
              <Icon id={`icon-${id}`} name={icon} size={iconSize} onClick={onIconClick} />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="50px"
                width="50px"
                onClick={customClick ? (event) => customClick(event) : () => null}
              >
                <Icon id={`icon-${id}`} name={icon} size={iconSize} />
              </Box>
            )}
          </Box>
        ) : null}
        {label && (
          <StyledLabel id={`label-for-${id}`} htmlFor={id} color={color}>
            {label}
          </StyledLabel>
        )}
        {isAPrice && (
          <Text mr={'xs'} pt={'m'}>
            €
          </Text>
        )}
      </StyledField>
      <Box pt="xs">
        {errMsg ? (
          <Text preset="caption" color="ERROR" id={`error-message-for-${id}`}>
            {errMsg}
          </Text>
        ) : (
          note && (
            <Box
              display="grid"
              gridGap="xs"
              gridAutoFlow="column"
              alignItems="start"
              color="GREY"
              justifyContent="left"
            >
              <Text preset="caption" color="GREY" id={`note-for-${id}`}>
                {note}
              </Text>
            </Box>
          )
        )}
      </Box>
    </div>
  );
};

export default React.forwardRef(Field);
