import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UnknownAction } from 'redux';

import { Icon, Button, Spinner, Checkbox } from '../../design-system';
import { breakpoints, colors, opacities, theme } from '../../design-system/systemprovider';
import { closeModal } from '../common/actions';
import { Mutations, getBFFData } from '../api';
import { ERR_AUTH } from '../auth/locale';
import { getIconUrl } from '../../design-system/icon/utils';
import { formatPrice, persistData } from '../common/utils';
import { getClientID } from '../tracking';
import { updateNewsletter } from '../../modules/account/actions';
import { RootState } from 'src/shared/store/rootReducer';
import {
  PAYPAL_EXPRESS_DESCRIPTION,
  PAYPAL_EXPRESS_FREE_DELIVERY,
  PAYPAL_EXPRESS_OPT_IN,
  PAYPAL_EXPRESS_PRIVACY_POLICY,
  PAYPAL_EXPRESS_SUBTITLE,
  PAYPAL_EXPRESS_TITLE,
  PAYPAL_EXPRESS_THRESHOLD_DELIVERY,
  PAYPAL_EXPRESS_PRICE_DELIVERY_DESCRIPTION,
} from './locale';
import { ShippingTypes } from '../cart/types';
import { css } from '@emotion/core';
import { transitions } from '../common/constants';

declare global {
  interface Window {
    dataLayer: {}[];
  }
}

const PaypalModal = () => {
  const { shipping_promo_amount: requiredAmountHome } = useSelector(
    (state: RootState) => state.cms.cart ?? 85
  );
  const { shippingTypes } = useSelector((state: RootState) => state.cart);
  const homeShippingPrice =
    shippingTypes.find((item) => item.label === ShippingTypes.HOME)?.amount ?? 4.9;
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { user } = useSelector((state: RootState) => state.account);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [optinIsChecked, setOptinIsChecked] = React.useState(false);
  const gaClientId = getClientID();
  const handleExpressButtonClick = async () => {
    setIsLoading(true);
    if (optinIsChecked && isLoggedIn && !user?.optInEmail) {
      dispatch(updateNewsletter({ optInEmail: true }) as unknown as UnknownAction);
    }
    if (optinIsChecked && !isLoggedIn) {
      persistData('subscribePaypalECS', optinIsChecked);
    }
    const response = await getBFFData(Mutations.paypalECS, { gaClientId });
    if (response.ok) {
      persistData('isPaypalECS', true);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'start_checkout' });
      const data = response.data.paypalECS;
      const form = document.createElement('form');
      form.action =
        process.env.DEPLOYMENT_ENV === 'production'
          ? 'https://live.adyen.com/hpp/skipDetails.shtml'
          : 'https://test.adyen.com/hpp/skipDetails.shtml';
      form.method = 'post';
      form.id = 'adyenForm';
      form.name = 'adyenForm';
      form.autocomplete = 'off';
      for (const key in data) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
      const submit = document.createElement('input');
      submit.type = 'submit';
      submit.value = 'Test PayPal ECS';
      form.appendChild(submit);
      const root = document.getElementById('root');
      if (root) {
        root.appendChild(form);
        form.submit();
      }
    }
    if (!response.ok) {
      setIsError(true);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          height: 48px;
          width: 100%;
          padding: ${theme.sizes.m};
        `}
      >
        <button
          type="button"
          data-cy="paypal-express-close-button"
          onClick={handleCloseModal}
          css={css`
            background: initial;
            border: initial;
            padding: initial;
          `}
        >
          <Icon name="close" size={16} />
        </button>
      </div>
      <div
        css={css`
          padding: 0 16px;
          @media (min-width: ${breakpoints.S}px) {
            padding: 0 80px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              font-size: ${theme.fontSizes.l};
              line-height: 110%;
              font-weight: 700;
              margin-right: ${theme.sizes.s};
              align-self: center;
            `}
          >
            {PAYPAL_EXPRESS_TITLE}
          </div>
          <img
            src={getIconUrl('paypalExpress')}
            css={css`
              width: 70px;
            `}
            alt="Logo Paypal"
          />
        </div>
        <div
          css={css`
            font-size: ${theme.fontSizes.s};
            font-weight: 700;
            line-height: 110%;
            margin-top: ${theme.sizes.xl};
          `}
        >
          {PAYPAL_EXPRESS_SUBTITLE}
        </div>
        <div
          css={css`
            font-size: ${theme.fontSizes.s};
            margin-top: ${theme.sizes.m};
            line-height: 110%;
            color: ${colors.GREY};
          `}
        >
          {PAYPAL_EXPRESS_DESCRIPTION}
        </div>
        {requiredAmountHome !== 0 && (
          <div
            css={css`
              font-size: ${theme.fontSizes.s};
              font-weight: 700;
              line-height: 110%;
              margin-top: ${theme.sizes.l};
              color: ${colors.GREY};
            `}
          >
            {PAYPAL_EXPRESS_THRESHOLD_DELIVERY}
          </div>
        )}

        <div
          css={css`
            font-size: ${theme.fontSizes.s};
            margin-top: ${theme.sizes.s};
            margin-bottom: ${theme.sizes.m};
          `}
        >
          {requiredAmountHome !== 0 && homeShippingPrice !== 0 ? (
            <span
              css={css`
                font-style: italic;
                line-height: 110%;
                color: ${colors.GREY};
              `}
            >
              {PAYPAL_EXPRESS_PRICE_DELIVERY_DESCRIPTION(
                formatPrice(homeShippingPrice).slice(0, -2),
                requiredAmountHome
              )}
            </span>
          ) : (
            <span
              css={css`
                color: #0d9560;
              `}
            >
              {PAYPAL_EXPRESS_FREE_DELIVERY}
            </span>
          )}
        </div>

        <Checkbox
          id="paypal-express-modal-optin-checkbox"
          data-cy={`paypal-express-modal-optin-checkbox-${optinIsChecked}`}
          onChange={() => setOptinIsChecked(!optinIsChecked)}
          checked={optinIsChecked}
        >
          <div
            css={css`
              font-size: ${theme.fontSizes.xs};
              color: ${colors.GREY};
            `}
          >
            {PAYPAL_EXPRESS_OPT_IN}
          </div>
        </Checkbox>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            height: auto;
            font-size: ${theme.fontSizes.s};
            font-weight: 700;
            margin-top: ${theme.sizes.xl};
            @media (min-width: ${breakpoints.S}px) {
              flex-direction: row-reverse;
              height: 44px;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 100%;
              @media (min-width: ${breakpoints.S}px) {
                width: 252px;
              }
            `}
          >
            <Button
              data-cy="paypal-express-modal-button"
              id="paypal-express-button"
              onClick={() => handleExpressButtonClick()}
              fontWeight="700"
            >
              {isError ? (
                ERR_AUTH
              ) : isLoading ? (
                <Spinner color="white" />
              ) : (
                'Poursuivre avec PayPal'
              )}
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              @media (min-width: ${breakpoints.S}px) {
                width: 173px;
              }
            `}
          >
            <Link
              to="/politique-de-confidentialite"
              data-cy="paypal-express-link-to-privacy-policy"
              target="_blank"
            >
              <div
                css={css`
                  margin-top: ${theme.sizes.l};
                  display: inline-block;
                  border-bottom: solid 1px black;
                  font-size: ${theme.fontSizes.xs};
                  line-height: 110%;
                  padding-bottom: 2px;
                  @media (min-width: ${breakpoints.S}px) {
                    margin-top: ${theme.sizes.na};
                  }
                  @media (hover: hover) {
                    transition: ${transitions.GENERIC};
                    :hover {
                      opacity: ${opacities.HOVERED};
                    }
                  }
                `}
              >
                {PAYPAL_EXPRESS_PRIVACY_POLICY}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalModal;
