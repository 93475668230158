import {
  CmsHomepageBanner,
  CmsHomepageTitle,
  CmsHomepageSpacing,
  CmsHomepage,
  CmsHomepageBannerSlice,
  CmsHomepageRecommendationsTab,
  CmsText,
  CmsBrandBanner,
  CmsCategories,
} from '../cms/types';

function getHomeBanners(banners: CmsHomepageBanner[]): {
  type?: 'slider' | 'full' | 'half';
  banners?: CmsHomepageBanner[];
}[] {
  const activeBanners =
    (banners ?? []).filter(
      (banner) =>
        banner.is_active &&
        banner.position &&
        banner.type &&
        !(banner.video && banner.type === 'slider')
    ) ?? [];

  return [...Array(16).keys()]
    .map((i) => {
      const rowBanners =
        (activeBanners ?? []).filter((banner) => banner.position === (i + 1).toString()) ?? [];
      return getHomeBannersForRow(rowBanners);
    })
    .map((row) => (row.banners.length > 0 ? row : {}));
}

function getHomeBannersForRow(rowBanners: CmsHomepageBanner[]): {
  type: 'slider' | 'full' | 'half';
  banners: CmsHomepageBanner[];
} {
  const firstRowBanner = rowBanners?.[0];
  const type = firstRowBanner?.type;
  const banners: CmsHomepageBanner[] = [];
  if (type === 'full') {
    banners.push(firstRowBanner);
  } else if (type === 'half') {
    banners.push(firstRowBanner);
    const otherHalf = rowBanners?.[1];
    if (otherHalf) {
      banners.push(otherHalf);
    }
  }
  if (type === 'slider') {
    const sliderBanners = rowBanners.filter((banner) => banner.type === 'slider');
    banners.push(...sliderBanners);
  }

  return {
    type,
    banners,
  };
}

function getTitles(titles: CmsHomepageTitle[]): (CmsHomepageTitle | undefined)[] {
  return [...Array(16).keys()].map((i) =>
    (titles ?? []).find((title) => title.position === (i + 1).toString())
  );
}

function getSpaces(spaces: CmsHomepageSpacing[]): (CmsHomepageSpacing | undefined)[] {
  return [...Array(16).keys()].map((i) =>
    (spaces ?? []).find((spacing) => spacing.position === (i + 1).toString())
  );
}

function getNewHomeBanners(bannerSlices: CmsHomepageBannerSlice[]) {
  return [...Array(16).keys()].map((i) =>
    (bannerSlices ?? []).find((bannerSlice) => bannerSlice.primary.position === (i + 1).toString())
  );
}

function getRecommendationsTabs(recommendationsTabs: CmsHomepageRecommendationsTab[]) {
  return [...Array(16).keys()].map((i) =>
    (recommendationsTabs ?? []).find(
      (recommendationsTab) =>
        recommendationsTab.primary.is_active &&
        recommendationsTab.primary.position === (i + 1).toString()
    )
  );
}

function getBrandBanners(brandBanners: CmsBrandBanner[]) {
  return [...Array(16).keys()].map((i) =>
    brandBanners?.filter(
      (brandBanner) => brandBanner.primary?.brand_banner_position_on_page === (i + 1).toString()
    )
  );
}
function getCategoriesBanners(categoriesBanners: CmsCategories[]) {
  return [...Array(16).keys()].map((i) =>
    categoriesBanners?.filter(
      (categoriesBanners) =>
        (categoriesBanners.primary?.categories_block_is_active_desktop ||
          categoriesBanners.primary?.categories_title_alignment_mobile) &&
        categoriesBanners.primary?.category_position === (i + 1).toString()
    )
  );
}

export function getHomeContent(cms: CmsHomepage): {
  title?: CmsHomepageTitle;
  spacing?: CmsHomepageSpacing;
  banner?: {
    type: 'slider' | 'full' | 'half';
    banners: CmsHomepageBanner[];
  };
  newBanner?: CmsHomepageBannerSlice;
  categories?: boolean;
  recommendations?: boolean;
  recommendationsTab?: CmsHomepageRecommendationsTab;
  brandBanners?: CmsBrandBanner[];
  categoriesBanners?: CmsCategories[];
}[] {
  const titles = getTitles(cms?.titles ?? []);
  const spaces = getSpaces(cms?.spaces ?? []);
  const banners = getHomeBanners(cms?.banners ?? []);
  const newBanners = getNewHomeBanners(cms?.body ?? []);
  const recommendationsTabs = getRecommendationsTabs(cms?.recommendations_tabs ?? []);
  const brandBanners = getBrandBanners(cms?.body1 ?? []);
  const categoriesBanners = getCategoriesBanners(cms?.body2 ?? []);

  return [...Array(16).keys()]
    .map((i) => ({
      title: titles[i],
      spacing: spaces[i],
      banner: banners[i],
      newBanner: newBanners[i],
      recommendations:
        cms.recommendations_block_is_active && cms?.recommendations_position === (i + 1).toString(),
      recommendationsTab: recommendationsTabs[i],
      brandBanners: brandBanners[i],
      categoriesBanners: categoriesBanners[i],
    }))
    .map((obj) => {
      return Object.keys(obj)
        .filter((k) => obj[k] !== undefined && obj[k] !== false) // remove undefined and false
        .filter((k) => typeof obj[k] !== 'object' || Object.keys(obj[k]).length !== 0) // remove empty objects
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {}); // re-add the keys and values
    });
}

export const hasValidCmsText = (cmsText: CmsText | undefined | null): boolean => {
  return Boolean(cmsText?.[0]?.text?.[0]?.length);
};
