import * as React from 'react';
import { Box, Icon, breakpoints, colors } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';

import styled from '@emotion/styled';
import { heights } from '../../common/constants';

type TSizeGuideTable = {
  sizeGuide: Array<{
    entries: Array<string | string[] | { stepIndicator?: string; label: string }>;
    variant?: string;
  }>;
  title?: string;
  id: string;
  isPopup?: boolean;
};

const StyledSizeGuideTable = styled.div<{
  isPopup?: TSizeGuideTable['isPopup'];
}>`
  .toolTip__icon {
    margin: auto;
  }
  .size-guide-table__title {
    text-align: center;
    font-family: 'Ogg-text', serif;
    font-size: 20px;
    line-height: 110%;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 0;
    padding: 0 16px;
    @media (min-width: ${breakpoints.S - 1}px) {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  .size-guide-table__wrapper {
    overflow-x: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    @media (min-width: ${breakpoints.S - 1}px) {
      overflow-x: unset;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
  }
  .size-guide-table__content {
    border-collapse: collapse;
    text-align: center;
    min-width: 100%;
    width: max-content;
    margin: auto;
    @media (min-width: ${breakpoints.S - 1}px) {
      min-width: 510px;
      width: unset;
    }
  }
  .size-guide-table__content-head {
    z-index: 10;
    @media (min-width: ${breakpoints.S - 1}px) {
      position: sticky;
      top: ${(props) => (props.isPopup ? '24px' : heights.HEADER + 24 + 'px')};
    }
    &::before {
      @media (min-width: ${breakpoints.S - 1}px) {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: -24px;
        height: 25px;
        background: ${colors.BACKGROUND};
      }
    }
  }
  .table-th__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .table-th__step-indicator {
    background-color: #fff;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }
  .size-guide-table__td,
  .size-guide-table__th {
    border: 1px solid #f2f2f2;
    padding: 10px;
    font-size: 12px;
    line-height: 110%;
  }
  .size-guide-table__th {
    background-color: #000;
    color: #fff;
  }
  .size-guide-table__tbody {
    .size-guide-table__tr {
      background: #fff;
    }
    .size-guide-table__tr--is-gray {
      background: ${colors.BACKGROUND};
    }
  }
`;

const DisplayCellContent = ({ content }: { content: string }) => {
  if (content === 'tooltip') {
    return (
      <ToolTip>
        <Icon name="infoCircle" size={16} className="toolTip__icon" />
        <Box>
          <p>L&apos;équivalence de taille n&apos;est pas recommandé</p>
        </Box>
      </ToolTip>
    );
  }
  return <>{content}</>;
};

const SizeGuideTable = ({ sizeGuide, id, title, isPopup }: TSizeGuideTable) => {
  return (
    <StyledSizeGuideTable isPopup={isPopup}>
      {title && <h2 className="size-guide-table__title">{title}</h2>}
      <div className="size-guide-table__wrapper">
        <table className="size-guide-table__content">
          <thead className="size-guide-table__content-head">
            <tr className="size-guide-table__tr">
              {sizeGuide[0].entries.map((cell, index) => {
                return (
                  <th
                    key={`${id}-${index}`}
                    colSpan={
                      Array.isArray(sizeGuide[1].entries[index])
                        ? (sizeGuide[1].entries[index] as string[]).length
                        : undefined
                    }
                    className="size-guide-table__th"
                  >
                    <div className="table-th__wrapper">
                      {typeof cell === 'object' &&
                        'stepIndicator' in cell &&
                        cell.stepIndicator &&
                        !isPopup && (
                          <span className="table-th__step-indicator">{cell.stepIndicator}</span>
                        )}
                      <span>
                        {typeof cell === 'object' && 'label' in cell && cell.label
                          ? cell.label
                          : cell}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="size-guide-table__tbody">
            {sizeGuide.slice(1).map((item, index) => {
              return (
                <tr
                  key={`${id}-${index}`}
                  className={`size-guide-table__tr ${
                    item.variant && item.variant.includes('gray')
                      ? 'size-guide-table__tr--is-gray'
                      : ''
                  }`}
                >
                  {item.entries.map((cell, cellIndex) => {
                    if (Array.isArray(cell)) {
                      return cell.map((subCell, subCellIndex) => (
                        <td key={`${id}-${index}-${subCellIndex}`} className="size-guide-table__td">
                          <DisplayCellContent content={subCell} />
                        </td>
                      ));
                    }
                    return (
                      !(cell === 'gray') && (
                        <td key={`${id}-${index}-${cellIndex}`} className="size-guide-table__td">
                          <DisplayCellContent content={cell as string} />
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </StyledSizeGuideTable>
  );
};

export { SizeGuideTable };
