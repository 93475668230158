import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '../../common/actions';
import { RootState } from '../../../store/rootReducer';
import { setStep } from '../../cart/actions';
import { CartStep } from '../../cart/types';
import { RefusalReason } from '../types';
import { PAYMENT_REFUSED } from '../locale';
import { setFeedback } from '../../form/actions';
import { Forms } from '../../form/types';
import { getPersistedData } from '../../common/utils';
import { ErrorsModal } from './ErrorsModal';

const PaymentError = () => {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.form.payment);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        setFeedback({ form: Forms.payment, ok: false, message: RefusalReason.EXPIRED_TOKEN })
      );
    }, 1200000); // 20 minutes
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    if (!form.feedback.ok) {
      let message = '';
      switch (form.feedback.message) {
        case RefusalReason.EXPIRED_TOKEN:
          message = 'Votre session de paiement a expirée.';
          break;
        case RefusalReason.DECLINED:
          message = PAYMENT_REFUSED + getPersistedData('orderDetail').orderNumber;
          break;
        case '':
          message = 'Une erreur est survenue lors de la validation du paiement.';
          break;
        default:
          message = 'Une erreur est survenue lors de la validation du paiement.';
          break;
      }

      const needCustomerServiceMail =
        form.feedback.message === RefusalReason.EXPIRED_TOKEN || form.feedback.message === '';
      const needToolTip = form.feedback.message === '';

      dispatch(
        openModal({
          content: (
            <ErrorsModal
              message={message}
              customerServiceMail={needCustomerServiceMail}
              toolTip={needToolTip}
            />
          ),
          preset: 'paymentError',
          onBackgroundClick: () => dispatch(setStep(CartStep.DELIVERY)),
        })
      );
    }
  }, [form.feedback.ok]);

  return null;
};

export default PaymentError;
