import * as React from 'react';
import { useSelector } from 'react-redux';

import { Box, Icon, Text } from '../../../design-system';
import locale from '../locale';
import { ColorText } from '../../common/components/Custom';
import { RECYCLE_BG_COLOR, RECYCLE_TEXT_COLOR } from './RecycleLabel';
import { RootState } from '../../../store/rootReducer';

const RecycleDetails = () => {
  const { price_bras, price_panties } = useSelector((state: RootState) => state.cms.product) ?? {};

  return (
    <>
      <Box display="grid" textAlign="center" mb="l">
        <Text preset="subheading" fontWeight="bold" mx="l" mb="s">
          {locale.LBL_RECYCLE}
        </Text>
        <Text mx="l" mb="m" preset="caption">
          {locale.TXT_DETAILS}
        </Text>
        <Box display="grid" justifyContent="center" alignItems="center" gridGap="l" mx="l">
          <Box
            display="grid"
            justifyContent="left"
            alignItems="center"
            gridGap="m"
            gridAutoFlow="column"
          >
            <Icon name="recycleStep1" size={40} />
            <Box display="grid" gridGap="xs" textAlign="left">
              <ColorText cmsColor={RECYCLE_BG_COLOR} preset="caption">
                {locale.TXT_STEP_1}
              </ColorText>
              <ColorText cmsColor={RECYCLE_TEXT_COLOR} preset="caption">
                {locale.TXT_STEP_1_DETAILS}
              </ColorText>
            </Box>
          </Box>
          <Box
            display="grid"
            justifyContent="left"
            alignItems="center"
            gridGap="m"
            gridAutoFlow="column"
          >
            <Icon name="recycleStep2" size={40} />
            <Box display="grid" gridGap="xs" textAlign="left">
              <ColorText cmsColor={RECYCLE_BG_COLOR} preset="caption">
                {locale.TXT_STEP_2}
              </ColorText>
              <ColorText cmsColor={RECYCLE_TEXT_COLOR} preset="caption">
                {locale.TXT_STEP_2_DETAILS}
              </ColorText>
            </Box>
          </Box>
          <Box
            display="grid"
            justifyContent="left"
            alignItems="center"
            gridGap="m"
            gridAutoFlow="column"
          >
            <Icon name="recycleStep3" size={40} />
            <Box display="grid" gridGap="xs" textAlign="left">
              <ColorText cmsColor={RECYCLE_BG_COLOR} preset="caption">
                {locale.TXT_STEP_3}
              </ColorText>
              <ColorText cmsColor={RECYCLE_TEXT_COLOR} preset="caption">
                {locale.TXT_STEP_3_DETAILS}
              </ColorText>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bg={RECYCLE_BG_COLOR}
        p="s"
        display="grid"
        justifyContent="center"
        alignItems="center"
        gridGap="xs"
        textAlign="center"
      >
        <Text color="WHITE" preset="caption">
          {locale.TXT_PAYBACK}
        </Text>
        <Text
          color="WHITE"
          preset="caption"
        >{`${price_bras}€ par Soutien-gorge | ${price_panties}€ par Bas`}</Text>
      </Box>
    </>
  );
};

export default RecycleDetails;
