import { removeDiacritics } from '../store-locator/utils';
import { CDN_URL } from '../api/constants';
import { RefinementListRenderState } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

export const universalSizes = [
  'TU',
  'XS',
  'XS/S',
  'S',
  'SM',
  'M',
  'M/L',
  'L',
  'LXL',
  'XL',
  '1X2X',
  'XXL',
];
export const cupSizes = ['A', 'B', 'BC', 'C', 'D', 'DE', 'E', 'F', 'G', 'H', 'J'];
const universalBraSizesAlpha = [
  'SG-TU',
  'SG-XS',
  'SG-XS/S',
  'SG-S',
  'SG-M',
  'SG-M/L',
  'SG-L',
  'SG-XL',
  'SG-1X2X',
  'SG-XXL',
];
const universalBraSizesNumber = ['SG-36', 'SG-38', 'SG-40', 'SG-42', 'SG-44', 'SG-46', 'SG-48'];
const universalPantySizesAlpha = [
  'P-TU',
  'P-XS',
  'P-XS/S',
  'P-S',
  'P-SM',
  'P-M',
  'P-M/L',
  'P-L',
  'P-XL',
  'P-1X2X',
  'P-XXL',
];

const universalPantySizesNumber = ['P-34', 'P-36', 'P-38', 'P-40', 'P-42', 'P-44', 'P-46', 'P-48'];

const universalTightSizes = ['P-0', 'P-1', 'P-2', 'P-3', 'P-4'];

const braSizeReggex = /^(\d{2,3})([A-Za-z]{1,2})$/;

export function isUniversalSize(size: string): boolean {
  return universalSizes.indexOf(size) > -1;
}

export function isStandardSize(size: string): boolean {
  return Number(size) >= 10;
}

export function isTightsSize(size: string): boolean {
  return Number(size) >= 1 && Number(size) <= 4;
}

export function isBraSize(size: string): boolean {
  return Boolean(size) && braSizeReggex.test(size);
}

export function isUniversalBraSizeAlpha(size: string): boolean {
  return universalBraSizesAlpha.indexOf(size) > -1;
}
export function isUniversalBraSizeNumber(size: string): boolean {
  return universalBraSizesNumber.indexOf(size) > -1;
}
export function isUniversalPantySizeAlpha(size: string): boolean {
  return universalPantySizesAlpha.indexOf(size) > -1;
}
export function isUniversalPantySizeNumber(size: string): boolean {
  return universalPantySizesNumber.indexOf(size) > -1;
}
function isUniversalTightSize(size: string): boolean {
  return universalTightSizes.indexOf(size) > -1;
}

function sortUniversalBraSizeAlpha(a: { label: string }, b: { label: string }): number {
  const indexA = universalBraSizesAlpha.indexOf(a.label);
  const indexB = universalBraSizesAlpha.indexOf(b.label);
  return indexA - indexB;
}
function sortUniversalPantySizeAlpha(a: { label: string }, b: { label: string }): number {
  const indexA = universalPantySizesAlpha.indexOf(a.label);
  const indexB = universalPantySizesAlpha.indexOf(b.label);
  return indexA - indexB;
}
function sortUniversalBraOrPantySizeNumber(a: { label: string }, b: { label: string }): number {
  const labelA = a.label.replace(/^(SG-|P-)/, '');
  const labelB = b.label.replace(/^(SG-|P-)/, '');
  const aNumber = parseInt(labelA, 10);
  const bNumber = parseInt(labelB, 10);
  return aNumber - bNumber;
}

export function sortUniversalSize(a: { label: string }, b: { label: string }): number {
  const indexA = universalSizes.indexOf(a.label);
  const indexB = universalSizes.indexOf(b.label);
  return indexA - indexB;
}

export function sortStandardSize(a: { label: string }, b: { label: string }): number {
  return Number.parseInt(a.label, 10) - Number.parseInt(b.label, 10);
}

export const separateBandAndCupForBraSize = (
  size: string
): {
  bandSize: string;
  cupSize: string;
} | null => {
  const matches = size.match(braSizeReggex);

  if (matches) {
    const [, bandSize, cupSize] = matches; // Use [, bandSize, cupSize] to skip the full match

    return { bandSize, cupSize };
  }
  // Handle invalid bra size format
  return null;
};

export function sortBraSize(a: { label: string }, b: { label: string }): number {
  const separateSizeA = separateBandAndCupForBraSize(a.label);
  const separateSizeB = separateBandAndCupForBraSize(b.label);

  if (separateSizeA && separateSizeB) {
    const { bandSize: bandSizeA, cupSize: cupSizeA } = separateSizeA;
    const { bandSize: bandSizeB, cupSize: cupSizeB } = separateSizeB;

    if (bandSizeA === bandSizeB) {
      return cupSizes.indexOf(cupSizeA) - cupSizes.indexOf(cupSizeB);
    }

    return Number.parseInt(bandSizeA, 10) - Number.parseInt(bandSizeB, 10);
  }
  // Handle cases where size is not in the expected format
  return 0;
}

export function sortSizeFiltersHook(items: RefinementListRenderState['items']) {
  const sizes: { [key: string]: RefinementListRenderState['items'] } = {
    uni: [],
    uniBraAlpha: [],
    uniBraNumber: [],
    uniPantyAlpha: [],
    uniPantyNumber: [],
    bra: [],
    tights: [],
    other: [],
  };

  items.forEach((item) => {
    switch (true) {
      case isUniversalSize(item.label):
        sizes.uni.push(item);
        break;
      case isTightsSize(item.label):
        sizes.tights.push(item);
        break;
      case isBraSize(item.label):
        sizes.bra.push(item);
        break;
      case isUniversalBraSizeAlpha(item.label):
        sizes.uniBraAlpha.push(item);
        break;
      case isUniversalBraSizeNumber(item.label):
        sizes.uniBraNumber.push(item);
        break;
      case isUniversalPantySizeAlpha(item.label):
        sizes.uniPantyAlpha.push(item);
        break;
      case isUniversalPantySizeNumber(item.label):
        sizes.uniPantyNumber.push(item);
        break;
      default:
        if (!isUniversalTightSize(item.label)) {
          sizes.other.push(item);
        }
        break;
    }
  });

  return {
    uni: [...sizes.uni].sort(sortUniversalSize),
    uniBraAlpha: [...sizes.uniBraAlpha].sort(sortUniversalBraSizeAlpha),
    uniBraNumber: [...sizes.uniBraNumber].sort(sortUniversalBraOrPantySizeNumber),
    uniPantyAlpha: [...sizes.uniPantyAlpha].sort(sortUniversalPantySizeAlpha),
    uniPantyNumber: [...sizes.uniPantyNumber].sort(sortUniversalBraOrPantySizeNumber),
    bra: [...sizes.bra].sort(sortBraSize),
    tights: [...sizes.tights].sort(sortStandardSize),
    other: [...sizes.other].sort(sortStandardSize),
  };
}

export function getColorFilterImageLink(colorLabel: string): string {
  const normalizedColorLabel = removeDiacritics(colorLabel)
    .replace(/[^a-z]/gi, '')
    .toLowerCase();

  return `${CDN_URL}/color/${normalizedColorLabel}.jpg?w=24`;
}

export function getIsColorFilterWhitish(colorLabel: string): boolean {
  return (
    colorLabel.includes('Blanc') ||
    colorLabel.includes('Ivoire') ||
    colorLabel.includes('Or') ||
    colorLabel.includes('empty') ||
    colorLabel.includes('Multicolore')
  );
}
export function getRefinementLabel(label: string): string {
  return isUniversalBraSizeAlpha(label) || isUniversalBraSizeNumber(label)
    ? `${label?.split('-')?.[1]} (Haut)`
    : isUniversalPantySizeAlpha(label) || isUniversalPantySizeNumber(label)
    ? `${label?.split('-')?.[1]} (Bas)`
    : label;
}
