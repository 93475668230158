import { CDN_S3_BUCKET, OPEN_STREET_MAP_URL, OPEN_STREET_MAP_REVERSE_URL } from './constants';

export async function getStoresData() {
  const filename = `https://${CDN_S3_BUCKET}/yext/stores.json`;
  try {
    const response = await fetch(filename, { cache: 'no-store' });
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
}

export async function getAreaCoordinates(searchText) {
  const url = `${OPEN_STREET_MAP_URL}?q=${searchText}&polygon_geojson=1&countrycodes=fr&format=jsonv2`;
  try {
    const response = await fetch(url, {});
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
}

export async function getAddress(lat: string | number, lng: string | number) {
  const url = `${OPEN_STREET_MAP_REVERSE_URL}?lat=${lat}&lon=${lng}&format=jsonv2`;
  try {
    const response = await fetch(url, {});
    const json = await response.json();
    return json;
  } catch (error) {
    return null;
  }
}
