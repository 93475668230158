import { RefinementListRenderState } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import * as React from 'react';
import styled from '@emotion/styled';

import { Icon, ButtonSize, colors, opacities } from '../../../design-system';
import { durations } from '../../common/constants';
import {
  TITLE_BRA_SIZE,
  TITLE_PANTY_SIZE,
  TITLE_TIGHT_SIZE,
  TITLE_OTHER_SIZE,
  TITLE_UNIVERSAL_SIZE,
} from '../locale';

type SectionOpenState = {
  [TITLE_BRA_SIZE]: boolean;
  [TITLE_PANTY_SIZE]: boolean;
  [TITLE_TIGHT_SIZE]: boolean;
  [TITLE_OTHER_SIZE]: boolean;
  [TITLE_UNIVERSAL_SIZE]: boolean;
};

type Props = {
  title: string;
  subTitle?: string;
  iconName?: string;
  refine: RefinementListRenderState['refine'];
  items: RefinementListRenderState['items'];
  isOpen: SectionOpenState;
  setIsOpen?: (value: SectionOpenState) => void;
  isMoreSize: boolean;
  isSecondary?: boolean;
};

type StyledButtonProps = {
  margin: string;
  isSecondary: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  margin: ${(props) => props.margin};
  padding-left: inherit;
  padding-right: inherit;
  background: inherit;
  border: none;
  width: 100%;
  text-align: left;
  color: ${colors.BLACK};
  position: relative;
  pointer-events: ${(props) => (props.isSecondary ? 'none' : 'initial')};

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      opacity: ${opacities.HOVERED};
      transition: ${`opacity ${durations.ENTER}ms ease-in-out`};
    }
  }
`;

type StyledParagraphProps = {
  paddingTop?: string | number;
  height?: string | undefined;
  color?: string;
};

const StyledParagraph = styled.p<StyledParagraphProps>`
  font-size: 1.4rem;
  line-height: 110%;
  margin-block-start: 0;
  margin-block-end: 0;
  height: ${(props) => props.height ?? 'auto'};
  display: flex;
  align-items: center;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : 0)};
  color: ${(props) => (props.color ? props.color : colors.BLACK)};
`;

type StyledAbsoluteDivProps = {
  top: string | number;
  right: string | number;
};

const StyledAbsoluteDiv = styled.div<StyledAbsoluteDivProps>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
`;

type StyledDivProps = {
  paddingBottom: string | number;
};

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 7px;
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const SubSizes: React.FC<Props> = ({
  items,
  refine,
  title,
  subTitle = '',
  iconName = '',
  isSecondary = false,
  isOpen,
  setIsOpen = () => null,
  isMoreSize,
}) => {
  const onButtonClick = () => {
    setIsOpen({ ...isOpen, [title]: !isOpen[title] });
  };

  return isSecondary && !isOpen[title] ? null : (
    <>
      <StyledButton
        data-cy={`filter-button-to-${isOpen[title] ? 'close' : 'open'}-${title}-size-block`}
        onClick={() => onButtonClick()}
        margin={
          !isOpen[title] && isMoreSize
            ? '16px 0'
            : !isOpen[title] && !isMoreSize
            ? '16px 0 8px 0'
            : isOpen[title] && !items.length
            ? '16px 0 0 0'
            : '16px 0'
        }
        isSecondary={isSecondary}
      >
        {isSecondary ? null : (
          <>
            <StyledParagraph height={!items.length ? '32px' : 'auto'}>
              {title.toUpperCase()}
            </StyledParagraph>
            <StyledAbsoluteDiv top={title === TITLE_OTHER_SIZE ? 0 : '8px'} right={0}>
              <Icon name={isOpen[title] ? 'minus' : 'plus'} size={16} />
            </StyledAbsoluteDiv>
          </>
        )}
        {subTitle && items.length > 0 ? (
          <StyledParagraph paddingTop="4px" color={colors.GREY2}>
            {subTitle}
          </StyledParagraph>
        ) : null}
        {iconName ? (
          <StyledAbsoluteDiv top={0} right={'36px'}>
            <Icon name={iconName} size={32} />
          </StyledAbsoluteDiv>
        ) : null}
      </StyledButton>
      {isOpen[title] && items.length > 0 ? (
        <StyledDiv paddingBottom={title === TITLE_OTHER_SIZE ? '16px' : 0}>
          {items.map((item, index) => (
            <div key={index}>
              <ButtonSize
                id={`filter-size-${item.value}`}
                isSelected={item.isRefined}
                onClick={() => refine(item.value)}
                data-testid={`filter-size-button-plp-${index}`}
              >
                {isSecondary || title === TITLE_PANTY_SIZE
                  ? item.label?.split('-')?.[1]
                  : item.label}
              </ButtonSize>
            </div>
          ))}
        </StyledDiv>
      ) : null}
    </>
  );
};
