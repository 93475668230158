import React from 'react';
import { Box, SpinnerAndIconLinear } from '../../../design-system';
import { zIndex } from '../../common/constants';

type Props = {
  delay?: number;
  size?: number;
};

export function LoadingScreen({ delay = 50, size = 50 }: Props) {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplay(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
      setDisplay(false);
    };
  }, []);

  if (!display) {
    return null;
  }

  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={'rgba(255,255,255,0.75)'}
      zIndex={zIndex.PRODUCT_QUICK_ADD + 1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <SpinnerAndIconLinear
        color="black"
        size={size}
        stroke={40}
        iconName="Ddarjeeling"
        id="spinner-linear"
      />
    </Box>
  );
}
