import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import { theme, globalStyles } from './constants';

const SystemProvider = ({
  customTheme,
  customStyles,
  children,
}: {
  customTheme?: any;
  customStyles?: any;
  children?: React.ReactNode;
}) => (
  <ThemeProvider theme={{ ...theme, ...customTheme }}>
    <Global
      styles={{
        ...globalStyles,
        ...customStyles,
      }}
    />
    {children}
  </ThemeProvider>
);

export default SystemProvider;
