import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { DynamicButtonStatus } from '../../design-system';
import types from './actionTypes';
import locale from './locale';
import { Order, OrderDetail, User, Address } from './types';
import { Queries, getBFFData, Mutations, hasToken } from '../api';
import { Forms } from '../form/types';
import {
  setFormValues,
  setFormValidation,
  setFeedback,
  FormActions,
  setCtaState,
} from '../form/actions';
import {
  requestCartSuccess,
  requestCartFailure,
  requestShippingTypesSuccess,
  setStep,
} from '../cart/actions';
import { getIdPayload } from '../auth/utils';
import { MSG_SAVED, ERR_NOT_SAVED } from '../form/locale';
import { populateForm } from '../form/utils';
import { CartStep } from '../cart/types';
import { RootState } from '../../store/rootReducer';
import { refreshIfNeeded } from '../cart/utils';
import { requestWishlistSuccess } from '../wishlist/actions';
import { PickupStation } from '../pickup/types';

export const requestOrders = () => action(types.REQUEST_ORDERS, null);
export const requestOrdersSuccess = (payload: Order[]) => action(types.SUCCESS_ORDERS, payload);
export const requestOrdersFailure = (payload: string) => action(types.ERROR_ORDERS, payload);

type OrdersActions = ActionType<
  typeof requestOrders | typeof requestOrdersSuccess | typeof requestOrdersFailure
>;

export const requestOrder = () => action(types.REQUEST_ORDER, null);
export const requestOrderSuccess = (payload: OrderDetail) => action(types.SUCCESS_ORDER, payload);
export const requestOrderFailure = (payload: string) => action(types.ERROR_ORDER, payload);

type OrderActions = ActionType<
  typeof requestOrder | typeof requestOrderSuccess | typeof requestOrderFailure
>;

export const requestUserSuccess = (payload: User) => action(types.SUCCESS_USER, payload);

type UserActions = ActionType<typeof requestUserSuccess | typeof setIsFetching>;

function updateUserInfoForm(user) {
  return async (dispatch: Dispatch<FormActions>) => {
    dispatch(
      setFormValues({
        form: Forms.personal,
        values: populateForm(user, ['firstName', 'lastName', 'email', 'phone', 'dob', 'prefix']),
      })
    );
  };
}

function updateNewsletterForm(user: User) {
  return async (dispatch: Dispatch<FormActions>) => {
    dispatch(
      setFormValues({
        form: Forms.newsletter,
        values: populateForm(user, ['optInSMS', 'optInEmail']),
      })
    );
  };
}

export function getOrder({ orderNumber, migrated }: { orderNumber: number; migrated: boolean }) {
  return async (dispatch) => {
    dispatch(requestOrder());
    const response = await getBFFData(Queries.getOrder, { orderNumber, migrated });
    if (response.ok) {
      dispatch(requestOrderSuccess(response.data.order));
    } else {
      dispatch(requestOrderFailure(response.data));
    }
  };
}

export function loadUserInfo(
  storeId?: string | undefined,
  pickupStation?: PickupStation | undefined
) {
  return async (dispatch) => {
    const response = await getBFFData(Queries.getUser);
    if (response.ok) {
      const { user, cart, shippingTypes, wishlist } = response.data;
      dispatch(updateUser(user));
      if (storeId) {
        const cartWithStoreId = {
          ...cart,
          storeId,
        };
        dispatch(requestCartSuccess(cartWithStoreId));
      }
      if (pickupStation) {
        const cartWithPickupStationId = {
          ...cart,
          pickupStation,
        };
        dispatch(requestCartSuccess(cartWithPickupStationId));
      }
      if (!storeId && !pickupStation) {
        dispatch(requestCartSuccess(cart));
      }
      dispatch(requestShippingTypesSuccess(shippingTypes));
      dispatch(requestWishlistSuccess(wishlist));
    } else {
      dispatch(requestCartFailure(response.data));
      dispatch(requestOrderFailure(response.data));
      const idPayload = await getIdPayload();
      if (idPayload) {
        const { given_name, family_name, website, email } = idPayload;
        const user = {
          firstName: given_name,
          lastName: family_name,
          optInEmail: website === '1',
          email,
        };
        dispatch(updateUser(user));
      }
    }
  };
}

export function loadUserDetails() {
  return async (dispatch) => {
    const response = await getBFFData(Queries.getUserDetails);
    if (response.ok) {
      const { user } = response.data;
      dispatch(updateUser(user));
    }
  };
}

export const setIsFetching = (payload: boolean) => action(types.SET_IS_FETCHING, payload);

function updateUser(user) {
  return async (dispatch) => {
    if (user) {
      dispatch(requestUserSuccess(user));
      dispatch(updateUserInfoForm(user));
      dispatch(updateNewsletterForm(user));
    }
  };
}

export function setDeliveryStep() {
  return async (dispatch, getState: () => RootState) => {
    dispatch(setCtaState({ form: Forms.delivery, ctaState: DynamicButtonStatus.Loading }));
    const isAuthorized = await hasToken();
    if (isAuthorized) {
      dispatch(loadUserInfo());
      dispatch(setStep(CartStep.DELIVERY));
    }

    dispatch(setCtaState({ form: Forms.delivery, ctaState: DynamicButtonStatus.Default }));

    refreshIfNeeded(getState());
  };
}

export function setCartStep() {
  return async (dispatch, getState: () => RootState) => {
    dispatch(setCtaState({ form: Forms.delivery, ctaState: DynamicButtonStatus.Loading }));
    const isAuthorized = await hasToken();
    if (isAuthorized) {
      dispatch(loadUserInfo());
      dispatch(setStep(CartStep.LISTING));
    }

    dispatch(setCtaState({ form: Forms.delivery, ctaState: DynamicButtonStatus.Default }));

    refreshIfNeeded(getState());
  };
}

export function updateUserInfo(user) {
  return async (dispatch) => {
    dispatch(setCtaState({ form: Forms.personal, ctaState: DynamicButtonStatus.Loading }));
    const response = await getBFFData(Mutations.updateUser, { user });
    if (response.ok) {
      const { user } = response.data;
      switch (user.errorCode) {
        case 'ERR_EMAIL_EXISTS':
          dispatch(
            setFormValidation({
              form: Forms.personal,
              values: {
                email: locale[user.errorCode],
              },
            })
          );
          break;
        default:
          dispatch(updateUserInfoForm(user));
          dispatch(
            setFeedback({
              form: Forms.personal,
              ok: true,
              message: MSG_SAVED,
              isDirty: false,
            })
          );
      }
    } else {
      dispatch(
        setFeedback({
          form: Forms.personal,
          ok: false,
          message: ERR_NOT_SAVED,
          isDirty: true,
        })
      );
    }
    dispatch(setCtaState({ form: Forms.personal, ctaState: DynamicButtonStatus.Default }));
  };
}

export function updateNewsletter(user) {
  return async (dispatch) => {
    dispatch(
      setFeedback({
        form: Forms.newsletter,
        ok: true,
        message: '',
        isDirty: false,
      })
    );
    const response = await getBFFData(Mutations.updateUser, { user });
    if (response.ok) {
      const { user } = response.data;
      dispatch(updateNewsletterForm(user));
      dispatch(
        setFeedback({
          form: Forms.newsletter,
          ok: true,
          message: MSG_SAVED,
          isDirty: false,
        })
      );
    }
  };
}

export function updateAddresses(addresses: Address[]) {
  const user = { addresses };
  return async (dispatch) => {
    dispatch(setCtaState({ form: Forms.address, ctaState: DynamicButtonStatus.Loading }));
    const response = await getBFFData(Mutations.updateUser, { user });
    if (response.ok) {
      const { user } = response.data;
      dispatch(requestUserSuccess(user));
      dispatch(
        setFeedback({
          form: Forms.address,
          ok: true,
          message: MSG_SAVED,
          isDirty: false,
        })
      );
    } else {
      dispatch(
        setFeedback({
          form: Forms.address,
          ok: false,
          message: ERR_NOT_SAVED,
          isDirty: true,
        })
      );
    }
    dispatch(setCtaState({ form: Forms.address, ctaState: DynamicButtonStatus.Default }));
  };
}

export function getOrders() {
  return async (dispatch) => {
    dispatch(requestOrders());
    const response = await getBFFData(Queries.getOrders);
    if (response.ok) {
      dispatch(requestOrdersSuccess(response.data.orders));
    } else {
      dispatch(requestOrdersFailure(response.data));
    }
  };
}

export type AccountActions = OrdersActions | OrderActions | UserActions;
