export default {
  REQUEST_ORDERS: 'ACCOUNT / REQUEST_ORDERS',
  ERROR_ORDERS: 'ACCOUNT / ERROR_ORDERS',
  SUCCESS_ORDERS: 'ACCOUNT / SUCCESS_ORDERS',
  REQUEST_ORDER: 'ACCOUNT / REQUEST_ORDER',
  ERROR_ORDER: 'ACCOUNT / ERROR_ORDER',
  SUCCESS_ORDER: 'ACCOUNT / SUCCESS_ORDER',
  SUCCESS_USER: 'ACCOUNT / SUCCESS_USER',
  SET_IS_FETCHING: 'ACCOUNT / SET_IS_FETCHING',
} as const;
