import React, { useState } from 'react';
import { getIconUrl } from '../../../design-system/icon/utils';
import { Box, colors, Text } from '../../../design-system';
import { ToolTip } from '../../common/components/Custom';
import { LBL_EMAIL_PATTERN } from '../locale';
import { TGiftCardInfos } from './Product';

export type TPattern = {
  url: string;
  name: string;
};

export const patternVariants: TPattern[] = [
  {
    url: getIconUrl('gift-card-holly'),
    name: 'holly',
  },
];

type Props = {
  objectID: string;
  onSelectPattern: (pattern: TPattern) => void;
  giftCardInfos: TGiftCardInfos;
};

export default function PatternSelector({ objectID, onSelectPattern, giftCardInfos }: Props) {
  const [selectedPattern, setSelectedPattern] = useState(giftCardInfos.emailPattern);

  const handleSelectPattern = (pattern: TPattern) => {
    setSelectedPattern(pattern);
    onSelectPattern(pattern);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Text whiteSpace="pre-wrap">{`${LBL_EMAIL_PATTERN} : `}</Text>
        <Text
          fontWeight="bold"
          id={`product-modal-${objectID}-color-label`}
          textTransform="capitalize"
        >
          {selectedPattern.name}
        </Text>
      </Box>
      <Box
        display="grid"
        alignItems="center"
        gridGap="m"
        gridTemplateColumns="repeat(9, min-content)"
        gridAutoRows="min-content"
      >
        {(patternVariants ?? []).map((pattern, index) => {
          const isSelected = pattern.name === selectedPattern.name;

          return (
            <Box
              key={index}
              id={`catalog-item-color-${objectID}-${index}`}
              border={isSelected ? `1px solid ${colors.BLACK}` : ''}
              borderRadius="50%"
              onClick={() => handleSelectPattern(pattern)}
            >
              <ToolTip
                background={`url(${pattern.url})`}
                backgroundPosition="center"
                backgroundSize="cover"
                border={isSelected ? '2px solid WHITE' : ''}
                borderRadius="50%"
                size={['20px', '24px', '20px']}
              >
                <Box>
                  <Text preset="caption" color="GREY" withEllipsis textTransform="uppercase">
                    {pattern.name}
                  </Text>
                </Box>
              </ToolTip>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
