import * as React from 'react';

import { SystemProvider } from '../../../design-system';
import { customStyles } from '../constants';

function withSystemProvider(WrappedComponent) {
  const WithSystemProvider = (props) => (
    <SystemProvider customStyles={customStyles}>
      <WrappedComponent {...props} />
    </SystemProvider>
  );

  WithSystemProvider.displayName = `WithSystemProvider(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WithSystemProvider;
}

export default withSystemProvider;
