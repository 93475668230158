import { css } from '@emotion/core';
import React from 'react';
import { useRefinementList } from 'react-instantsearch';

import { Box, Text } from '../../../design-system';
import { sortByCount } from '../../common/utils';
import { Color } from './Color';
import { colors } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = { attribute: string };

export const Colors: React.FC<Props> = ({ attribute }) => {
  const { items, refine } = useRefinementList({
    attribute,
    limit: 50,
    sortBy: ['count:desc'],
  });
  const { isDesktop, isTablet } = useMediaQueries();

  return isDesktop ? (
    <Box
      display="flex"
      ml="xxs"
      flexWrap="wrap"
      css={css`
        gap: 4px;
      `}
    >
      {items?.map((item, index) => (
        <Box
          key={`color-filter-${index}`}
          id={`filter-color-${item.value}`}
          display="flex"
          onClick={() => refine(item.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
              event.preventDefault();
              refine(item.value);
            }
          }}
          css={css`
            :hover {
              #${`filter-color-${item.value}`} {
                border: 3px solid ${item.label === 'Blanc' ? '#e6e6e6' : colors.WHITE};
                box-shadow: 0px 0px 0px ${item.isRefined ? 2 : 1}px ${colors.BLACK};
              }
            }

            :focus-visible {
              outline: 0;

              #${`filter-color-${item.value}`} {
                border: 3px solid ${item.label === 'Blanc' ? '#e6e6e6' : colors.WHITE};
                box-shadow: 0px 0px 0px 2px ${colors.BLACK};
              }
            }
          `}
          tabIndex={0}
          role="button"
        >
          <Box
            display="flex"
            flexWrap="wrap"
            mb="20px"
            mt="s"
            minWidth="50px"
            justifyContent="center"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="40px"
                width="40px"
              >
                <Color
                  id={`filter-color-${item.value}`}
                  label={item.label}
                  isRefined={item.isRefined}
                />
              </Box>
              <Text fontWeight={item.isRefined ? 'bold' : 'colorRegular'}>{item.label}</Text>
              <Text fontWeight={item.isRefined ? 'bold' : 'colorRegular'}>({item.count})</Text>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Box display="flex" justifyContent="center">
      <Box display="flex" flexWrap="wrap" width={isTablet ? '480px' : '320px'}>
        {items?.sort(sortByCount).map((item, index) => (
          <Box
            key={`color-filter-${item.label}-${index}`}
            display="flex"
            width={isTablet ? '120px' : '80px'}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              py="m"
              id={`filter-color-${item.value}`}
              onClick={() => refine(item.value)}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Color
                  id={`filter-color-${item.value}`}
                  label={item.label}
                  isRefined={item.isRefined}
                />
                <Text
                  preset="subheading"
                  fontWeight={item.isRefined ? 'colorBold' : 'colorRegular'}
                >
                  {item.label}
                </Text>
                <Text
                  preset="subheading"
                  fontWeight={item.isRefined ? 'colorBold' : 'colorRegular'}
                >
                  ({item.count})
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
