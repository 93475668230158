import React, { useEffect, useState, useMemo } from 'react';

export const useTextReveal = (
  text: string | undefined,
  isActive: boolean,
  containerRef: React.RefObject<HTMLDivElement | HTMLHeadingElement | HTMLParagraphElement | null>
) => {
  const [isInView, setIsInView] = useState(false);
  const [data, setData] = useState<JSX.Element[]>([]);
  const [visibleCharacters, setVisibleCharacters] = useState(0);
  const [hasBeenRevealed, setHasBeenRevealed] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.target === containerRef.current) {
          if (entry.isIntersecting && !hasBeenRevealed) {
            setIsInView(true);
          } else if (!entry.isIntersecting) {
            setIsInView(false);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (containerRef?.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef?.current) {
        observer.unobserve(containerRef.current);
      }

      observer.disconnect();
    };
  }, [containerRef, hasBeenRevealed]);

  useEffect(() => {
    if (text && isInView && isActive) {
      const characters = text.split('');

      const interval = setInterval(() => {
        setVisibleCharacters((prevVisibleCharacters) => {
          const nextVisibleCharacters = prevVisibleCharacters + 1;
          if (nextVisibleCharacters === characters.length) {
            clearInterval(interval);
            setHasBeenRevealed(true);
          }
          return nextVisibleCharacters;
        });
      }, 15);

      return () => clearInterval(interval);
    }
    return () => null;
  }, [text, visibleCharacters, isInView, isActive]);

  useEffect(() => {
    if ((isInView || visibleCharacters > 0) && isActive) {
      const characters = text ? text.split('') : [];
      const elements = characters.map((character, index) => (
        <span
          key={index}
          style={{
            opacity: index < visibleCharacters ? 1 : 0,
            pointerEvents: 'none',
          }}
          className={`character-${index}`}
        >
          {character}
        </span>
      ));
      setData(elements);
    } else if (!isActive) {
      setData(text ? [<span key={0}>{text}</span>] : []);
    }
  }, [text, visibleCharacters, isInView, isActive]);

  return useMemo(() => ({ isInView, data }), [isInView, data]);
};
