import React from 'react';
import { css } from '@emotion/core';
import { datadogLogs } from '@datadog/browser-logs';

import { CmsProduct } from '../../cms/types';
import { breakpoints, colors } from '../../../design-system';

type Props = {
  cmsProduct: CmsProduct;
  traceability: string;
  productRef: string;
};

export const CsrTable = ({ cmsProduct, traceability, productRef }: Props) => {
  const { title_table_first_line, title_table_second_line, title_table_third_line } =
    cmsProduct ?? {};

  const traceabilityList = traceability.split('\n');

  // @ts-ignore: ts error DisplayNames not exist on typeof Intl
  const regionNames = new Intl.DisplayNames(['fr'], { type: 'region' });

  function getRegionName(regionCode: string): string {
    try {
      return regionNames.of(regionCode) ?? 'Unknown';
    } catch (error) {
      datadogLogs.logger.debug('Debug DAR-3411 case product field traceability country not found', {
        productRef,
        traceability,
      });
      return 'Unknown';
    }
  }
  return (
    <div
      id="csr-traceability-table"
      css={css`
        position: relative;
        background-color: ${colors.WHITE};
        border: solid 1px ${colors.LIGHT};
      `}
    >
      <div
        css={css`
          overflow-x: auto;
          overflow-y: visible;
          margin-left: 175px;
          @media (min-width: ${breakpoints.S}px) {
            margin-left: 200px;
          }
        `}
      >
        <table
          css={css`
            table-layout: fixed;
          `}
        >
          <tbody>
            <tr>
              <th
                css={css`
                  vertical-align: top;
                  padding: 8px 4px;
                  width: 175px;
                  text-align: left;
                  font-size: 1.4rem;
                  line-height: 110%;
                  font-weight: 700;
                  position: absolute;
                  margin-left: -175px;
                  @media (min-width: ${breakpoints.S}px) {
                    padding: 12px 6px;
                    width: 200px;
                    margin-left: -200px;
                  }
                `}
              ></th>
              {traceabilityList?.map((columnName, index) => {
                return (
                  <th
                    key={`Csr-colum-index-${index}`}
                    css={css`
                      vertical-align: top;
                      padding: 24px 4px 8px 4px;
                      width: 175px;
                      text-align: left;
                      font-size: 1.4rem;
                      line-height: 110%;
                      font-weight: 700;
                      @media (min-width: ${breakpoints.S}px) {
                        padding: 24px 6px 12px 6px;
                        width: 200px;
                      }
                    `}
                  >
                    {columnName?.split('|')[0]}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th
                css={css`
                  vertical-align: top;
                  padding: 8px 4px 8px 16px;
                  width: 175px;
                  text-align: left;
                  font-size: 1.4rem;
                  font-weight: 700;
                  position: absolute;
                  margin-left: -175px;
                  @media (min-width: ${breakpoints.S}px) {
                    padding: 12px 6px 12px 16px;
                    width: 200px;
                    margin-left: -200px;
                  }
                `}
              >
                {title_table_first_line ?? ''}
              </th>
              {traceabilityList?.map((element, index) => {
                const dataSplitByPipe = element?.split('|');
                const listOfCountries = dataSplitByPipe?.[1]?.split(',');
                return (
                  <td
                    key={`Csr-table-line-1-index-${index}`}
                    css={css`
                      vertical-align: top;
                      padding: 8px 4px;
                      width: 175px;
                      min-width: 120px;
                      font-size: 1.4rem;
                      line-height: 110%;
                      text-align: left;
                      @media (min-width: ${breakpoints.S}px) {
                        padding: 12px 6px;
                        width: 200px;
                        min-width: auto;
                      }
                    `}
                  >
                    {listOfCountries?.map((el, index) => {
                      if (index + 1 < listOfCountries.length) {
                        return getRegionName(el) + ' ou ';
                      }
                      return getRegionName(el);
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th
                css={css`
                  position: absolute;
                  vertical-align: top;
                  padding: 8px 4px 8px 16px;
                  width: 175px;
                  text-align: left;
                  font-size: 1.4rem;
                  line-height: 110%;
                  font-weight: 700;
                  margin-left: -175px;
                  @media (min-width: ${breakpoints.S}px) {
                    padding: 12px 6px 12px 16px;
                    width: 200px;
                    margin-left: -200px;
                  }
                `}
              >
                {title_table_second_line ?? ''}
              </th>
              {traceabilityList?.map((element, index) => {
                const dataSplitByPipe = element?.split('|');
                const listOfCountries = dataSplitByPipe?.[2]?.split(',');
                return (
                  <td
                    key={`Csr-table-line-2-index-${index}`}
                    css={css`
                      vertical-align: top;
                      padding: 8px 4px;
                      width: 175px;
                      min-width: 120px;
                      font-size: 1.4rem;
                      line-height: 110%;
                      text-align: left;
                      @media (min-width: ${breakpoints.S}px) {
                        padding: 12px 6px;
                        width: 200px;
                        min-width: auto;
                      }
                    `}
                  >
                    {listOfCountries?.map((el, index) => {
                      if (index + 1 < listOfCountries.length) {
                        return getRegionName(el) + ' ou ';
                      }
                      return getRegionName(el);
                    })}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th
                css={css`
                  position: absolute;
                  vertical-align: top;
                  padding: 8px 4px 8px 16px;
                  width: 175px;
                  text-align: left;
                  font-size: 1.4rem;
                  line-height: 110%;
                  font-weight: 700;
                  margin-left: -175px;
                  @media (min-width: ${breakpoints.S}px) {
                    padding: 12px 6px 12px 16px;
                    width: 200px;
                    margin-left: -200px;
                  }
                `}
              >
                {title_table_third_line ?? ''}
              </th>
              {traceabilityList?.map((element, index) => {
                const dataSplitByPipe = element?.split('|');
                const listOfCountries = dataSplitByPipe?.[3]?.split(',');
                return (
                  <td
                    key={`Csr-table-line-3-index-${index}`}
                    css={css`
                      vertical-align: top;
                      padding: 8px 4px;
                      width: 175px;
                      min-width: 120px;
                      font-size: 1.4rem;
                      line-height: 110%;
                      text-align: left;
                      @media (min-width: ${breakpoints.S}px) {
                        padding: 12px 6px;
                        width: 200px;
                        min-width: auto;
                      }
                    `}
                  >
                    {listOfCountries?.map((el, index) => {
                      if (index + 1 < listOfCountries.length) {
                        return getRegionName(el) + ' ou ';
                      }
                      return getRegionName(el);
                    })}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
