import * as React from 'react';

import { Box, Icon, Text } from '../../../design-system';
import { ARRANGE_APPOINTMENT_TXT } from '../locale';

type Props = {
  name: string;
  phone: string;
};
const StoreContact = ({ name, phone }: Props) => {
  return (
    <Box border="1px solid BLACK" p={['s', 'm']} display="grid" gridGap="m" textAlign="center">
      <Box>
        <Text preset={['subheading', 'heading']}>{ARRANGE_APPOINTMENT_TXT}</Text>
        <Text preset={['subheading', 'heading']} fontWeight="bold">
          {name}
        </Text>
      </Box>

      <Text preset={['subheading', 'heading']} fontWeight="bold">
        {phone}
      </Text>
      <Box display="grid" gridAutoFlow="column" gridGap="m" m="auto">
        {['Gratuit', "Sans Obligation d'achat"].map((item, index) => (
          <Box key={index} display="flex">
            <Icon name="checkmarkCircle" />
            <Text ml="s">{item}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StoreContact;
