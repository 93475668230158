import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { Box, Text } from '../../../design-system';
import { Title } from '../../common/components/Custom';

const StorePromotions = () => {
  const { promotions, promotions_title, promotions_subtitle } = useSelector(
    (state: RootState) => state.cms.storelocator
  );

  return (
    <Box textAlign="center">
      <Title>{promotions_title}</Title>
      {promotions_subtitle && (
        <Text color="GREY" mt="s">
          {promotions_subtitle}
        </Text>
      )}
      <Box
        display="grid"
        gridGap="m"
        mx={['na', 'auto']}
        px={['m', 'na']}
        my="m"
        gridAutoFlow="column"
        gridTemplateColumns="1fr 1fr 1fr"
        maxWidth="mwl"
        width={['auto', 'calc(100vw - 200px)']}
        overflowX="auto"
      >
        {promotions
          .slice(0, 3)
          .map(({ promotion_image, promotion_title, promotion_subtitle }, index) => (
            <Box
              display="grid"
              height={['42vh', '34vw', '34vw', '830px']}
              width={['50vw', 'auto']}
              backgroundImage={promotion_image.url ? `url(${promotion_image?.url ?? ''})` : ''}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="cover"
              key={index}
            >
              <Box m="s" justifySelf="center" alignSelf="center">
                <Text preset={['heading', 'heading', 'bannerSmall']} color="WHITE">
                  {promotion_title}
                </Text>
                {promotion_subtitle && (
                  <Text preset="body" color="WHITE">
                    {promotion_subtitle}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default StorePromotions;
