export type State = {
  storesByLocation: StoreList;
  errMsg: string;
  isFetching: boolean;
  allStores: Store[];
};

export type StoreList = {
  geoCoordinate: StoreCoordinate;
  stores: Store[];
  totalCount: number;
};

export enum PaymentOptions {
  CASH = 'CASH',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  CHECK = 'CHECK',
  GIFTSCARD = 'GIFTSCARD',
}

export enum StoreService {
  eReservation = 'eReservation',
  clickCollect = 'clickCollect',
  wheelchair = 'wheelchair',
  parking = 'parking',
}

export type Store = {
  id: string;
  name?: string;
  storeType?: string;
  brands?: string[];
  address?: StoreAddress;
  isoRegionCode?: string;
  mainPhone?: string;
  websiteUrl?: string;
  timezone?: string;
  timeZoneUtcOffset?: string;
  displayCoordinate: StoreCoordinate;
  routableCoordinate: StoreCoordinate;
  featuredMessage?: string;
  hours?: StoreHours;
  photoGallery: StorePhotoGallery[];
  paymentOptions: PaymentOptions[];
  services: StoreService[];
};

export type StorePhotoGallery = {
  url: string;
  width: string;
  height: string;
};

export type StoreAddress = {
  line1?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
};

export type StoreCoordinate = {
  latitude: number;
  longitude: number;
};

export type StoreHours = {
  monday?: StoreHourDayDescription;
  tuesday?: StoreHourDayDescription;
  wednesday?: StoreHourDayDescription;
  thursday?: StoreHourDayDescription;
  friday?: StoreHourDayDescription;
  saturday?: StoreHourDayDescription;
  sunday?: StoreHourDayDescription;
  holidayHours?: StoreHolidayHoursDescription[];
  reopenDate?: string;
};

export type StoreHourDayDescription = {
  openIntervals?: StoreInterval[];
  isClosed?: boolean;
};

export type StoreHolidayHoursDescription = {
  openIntervals?: StoreInterval[];
  isClosed?: boolean;
  date?: string;
  isRegularHours?: boolean;
};

export type StoreInterval = {
  start?: string;
  end?: string;
};

export type Range = {
  from: string;
  to: string;
};

export type OpeningHours = {
  day: string;
  open: string[];
  close: string[];
  endDay?: string;
};

export type Position = {
  lat: number;
  lng: number;
};

export type Bounds = {
  northeast: Position;
  southwest: Position;
};

export enum ZoomLevels {
  AREA = 17,
  DISTRICT = 13,
  CITY = 10,
  COUNTRY = 5,
}

export enum MapEvents {
  dragend = 'dragend',
  zoomChanged = 'zoom_changed',
}

export enum StoreModalMobileSteps {
  LISTING,
  MAP,
}

export type Suggestion = {
  name: string;
};

export enum StoresListType {
  DELIVERY_STORE = 'DELIVERY_STORE',
  ERESERVATION_STORE = 'ERESERVATION_STORE',
  STORE_LOCATOR = 'STORE_LOCATOR',
}

export type MapState = {
  loading: boolean;
  map?: google.maps.Map;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
  autocomplete?: google.maps.places.AutocompleteService;
  maps?: typeof google.maps;
};
