export const TITLE_BRA_SIZE = 'soutiens-gorge';
export const SUBTITLE_BRA_SIZE = 'De A à H';
export const SUBTITLE_BRA_UNIVERSAL_SIZE_NUMBER = 'De 36 à 48';
export const SUBTITLE_BRA_UNIVERSAL_SIZE_ALPHA = 'Taille universelle Soutiens-gorge';
export const TITLE_PANTY_SIZE = 'bas';
export const SUBTITLE_PANTY_SIZE = 'De 36 à 48';
export const SUBTITLE_PANTY_UNIVERSAL_SIZE = 'Taille universelle Bas';
export const TITLE_CUPONLY_SIZE = 'bonnet';
export const TITLE_TIGHT_SIZE = 'collants';
export const SUBTITLE_TIGHT_SIZE = 'De 1 à 4';
export const TITLE_OTHER_SIZE = 'autre';
export const TITLE_UNIVERSAL_SIZE = 'taille universelle';
export const SUBTITLE_UNIVERSAL_SIZE = 'Haut et Bas';
