import { authState } from './state';
import types from './actionTypes';
import { AuthActions } from './actions';
import { State } from './types';

export default function cart(state = authState, action: AuthActions): State {
  switch (action.type) {
    case types.REQUEST_LOGIN:
    case types.REQUEST_SIGNUP:
    case types.SUCCESS_LOGOUT:
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        errLogin: '',
        errSignup: '',
        errSocial: '',
      };
    case types.SUCCESS_LOGIN:
    case types.SUCCESS_SIGNUP:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        errLogin: '',
        errSignup: '',
        errSocial: '',
      };
    case types.ERROR_LOGIN:
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        errLogin: action.payload,
        errSignup: '',
        errSocial: '',
      };
    case types.ERROR_SIGNUP:
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        errLogin: '',
        errSignup: action.payload,
        errSocial: '',
      };
    case types.ERROR_SOCIAL:
      return {
        ...state,
        user: undefined,
        isLoggedIn: false,
        errLogin: '',
        errSignup: '',
        errSocial: action.payload,
      };
    case types.SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case types.SET_USER_EXIST:
      return {
        ...state,
        userExist: action.payload,
      };
    case types.SET_HAS_FORGOT_PASSWORD:
      return {
        ...state,
        hasForgotPassword: action.payload,
      };
    default:
      return state;
  }
}
