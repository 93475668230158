import * as React from 'react';

import { Box, Icon } from '../../../design-system';
import { Store as StoreType } from '../types';
import OpeningHours from './OpeningHours';

export type Props = {
  store: StoreType;
  onClick: (id: string) => void;
};

const SelectedStore = ({ store, onClick }: Props) => {
  const { id, name, hours, address } = store ?? {};
  const { line1, line2, postalCode, city } = address ?? {};

  const handleClick = () => {
    onClick(id ?? '');
  };

  return (
    <Box
      id={`store-${id}-box`}
      onClick={handleClick}
      p="m"
      bg="#F9F9F9"
      data-cy="store-delivery-selected-edition"
    >
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Box
          id={`store-${id}-name`}
          fontSize="1.4rem"
          fontWeight={700}
          mb="s"
          textTransform="uppercase"
        >
          {name ?? ''}
        </Box>
        <Icon name="edit" size={16} />
      </Box>
      {line2 && (
        <Box fontSize="1.2rem" lineHeight="110%" mt="s">
          {line2}
        </Box>
      )}
      <Box fontSize="1.2rem" lineHeight="110%" mt={line1 ? 'xs' : 's'} mb="s">
        {line1 ? `${line1}, ${postalCode} ${city}` : `${postalCode} ${city}`}
      </Box>
      <OpeningHours hours={hours ?? {}} isShortenedVersion />
    </Box>
  );
};

export default SelectedStore;
