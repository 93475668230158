import { getBFFData, Mutations } from '../api';

export async function subscribeNewsletter({
  mail,
  origin,
}: {
  mail: string;
  origin: string;
}): Promise<boolean> {
  const response = await getBFFData(Mutations.subscribeNewsletter, {
    payload: { MAIL: mail, ORIGIN: origin },
  });
  return response.data.subscribeNewsletters?.success;
}
