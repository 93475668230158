import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import { Box, Field, Button, Icon, colors, opacities } from '../../../design-system';
import { YOUR_POSITION } from '../../store-locator/locale';

type Props = {
  onIconClick: () => void;
  onChange: (value: string) => void;
  onClick: () => void;
  setForceAutcompleteClosing?: (value: boolean) => void;
  value: string;
  errMsg: string;
  placeholder?: string;
  isPositioned?: boolean;
  isPickupDelivery: boolean;
};

const Search = ({
  onIconClick,
  onChange,
  onClick,
  setForceAutcompleteClosing = () => null,
  value,
  errMsg,
  placeholder,
  isPositioned,
  isPickupDelivery,
}: Props) => {
  const onKeyPress = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      setForceAutcompleteClosing(true);
      onIconClick();
    }
  };

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  return (
    <form autoComplete="off">
      <Box display="flex">
        <Box width="100%" mr="s">
          <Field
            id="pickup-input-field"
            icon="search"
            iconSize={16}
            iconMargin="0"
            autoComplete="off"
            placeholder={isPositioned ? YOUR_POSITION : placeholder}
            iconOpacity={isPositioned ? 1 : opacities.DISABLED}
            isDelivery={isPickupDelivery}
            customClick={onIconClick}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            errMsg={errMsg}
          />
        </Box>
        <Box width="50px">
          <StyledButton id="pickup-search-button" onClick={handleClick}>
            <Icon name="mapMarkerFull" size={24} color={colors.WHITE} />
          </StyledButton>
        </Box>
      </Box>
    </form>
  );
};

const StyledButton = styled(Button)(
  css({
    height: ['50px', '50px'],
  })
);

export default Search;
