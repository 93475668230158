import * as React from 'react';
import { Icon, Text, Box } from '../../../design-system';
import { CmsImage } from '../../cms/types';

type Props = {
  reassurance: {
    image?: CmsImage;
    text: string;
    icon: string;
  }[];
  reassurances: {
    reassurance_icon?: CmsImage;
  }[];
};

export const Reassurance = ({ reassurance, reassurances }: Props) => {
  return (
    <Box display="flex" flexDirection="column-reverse" gridGap="m" my={['m', 'na']}>
      {reassurance.map(({ text, icon }, index) => (
        <Box
          key={index}
          display="flex"
          flexWrap="wrap"
          gridGap="m"
          gridTemplateAreas={[
            '"icon text" ". image"',
            '"icon text" ". image"',
            '"icon text image"',
          ]}
          alignItems="center"
          justifyContent="left"
        >
          <Box gridArea="icon">
            <Icon name={icon} size={20} />
          </Box>
          <Text gridArea="text">{text}</Text>
          {index === 0 && (
            <Box display="flex" flexWrap="wrap" gridGap="8px" mt="m">
              {reassurances.map((img) => (
                <Box
                  key={img?.reassurance_icon?.url}
                  gridArea="image"
                  height="l"
                  width={`calc(24px * ${
                    (img?.reassurance_icon?.dimensions?.width ?? 0) /
                    (img?.reassurance_icon?.dimensions?.height ?? 1)
                  })`}
                  background={`url(${img?.reassurance_icon?.url ?? ''}) no-repeat center / contain`}
                />
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
