import paths from '../../modules/routing/paths';
import { BreadcrumbsItem } from './Breadcrumbs';

const baseUrl =
  process.env.DEPLOYMENT_ENV === 'production'
    ? 'https://www.darjeeling.fr'
    : 'https://stg.new.darjeeling.fr';

const getAbsoluteUrl = ({ baseUrl, path }: { baseUrl: string; path: string }): string => {
  return new URL(path, baseUrl).href;
};

export const getJsonLdDataForBreadcrumb = ({
  items,
  pathname,
}: {
  items: BreadcrumbsItem[];
  pathname: string;
}) => {
  const isProductPage = pathname.startsWith(paths.PRODUCT);
  const isSelectionPage = pathname.startsWith(paths.SELECTION);
  const breadcrumbName = `Breadcrumb ${
    isProductPage ? 'product' : isSelectionPage ? 'selections' : 'catalogue'
  } page`;

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: breadcrumbName,
    itemListElement: items.map((item, index) => {
      const listItem: any = {
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
      };

      if (!item.readonly) {
        listItem.item = {
          '@type': 'WebPage',
          '@id': getAbsoluteUrl({ baseUrl, path: item.value }),
        };
      }

      return listItem;
    }),
  };
};
