import * as React from 'react';

import { breakpoints } from '../../../design-system';
import { CmsHomepageTitle } from '../../cms/types';
import styled from '@emotion/styled';

type Props = {
  content: CmsHomepageTitle | undefined;
};
const StyledTitle = styled.div<{
  alignment?: string;
  font_family?: string;
  font_size_desktop?: number;
  font_size_mobile?: number;
}>`
  margin: 0 16px;
  @media (min-width: ${breakpoints.S - 1}px) {
    margin: 0;
  }
  .title__banner {
    font-family: ${(props) => props?.font_family};
    font-size: ${(props) => (props?.font_size_mobile ? `${props.font_size_mobile}px` : '32px')};
    line-height: ${(props) => (props?.font_size_mobile ? `${props.font_size_mobile}px` : '32px')};
    text-align: ${(props) => props?.alignment || 'center'};
    margin: 0;
    @media (min-width: ${breakpoints.S - 1}px) {
      font-size: ${(props) => (props?.font_size_desktop ? `${props.font_size_desktop}px` : '48px')};
      line-height: ${(props) =>
        props?.font_size_desktop ? `${props.font_size_desktop}px` : '48px'};
    }
  }
`;

export const Title = ({ content }: Props) => {
  if (!content) {
    return null;
  }
  const { alignment, text, font_family, font_size_desktop, font_size_mobile } = content ?? {};
  return (
    <StyledTitle
      alignment={alignment}
      font_family={font_family}
      font_size_desktop={font_size_desktop}
      font_size_mobile={font_size_mobile}
    >
      <h2 className="title__banner">{text}</h2>
    </StyledTitle>
  );
};
