import React from 'react';
import styled from '@emotion/styled';

import { breakpoints } from '../../../../design-system';
import { CmsImage } from '../../../cms/types';
import { Product } from '../../../product/types';
import { ProductCard } from '../../../catalog/components/ProductCard';
import { CatalogProduct } from '../../../catalog/types';

type Props = {
  hits: Product[];
  products: { ref: string; customImage: CmsImage | undefined; position: number }[];
};

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 0 16px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.M}px) {
    width: calc(50% - 40px);
  }
`;

export const CollectionBannerResult = ({ hits, products }: Props) => {
  return (
    <StyledContainer id="collection-products">
      {hits
        .sort((a, b) => {
          const aPosition = products.find(
            (p) => p.ref === `${a.productRef}-${a.colorRef}`
          )?.position;
          const bPosition = products.find(
            (p) => p.ref === `${b.productRef}-${b.colorRef}`
          )?.position;
          if (aPosition && bPosition) {
            return aPosition - bPosition;
          }
          return 0;
        })
        .map((hit) => {
          const product = {
            ...hit,
            objectID: `${hit.productRef}/${hit.colorRef}`,
            rcFilter: `${hit.productRef}-${hit.colorRef}`,
          };
          const customImage = products.find(
            (product) => product.ref === `${hit.productRef}-${hit.colorRef}`
          )?.customImage;
          return (
            <ProductCard
              key={`${hit.productRef}-${hit.colorRef}`}
              hit={product as unknown as CatalogProduct}
              alternativeSource={customImage}
              width="calc(50% - 8px)"
            />
          );
        })}
    </StyledContainer>
  );
};
