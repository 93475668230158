import { useSortBy } from 'react-instantsearch';
import React from 'react';

import { Box, Dropdown, Button } from '../../../design-system';
import { indices } from '../../search';
import { sortIndices } from '../constants';

export type Props = {
  currentRefinement: string;
  refine: (value: string) => void;
  createURL: (value: string) => string;
};

export const SortSelector: React.FC = () => {
  const { currentRefinement, refine } = useSortBy({ items: sortIndices });
  const handleChange = (value) => {
    refine(value);
  };

  const [defaultIndex] = sortIndices;

  return (
    <Box display="grid" gridAutoFlow="column" gridGap="xs" alignItems="center" width="160px">
      <Dropdown
        id="sort-selector"
        options={
          currentRefinement === defaultIndex.value
            ? [
                { ...defaultIndex, label: 'Trier par', hidden: true },
                sortIndices[1],
                sortIndices[2],
                sortIndices[3],
                sortIndices[4],
              ]
            : currentRefinement === indices.T2S
            ? [
                { ...sortIndices[5], label: 'Trier par', hidden: true },
                sortIndices[1],
                sortIndices[2],
                sortIndices[3],
                sortIndices[4],
              ]
            : [
                sortIndices[1],
                sortIndices[2],
                sortIndices[3],
                sortIndices[4],
                {
                  ...defaultIndex,
                  label: (
                    <Box mr="-8px" width="100%">
                      <Button id="sort-selector-clear" preset="subtle">
                        Réinitialiser
                      </Button>
                    </Box>
                  ),
                },
              ]
        }
        selected={currentRefinement}
        onChange={handleChange}
        background="WHITE"
      />
    </Box>
  );
};
