import * as React from 'react';
import { StyledAccordion } from './accordion.style';
import AccordionItem, { AccordionItemType } from './components/AccordionItem';
type Props = {
  id: string;
  items: AccordionItemType[];
  focus?: number;
  isTitleCentered?: boolean;
  isPLPFilter?: boolean;
  noCollapse?: boolean;
};
const Accordion = ({
  id,
  items = [],
  focus = 0,
  isTitleCentered,
  isPLPFilter,
  noCollapse,
  ...rest
}: Props) => {
  const [open, setOpen] = React.useState(focus);
  const children = items.map((item) => {
    return item.title ? (
      <AccordionItem
        key={item.index}
        id={`${id}-child-${item.index}`}
        index={item.index}
        item={item}
        isOpen={item.index === open}
        isTitleCentered={isTitleCentered}
        isPLPFilter={isPLPFilter}
        onClick={() => setOpen(open === item.index ? -1 : item.index)}
        noCollapse={noCollapse}
      />
    ) : null;
  });
  return (
    <StyledAccordion id={id} {...rest}>
      {children}
    </StyledAccordion>
  );
};
export default Accordion;
