import * as qs from 'qs';

import { BreadcrumbsItems } from './types';
import paths from '../routing/paths';
import { CmsCatalogBanner, CmsPromotion, CmsSliderItem } from '../cms/types';
import { capitalize } from '../ecart/utils';
import { colors } from '../../design-system';

export function toProperCase(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function getCatalogValue(name: string) {
  return name ? removeAccents(name.replace(/\s/g, '+').toLocaleLowerCase()) : '';
}

export function getCatalogLabel(name: string) {
  return name ? toProperCase(name.replace(/\+/g, ' ').toLowerCase()) : '';
}

export function getBreadcrumbItems(items: string[]): BreadcrumbsItems {
  return [
    { label: getCatalogLabel(items[0]), value: `${paths.CATALOG}/${getCatalogValue(items[0])}` },
    {
      label: getCatalogLabel(items[1]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(items[1])}`,
    },
    {
      label: getCatalogLabel(items[2]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(
        items[1]
      )}/${getCatalogValue(items[2])}`,
    },
    {
      label: getCatalogLabel(items[3]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(
        items[1]
      )}/${getCatalogValue(items[2])}/${getCatalogValue(items[3])}`,
    },
  ].filter((item) => Boolean(item.label));
}

export function getCatalogId(ids: string[]): string {
  return removeAccents(
    (ids ?? [])
      .reduce(
        (accumulator, id) => (id ? `${accumulator}_${id.replace(/\+/g, '_')}` : accumulator),
        ''
      )
      .substring(1)
      .toLowerCase()
  );
}

export function getCatalogName(ids: string[]): string {
  return (ids ?? []).reduce((accumulator, id) =>
    id ? `${accumulator} - ${getCatalogLabel(id)}` : accumulator
  );
}

export function parseSearch(search: string) {
  return qs.parse(search.slice(1)) ?? {};
}

export function getPage(search: string): number {
  const parsedSearch = parseSearch(search);
  return Number.parseInt(parsedSearch?.page ?? '1', 10);
}

export function getPromotionColors({
  cmsContent,
  promotionPercentage,
  promotionTextColor,
  promotionBackgroundColor,
  isOutlet,
}: {
  cmsContent?: CmsPromotion;
  promotionPercentage?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  isOutlet?: boolean;
}): { textColor: string; backgroundColor: string } {
  const { discount_percentages, label_text_color, label_background_color } = cmsContent ?? {};

  const { percentageTextColor, percentageBackgroundColor } = getPercentageColors({
    discount_percentages,
    promotionPercentage,
  });

  return {
    textColor:
      promotionPercentage && !isOutlet
        ? percentageTextColor
        : promotionTextColor || label_text_color || '',
    backgroundColor:
      promotionPercentage && !isOutlet
        ? percentageBackgroundColor
        : promotionBackgroundColor || label_background_color || '',
  };
}

export function getPercentageColors({
  discount_percentages,
  promotionPercentage,
}: {
  discount_percentages?: {
    text_color?: string | undefined;
    background_color?: string | undefined;
  }[];
  promotionPercentage?: string;
}): { percentageTextColor: string; percentageBackgroundColor: string } {
  const numberOfPercentages = discount_percentages?.length;
  if (
    discount_percentages &&
    numberOfPercentages &&
    numberOfPercentages > 0 &&
    promotionPercentage
  ) {
    const increments = 100 / numberOfPercentages;
    const numberDiscountPercentage = Number.parseFloat(promotionPercentage);
    const indexAtPercentages = Math.trunc(numberDiscountPercentage / increments);
    const { text_color, background_color } =
      discount_percentages[
        indexAtPercentages > numberOfPercentages - 1 ? numberOfPercentages - 1 : indexAtPercentages
      ] ?? {};
    return {
      percentageTextColor: text_color || '',
      percentageBackgroundColor: background_color || '',
    };
  }
  return { percentageTextColor: '', percentageBackgroundColor: '' };
}

export const getGridColumn = ({
  type,
  colIndex = '1',
  colPosition = 'left',
  isLargeDesktop,
}: {
  type: CmsCatalogBanner['banner_type'];
  colIndex?: CmsCatalogBanner['banner_column'];
  colPosition?: CmsCatalogBanner['banner_column_position'];
  isLargeDesktop: boolean;
}) => {
  const numberOfColumns = isLargeDesktop ? 4 : 2;
  const colStart = parseInt(colIndex || '1', 10);

  const colGridStart = colStart > numberOfColumns ? numberOfColumns : colStart;
  const colGridEnd = colStart + 1 > numberOfColumns ? -1 : colStart + 1;

  switch (type) {
    case 'one tile':
      return `${colGridStart} / ${colGridEnd}`;
    case 'full column':
    case 'highlighted':
      return '1 / -1';
    case 'half column':
      return colPosition === 'right' ? '-3 / -1' : '1 / 3';
    default:
      return `${colGridStart} / ${colGridEnd}`;
  }
};

export const findItemToActivate = ({
  items,
  pathname,
  hash,
  setActiveIndex,
}: {
  items: CmsSliderItem[];
  pathname: string;
  hash: string;
  setActiveIndex: (value: number) => void;
}): void => {
  items.forEach((item: CmsSliderItem, index: number) => {
    if (item.slider_with_number_link?.url) {
      try {
        const itemUrl = new URL(item.slider_with_number_link.url);
        const canBeActive = itemUrl.pathname === pathname;
        if (itemUrl.href.includes(hash) && canBeActive) {
          setActiveIndex(index);
        }
      } catch {
        // Do nothing intentionally
      }
    }
  });
};

export const getMetaTitleForPLP = ({
  name,
  page,
  seotitle,
  defaultTitle,
}: {
  name?: string;
  page: number;
  seotitle?: string;
  defaultTitle: string;
}): string => {
  if (seotitle) {
    if (page > 1) {
      const [firstPart, secondPart] = seotitle.split('|');
      return `${firstPart}- Page ${page} |${secondPart}`;
    }
    return seotitle;
  }
  if (name) {
    const formatedName = capitalize(name.replace(/[+-]/g, ' '));
    if (page > 1) {
      return `${formatedName} - Page ${page} | ${defaultTitle}`;
    }
    return `${formatedName} | ${defaultTitle}`;
  }
  if (page > 1) {
    return `Catalogue - Page ${page} | ${defaultTitle}`;
  }
  return `Catalogue | ${defaultTitle}`;
};

export const getColorBorder = ({
  isSelected,
  isWhitish,
}: {
  isSelected: boolean;
  isWhitish: boolean;
}): string => {
  if (isSelected) {
    return `1px solid ${colors.BLACK}`;
  }
  if (isWhitish) {
    return `1px solid ${colors.LIGHT}`;
  }
  return 'none';
};
