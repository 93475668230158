import * as React from 'react';
import { useDispatch } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { Box, Icon } from '../../../design-system';
import { BannerVideo } from '../components/BannerVideo';
import { BannerText } from '../components/BannerText';
import { getIsClient } from '../../common/utils';
import { CmsCatalog } from '../../cms/types';
import useOnScreen from '../../common/hooks/useOnScreen';
import { setIsBannerOnScreen } from '../../catalog/actions';
import { Timer } from '../../cms';
import { getImageHeight } from '../utils';
import { ColorText } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

if (getIsClient()) {
  smoothscroll.polyfill();
}

type Props = {
  cmsContent: CmsCatalog;
};

export const MainBanner = ({ cmsContent }: Props) => {
  const dispatch = useDispatch();
  const divRef = React.useRef<HTMLDivElement>(null);
  const INTERSECTION_OFFSET = '-54px';
  const isOnScreen = useOnScreen(divRef, INTERSECTION_OFFSET);
  const { isDesktop } = useMediaQueries();

  React.useEffect(() => {
    dispatch(setIsBannerOnScreen(isOnScreen));
  }, [isOnScreen]);

  const {
    image,
    image_mobile,
    image_position,
    justification,
    alignment,
    text_color,
    text,
    text_right,
    text_up,
    cta_text,
    background_color,
    video,
    is_chevron_hidden,
    promotion_end_date_time: timestamp,
  } = cmsContent ?? {};

  const handleClick = () => {
    window.scrollTo({
      top: (divRef?.current?.scrollHeight ?? 700) + 100,
      behavior: 'smooth',
    });
  };

  function getFirstTextLineHeigh() {
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-80pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-80pt')
    ) {
      return 54;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-50pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-50pt')
    ) {
      return 44;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-40pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-40pt')
    ) {
      return 34;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-30pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-30pt')
    ) {
      return 24;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-20pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-20pt')
    ) {
      return 20;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-16pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-16pt')
    ) {
      return 18;
    }
    if (
      (text && text[0]?.spans[0]?.data?.label === 'Ogg-text-12pt') ||
      (text && text[0]?.spans[0]?.data?.label === 'MaisonNeue-12pt')
    ) {
      return 16;
    }
    return 0;
  }

  function getLastTextLineHeigh() {
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-80pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-80pt')
    ) {
      return 54;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-50pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-50pt')
    ) {
      return 44;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-40pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-40pt')
    ) {
      return 34;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-30pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-30pt')
    ) {
      return 24;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-20pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-20pt')
    ) {
      return 20;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-16pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-16pt')
    ) {
      return 18;
    }
    if (
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'Ogg-text-12pt') ||
      (text &&
        text.length > 1 &&
        text[text.length - 1]?.spans[0]?.data?.label === 'MaisonNeue-12pt')
    ) {
      return 16;
    }
    return 0;
  }

  const difLineHeightFirstLast = getFirstTextLineHeigh() - getLastTextLineHeigh();

  let fontFirstElement = '';
  text && text[0]?.spans[0]?.data?.label !== ''
    ? (fontFirstElement = text[0]?.spans[0]?.data?.label.slice(0, -5))
    : (fontFirstElement = '');

  function getAdjustedPaddingOggText() {
    if (difLineHeightFirstLast < -20) {
      return '16px';
    }
    if (difLineHeightFirstLast >= -20 && difLineHeightFirstLast <= -8) {
      return '12px';
    }
    if (difLineHeightFirstLast > -8 && difLineHeightFirstLast < 8) {
      return '10px';
    }
    if (difLineHeightFirstLast >= 8 && difLineHeightFirstLast <= 20) {
      return '8px';
    }
    if (difLineHeightFirstLast > 20 && difLineHeightFirstLast < 34) {
      return '6px';
    }
    return '4px';
  }

  function getAdjustedPaddingMaisonNeue() {
    if (difLineHeightFirstLast <= -20) {
      return '18px';
    }
    if (difLineHeightFirstLast > -20 && difLineHeightFirstLast < -8) {
      return '16px';
    }
    if (
      difLineHeightFirstLast === 0 &&
      (getFirstTextLineHeigh() === 44 || getFirstTextLineHeigh() === 54)
    ) {
      return '14px';
    }
    if (
      (difLineHeightFirstLast >= 14 && difLineHeightFirstLast <= 18) ||
      difLineHeightFirstLast === 8
    ) {
      return '10px';
    }
    if (difLineHeightFirstLast >= -8 && difLineHeightFirstLast <= 20) {
      return '12px';
    }
    return '8px';
  }

  function getAdjustedPaddingOnlyOneLine() {
    if (fontFirstElement !== 'MaisonNeue') {
      if (getFirstTextLineHeigh() >= 16 && getFirstTextLineHeigh() < 44) {
        return '9px';
      }
      if (getFirstTextLineHeigh() >= 44 && getFirstTextLineHeigh() <= 54) {
        return '11px';
      }
    }
    if (fontFirstElement === 'MaisonNeue') {
      if (getFirstTextLineHeigh() >= 16 && getFirstTextLineHeigh() <= 18) {
        return '10px';
      }
      if (getFirstTextLineHeigh() > 18 && getFirstTextLineHeigh() < 34) {
        return '12px';
      }
      if (getFirstTextLineHeigh() >= 34 && getFirstTextLineHeigh() <= 44) {
        return '14px';
      }
      if (getFirstTextLineHeigh() === 54) {
        return '16px';
      }
    }
    return 's';
  }

  const isFullWidth = image_position === 'full';

  const gridTemplateAreas =
    image_position === 'left'
      ? "'space1 content'"
      : image_position === 'right'
        ? "'content space1'"
        : justification === 'right'
          ? "'space1 space2 content'"
          : justification === 'left'
            ? "'content space1 space2'"
            : "'space1 content space2'";

  const gridTemplateColumns = isFullWidth ? '1fr 1fr 1fr' : '1fr 1fr';

  const { full, half } = image ?? {};

  const url = isDesktop
    ? isFullWidth
      ? (full?.url ?? '')
      : (half?.url ?? '')
    : (image_mobile?.url ?? '');

  const backgroundColor = background_color || 'BACKGROUND';

  const hasText = text?.[0]?.text || text_right?.[0]?.text || text_up?.[0]?.text;

  const width = isDesktop ? (isFullWidth ? '100%' : '50%') : '100vw';

  const height = isDesktop
    ? '50vh'
    : video
      ? 'auto'
      : getImageHeight({ image: image_mobile, width });

  const MIN_DESKTOP_HEIGHT = '300px';

  const textColor = text_color || 'WHITE';

  const fragments = {
    cta: is_chevron_hidden ? null : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexDirection="column"
        mb={['s', 'l']}
      >
        <ColorText preset="caption" cmsColor={textColor} mb="s">
          {cta_text}
        </ColorText>
        <Icon name="chevronDown" size={20} color={textColor} />
      </Box>
    ),
    text: hasText ? (
      <BannerText
        textColor={textColor}
        text_up={text_up}
        text_right={text_right}
        text={text}
        isDesktop={isDesktop}
      />
    ) : null,
    video: video ? <BannerVideo video={video} /> : null,
    timer: timestamp ? (
      <Box mt="m" mb="xxl" mx={isDesktop ? 'initial' : 'm'}>
        <Timer timestamp={timestamp} textColor={textColor} preset="bannerLarge" />
      </Box>
    ) : null,
  };

  return (
    <Box
      ref={divRef}
      onClick={handleClick}
      mx={['na', '-24px', 'na']}
      mb={!isDesktop && !isOnScreen ? 'na' : ['m', 'l', 'xl']}
    >
      {isDesktop ? (
        <Box height={height} minHeight={MIN_DESKTOP_HEIGHT} backgroundColor={backgroundColor}>
          {video ? (
            fragments.video
          ) : (
            <Box position="relative" backgroundColor={backgroundColor} display="flex">
              <Box
                backgroundImage={`url(${url})`}
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundPosition="top"
                width={width}
                height={height}
                minHeight={MIN_DESKTOP_HEIGHT}
                ml={image_position === 'right' ? 'auto' : 'na'}
              />

              <Box
                position="absolute"
                display="grid"
                mx="auto"
                width="100%"
                height={height}
                textAlign={alignment}
                gridTemplateAreas={gridTemplateAreas}
                gridTemplateColumns={gridTemplateColumns}
              >
                <Box gridArea="space1" />
                {image_position === 'full' && <Box gridArea="space2" />}
                <Box
                  position="relative"
                  gridArea="content"
                  width="100%"
                  display="grid"
                  gridAutoFlow="row"
                  justifyContent="center"
                  height="100%"
                  px="xxxl"
                  py="m"
                >
                  {fragments.text}
                  {fragments.timer}
                  <Box position="absolute" bottom={0} justifySelf="center">
                    {fragments.cta}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          {hasText ? (
            <Box
              backgroundColor={backgroundColor}
              pt={
                text_right && text_right?.[0]?.text !== ''
                  ? 's'
                  : getLastTextLineHeigh() === 0
                    ? getAdjustedPaddingOnlyOneLine()
                    : fontFirstElement === 'MaisonNeue'
                      ? getAdjustedPaddingMaisonNeue()
                      : getAdjustedPaddingOggText()
              }
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                {fragments.text}
              </Box>
              {fragments.timer}
              {video ? (
                <Box width={width} height={height}>
                  {fragments.video}
                </Box>
              ) : (
                <Box
                  backgroundImage={`url(${url})`}
                  backgroundSize="cover"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="top"
                  width={width}
                  height={height}
                  mt="s"
                />
              )}
              {fragments.cta}
            </Box>
          ) : video ? (
            <Box width={width} height={height}>
              {fragments.video}
            </Box>
          ) : (
            <Box
              backgroundColor={backgroundColor}
              backgroundImage={`url(${url})`}
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="top"
              textAlign="center"
              width={width}
              height={height}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              position="relative"
            >
              <Box position="absolute" bottom="16px" justifySelf="center">
                {fragments.cta}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
