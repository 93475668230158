import React from 'react';
import { RichText } from 'prismic-reactjs';

import { Box, Text, Divider } from '../../../design-system';
import { ProductUsp as ProductUspType } from '../../cms/types';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  item: ProductUspType;
};

const ProductUspModal = ({ item }: Props) => {
  const { usp_title, body } = item ?? {};

  return hasValidCmsText(body) ? (
    <Box display="grid" gridGap="m" textAlign="center" my="m" mx={['na', 'xxxl', 'l']}>
      <Text fontWeight="bold">{usp_title}</Text>
      <Divider color="BLACK" />
      <Box>
        <RichText render={body} />
      </Box>
    </Box>
  ) : null;
};

export default ProductUspModal;
