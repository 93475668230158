import { Feedback, SubAnswer, SubQuestionsGlobal } from './types';

export function getFeedBackRatingCount(feedbacks: Feedback[]): number[] {
  const counts = [0, 0, 0, 0, 0];

  feedbacks.forEach((feedback: Feedback) => {
    counts[feedback.rating - 1]++;
  });

  return counts.reverse();
}

export function customSubReviewsSort(
  subArray: (SubAnswer | SubQuestionsGlobal)[]
): (SubAnswer | SubQuestionsGlobal)[] {
  // sortOrder values or order potentially to be adapted on wizVille label changes...
  const sortOrder = [
    'Taille juste',
    'Confort', // can be 'Confort' or 'Confort - Maintien'
    'Style',
    'Rapport Qualité-Prix',
    'Qualité de la collection',
  ];

  const sortedResults = sortOrder.reduce(
    (sortedArr: (SubAnswer | SubQuestionsGlobal)[], key: string) => {
      const sub = subArray.find((item: SubAnswer | SubQuestionsGlobal) =>
        // using includes instead of === to allow the 2 different label provided
        // by WizVille 'Confort' and 'Confort - Maintien' targeting the same rating label
        item.sub_question_title?.fr.includes(key)
      );
      if (sub) {
        sortedArr.push(sub);
      }
      return sortedArr;
    },
    []
  );

  return sortedResults;
}
