import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';

import { Box, Text } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { getOrders } from '../actions';
import OrderListItem from './OrderListItem';
import LoadingOrders from './LoadingOrders';
import { ERR_GENERIC } from '../../common/locale';
import { useNavigate } from 'react-router-dom';

export const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOrders() as unknown as UnknownAction);
  }, []);

  const orders = useSelector((state: RootState) => state.account.orders);
  const isFetching = useSelector((state: RootState) => state.account.isFetching);
  const errMsg = useSelector((state: RootState) => state.account.errMsg);

  const handleOrderClick = ({
    orderNumber,
    migrated,
  }: {
    orderNumber: number;
    migrated: boolean;
  }) => {
    navigate(`${orderNumber}${migrated ? '?migrated=1' : ''}`);
  };

  return (
    <Box display="grid" gridTemplateAreas="'title' 'table'" gridGap="xl" mt="xl">
      <Box gridArea="title">
        <Text preset="heading">Vos commandes</Text>
      </Box>
      {isFetching ? (
        <LoadingOrders />
      ) : errMsg ? (
        <Text preset="caption" color="ERROR">
          {ERR_GENERIC}
        </Text>
      ) : (
        <Box gridArea="table">
          <Box
            display="grid"
            gridTemplateColumns={[
              'repeat(3, minmax(0, auto))',
              'repeat(3, minmax(0, auto))',
              'repeat(7, minmax(0, auto))',
            ]}
            textAlign="center"
          >
            <Box display={['none', 'none', 'block']} px="l" pb="m">
              <Text>Date</Text>
            </Box>
            <Box px="l" pb="m">
              <Box display={['block', 'block', 'none']}>
                <Text>Commande</Text>
              </Box>
              <Box display={['none', 'none', 'block']}>
                <Text>N° commande</Text>
              </Box>
            </Box>
            <Box px="l" pb="m">
              <Text>Montant</Text>
            </Box>
            <Box display={['none', 'none', 'block']} px="l" pb="m">
              <Text>Livraison</Text>
            </Box>
            <Box px="l" pb="m">
              <Text>Statut</Text>
            </Box>
            <Box display={['none', 'none', 'block']} px="l" pb="m">
              <Text>N° de suivi</Text>
            </Box>
            <Box display={['none', 'none', 'block']} px="l" pb="m" />
            {(orders ?? []).map((order) => (
              <OrderListItem
                key={order.orderNumber}
                order={order}
                onOrderClick={handleOrderClick}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Orders;
