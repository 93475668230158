import * as React from 'react';

import { Box, Icon, colors } from '../../../../../design-system';

type Props = {
  length: number;
  activeIndex: number;
  onClick: (index: number) => void;
};

export const SliderDots = ({ length, activeIndex, onClick }: Props) => (
  <Box mx="auto" my="m" display="flex" justifyContent="center" alignItems="center">
    {[...Array(length).keys()].map((index) => (
      <Box key={index} mx="xs">
        <Icon
          name="dot"
          color={index === activeIndex ? colors.BLACK : colors.GREY2}
          size={6}
          onClick={() => onClick(index)}
        />
      </Box>
    ))}
  </Box>
);
