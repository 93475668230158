import * as React from 'react';

import { Box, Text, Icon, colors } from '../../../design-system';
import { PickupStation } from '../types';
import OpeningHours from './OpeningHours';

export type Props = {
  station: PickupStation;
  onClick: (id: string) => void;
};

const SelectedStation = ({ station, onClick }: Props) => {
  const { accessForPRM, parking, name, id, line1, line2, postalCode, city } = station;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Box
      display="grid"
      gridGap="m"
      gridAutoRows="min-content"
      p="m"
      width="100%"
      onClick={handleClick}
      data-cy="pickup-station-delivery-selected-edition"
      id={`station-${id}-box`}
      bg={colors.BACKGROUND}
    >
      <Box display="grid" gridGap="xs" gridAutoRows="min-content" width="100%">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Text id={`station-${id}-name`} textTransform="uppercase" fontWeight={700}>
            {name}
          </Text>
          <Icon name="edit" size={16} />
        </Box>
        <Text id={`station-${id}-address`} py="s">
          {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
        </Text>
        {(parking || accessForPRM) && (
          <Box display="flex" mb="s">
            {accessForPRM && <Icon name="wheelchair" />}
            {parking && <Icon name="parkingColissimo" />}
          </Box>
        )}
        <OpeningHours station={station} />
      </Box>
    </Box>
  );
};

export default SelectedStation;
