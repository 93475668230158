import React, { useMemo, useState, FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { UseRefinementListProps, useRefinementList } from 'react-instantsearch';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { SubSizes } from './SubSizes';
import { sortSizeFiltersHook } from '../utils';
import { Divider, Icon } from '../../../design-system';
import { LINK_SIZE_GUIDE } from '../../product/locale';
import paths from '../../routing/paths';
import {
  SUBTITLE_BRA_SIZE,
  SUBTITLE_BRA_UNIVERSAL_SIZE_ALPHA,
  SUBTITLE_BRA_UNIVERSAL_SIZE_NUMBER,
  SUBTITLE_PANTY_SIZE,
  SUBTITLE_PANTY_UNIVERSAL_SIZE,
  SUBTITLE_TIGHT_SIZE,
  SUBTITLE_UNIVERSAL_SIZE,
  TITLE_BRA_SIZE,
  TITLE_OTHER_SIZE,
  TITLE_PANTY_SIZE,
  TITLE_TIGHT_SIZE,
  TITLE_UNIVERSAL_SIZE,
} from '../locale';

type Props = UseRefinementListProps;

type StyledDivProps = {
  isMoreSize: boolean;
};

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  padding: ${(props) => (props.isMoreSize ? '16px 0' : '16px 0 8px 0')};
  p {
    font-size: 1.4rem;
    margin: 0 8px 0 0;
  }
`;

function getHash(pathname: string, id: string): string {
  if (pathname.toLowerCase().includes('bain')) {
    return '#3';
  }
  if (pathname.toLowerCase().includes('softstretch')) {
    return '#4';
  }
  if (pathname.toLowerCase().includes('pyjama')) {
    return '#2';
  }
  if (id === 'catalogue-panty-size-guide-link') {
    return '#1';
  }
  return '';
}

function getSizeGuideLink({
  pathname,
  id,
  isMoreSize,
}: {
  pathname: string;
  id: string;
  isMoreSize: boolean;
}): JSX.Element {
  const hash = getHash(pathname, id);
  return (
    <Link
      data-testid={id}
      id={id}
      to={`${paths.SIZE_GUIDE}${hash}`}
      css={css`
        text-decoration: none;
      `}
    >
      <StyledDiv isMoreSize={isMoreSize}>
        <p className="anchor-animated">{LINK_SIZE_GUIDE}</p>
        <Icon name="ruler" size={16} />
      </StyledDiv>
    </Link>
  );
}

export const Sizes: FC<Props> = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState({
    [TITLE_BRA_SIZE]: false,
    [TITLE_PANTY_SIZE]: false,
    [TITLE_TIGHT_SIZE]: false,
    [TITLE_OTHER_SIZE]: false,
    [TITLE_UNIVERSAL_SIZE]: false,
  });
  const { pathname } = useLocation();
  const { items, refine } = useRefinementList(props);
  const sortedSizes = sortSizeFiltersHook(items);
  const { bra, uniBraNumber, uniBraAlpha, ...nonBraSizes } = sortedSizes;

  return useMemo(() => {
    return (
      <>
        {(bra?.length > 0 || uniBraNumber?.length > 0 || uniBraAlpha?.length > 0) && (
          <>
            <SubSizes
              items={bra}
              title={TITLE_BRA_SIZE}
              subTitle={SUBTITLE_BRA_SIZE}
              iconName="bra"
              refine={refine}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMoreSize={Object.values(nonBraSizes).some((value) => value.length)}
            />
            {uniBraNumber?.length > 0 ? (
              <SubSizes
                items={uniBraNumber}
                title={TITLE_BRA_SIZE}
                subTitle={SUBTITLE_BRA_UNIVERSAL_SIZE_NUMBER}
                refine={refine}
                isOpen={isOpen}
                isMoreSize={Object.values(nonBraSizes).some((value) => value.length)}
                isSecondary
              />
            ) : null}
            {uniBraAlpha?.length > 0 ? (
              <SubSizes
                items={uniBraAlpha}
                title={TITLE_BRA_SIZE}
                subTitle={SUBTITLE_BRA_UNIVERSAL_SIZE_ALPHA}
                refine={refine}
                isOpen={isOpen}
                isMoreSize={Object.values(nonBraSizes).some((value) => value.length)}
                isSecondary
              />
            ) : null}
            {isOpen[TITLE_BRA_SIZE]
              ? getSizeGuideLink({
                  pathname,
                  id: 'catalogue-bra-size-guide-link',
                  isMoreSize: Object.values(nonBraSizes).some((value) => value.length),
                })
              : null}
            {Object.values(nonBraSizes).some((value) => value.length) ? <Divider /> : null}
          </>
        )}
        {(sortedSizes.uniPantyNumber?.length > 0 || sortedSizes.uniPantyAlpha?.length > 0) && (
          <>
            <SubSizes
              items={sortedSizes.uniPantyNumber}
              title={TITLE_PANTY_SIZE}
              subTitle={SUBTITLE_PANTY_SIZE}
              iconName="panty"
              refine={refine}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMoreSize={
                sortedSizes.tights?.length > 0 ||
                sortedSizes.other?.length > 0 ||
                sortedSizes.uni?.length > 0
              }
            />
            {sortedSizes.uniPantyAlpha?.length > 0 ? (
              <SubSizes
                items={sortedSizes.uniPantyAlpha}
                title={TITLE_PANTY_SIZE}
                subTitle={SUBTITLE_PANTY_UNIVERSAL_SIZE}
                refine={refine}
                isOpen={isOpen}
                isMoreSize={
                  sortedSizes.tights?.length > 0 ||
                  sortedSizes.other?.length > 0 ||
                  sortedSizes.uni?.length > 0
                }
                isSecondary
              />
            ) : null}
            {isOpen[TITLE_PANTY_SIZE]
              ? getSizeGuideLink({
                  pathname,
                  id: 'catalogue-panty-size-guide-link',
                  isMoreSize:
                    sortedSizes.tights?.length > 0 ||
                    sortedSizes.other?.length > 0 ||
                    sortedSizes.uni?.length > 0,
                })
              : null}
            {sortedSizes.tights?.length > 0 ||
            sortedSizes.other?.length > 0 ||
            sortedSizes.uni?.length > 0 ? (
              <Divider />
            ) : null}
          </>
        )}
        {sortedSizes.tights?.length > 0 && (
          <>
            <SubSizes
              items={sortedSizes.tights}
              title={TITLE_TIGHT_SIZE}
              subTitle={SUBTITLE_TIGHT_SIZE}
              refine={refine}
              isOpen={isOpen}
              isMoreSize={sortedSizes.other?.length > 0 || sortedSizes.uni?.length > 0}
              setIsOpen={setIsOpen}
            />
            {isOpen[TITLE_TIGHT_SIZE]
              ? getSizeGuideLink({
                  pathname,
                  id: 'catalogue-tight-size-guide-link',
                  isMoreSize: sortedSizes.other?.length > 0 || sortedSizes.uni?.length > 0,
                })
              : null}
            {sortedSizes.other?.length > 0 || sortedSizes.uni?.length > 0 ? <Divider /> : null}
          </>
        )}
        {sortedSizes.other?.length > 0 && (
          <>
            <SubSizes
              items={sortedSizes.other}
              title={TITLE_OTHER_SIZE}
              refine={refine}
              isOpen={isOpen}
              isMoreSize={sortedSizes.uni?.length > 0}
              setIsOpen={setIsOpen}
            />
            {sortedSizes.uni?.length > 0 ? <Divider /> : null}
          </>
        )}
        {sortedSizes.uni?.length > 0 ? (
          <>
            <SubSizes
              items={sortedSizes.uni}
              title={TITLE_UNIVERSAL_SIZE}
              subTitle={SUBTITLE_UNIVERSAL_SIZE}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMoreSize={false}
              refine={refine}
            />
            {isOpen[TITLE_UNIVERSAL_SIZE]
              ? getSizeGuideLink({
                  pathname,
                  id: 'catalogue-universal-size-guide-link',
                  isMoreSize: false,
                })
              : null}
          </>
        ) : null}
      </>
    );
  }, [sortedSizes]);
};
