import * as React from 'react';
import styled from '@emotion/styled';

import { Box, breakpoints, mq } from '../../../design-system';
import { Banner } from '../components/Banner';
import { Footer } from '../../footer';
import { heights } from '../constants';
import { Links } from '../../cms';
import { Header } from '../../header';

const CommonUI = ({ children, isActive, hasFooter }) =>
  isActive ? (
    <Box display="flex" flexDirection="column">
      <Links />
      <Banner />
      <StyledPage display="flex" flexDirection="column" maxWidth="mwl">
        <Header />
        <Box minHeight={heights.PAGE}>{children}</Box>
        {hasFooter && <Footer />}
      </StyledPage>
    </Box>
  ) : (
    children
  );

const StyledPage = styled(Box)({
  [`@media ${mq.FULL}`]: {
    alignSelf: 'center',
    justifySelf: 'center',
    minWidth: `${breakpoints.XL}px`,
  },
});

export default CommonUI;
