import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { loadCmsContent } from '../actions';
import { CmsCustomTypes } from '../types';

export type WithCmsActions = {
  loadCmsContent: (type: CmsCustomTypes, options?: { uid?: string; fallbackValue?: any }) => void;
};

function withCms(WrappedComponent, type: CmsCustomTypes) {
  const Form = (props) => <WrappedComponent {...props} />;

  Form.displayName = `withCms(${WrappedComponent.displayName || WrappedComponent.name})`;

  return connect((state: RootState) => ({ cmsContent: state.cms[type] }), { loadCmsContent })(Form);
}

export default withCms;
