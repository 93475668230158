import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import Box from '../../box';

type Props = {
  id: string;
  onToggle: () => void;
  isChecked: boolean;
};
type StyledLabelProps = {
  isChecked: boolean;
};
const StyledLabel = styled.label<StyledLabelProps>(
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '36px',
    height: '18px',
    borderRadius: '36px',
    bg: 'GREY2',
    cursor: 'pointer',
    ':after': {
      content: '""',
      display: 'block',
      borderRadius: '50%',
      size: '18px',
      bg: 'WHITE',
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.2)',
      transition: '0.2s',
    },
  }),
  ({ isChecked }) =>
    isChecked &&
    css({
      bg: '#41DDA3',
      ':after': {
        ml: '18px',
      },
    })
);

const StyledInput = styled.input({
  opacity: 0,
  zIndex: 1,
  borderRadius: '15px',
  width: '40px',
  height: '15px',
});

export const ToggleCheckbox = ({ id, onToggle, isChecked }: Props) => {
  return (
    <Box position="relative">
      <StyledInput id={id} type="checkbox" checked={isChecked} onChange={onToggle} />
      <StyledLabel htmlFor={id} isChecked={isChecked} />
    </Box>
  );
};
