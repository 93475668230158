import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Icon } from '../../../design-system';
import { closeModal } from '../../common/actions';
import RecycleDetails from './RecycleDetails';

const RecycleModal = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Box display="grid">
      <Box m="m" justifySelf="right">
        <Icon name="close" onClick={handleCloseModal} />
      </Box>
      <RecycleDetails />
    </Box>
  );
};

export default RecycleModal;
