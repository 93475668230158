import * as React from 'react';
import { scrollToTop } from '../utils';

export default function useBodyScrollLock({
  isActive,
  withScrollToTop,
  excludeId,
}: {
  isActive: boolean;
  withScrollToTop?: boolean;
  excludeId?: string;
}) {
  React.useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      const target = event.target as HTMLElement;
      if (excludeId && target.closest(`#${excludeId}`)) {
        return;
      }
      event.preventDefault();
    };

    const handleTouchMove = (event: TouchEvent) => {
      const target = event.target as HTMLElement;
      if (excludeId && target.closest(`#${excludeId}`)) {
        return;
      }
      event.preventDefault();
    };

    if (isActive) {
      if (withScrollToTop) {
        scrollToTop();
      }
      document.body.style.overflowY = 'hidden';
      document.addEventListener('wheel', handleScroll, { passive: false });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    } else {
      document.body.style.overflowY = 'initial';
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchmove', handleTouchMove);
    }

    return () => {
      document.body.style.overflowY = 'initial';
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isActive, withScrollToTop, excludeId]);
}
