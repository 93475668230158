import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Text } from '../../../design-system';
import { Sizes } from '../../product/components/Sizes';
import { getSizeMessage, sizeNoLongerAvailable, sortSizes } from '../../product/utils';
import locale from '../locale';
import { Size } from '../../product/types';
import { ERR_CHOOSE_SIZE } from '../../product/locale';
import { closeModal } from '../../common/actions';
import { RootState } from 'src/shared/store/rootReducer';

type Props = {
  id: string;
  sizeVariants: Size[];
  handleSizeChange: (sku: string) => void;
  sku?: string;
  productRef: string;
  colorRef: string;
};

const WishlistSizeModal = ({
  id,
  sizeVariants,
  sku,
  handleSizeChange,
  productRef,
  colorRef,
}: Props) => {
  const dispatch = useDispatch();

  const [selectedSku, setSelectedSku] = React.useState(sku ?? '');
  const [hoveredSku, setHoveredSku] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');
  const { cart } = useSelector((state: RootState) => state.cart);
  const isNoLongerAvailable = sizeNoLongerAvailable(cart, productRef, colorRef, sizeVariants);
  const sortedSizes = sortSizes(sizeVariants ?? []);

  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);
  const sizeMessage = getSizeMessage(hoveredSize);

  const handleMoveClick = () => {
    if (selectedSku) {
      handleSizeChange(selectedSku);
      dispatch(closeModal());
    } else {
      setErrMsg(ERR_CHOOSE_SIZE);
    }
  };

  return (
    <Box id="box-wishlist-item-size-selector-modal" display="grid" px="xl" py="m">
      <Sizes
        objectID={id}
        {...{
          sortedSizes,
          errMsg,
          sizeMessage,
          selectedSku,
          setHoveredSku,
          setSelectedSku,
          isNoLongerAvailable,
        }}
        isWishlist
      />
      <Box mt="m">
        <Button id={`btn-wishlist-move-to-cart-${id}`} onClick={handleMoveClick}>
          <Text fontWeight="bold" textTransform="uppercase" color="WHITE">
            {locale.CONFIRM_SIZE}
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

export default WishlistSizeModal;
