import React from 'react';
import { Selector } from '../../../design-system';
import { Product } from '../../product/types';
import { getSizeLabel, sortSizes } from '../../product/utils';
import { ERESA_SIZE_SELECTION } from '../locale';

type Props = {
  product: Product;
  selectedSize: string;
  setSelectedSize: (value: string) => void;
  isSizeError: boolean;
};

export const EresaSizeSelector = ({
  product,
  selectedSize,
  setSelectedSize,
  isSizeError,
}: Props) => {
  const { sizeVariants, productRef, colorRef } = product;

  const objectID = productRef + '-' + colorRef;
  const dropdownOptions = sortSizes(sizeVariants ?? []).map((size) => {
    return {
      value: getSizeLabel(size),
      label: getSizeLabel(size),
    };
  });
  const onSizeChange = (newSize: string) => {
    setSelectedSize(newSize);
  };
  dropdownOptions.unshift({ value: '', label: ERESA_SIZE_SELECTION });

  return (
    <Selector
      id={`eresa-modal-select-size-${objectID}`}
      data-cy="ereservation-additionnal-product-selector"
      options={dropdownOptions}
      value={selectedSize}
      onChange={onSizeChange}
      hasError={isSizeError}
      isEresaModal
    />
  );
};
