import * as React from 'react';

import { Box, LoadingBox } from '../../../design-system';

const LoadingOrderDetail = () => {
  return (
    <Box display="grid" gridGap="l" mr="xl">
      <LoadingBox height="84px" />
      <LoadingBox height="64px" />
      <LoadingBox height="84px" />
    </Box>
  );
};

export default LoadingOrderDetail;
