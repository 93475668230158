import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box } from '../../design-system';
import { opacities } from '../../design-system/systemprovider';
import { openModal } from '../common/actions';
import PaypalModal from './PaypalModal';

type Props = {
  isCartValid: boolean;
};

export const PaypalExpressButton = ({ isCartValid }: Props) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openModal({ content: <PaypalModal />, preset: 'paypalECS' }));

  return (
    <ExpressPaypalButton isCartValid={isCartValid} onClick={handleClick}>
      <Box fontSize="16px" alignSelf="center" mr="10px">
        Paiement rapide avec
      </Box>
      <Box alignSelf="center">
        <img
          height="20px"
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="PayPal"
        />
      </Box>
    </ExpressPaypalButton>
  );
};

type ExpressPaypalButtonProps = {
  isCartValid: boolean;
};

const ExpressPaypalButton = styled(Box)<ExpressPaypalButtonProps>(
  css({
    '@media(hover: hover)': {
      ':hover': {
        cursor: 'pointer',
        transition: 'opacity 0.5s',
        opacity: opacities.HOVERED,
      },
    },
  }),
  {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#F7C557',
    marginTop: '4px',
    height: '44px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ isCartValid }) =>
    !isCartValid && {
      pointerEvents: 'none',
      opacity: 0.4,
    }
);
