import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { css } from '@emotion/core';

import { Box, breakpoints, colors } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { emptyText } from '../../cms/state';
import {
  CmsHomepagePromoCategoriesByButton,
  CmsHomepagePromoCategoriesByButtonItems,
} from '../../cms/types';
import { BannerCta } from '../components/BannerCta';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

type Props = {
  id: string;
  content: CmsHomepagePromoCategoriesByButton;
  ctaList: CmsHomepagePromoCategoriesByButtonItems[];
};

export function PromoCategoriesByButton({ id, content, ctaList }: Props) {
  const { isMobile } = useMediaQueries();
  const {
    title,
    title_mobile,
    description,
    description_mobile,
    background_image,
    background_image_mobile,
    title_color,
    description_color,
  } = content ?? {};

  if (
    !background_image?.dimensions?.width ||
    !background_image_mobile?.dimensions?.width ||
    !background_image?.dimensions?.height ||
    !background_image_mobile?.dimensions?.height
  ) {
    return null;
  }

  return (
    <div
      id={id}
      css={css`
        position: relative;
      `}
    >
      <img
        src={isMobile ? background_image_mobile.url : background_image.url}
        width="100%"
        alt={
          isMobile
            ? (background_image_mobile.alt ?? 'promo-categories-by-button-image-description-mobile')
            : (background_image.alt ?? 'promo-categories-by-button-image-description')
        }
      />
      <div
        css={css`
          width: 100%;
          position: absolute;
          bottom: 32px;
          left: 50%;
          transform: translate(-50%, 0%);
          @media (min-width: ${breakpoints.S}px) {
            width: 512px;
            max-width: 512px;
          }
        `}
      >
        <Box color={title_color}>
          <RichText render={isMobile ? title_mobile : (title ?? [...emptyText])} />
        </Box>
        <Box color={description_color} paddingTop={'16px'}>
          <RichText render={isMobile ? description_mobile : (description ?? [...emptyText])} />
        </Box>
        <Box
          width={isMobile ? '100%' : '512px'}
          display={'grid'}
          gridTemplateColumns={isMobile || ctaList.length === 1 ? '1fr' : '1fr 1fr'}
          gridColumnGap={24}
          gridRowGap={isMobile ? 16 : 24}
          marginTop="32px"
          marginX={'auto'}
        >
          {ctaList.map((cta, i) => (
            <Box
              key={`hp-promo-categories-by-button-cta-${`${cta.cta_name}+${i}`}`}
              width={isMobile ? '100%' : '244px'}
              margin={'auto'}
              height={'44px'}
              paddingX={isMobile ? 16 : 0}
            >
              <Link
                data-testid={`hp-promo-categories-by-button-link-${i}`}
                id={`hp-promo-categories-by-button-link-${i}`}
                className={
                  cta.cta_type === 'white link' || cta.cta_type === 'black link'
                    ? 'anchor-animated'
                    : ''
                }
                to={getFormatedLinkForReactRouterLink(cta.link)}
                css={css`
                  text-decoration: none;
                  font-size: 16px;
                  font-weight: 700;
                  color: ${cta.cta_type === 'white link' ? colors.WHITE : colors.BLACK};
                `}
              >
                {!(cta.cta_type === 'white link' || cta.cta_type === 'black link') ? (
                  <BannerCta
                    id={`hp-promo-categories-by-button-cta-${`${i}`}`}
                    type={cta.cta_type ?? 'black button'}
                    text={cta.cta_name ?? ''}
                    color={cta.cta_background_color}
                    fontColor={cta.cta_font_color}
                  />
                ) : (
                  cta.cta_name
                )}
              </Link>
            </Box>
          ))}
        </Box>
      </div>
    </div>
  );
}
