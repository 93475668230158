import { ecartState } from './state';
import { State } from './types';
import types from './actionTypes';
import { ECartActions } from './actions';

export default function ecart(state = ecartState, action: ECartActions): State {
  switch (action.type) {
    case types.SET_ERESA_REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.SET_ERESA_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    case types.REQUEST_ECART_SUCCESS:
      return {
        ...state,
        ecart: {
          ...state.ecart,
          storeInfo: action.payload.storeInfo,
          total: action.payload.total,
          items: action.payload.items,
        },
        isLoading: false,
        isError: false,
      };
    case types.SET_ADDITIONNAL_LIST_ERESA:
      return {
        ...state,
        additionnals_products: action.payload,
      };
    case types.SET_MAIN_PRODUCT_ERESA:
      return {
        ...state,
        main_product: action.payload,
      };
    default:
      return state;
  }
}
