import * as React from 'react';

import { Box, LoadingBox, Divider } from '../../../design-system';
import { Header } from './Header';

export const LoadingCart = () => {
  const item = (
    <Box
      display="grid"
      gridTemplateAreas="'image  name closeButton' 'image colorAndSize price' 'image quantity .'"
      gridTemplateColumns="auto 1fr auto"
      my="m"
      gridGap="m"
      mx={['m', 'na']}
    >
      <LoadingBox gridArea="image" width="125px" height="166px" />
      <LoadingBox gridArea="name" height="m" width="48px" />
      <LoadingBox gridArea="closeButton" height="m" width="l" />
      <LoadingBox gridArea="colorAndSize" height="xl" width="72px" />
      <LoadingBox gridArea="price" height="xl" width="l" />
      <LoadingBox gridArea="quantity" height="m" width="l" />
    </Box>
  );
  return (
    <Box display="flex" justifyContent="space-between" flexDirection={['column', 'row']}>
      <Box width={['100%', '60%']} maxWidth="mws" mx={['na', 'l']}>
        <Header step={0} isLoading />
        <LoadingBox display={['block', 'none']} height="34px" />
        <Box display={['none', 'block']}>
          <Divider my="l" />
        </Box>
        <Box my="l" />
        {item}
        <LoadingBox display={['grid', 'none']} width="100%" height="276px" my="l" />
        <LoadingBox display={['none', 'block']} height="102px" my="xl" />
      </Box>
      <LoadingBox
        display={['none', 'grid']}
        width="30%"
        position="fixed"
        top={0}
        right={0}
        height="100vh"
      />
    </Box>
  );
};
