import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { CmsMenuItem } from '../../cms/types';

type Props = {
  rows: CmsMenuItem[];
  columnIndex: number;
};
const StyledThirdLevel = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-top: 16px;
`;

export const ThirdLevel = ({ columnIndex, rows }: Props) => {
  return (
    <StyledThirdLevel>
      {rows.map((row, index) => {
        return (
          <Link
            id={`link-submenu-row-${columnIndex}-${index}`}
            className="link-menu link-menu--third-level"
            data-testid={`link-submenu-row-${index}`}
            aria-label={`Lien vers la page ${row.name}`}
            to={row.link ?? '#'}
            key={`link-submenu-row-${columnIndex}-${index}`}
          >
            {row.name}
          </Link>
        );
      })}
    </StyledThirdLevel>
  );
};
