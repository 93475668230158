import React from 'react';
import styled from '@emotion/styled';
import { CmsFontFamily } from '../../cms/types';

const Container = styled.div<{ margin: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
`;

const Letter = styled.span<{
  fontFamily: CmsFontFamily;
  animationSpeed: number;
  animationDelay: number;
}>`
  font-family: ${(props) => props.fontFamily};
  animation: ${(props) => `scroll ${props.animationSpeed}s ease infinite alternate`};
  animation-delay: ${(props) => props.animationDelay}s;
  display: inline-block;
  transform-origin: center;

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-4px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

type Props = {
  text: string;
  id: string;
  fontFamily: CmsFontFamily;
  margin: string;
  animationSpeed: number;
  animationDelay: number;
};

export const AnimatedText = ({
  text,
  id,
  fontFamily,
  margin,
  animationSpeed,
  animationDelay,
}: Props) => {
  return (
    <Container id={id} margin={margin}>
      {text.split('').map((char, index) => (
        <Letter
          key={`${char}-${index}`}
          fontFamily={fontFamily}
          animationSpeed={animationSpeed}
          animationDelay={index * animationDelay}
        >
          {char}
        </Letter>
      ))}
    </Container>
  );
};
