import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { Helmet } from 'react-helmet-async';

import { Box } from '../../../design-system';
import { cmsBrandInit, setCmsContent } from '../actions';
import { CmsCustomTypes } from '../types';
import { RootState } from '../../../store/rootReducer';
import BrandItem from './BrandItem';
import paths from '../../routing/paths';

const Brand = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cmsContent = useSelector((state: RootState) => state.cms.grid);

  const cmsUid = Object.values(params).join('_');
  const type = cmsContent?.type ?? '';
  const { seotitle, seodescription } = cmsContent ?? {};
  const items = cmsContent?.items ?? [];

  React.useEffect(() => {
    if (cmsUid) {
      dispatch(cmsBrandInit(cmsUid) as unknown as UnknownAction);
    }
    return () => {
      dispatch(
        setCmsContent({
          type: CmsCustomTypes.grid,
          content: { notFound: false },
        })
      );
    };
  }, [cmsUid]);

  const notFound = cmsContent?.notFound;
  if (notFound) {
    navigate(paths.NOT_FOUND);
  }

  if (cmsUid !== type) {
    return null;
  }

  const metaImage = items.find((item) => item.image?.url)?.image?.url;

  return (
    <>
      <Helmet>
        {seotitle && <title>{seotitle}</title>}
        {seotitle && <meta property="og:title" content={seotitle} />}
        {seodescription && <meta name="description" content={seodescription} />}
        {seodescription && <meta property="og:description" content={seodescription} />}
        {metaImage && <meta property="og:image" content={metaImage} />}
      </Helmet>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        alignItems="center"
        justifyContent="center"
        mx="auto"
      >
        {items.map((item, index) => (
          <BrandItem key={index} item={item}></BrandItem>
        ))}
      </Box>
    </>
  );
};

export default Brand;
