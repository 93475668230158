import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import { CmsHomepageCollectionBanner } from '../../../cms/types';
import { Image, breakpoints, colors } from '../../../../design-system';
import { useMediaQueries } from '../../../common/hooks/useMediaQuery';
import { BannerCta } from '../../components/BannerCta';
import { CollectionBannerResult } from './CollectionBannerResult';
import { useProductsInfos } from '../../../common/hooks/useProductsInfos';
import { hasValidCmsText } from '../../../home/utils';

type Props = {
  id: string;
  content: CmsHomepageCollectionBanner;
};

const CMS_BLOCK_WIDTH = 680;

const StyledCollectionBannerContainer = styled.div<{ linkColor: string }>`
  max-width: 100vw;
  margin: auto;

  @media (min-width: ${breakpoints.L}px) {
    max-width: 1440px;
  }

  .collection-banner-cms-box {
    text-align: center;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.S}px) {
      text-align: left;
      padding: 0;
      margin-bottom: 24px;
    }

    @media (min-width: ${breakpoints.L}px) {
      width: ${CMS_BLOCK_WIDTH}px;
    }
  }

  .collection-banner-cms-description {
    margin-top: 8px;
  }

  .collection-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${breakpoints.M}px) {
      flex-direction: row;
    }
  }

  .collection-banner-image-block {
    position: relative;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.M}px) {
      width: calc(50% - 40px);
      margin-bottom: 0;
    }
  }

  .collection-banner-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  .collection-banner-cta,
  .anchor-animated {
    position: absolute;
    text-decoration: none;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;

    @media (min-width: ${breakpoints.S}px) {
      bottom: 24px;
    }
  }

  .collection-banner-cta:not(.anchor-animated) {
    min-width: 200px;
  }

  .anchor-animated {
    color: ${(props) => props.linkColor};
    font-size: 1.6rem;
  }
`;

export function CollectionBanner({ id, content }: Props) {
  const {
    title,
    title_mobile,
    description,
    description_mobile,
    image,
    image_mobile,
    cta_name,
    cta_link,
    cta_type,
    cta_background_color,
    cta_font_color,
    product_ref_1,
    custom_image_1,
    product_ref_2,
    custom_image_2,
    product_ref_3,
    custom_image_3,
    product_ref_4,
    custom_image_4,
  } = content;
  const products = [
    { ref: product_ref_1 ?? '', customImage: custom_image_1, position: 1 },
    { ref: product_ref_2 ?? '', customImage: custom_image_2, position: 2 },
    { ref: product_ref_3 ?? '', customImage: custom_image_3, position: 3 },
    { ref: product_ref_4 ?? '', customImage: custom_image_4, position: 4 },
  ];
  const { isMobile, isTablet } = useMediaQueries();

  const listOfProductsRef = products
    .map((product) => product.ref)
    .filter((ref) => ref !== undefined) as string[];

  const { hits } = useProductsInfos(listOfProductsRef);

  if (!hits?.length) {
    return null;
  }

  const isCmsTitleMobileToDisplay = isMobile && hasValidCmsText(title_mobile);
  const isCmsTitleDesktopToDisplay = !isMobile && hasValidCmsText(title);
  const isCmsDescriptionMobileToDisplay = isMobile && hasValidCmsText(description_mobile);
  const isCmsDescriptionDesktopToDisplay = !isMobile && hasValidCmsText(description);
  const isCmsBlockToDisplay =
    isCmsTitleMobileToDisplay ||
    isCmsDescriptionMobileToDisplay ||
    isCmsTitleDesktopToDisplay ||
    isCmsDescriptionDesktopToDisplay;

  return (
    <StyledCollectionBannerContainer
      id={`collection-banner-${id}`}
      linkColor={cta_type === 'white link' ? colors.WHITE : colors.BLACK}
    >
      {isCmsBlockToDisplay && (
        <div className="collection-banner-cms-box">
          {isCmsTitleMobileToDisplay && <RichText render={title_mobile} />}
          {isCmsTitleDesktopToDisplay && <RichText render={title} />}
          {(isCmsDescriptionMobileToDisplay || isCmsDescriptionDesktopToDisplay) && (
            <div className="collection-banner-cms-description">
              {isCmsDescriptionMobileToDisplay && <RichText render={description_mobile} />}
              {isCmsDescriptionDesktopToDisplay && <RichText render={description} />}
            </div>
          )}
        </div>
      )}
      <div className="collection-banner-content">
        <div className="collection-banner-image-block">
          <Link
            data-testid={`collection-banner-image-link-to-${cta_link ?? '/'}`}
            className="collection-banner-link"
            to={cta_link ?? '/'}
          >
            <Image
              objectFitValue="cover"
              isAbsolute={isMobile || isTablet ? false : true}
              width={isMobile || isTablet ? '100%' : 'calc(50% - 40px)'}
              alt={(isMobile ? image_mobile?.alt : image?.alt) ?? ''}
              src={(isMobile ? image_mobile?.url : image?.url) ?? ''}
              srcSet={`${(isMobile ? image_mobile?.retina?.url : image?.retina?.url) ?? ''} 2x`}
            />
          </Link>
          {cta_name && cta_link && (
            <Link
              data-testid={`hp-collection-banner-cta-button-link-to-${cta_link}`}
              to={cta_link}
              className={`collection-banner-cta ${cta_type === 'white link' || cta_type === 'black link' ? 'anchor-animated' : ''}`}
            >
              {!(cta_type === 'white link' || cta_type === 'black link') ? (
                <BannerCta
                  id="collection-banner-cta"
                  text={cta_name}
                  type={cta_type ?? 'white button'}
                  fontColor={cta_font_color}
                  color={cta_background_color}
                />
              ) : (
                cta_name
              )}
            </Link>
          )}
        </div>
        <CollectionBannerResult products={products} hits={hits} />
      </div>
    </StyledCollectionBannerContainer>
  );
}
