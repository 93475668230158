import * as React from 'react';

import { Box, Icon, Text } from '../../../design-system';
import { TXT_BACK } from '../locale';

type Props = {
  id: string;
  onClick: () => void;
  preset?: 'body' | 'caption' | 'darkTheme';
  backText?: string;
  isOrderDetail?: boolean;
};

const BackButton = ({
  id,
  preset = 'body',
  isOrderDetail,
  onClick,
  backText = TXT_BACK,
}: Props) => (
  <Box
    id={id}
    display="flex"
    alignItems="center"
    justifyContent={preset === 'darkTheme' ? 'center' : 'na'}
    mb={preset === 'caption' ? 'na' : 's'}
    mt={isOrderDetail ? '-24px' : preset === 'caption' ? 'na' : 's'}
    onClick={onClick}
    bg={preset === 'darkTheme' ? 'black' : 'na'}
    width={preset === 'darkTheme' ? '73px' : 'auto'}
    height={preset === 'darkTheme' ? '30px' : 'auto'}
    borderRadius={preset === 'darkTheme' ? '19px' : 'na'}
  >
    <Box mr="xs" ml={preset === 'darkTheme' ? '-6px' : 'na'}>
      <Icon
        name="chevronLeft"
        size={preset === 'caption' ? 10 : 12.5}
        color={preset === 'darkTheme' ? '#FFF' : '#000'}
      />
    </Box>
    <Text preset={preset}>{backText}</Text>
  </Box>
);

export default BackButton;
