import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { breakpoints, colors } from '../systemprovider';
import Icon from '../icon';
import { getJsonLdDataForBreadcrumb } from './utils';

type Props = {
  items: BreadcrumbsItem[];
};

export type BreadcrumbsItem = {
  label: string;
  value: string;
  readonly?: boolean;
};

const StyledBreadcrumbsOl = styled.ol`
  list-style: none;
  margin: 8px 0;
  padding: 0 0 4px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;

  @media (min-width: ${breakpoints.M}px) {
    margin: 0;
  }

  .breadcrumb-item-li {
    display: flex;
    align-items: center;
  }

  .breadcrumb-item-label {
    display: inline-block;
    color: ${colors.GREY2};
    text-decoration-color: ${colors.GREY2};
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0;

    @media (hover: hover) {
      &:hover:not(.last-breadcrumb-item) {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }

  .last-breadcrumb-item {
    color: ${colors.BLACK};
    font-weight: 700;
  }

  .breadcrumb-item-separator {
    margin: 0 4px 2px 4px;
  }
`;

export const Breadcrumbs = ({ items = [] }: Props) => {
  const { pathname } = useLocation();

  return items.length === 0 ? null : (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(
            getJsonLdDataForBreadcrumb({
              items: [{ value: '/', label: 'Accueil' }, ...items],
              pathname,
            })
          )}
        </script>
      </Helmet>
      <StyledBreadcrumbsOl id="breadcrumbs-ol">
        {[{ value: '/', label: 'Accueil' }, ...items].map((item, index) => (
          <li className="breadcrumb-item-li" key={`breadcrumb-item-${item.label}-${index}`}>
            {item.readonly ? (
              <p className="breadcrumb-item-label last-breadcrumb-item">{item.label}</p>
            ) : (
              <Link
                id={`link-breadcrumb-${index}-${item.label}`}
                to={item.value}
                aria-label={`Lien vers la page ${item.value}`}
                data-testid={`breadcrumb-item-${item.value}-link`}
              >
                <p className="breadcrumb-item-label">{item.label}</p>
              </Link>
            )}
            {index < items.length && (
              <Icon
                name="chevronRight"
                size={12}
                color={colors.GREY2}
                className="breadcrumb-item-separator"
              />
            )}
          </li>
        ))}
      </StyledBreadcrumbsOl>
    </>
  );
};
