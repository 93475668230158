import React from 'react';
import { css } from '@emotion/core';

import { CmsImage } from '../../cms/types';
import { breakpoints, Icon } from '../../../design-system';
import { TITLE_PAYMENT_METHODS_SECURE } from '../locale';

type Props = {
  images: {
    payment_method?: CmsImage;
  }[];
};

export const PaymentMethods = ({ images }: Props) => {
  return !images?.length ? null : (
    <div
      id="footer-payment-method-list"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 16px;
        padding-top: 32px;

        @media (min-width: ${breakpoints.S}px) {
          padding-top: 0;
          align-items: unset;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Icon name="creditCardSecure" size={16} />
        <p
          css={css`
            font-size: 1.4rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-left: 8px;
          `}
        >
          {TITLE_PAYMENT_METHODS_SECURE}
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          grid-gap: 8px;
        `}
      >
        {images.map((img, index) => (
          <img
            key={img?.payment_method?.url ?? `footer-payment-method-${index}`}
            src={img?.payment_method?.url ?? ''}
            alt={img?.payment_method?.alt ?? `footer-payment-method-image-${index}`}
            height="24px"
          />
        ))}
      </div>
    </div>
  );
};
