import React from 'react';

import { Box } from '../../../design-system';
import { H1Catalog, SubTitle } from '../../common/components/Custom';

type Props = { title: string | undefined; subtitle: string | undefined };

export function CatalogTitle({ title, subtitle }: Props) {
  if (!title) {
    return null;
  }
  return (
    <Box
      display="flex"
      width={'100%'}
      justifyContent="center"
      marginTop={['16px', '16px', '0']}
      marginBottom={['15px', '29px']}
    >
      <Box width={['90%', '70%']} textAlign="center">
        <H1Catalog>{title}</H1Catalog>
        {subtitle ? <SubTitle marginTop="8px">{subtitle}</SubTitle> : null}
      </Box>
    </Box>
  );
}
