import { wishlistState } from './state';
import { State } from './types';
import types from './actionTypes';
import { WishlistActions } from './actions';

export default function cart(state = wishlistState, action: WishlistActions): State {
  switch (action.type) {
    case types.REQUEST_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: action.payload,
        isFetching: false,
      };
    case types.REQUEST_WISHLIST_FAILURE:
      return {
        ...state,
        errMsg: action.payload,
        isFetching: false,
      };
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errMsg: '',
      };
    case types.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
}
