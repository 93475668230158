import React from 'react';
import { colors } from '../../../design-system';

type Props = {
  name: 'account' | 'miniCart' | 'wishlist';
};

export const CustomIconToSvg = ({ name }: Props) => {
  return name === 'account' ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="account__icon"
    >
      <circle
        cx="12"
        cy="6"
        r="4"
        stroke={`var(--svg-stroke-color, ${colors.WHITE})`}
        strokeWidth="1.3"
        strokeLinejoin="round"
        fill="var(--svg-fill-color, transparent)"
      />
      <path
        d="M16.8377 22H7.16228C5.1146 22 3.6687 19.9939 4.31623 18.0513L5.31623 15.0513C5.72457 13.8263 6.87099 13 8.16228 13H15.8377C17.129 13 18.2754 13.8263 18.6838 15.0513L19.6838 18.0513C20.3313 19.9939 18.8854 22 16.8377 22Z"
        stroke={`var(--svg-stroke-color, ${colors.WHITE})`}
        strokeWidth="1.3"
        strokeLinejoin="round"
        fill="var(--svg-fill-color, transparent)"
      />
    </svg>
  ) : name === 'miniCart' ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="shopping-cart__icon"
    >
      <path
        d="M4.67151 8.6279C4.85917 7.12661 6.13538 6 7.64835 6H16.3517C17.8646 6 19.1408 7.12661 19.3285 8.6279L20.5785 18.6279C20.8023 20.4185 19.4061 22 17.6017 22H6.39835C4.59385 22 3.19769 20.4185 3.42151 18.6279L4.67151 8.6279Z"
        stroke={`var(--svg-stroke-color, ${colors.WHITE})`}
        strokeWidth="1.3"
        strokeLinejoin="round"
        fill="var(--svg-fill-color, transparent)"
      />
      <path
        d="M16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6"
        stroke={`var(--svg-stroke-color, ${colors.WHITE})`}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </svg>
  ) : name === 'wishlist' ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="wishlist__icon"
    >
      <path
        d="M12 6.6219C9.3 1.99587 3 3.84629 3 9.39752C3 14.9488 12 20.5 12 20.5C12 20.5 21 14.9488 21 9.39752C21 3.84629 14.7 1.99587 12 6.6219Z"
        stroke={`var(--svg-stroke-color, ${colors.BLACK})`}
        fill="var(--svg-fill-color, transparent)"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;
};
