import { useState, useEffect } from 'react';

import { getIsClient } from '../utils';

export function useContainerSize(
  ref: React.RefObject<HTMLDivElement>,
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean
): {
  width: number;
  height: number;
} {
  if (!getIsClient()) {
    return { width: 0, height: 0 };
  }
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 } as {
    width: number;
    height: number;
  });

  const updateDimensions = () => {
    setDimensions({
      width: ref?.current?.clientWidth ?? 0,
      height: ref?.current?.clientHeight ?? 0,
    });
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [isMobile, isTablet, isDesktop]);

  return dimensions;
}
