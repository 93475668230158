import * as React from 'react';
import styled from '@emotion/styled';

import { breakpoints, colors } from '../../../design-system';
import { CmsHomepageSpacing } from '../../cms/types';

type Props = {
  content: CmsHomepageSpacing | undefined;
};

const StyledSpacing = styled.div<{
  background_color_mobile?: string;
  height_mobile?: number;
  background_color?: string;
  height_desktop?: number;
}>`
  margin: -16px 0;
  background: ${(props) => props.background_color_mobile || colors.WHITE};
  height: ${(props) => props.height_mobile}px;
  @media (min-width: ${breakpoints.S - 1}px) {
    margin: -20px 0;
    background: ${(props) => props.background_color || colors.WHITE};
    height: ${(props) => props.height_desktop}px;
  }
`;

export const Spacing = ({ content }: Props) => {
  if (!content) {
    return null;
  }
  const { background_color, background_color_mobile, height_mobile, height_desktop } =
    content ?? {};
  return (
    <StyledSpacing
      background_color={background_color}
      background_color_mobile={background_color_mobile}
      height_mobile={height_mobile}
      height_desktop={height_desktop}
    />
  );
};
