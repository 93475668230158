import * as React from 'react';

import { Box, LoadingBox } from '../../../design-system';

const LoadingProduct = () => (
  <>
    <Box display={['none', 'grid']} gridGap="l" mx="l">
      <LoadingBox height="18px" width="40%" />
      <Box
        display="grid"
        gridGap="m"
        gridTemplateColumns="7fr 5fr"
        maxWidth="mwm"
        justifySelf="center"
      >
        <Box display="grid" gridGap="m">
          <LoadingBox width="54vh" height="72vh" />
          <LoadingBox height="l" />
          <LoadingBox height="20px" />
          <LoadingBox height="240px" />
        </Box>
        <Box position="relative" width={['auto', '80%', '400px']} justifySelf="center">
          <Box display="grid" gridGap="s" alignSelf="start" position="sticky" top="140px" mt="xxl">
            <Box display="grid" gridGap="m">
              <LoadingBox height="l" />
              <LoadingBox height="18px" width="66%" />
              <LoadingBox height="20px" width="80px" />
              <LoadingBox height="l" />
              <LoadingBox height="l" />
              <LoadingBox height="48px" />
              <LoadingBox height="48px" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box display={['grid', 'none']} gridGap="s" mx="m">
      <LoadingBox height="18px" width="75%" />
      <LoadingBox mx="-16px" width="100vw" height="133vw" />
      <Box display="flex" justifyContent="space-between" mt="m" mb="xs" height="20px">
        <LoadingBox width="66%" />
        <LoadingBox width="20%" />
      </Box>
      <LoadingBox width="66%" height="12px" mb="l" />
      <LoadingBox mb="m" height="18px" />
      <LoadingBox height="18px" mb="l" />
      <LoadingBox height="48px" />
    </Box>
  </>
);

export default LoadingProduct;
