import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import { RootState } from '../../../store/rootReducer';
import { RecommendationTypes } from '../../recommendations/types';
import { loadRecommendations } from '../../recommendations/actions';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { RecommendationsBlock } from '../../recommendations/components/RecommendationsBlock';

export const CartRecommendations = () => {
  const dispatch = useDispatch();

  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.cart
  );
  const { cart_recommendation_title: title } = useSelector((state: RootState) => state.cms.cart);
  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (!recommendedProducts?.length) {
      dispatch(loadRecommendations(RecommendationTypes.cart) as unknown as UnknownAction);
    }
  }, []);

  if (!recommendedProducts.length) {
    return null;
  }

  return <RecommendationsBlock id="cart-page" title={title} hits={hits} tracking={tracking} />;
};
