import { OpeningHours, StationHours } from './types';

export function mergeStationOpeningHours(hours: StationHours): OpeningHours[] {
  return Object.keys(hours)
    .filter((day) => hours[day])
    .reduce<OpeningHours[]>((prevVal, curVal) => {
      const last = prevVal[prevVal.length - 1];
      if (last && JSON.stringify(last.openingHours) === JSON.stringify(hours[curVal])) {
        prevVal[prevVal.length - 1].endDay = curVal;
      } else {
        prevVal.push({
          day: curVal,
          openingHours: hours[curVal],
        });
      }
      return prevVal;
    }, []);
}
