import { PickupState } from './state';
import { State } from './types';
import { PickupActions } from './actions';
import types from './actionTypes';

export default function pickup(state = PickupState, action: PickupActions): State {
  switch (action.type) {
    case types.RESET_TOKEN:
      return {
        ...state,
        token: '',
        isFetching: true,
      };
    case types.REQUEST_TOKEN:
      return {
        ...state,
        isFetching: true,
      };
    case types.REQUEST_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        token: action.payload,
      };
    case types.REQUEST_TOKEN_FAILURE:
      return {
        ...state,
        token: 'token',
        errMsg: action.payload,
        isFetching: false,
      };

    case types.SET_GEO_LOCATION_POSITION:
      return {
        ...state,
        geoCoordinate: action.payload,
      };
    case types.REQUEST_PICKUP_STATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case types.REQUEST_PICKUP_STATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pickupStations: action.payload,
        totalCount: action.payload.length,
      };
    case types.REQUEST_PICKUP_STATIONS_FAILURE:
      return {
        ...state,
        errMsg: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}
