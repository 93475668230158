const wizVilleEndpoint = (productCode: string) =>
  `https://storage.googleapis.com/trustville-widgets/product/c_1469/${productCode}/prod/feed.json`;

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getReviews(productCode) {
  return fetch(wizVilleEndpoint(productCode))
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);
}
