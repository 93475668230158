import * as React from 'react';
import { Box, Text } from '../../../design-system';

type Props = {
  isInStock: boolean;
};

const Availability = ({ isInStock }: Props) => (
  <Box display="flex" alignItems="baseline">
    <Box
      width="s"
      height="s"
      backgroundColor={isInStock ? 'SUCCESS' : 'ERROR'}
      borderRadius="50%"
      mx="s"
    />
    <Text preset="caption" color={isInStock ? 'SUCCESS' : 'ERROR'} whiteSpace="nowrap">
      {isInStock ? 'EN STOCK' : 'INDISPONIBLE'}
    </Text>
  </Box>
);

export default Availability;
