import React, { useEffect, useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { DynamicButton, EmailField, colors } from '../../../design-system';
import { theme } from '../../../design-system/systemprovider';
import {
  FORGOT_EMAIL_MESSAGE_1,
  FORGOT_EMAIL_MESSAGE_2,
  CTA_CONTENT_FORGOT_PASSWORD,
  ERR_EMAIL_NOT_SEND,
  ERR_EMAIL_NOT_FOUND,
  SUCCESS_EMAIL_SENT,
  ATTEMPT_LIMIT_EXCEEDED,
  MSG_ERROR,
  MSG_EMAIL_SEND,
  AUTH_BAR_FORGOT_PASSWORD_TITLE,
} from '../locale';
import { Forms, FormForgotPassword } from '../../form/types';
import { validate } from '../../form/utils';
import { requestPasswordReset } from '../actions';
import useForm from '../../form/hooks/useForm';
import { ErrorCodes } from '../types';
import { Alert } from '../../../design-system/alert';

type Props = {
  email: string;
};

const StyledForgotPasswordContainer = styled.div`
  margin: ${theme.space.m};
  .forgot-password__subtitle {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin: 0 0 16px 0;
  }
  .forgot-pwd__btn-reset-password {
    text-transform: uppercase;
  }
  .error-forgot-password,
  .success-forgot-password-send {
    font-size: 1.2rem;
    color: ${colors.MAIN_GREY};
    margin: 0;
  }
  .forgot-password-form {
    display: grid;
    grid-gap: ${theme.space.m};
  }
  .forgot-password-mesage {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${colors.MAIN_GREY};
    margin: 0;
  }
`;

export const ForgotPassword = ({ email }: Props) => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState<boolean | null>(null);
  const formState = useForm(Forms.forgotPassword);
  const { actions } = formState;
  const form = formState.form as FormForgotPassword;

  const clearMessage = () =>
    actions.setFeedback({ form: Forms.forgotPassword, ok: false, message: '' });

  useEffect(() => {
    return () => {
      clearMessage();
    };
  }, []);

  const validateEmail = (value: string): string => validate({ value, key: 'email' });

  const handleChange = (value: string) => {
    if (form.validation.email) {
      actions.setFormValidation({
        form: Forms.forgotPassword,
        values: { email: validateEmail(value) },
      });
    }

    actions.setFormValues({ form: Forms.forgotPassword, values: { email: value } });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errMsg = validateEmail(form.values.email);

    actions.setFormValidation({
      form: Forms.forgotPassword,
      values: { email: errMsg },
    });

    if (!errMsg) {
      clearMessage();
      dispatch(requestPasswordReset(form.values.email) as unknown as UnknownAction);
    }
  };

  useEffect(() => {
    if (form.ctaState === 2) {
      setIsError(true);
    }
    if (form.ctaState === 1) {
      setIsError(false);
    }
  }, [form]);

  useEffect(() => {
    handleChange(email);
  }, [email]);

  return (
    <StyledForgotPasswordContainer>
      <p className="forgot-password__subtitle">{AUTH_BAR_FORGOT_PASSWORD_TITLE}</p>
      {isError === true && (
        <Alert type="error" title={MSG_ERROR}>
          <p id="error-forgot-password" className="error-forgot-password">
            {form.feedback.message === ErrorCodes.UserNotFoundException && ERR_EMAIL_NOT_FOUND}
            {form.feedback.message === ErrorCodes.LimitExceededException && ATTEMPT_LIMIT_EXCEEDED}
            {form.feedback.message !== ErrorCodes.UserNotFoundException &&
              form.feedback.message !== ErrorCodes.LimitExceededException &&
              ERR_EMAIL_NOT_SEND}
          </p>
        </Alert>
      )}
      {isError === false && (
        <Alert type="success" title={MSG_EMAIL_SEND}>
          <p id="success-forgot-password-send" className="success-forgot-password-send">
            {SUCCESS_EMAIL_SENT}
          </p>
        </Alert>
      )}
      <form onSubmit={handleSubmit} className="forgot-password-form">
        <EmailField
          onChange={(value) => handleChange(value)}
          errMsg={form.validation.email}
          value={form.values.email}
          id="reset-password-email"
          autoComplete="email"
          autoFocus
        />
        <p className="forgot-password-mesage">
          {FORGOT_EMAIL_MESSAGE_1} {FORGOT_EMAIL_MESSAGE_2}
        </p>
        <DynamicButton
          type="submit"
          id="btn-submit-reset-password-email"
          className="forgot-pwd__btn-reset-password"
          data-testid="btn-submit-reset-password-email"
          data={CTA_CONTENT_FORGOT_PASSWORD}
          feedback={form.ctaState}
        />
      </form>
    </StyledForgotPasswordContainer>
  );
};
