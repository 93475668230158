import styled from '@emotion/styled';
import Text from '../text';

type Props = {
  isCurrentPage?: boolean;
};
export const StyledPaginator = styled(Text)<Props>(
  {
    cursor: 'pointer',
    padding: '0 4px',
    userSelect: 'none',
    opacity: 0.5,
  },
  ({ isCurrentPage = true }) =>
    isCurrentPage && {
      opacity: 1,
    }
);
