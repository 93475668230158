import * as React from 'react';
import Transition from 'react-transition-group/Transition';

import { Box, Text, Icon } from '../../../design-system';
import withPopup from '../hocs/withPopup';
import { ModalContent } from './Modal';
import { ModalPreset } from '../types';

type Props = {
  isOpen: boolean;
  content: React.ReactNode;
  preset: ModalPreset;
  closePopup: () => void;
  title?: string;
};

export const Popup = ({ isOpen, content, title, preset, closePopup }: Props) => (
  <Transition in={isOpen} mountOnEnter unmountOnExit timeout={0}>
    {() => (
      <ModalContent isPopup preset={preset}>
        <Box m={title ? 'xl' : 'na'}>
          {title && (
            <Box display="flex" alignItems="center" justifyContent="space-between" mb="xl">
              <Text preset="heading">{title}</Text>
              <Icon name="close" onClick={closePopup} />
            </Box>
          )}
          {content}
        </Box>
      </ModalContent>
    )}
  </Transition>
);

export default withPopup(Popup);
