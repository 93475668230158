import { ShippingTypes } from './types';

export const SHIPMENT_METHODS_TEXT = {
  [ShippingTypes.HOME]: 'Livraison à domicile',
  [ShippingTypes.STORE]: 'Livraison en magasin',
  [ShippingTypes.PICKUP]: 'Livraison en point relais',
};
export const NEW_ADDRESS = 'Nouvelle Adresse';
export const ERR_TERMS_OF_SALES = 'Merci de valider les conditions générales de vente';
export const COUPON_ACCEPTED = 'Votre code de réduction a été appliqué à votre panier';
export const CTA_TO_DELIVERY: [string, string, string] = ['Valider mon panier', '', ''];
export const CTA_TO_PAYMENT: [string, string, string] = ['Valider ma livraison', '', ''];
export const LABEL_SHIPPING = 'Choisissez votre mode de livraison';
export const MSG_EMPTY_CART = 'Votre Panier est vide';
export const SHIPPING_AMOUNT_FREE = 'Offerte';
export const SHIPPING_AMOUNT_INVALID = 'Ignoré';
export const MSG_CART_ITEM_REMOVED = 'L’article a été bien supprimé de votre panier';
export const MSG_CART_ITEM_INVALID = 'Malheureusement la quantité souhaitée n’est pas disponible.';
export const MSG_CART_ITEM_UNAVAILABLE = 'Malheureusement cet article n’est plus disponible.';
export const TXT_CART_ITEM_REMOVE = 'Supprimer cet article';
export const MSG_CART_ITEMS_UNAVAILABLE =
  'Malheureusement certains articles ne sont plus disponibles.';
export const TXT_CART_ITEMS_REMOVE = 'Supprimer ces articles';
export const TXT_CART_ITEM_UNDO = 'Revenir en arrière';
export const LBL_FEEDBACK_TOTAL_CART = 'Total Panier';
export const MSG_FEEDBACK_SUCCESS = 'Ajouté avec succès';
export const MSG_GIFT_BANNER_TITLE = 'Félicitations !';
export const MSG_GIFT_BANNER_BODY =
  'En guise de cadeau, vous trouverez un article entièrement gratuit.';
export const MSG_GIFT_BANNER_BODY_MULTIPLE =
  'En guise de cadeaux, vous trouverez plusieurs articles entièrement gratuits.';
export const LBL_GIFT_ITEM = 'GRATUIT';
export const LBL_SUMMARY_TITLE = 'Votre panier';
export const LBL_PROMO_CODE = 'Code promo';
export const LBL_DELIVERY_TITLE_SUMMARY = `Livraison choisie à l'étape suivante`;
export const LBL_DELIVERY_CHOICE = [`Boutique (sans minimum d'achat)`, 'Domicile', 'Point relais'];
export const LBL_RETURN_AND_EXCHANGES = [
  'Échanges et retours',
  'Échanges et retours en boutique',
  'Les retours par courrier restent à la charge du client.',
];
export const LBL_DELIVERY = 'Livraison';
export const LBL_FREE = 'Gratuite';
export const LBL_TOTAL = 'Total à payer';
export const NEW_LBL_TOTAL = 'Total';
export const LBL_SUBTOTAL = 'Sous-total';
export const LBL_DISCOUNTS = 'Remise appliquée';
export const CTA_CLOSE_FEEDBACK = 'Fermer';
export const TXT_LINK_CART = 'Voir mon panier';
export const TXT_DROPDOWN_CART = 'Voir mes articles';
export const TITLE_CART = 'Panier';
export const TXT_MOVE_TO_WISHLIST = 'Déplacer en favoris';
export const MSG_MOVED_TO_WISHLIST = 'Déplacé en favoris !';
export const ERR_POSTAL_CODE =
  'Vous pouvez vous faire livrer à l’adresse de votre choix, uniquement en France métropolitaine (Corse inclus)';
export const LBL_GIFT_CART_DATE = `Date d'envoi : `;
export const LBL_GIFT_CART_RECIPIENT = 'Destinataire : ';
export const LBL_ERROR_FORM_SHIPPING = 'Veuillez valider une adresse de livraison';
export const LBL_ERROR_FORM_BILLING = 'Veuillez valider une adresse de facturation';
export const LBL_ERROR_FORM_OPEN = 'Veuillez valider vos modifications en cours';
export const LBL_OPTIN_NEWS_LETTER = ' Recevez nos actualités';
export const LBL_DELETE = 'supprimer';
export const LBL_APPLY = 'appliquer';
export const LBL_ADD = 'ajouter';
