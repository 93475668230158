import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Icon from '../../icon';

type Props = {
  id: string;
  iconName?: string;
  size?: number;
  onClick: () => void;
};

export function CloseButton({ id, iconName = 'close', size = 16, onClick }: Props) {
  return <HoveredCloseButton name={iconName} size={size} onClick={onClick} id={id} />;
}

const HoveredCloseButton = styled(Icon)(
  css({
    opacity: 1,
    transition: 'opacity 0.5s',
    '@media (hover: hover)': {
      ':hover': {
        opacity: 0.5,
      },
    },
  })
);
