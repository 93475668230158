import React from 'react';
import { Box, Text } from '..';

type Props = {
  type: 'success' | 'error';
  title: string;
  children: React.ReactNode;
};

const colors = {
  success: {
    backgroundColor: 'rgba(54,199,84,0.1)',
    borderColor: 'rgba(54,199,84,0.5)',
  },
  error: {
    backgroundColor: '#F4E0E1',
    borderColor: 'rgba(227,29,41,0.5)',
  },
};

export function Alert({ type, title, children }: Props) {
  return (
    <Box
      width={'100%'}
      backgroundColor={colors[type].backgroundColor}
      border={`solid 1px ${colors[type].borderColor}`}
      borderRadius={2}
      padding={8}
      marginBottom={16}
    >
      <Text fontWeight={'600'} marginBottom={4}>
        {title}
      </Text>
      {children}
    </Box>
  );
}
