import * as React from 'react';

import { Box } from '../../../design-system';
import { useTimer } from '../hooks/useTimer';
import locale from '../locale';
import { ColorText } from '../../common/components/Custom';
import TimerItem from './TimerItem';

type Props = {
  timestamp: string;
  textColor?: string;
  preset:
    | 'bannerLarge'
    | 'heading'
    | 'subheading'
    | 'promoBanner'
    | 'promoBannerMobile'
    | 'subheadingPromoBanner';
  isWrapped?: boolean;
};

const Timer = ({ timestamp, textColor, preset, isWrapped }: Props) => {
  const { days, hours, minutes, seconds, isExpired } = useTimer(timestamp);

  const withZero = (n: number) => (n < 10 ? `0${n}` : `${n}`);

  const items: { value: string; label: string }[] = [
    { value: `${days}`, label: days > 1 ? locale.DAYS : locale.DAY },
    { value: withZero(hours), label: hours > 1 ? locale.HOURS : locale.HOUR },
    { value: withZero(minutes), label: minutes > 1 ? locale.MINUTES : locale.MINUTE },
    { value: withZero(seconds), label: seconds > 1 ? locale.SECONDS : locale.SECOND },
  ];

  const wrappedItem = isWrapped ? items.pop() : undefined;

  if (isExpired) {
    return null;
  }

  return (
    <>
      <Box
        display="grid"
        alignItems="baseline"
        justifyContent="center"
        gridAutoFlow="column"
        gridGap={
          preset === 'bannerLarge' || preset === 'heading'
            ? 'xs'
            : preset === 'promoBanner'
            ? '24px'
            : preset === 'promoBannerMobile'
            ? '16px'
            : preset === 'subheadingPromoBanner'
            ? '8px'
            : 'na'
        }
        textAlign="center"
      >
        {items.map(({ value, label }, index) => (
          <React.Fragment key={index}>
            <TimerItem {...{ value, label, textColor, preset }} />
            {index < items.length - 1 && (
              <ColorText cmsColor={textColor} preset={preset} fontWeight="bold">
                :
              </ColorText>
            )}
          </React.Fragment>
        ))}
      </Box>
      {wrappedItem && (
        <TimerItem
          textColor={textColor}
          value={wrappedItem.value}
          label={wrappedItem.label}
          preset="bannerSmall"
        />
      )}
    </>
  );
};

export default Timer;
