export default {
  OPEN_SIDEBAR: 'SIDEBAR / OPEN',
  CLOSE_SIDEBAR: 'SIDEBAR / CLOSE',
  CHANGE_TITLE: 'SIDEBAR / CHANGE TITLE',
  OPEN_SIDEBAR_NEW: 'SIDEBAR NEW / OPEN',
  CLOSE_SIDEBAR_NEW: 'SIDEBAR NEW / CLOSE',
  CHANGE_TITLE_NEW: 'SIDEBAR NEW / CHANGE TITLE',
  OPEN_MODAL: 'MODAL / OPEN',
  CLOSE_MODAL: 'MODAL / CLOSE',
  OPEN_MODAL_NEW: 'MODAL NEW / OPEN',
  CLOSE_MODAL_NEW: 'MODAL NEW / CLOSE',
  OPEN_POPUP: 'POPUP / OPEN',
  CLOSE_POPUP: 'POPUP / CLOSE',
  UPDATE_DEVICE: 'COMMON / UPDATE DEVICE',
  SET_IS_OUTDATED: 'COMMON / SET IS OUTDATED',
} as const;
