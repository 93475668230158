import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { CmsFontFamily, CmsTagLine, CmsHtmlTag } from '../../cms/types';
import { useTextReveal } from '../../common/hooks/useTextReveal';
import { getOurBrandBannerPosition } from '../utils';
import { breakpoints, colors } from '../../../design-system';

type Props = {
  cmsData: CmsTagLine | null;
  index: number;
};

type StyledContainerProps = {
  tag_line_position_on_page: number;
  tag_line_margin_bottom: number;
  tag_line_margin_bottom_small_screen: number;
  tag_line_text_background_color: string;
  tag_line_text_color: string;
  tag_line_text_font_family: CmsFontFamily;
  tag_line_html_tag: CmsHtmlTag;
  tag_line_text_font_size: number;
  tag_line_text_font_size_small_screen: number;
  tag_line_text_font_weight: number;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  order: ${(props) => props.tag_line_position_on_page};
  background-color: ${(props) => props.tag_line_text_background_color};
  padding: 16px;
  width: 100%;
  margin-bottom: ${(props) => props.tag_line_margin_bottom_small_screen}px;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.tag_line_margin_bottom}px;
    padding: 24px;
  }

  @media (min-width: ${breakpoints.L}px) {
    padding: 32px;
  }

  .tag-line-text {
    margin-block-start: 0;
    margin-block-end: 0;

    span {
      font-size: ${(props) => props.tag_line_text_font_size_small_screen}px;
      font-weight: ${(props) => props.tag_line_text_font_weight};
      color: ${(props) => props.tag_line_text_color};
      font-family: ${(props) => props.tag_line_text_font_family};

      @media (min-width: ${breakpoints.M}px) {
        font-size: ${(props) => props.tag_line_text_font_size}px;
      }
    }
  }
`;

export const TagLineBanner = ({ cmsData, index }: Props) => {
  const {
    tag_line_position_on_page,
    tag_line_animation_active,
    tag_line_margin_bottom,
    tag_line_margin_bottom_small_screen,
    tag_line_text,
    tag_line_text_background_color,
    tag_line_text_color,
    tag_line_text_font_family,
    tag_line_html_tag,
    tag_line_text_font_size,
    tag_line_text_font_size_small_screen,
    tag_line_text_font_weight,
  } = cmsData?.primary ?? {};

  const containerRef = useRef<HTMLHeadingElement | HTMLParagraphElement | null>(null);
  const { data } = useTextReveal(tag_line_text, tag_line_animation_active ?? false, containerRef);
  const HtmlTag = tag_line_html_tag ?? 'p';

  return (
    <StyledContainer
      id={`tag-line-banner-block-${index}`}
      tag_line_position_on_page={getOurBrandBannerPosition(
        tag_line_position_on_page ?? 'Not activated'
      )}
      tag_line_margin_bottom={tag_line_margin_bottom ?? 0}
      tag_line_margin_bottom_small_screen={tag_line_margin_bottom_small_screen ?? 0}
      tag_line_text_background_color={tag_line_text_background_color ?? colors.WHITE}
      tag_line_text_color={tag_line_text_color ?? colors.BLACK}
      tag_line_text_font_family={tag_line_text_font_family ?? 'Ogg-text'}
      tag_line_text_font_size={tag_line_text_font_size ?? 40}
      tag_line_text_font_size_small_screen={tag_line_text_font_size_small_screen ?? 30}
      tag_line_text_font_weight={tag_line_text_font_weight ?? 700}
      tag_line_html_tag={tag_line_html_tag ?? 'p'}
    >
      <HtmlTag ref={containerRef} className="tag-line-text">
        {data}
      </HtmlTag>
    </StyledContainer>
  );
};
