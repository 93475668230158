import React, { useEffect } from 'react';

import { Product } from '../../product/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { isValidProduct } from '../../ecart/utils';
import { CatalogProduct } from '../../catalog/types';
import { ProductCard } from '../../catalog/components/ProductCard';

type Props = {
  productRef: string;
  isLastProduct: boolean;
  setIsLoading: (value: boolean) => void;
};

const addFieldToProduct = (hit: Product): Product => {
  const product = {
    ...hit,
    objectID: `${hit.productRef}/${hit.colorRef}`,
    rcFilter: `${hit.productRef}-${hit.colorRef}`,
  };
  return product;
};

export const SearchMostWantedProducts = ({ productRef, isLastProduct, setIsLoading }: Props) => {
  const { hits } = useProductsInfos([productRef]);
  const hit = hits?.length ? addFieldToProduct(hits[0]) : null;

  useEffect(() => {
    if (hit && isValidProduct(hit)) {
      setIsLoading(!isLastProduct);
    }
  }, [hit, isLastProduct]);

  return hit && isValidProduct(hit) ? (
    <div className="search-product-card">
      <ProductCard hit={hit as unknown as CatalogProduct} leftAlignToolTip />
    </div>
  ) : null;
};
