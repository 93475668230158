export const colors = {
  BLACK: '#000',
  MAIN_GREY: '#545454',
  GREY: '#5a5a5a',
  GREY2: '#9b9b9b',
  LIGHT: '#e6e6e6',
  LIGHT2: '#f2f2f2',
  BACKGROUND: '#f9f9f9',
  ERROR: '#d5292e',
  MAIN_ERROR: '#ED404B',
  SUCCESS: '#1EB23C',
  MAIN_SUCCESS: '#0D9560',
  PROMO: '#c21947',
  MAIN_PROMO: '#E31D29',
  WHITE: '#fff',
} as const;

export const opacities = {
  DISABLED: 0.4,
  HOVERED: 0.7,
} as const;

export const durations = {
  FOCUS_DELAY: 500,
  SCROLL_DELAY: 1000,
  LOADING_BACKGROUND: 2000,
} as const;

export const breakpoints = {
  S: 768,
  M: 992,
  L: 1226,
  XL: 1920,
} as const;

export const mq = {
  MOBILE: `(max-width: ${breakpoints.S}px)`,
  TABLET: `(min-width: ${breakpoints.S}px)`,
  DESKTOP: `(min-width: ${breakpoints.M}px)`,
  LARGE: `(min-width: ${breakpoints.L}px)`,
  FULL: `(min-width: ${breakpoints.XL}px)`,
} as const;

export const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    WebkitTextSizeAdjust: '100%',
    fontFamily: 'MaisonNeue, sans-serif',
    fontWeight: 400,
  },
  html: {
    fontSize: '10px',
  },
  body: {
    overflowX: 'hidden',
    margin: 0,

    width: '100vw',
  },
} as const;

export const theme = {
  colors,
  space: {
    na: 0,
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '80px',
    h: '160px',
  },
  breakpoints: [`${breakpoints.S}px`, `${breakpoints.M}px`, `${breakpoints.XL}`],
  fontSizes: {
    xs: '1.2rem',
    s: '1.4rem',
    sw: '1.5rem',
    m: '1.6rem',
    l: '2.0rem',
    xl: '3.0rem',
    xxl: '4.0rem',
    xxxl: '5.0rem',
    xxxxl: '6.0rem',
    xxxxxl: '7.0rem',
    h: '8.0rem',
    xh: '12.0rem',
  },
  lineHeights: {
    xs: '1.6rem',
    s: '1.8rem',
    sw: '1.9rem',
    m: '2.0rem',
    l: '2.4rem',
    xl: '3.4rem',
    xxl: '4.4rem',
    xxxl: '5.4rem',
    xxxxl: '6.4rem',
    xxxxxl: '7.4rem',
    h: '8.4rem',
    xh: '12.4rem',
  },
  zIndices: {
    absolute: 1,
    dropdown: 12,
    fixed: 100,
  },
  shadows: {
    onImage: '0 0 8px rgba(0,0,0, 0.24)',
  },
  fontWeights: {
    regular: 300,
    colorRegular: 400,
    colorBold: 700,
    bold: 600,
  },
  sizes: {
    na: 0,
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '48px',
    h: '80px',
    xh: '120px',
    mwxs: '456px',
    mws: '768px',
    mwm: '1024px',
    mwl: '1920px',
  },
} as const;
