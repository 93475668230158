export const LBL_EMAIL = 'Votre adresse e-mail';
export const CTA_SUBSCRIBE: [string, string, string] = [
  'VALIDER',
  'OK !',
  'Une erreur est survenue.',
];
export const MSG_PHOTOS_RETOUCHED = 'Photographies retouchées';
export const TITLE_PAYMENT_METHODS_SECURE = 'Paiement sécurisé';
export const TITLE_SOCIAL_LINKS = 'Suivez-Nous';
export const TITLE_LEGAL = 'Information';
