import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { numberOfWishlistItemsSelector } from '../selectors';
import { EmptyCart } from '../../cart/components/EmptyCart';
import { WishlistItemList } from './WishlistItemList';

export const WishlistBar = () => {
  const numberOfItems = useSelector((state: RootState) => numberOfWishlistItemsSelector(state));

  if (numberOfItems < 1) {
    return <EmptyCart mini wishlist />;
  }

  return <WishlistItemList />;
};
