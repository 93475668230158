import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/core';

import { Box, Text, Icon, colors } from '../../../design-system';
import locale from '../locale';
import { openModal } from '../../common/actions';
import RecycleModal from './RecycleModal';
import RecycleToolTip from './RecycleToolTip';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

export const RECYCLE_BG_COLOR = '#B5C6A7';
export const RECYCLE_TEXT_COLOR = '#96A688';

export const RecycleLabel = () => {
  const dispatch = useDispatch();

  const { isMobile, isTablet } = useMediaQueries();

  const [isHovered, setIsHovered] = useState(false);

  const handleOpenRecycleModal = () => {
    dispatch(openModal({ content: <RecycleModal /> }));
  };

  if (isMobile || isTablet) {
    return (
      <Box
        bg={RECYCLE_BG_COLOR}
        px="s"
        py="xs"
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="center"
        justifyContent="space-between"
        onClick={isTablet ? handleOpenRecycleModal : undefined}
      >
        <Box display="grid" gridAutoFlow="column" gridGap="s" alignItems="center">
          <Icon name="change" color="WHITE" size={20} />
          <Text preset="caption" color="WHITE" fontWeight="bold">
            {locale.LBL_RECYCLE}
          </Text>
        </Box>
        {isMobile && (
          <button
            type="button"
            className="anchor-animated"
            data-testid="mobile-btn-open-recycle-modal"
            id="mobile-btn-open-recycle-modal"
            onClick={handleOpenRecycleModal}
            css={css`
              font-size: 1.2rem;
              color: ${colors.WHITE}!important;
            `}
          >
            {locale.TXT_MORE_INFO}
          </button>
        )}
      </Box>
    );
  }
  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        bg={RECYCLE_BG_COLOR}
        px="s"
        py="xs"
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="center"
      >
        <Icon name="change" color="WHITE" size={20} />
        <Text preset="caption" color="WHITE" fontWeight="bold">
          {locale.LBL_RECYCLE}
        </Text>
      </Box>
      {isHovered && <RecycleToolTip />}
    </Box>
  );
};
