import React from 'react';
import { css } from '@emotion/core';

import { CartStep } from '../types';
import { zIndex } from '../../common/constants';
import { breakpoints, colors } from '../../../design-system/systemprovider/constants';
import { CartIemsListSummary } from './CartItemsListSummary';
import { TotalAmount } from './TotalAmount';
import { PaypalExpressButton } from '../../PaypalExpress/PaypalExpressButton';
import { Cta } from './Cta';
import { isCtaActive } from '../../form/utils';
import { FormAddress, FormDelivery, FormPayment, Forms } from '../../form/types';

type Props = {
  step: CartStep;
  isItemListBlockOpen: boolean;
  setIsItemListBlockOpen: (value: boolean) => void;
  isCartValid: boolean;
  handleCtaClick: () => void;
  forms: {
    shipping: FormAddress;
    billing: FormAddress;
    delivery: FormDelivery;
    payment: FormPayment;
  };
  formErrorType: Forms | null;
  openedFormType: Forms | null;
};

export const CtaStickyMobile = ({
  step,
  isItemListBlockOpen,
  setIsItemListBlockOpen,
  isCartValid,
  handleCtaClick,
  forms,
  formErrorType,
  openedFormType,
}: Props) => (
  <div
    id="sticky-button-mobile-block"
    css={css`
      display: block;
      position: fixed;
      width: 100%;
      z-index: ${zIndex.PRODUCT_SELECTOR_MODAL};
      bottom: 0;
      padding: 16px;
      background: ${colors.BACKGROUND};
      box-shadow: 0px -8px 8px rgba(0, 0, 0, 0.1);
      @media (min-width: ${breakpoints.S}px) {
        display: none;
      }
    `}
  >
    {step > CartStep.LISTING ? (
      <CartIemsListSummary
        step={step}
        isItemListBlockOpen={isItemListBlockOpen}
        setIsItemListBlockOpen={setIsItemListBlockOpen}
      />
    ) : null}
    {step === CartStep.LISTING ? <TotalAmount step={step} /> : null}
    {step < CartStep.PAYMENT ? (
      <Cta
        onClick={handleCtaClick}
        step={step}
        isActive={isCtaActive(forms) && forms[Forms.delivery].focus !== 'search'}
        disabled={!isCartValid || Boolean(formErrorType || openedFormType)}
        ctaState={
          step === CartStep.LISTING ? forms[Forms.delivery].ctaState : forms[Forms.payment].ctaState
        }
        formErrorType={formErrorType}
        openedFormType={openedFormType}
      />
    ) : null}
    {step === CartStep.LISTING ? <PaypalExpressButton isCartValid={isCartValid} /> : null}
  </div>
);
