import React from 'react';
import styled from '@emotion/styled';

import { CmsMenuItem } from '../../cms/types';
import { Link } from 'react-router-dom';

type Props = {
  banner: CmsMenuItem;
};
const StyledImageBanner = styled.div<Props>`
  background-image: ${(props) => `url(${props.banner.image?.url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 230px;
  height: 325px;
  border-radius: 8px;
  .image-banner-submenu__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .image-banner__description-container {
    text-align: center;
    margin: 8px;
  }
  .image-banner__description {
    font-size: 1.6rem;
    line-height: 2rem;
    color: #fff;
    font-weight: 600;
  }
`;

export const ImageBanner = ({ banner }: Props) => {
  const { name, link } = banner ?? {};
  return (
    <StyledImageBanner banner={banner}>
      {(link || name) && (
        <Link
          to={link ?? '#'}
          className="image-banner-submenu__link"
          data-testid="image-banner-submenu-link"
          aria-label={`Lien vers la page ${name}`}
        >
          {name && (
            <div className="image-banner__description-container">
              <span className="image-banner__description">{name}</span>
            </div>
          )}
        </Link>
      )}
    </StyledImageBanner>
  );
};
