export enum AnalyticsEventTypes {
  click,
  convert,
}

export enum AnalyticsEvents {
  clickItem = 'Click item',
  clickQuickAdd = 'Click quick add',
  clickItemFromBanner = 'Click item from banner',
  addToCartFromQuickAdd = 'Add to cart from quick add',
  addToCartFromPDP = 'Add to cart from PDP',
}

export type AnalyticsEventParams = {
  eventName: AnalyticsEvents;
  type: AnalyticsEventTypes;
  index: string;
  queryID: string;
  objectID: string;
  position?: number;
};
