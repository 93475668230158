import * as React from 'react';
import { useNavigate, useLocation, useNavigationType, NavigationType } from 'react-router-dom';
import { Icon, Text, colors, durations, opacities } from '../../../design-system';
import { TXT_BACK } from '../../common/locale';
import styled from '@emotion/styled';

type Props = {
  breadcrumbItems?: { label: string; value: string }[];
  isEreservation?: boolean;
  onClick?: () => void;
};

const StyledBackButton = styled.button<{ isEreservation: boolean }>`
  color: ${colors.BLACK};
  padding: 0;
  position: absolute;
  top: ${(props) => (props.isEreservation ? 18 : 56)}px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 24px;
  z-index: 1;
  border: 0.5px solid ${colors.LIGHT};
  border-radius: 4px;
  background: ${colors.WHITE};
  gap: 8px;
  transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      opacity: ${opacities.HOVERED};
    }
  }
`;

export const BackButton = ({ breadcrumbItems, isEreservation = false, onClick }: Props) => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const link = breadcrumbItems?.[breadcrumbItems.length - 2].value;
  const location = useLocation();
  const hasPreviousState = typeof location.key === 'string';
  const handleClickBack = () => {
    if (navigationType === NavigationType.Pop && !hasPreviousState && link) {
      navigate(link);
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledBackButton
      type="button"
      id={`btn-back-${isEreservation ? 'ereservation-modal' : 'pdp-mobile'}`}
      data-testid={`btn-back-${isEreservation ? 'ereservation-modal' : 'pdp-mobile'}`}
      onClick={onClick ? onClick : handleClickBack}
      isEreservation={isEreservation}
    >
      <Icon name="chevronLeft" size={10} />
      <Text preset="caption">{TXT_BACK}</Text>
    </StyledBackButton>
  );
};
