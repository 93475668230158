import * as React from 'react';
import styled from '@emotion/styled';

import { Box } from '../../../design-system';
import { zIndex } from '../../common/constants';
import RecycleDetails from './RecycleDetails';

const RecycleToolTip = () => {
  return (
    <StyledToolTip
      position="absolute"
      bg="WHITE"
      zIndex={zIndex.TOOLTIP}
      width="280px"
      top="48px"
      right={0}
      boxShadow="1px 1px 6px 0 rgb(0 0 0 / 27%)"
    >
      <Box mt="l">
        <RecycleDetails />
      </Box>
    </StyledToolTip>
  );
};

export const StyledToolTip = styled(Box)({
  ':after': {
    display: 'block',
    position: 'absolute',
    content: '""',
    border: '12px solid white',
    borderColor: 'white transparent transparent white',
    transformOrigin: '0 0',
    transform: 'rotate(45deg)',
    boxShadow: '-4px -4px 6px 0px rgb(0 0 0 / 10%)',
    right: '48px',
    top: '-14px',
    zIndex: zIndex.TOOLTIP,
  },
});

export default RecycleToolTip;
