import React from 'react';
import styled from '@emotion/styled';
import { getOurBrandBannerPosition } from '../utils';
import { breakpoints, colors } from '../../../design-system';
import { Categories } from '../../home/components/Categories';
import { CmsCategorieBanner } from '../../cms/types';

type Props = {
  cmsData: CmsCategorieBanner | null;
  index: number;
};

type StyledContainerProps = {
  categorie_banner_position_on_page: number;
  categorie_banner_margin_bottom: number;
  categorie_banner_margin_bottom_small_screen: number;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  order: ${(props) => props.categorie_banner_position_on_page};
  margin-bottom: ${(props) => props.categorie_banner_margin_bottom_small_screen}px;
  width: 100%;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.categorie_banner_margin_bottom}px;
  }
`;

export const CategorieBanner = ({ cmsData, index }: Props) => {
  const {
    categorie_banner_position_on_page,
    categorie_banner_margin_bottom,
    categorie_banner_margin_bottom_small_screen,
    categorie_banner_activation_desktop,
    categorie_banner_activation_mobile,
    categorie_banner_background_color_mobile,
    categorie_banner_title_alignment_desktop,
    categorie_banner_title_alignment_mobile,
    categorie_banner_title_desktop,
    categorie_banner_title_mobile,
  } = cmsData?.primary ?? {};

  const { items } = cmsData ?? {};

  if (!items?.length) {
    return null;
  }

  return (
    <StyledContainer
      id={`categorie-banner-block-${index}`}
      categorie_banner_position_on_page={getOurBrandBannerPosition(
        categorie_banner_position_on_page ?? 'Not activated'
      )}
      categorie_banner_margin_bottom={categorie_banner_margin_bottom ?? 0}
      categorie_banner_margin_bottom_small_screen={categorie_banner_margin_bottom_small_screen ?? 0}
    >
      <Categories
        content={items}
        titleDesktop={categorie_banner_title_desktop}
        titleMobile={categorie_banner_title_mobile}
        titleDesktopAlignment={categorie_banner_title_alignment_desktop}
        titleMobileAlignment={categorie_banner_title_alignment_mobile}
        backgroundColorMobile={categorie_banner_background_color_mobile ?? colors.WHITE}
        isActiveDesktop={categorie_banner_activation_desktop}
        isActiveMobile={categorie_banner_activation_mobile}
      />
    </StyledContainer>
  );
};
