import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../../../design-system';
import { formatPrice } from '../../common/utils';

type Props = {
  originalPrice: number;
  storePrice: number;
  isFeedBack?: boolean;
};

const StyledPriceContainer = styled.div<{ isFeedBack: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isFeedBack ? 'column' : 'row')};
  text-align: left;

  .store-price {
    font-size: 1.4rem;
    line-height: 21px;
    font-weight: 700;
    color: ${colors.BLACK};
    margin-right: 12px;
  }

  .original-price {
    font-size: 1.4rem;
    line-height: 21px;
    font-weight: 400;
    color: #989898;
    text-decoration: line-through;
  }
`;

export const Price = ({ originalPrice, storePrice, isFeedBack = false }: Props) => {
  const formattedOriginalPrice = formatPrice(originalPrice);
  const formattedStorePrice = formatPrice(storePrice);
  const hasPromotion = originalPrice !== storePrice;
  return (
    <StyledPriceContainer isFeedBack={isFeedBack}>
      <span className="store-price">{formattedStorePrice}</span>
      {hasPromotion && <span className="original-price">{formattedOriginalPrice}</span>}
    </StyledPriceContainer>
  );
};
