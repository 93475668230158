import * as React from 'react';

import { Box, Icon } from '../../../../../design-system';
import { transitions } from '../../../../common/constants';

type Props = {
  position: 'Left' | 'Right';
  isSliderHovered: boolean;
  onClick?: () => void;
};

export const SliderArrow = ({ position, isSliderHovered, onClick }: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      position="absolute"
      width="64px"
      height="100%"
      top={0}
      left={position === 'Left' ? 0 : 'initial'}
      right={position === 'Left' ? 'initial' : 0}
      zIndex="absolute"
      bg={
        isHovered
          ? 'rgba(255,255,255, 0.9)'
          : isSliderHovered
          ? 'rgba(255,255,255, 0.4)'
          : 'rgba(255,255,255, 0)'
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition={transitions.GENERIC}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
    >
      <Box opacity={1}>
        <Icon name={`chevron${position}`} size={20} />
      </Box>
    </Box>
  );
};
