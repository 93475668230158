import * as React from 'react';
import { Box, Icon, Button, Divider } from '../../../design-system';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../common/actions';
import {
  ERESA_CODE_PROMO_INFOS,
  ERESA_CONFIRMATION,
  ERESA_CONFIRMATION_DESCRIPTION,
  ERESA_CONTINUE_TO_BUY,
  ERESA_ERROR_SOLUTION_ONE,
  ERESA_ERROR_SOLUTION_TWO_1,
  ERESA_ERROR_SOLUTION_TWO_2,
  ERESA_ERROR_FREE_DELIVERY_STORE,
  ERESA_ERROR_TEXT,
} from '../locale';
import { RootState } from '../../../../shared/store/rootReducer';
import { setEresaRequestError } from '../actions';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  isError?: boolean;
  reservationId?: string;
};

export const EresaResults = ({ isError, reservationId = '' }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQueries();
  const { standards } = useSelector((state: RootState) => state.cms.ecart);
  const { isError: isEresaStateError } = useSelector((state: RootState) => state.ecart);

  React.useEffect(() => {
    return () => {
      if (isEresaStateError && isError) {
        dispatch(setEresaRequestError(false));
      }
    };
  }, []);

  return (
    <Box
      id={isError ? 'eresa-modal-results-error' : 'eresa-modal-results-success'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDirection="column"
      width="100%"
      height={isMobile ? '100vh' : '412px'}
      p={isMobile ? '16px' : '32px'}
    >
      {isError ? (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Box display="grid" alignItems="center" justifyContent="center" gridGap="xs">
            <Box position="relative" justifySelf="center">
              <Box
                size="16px"
                bg="#E31D29"
                borderRadius="50%"
                position="absolute"
                top="16px"
                right="-2px"
                display="grid"
                alignItems="center"
                justifyContent="center"
              >
                <Icon name="close" size={11} color="#FFF" />
              </Box>
              <Icon name="shop" size={30} />
            </Box>
          </Box>
          <Box
            width={isMobile ? '90%' : '65%'}
            fontSize="20px"
            fontWeight={700}
            mt="m"
            textAlign="center"
          >
            {ERESA_ERROR_TEXT}
          </Box>
          <Box
            mt="40px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            width={isMobile ? '90%' : '65%'}
          >
            <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
              <Box
                width="l"
                minWidth="l"
                minHeight="l"
                height="l"
                border="solid 1px #545454"
                borderRadius="50%"
                fontSize="14px"
                color="#545454"
                alignItems="center"
                justifyContent="center"
                display="flex"
                mr="m"
              >
                1
              </Box>
              <Box fontSize="16px" lineHeight="110%" color="#545454">
                {ERESA_ERROR_SOLUTION_ONE}
              </Box>
            </Box>
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              my="m"
              color="#545454"
              fontSize="16px"
            >
              ou
            </Box>
            <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
              <Box
                width="l"
                minWidth="l"
                minHeight="l"
                height="l"
                border="solid 1px #545454"
                borderRadius="50%"
                fontSize="14px"
                color="#545454"
                alignItems="center"
                justifyContent="center"
                display="flex"
                mr="m"
              >
                2
              </Box>
              <Box fontSize="16px" lineHeight="110%" color="#545454">
                {ERESA_ERROR_SOLUTION_TWO_1}
                <span style={{ fontWeight: 700 }}>{` ${ERESA_ERROR_FREE_DELIVERY_STORE} `}</span>
                {ERESA_ERROR_SOLUTION_TWO_2}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" width="100%" justifyContent="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="solid 1px #36C754"
              borderRadius="50%"
              width="l"
              height="l"
              bg="#FFF"
            >
              <Icon name="checkmark" size={12} color="#36C754" />
            </Box>
          </Box>
          <Box fontSize="20px" lineHeight="22px" fontWeight={700} mt="s" textAlign="center">
            {ERESA_CONFIRMATION(reservationId)}
          </Box>
          <Box
            display="flex"
            width="100%"
            textAlign="center"
            justifyContent="center"
            fontSize="14px"
            lineHeight="18px"
            color="#5A5A5A"
            mt="s"
          >
            {ERESA_CONFIRMATION_DESCRIPTION}
          </Box>
          <Box width="144px">
            <Divider my="32px" color="#9B9B9B" />
          </Box>
          <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
            {standards?.map((item, index) => {
              return (
                <Box
                  key={`eresa-modal-results-icon-${item?.icon}-${index}`}
                  display="flex"
                  width={`${450 / standards.length}px`}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Icon name={item?.icon ?? ''} size={18} />
                  <Box fontSize="12px" textAlign="center" color="#5A5A5A" mt="s">
                    {item?.standard ?? ''}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            display="flex"
            width="100%"
            my="32px"
            fontSize="12px"
            lineHeight="110%"
            textAlign="center"
          >
            {ERESA_CODE_PROMO_INFOS}
          </Box>
          <Box display="flex" width={isMobile ? '343px' : '210px'} height="44px">
            <Button
              data-cy="ereservation-results-button-close"
              fontWeight="700"
              id="eresa-modal-results-button-continue"
              onClick={() => dispatch(closeModal())}
            >
              {ERESA_CONTINUE_TO_BUY}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        position="absolute"
        top={isMobile ? '16px' : '32px'}
        right={isMobile ? '16px' : '32px'}
      >
        <Icon
          data-cy="ereservation-results-icon-close"
          onClick={() => dispatch(closeModal())}
          name="close"
          size={16}
        />
      </Box>
    </Box>
  );
};
