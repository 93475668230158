import { State, OrderStatus } from './types';

export const orderDetailState = {
  orderNumber: 0,
  createdAt: '',
  status: OrderStatus.NEW,
  shippingAddress: {
    id: '',
    name: '',
    company: '',
    firstName: '',
    lastName: '',
    street: '',
    streetAdditional: '',
    postal: '',
    city: '',
    country: '',
    phone: '',
    idTva: '',
    siret: '',
  },
  billingAddress: {
    id: '',
    name: '',
    company: '',
    firstName: '',
    lastName: '',
    street: '',
    streetAdditional: '',
    postal: '',
    city: '',
    country: '',
    phone: '',
    idTva: '',
    siret: '',
  },
  items: [],
  shippingType: undefined,
  storeInfos: {
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    id: 0,
    name: '',
    postalCode: '',
  },
  pickupStationInfos: undefined,
  total: 0,
  originalBaseTotal: 0,
  totalCouponAmount: 0,
  totalDiscount: 0,
  shippingAmount: 0,
  trackingNumber: '',
  tracking: [],
  paymentMethod: '',
  cardSummary: '',
  isDigital: false,
};

export const accountState: State = {
  isFetching: true,
  errMsg: '',
  orders: [],
  order: undefined,
  user: undefined,
};
