import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import AffiliateForm from './AffiliateForm';
import ImagesBlock from './ImagesBlock';
import TestimonialsBlock from './TestimonialsBlock';
import DescriptionBlock from './DescriptionBlock';
import ConditionsBlock from './ConditionsBlock';
import UspSection from '../../home/components/UspSection';
import { cmsAffiliateInit, cmsUspInit } from '../../cms/actions';
import { SeoDescription } from '../../common/components/SeoDescription';
import { BodyAffiliateTestimonialEl, BodyImagesEl } from '../../cms/types';
import { Helmet } from 'react-helmet-async';
import { AffiliateVideoBlock } from './AffiliateVideoBlock';

const StyledAffiliateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Affiliate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cmsUspInit() as unknown as UnknownAction);
    dispatch(cmsAffiliateInit('devenir-affilie') as unknown as UnknownAction);
  }, []);

  const cmsUsp = useSelector((state: RootState) => state.cms.usp);
  const {
    body,
    first_image,
    first_video,
    pdf_affiliation,
    meta_title,
    meta_description,
    seo_description,
  } = useSelector((state: RootState) => state.cms.affiliation) ?? {};
  const { usp, usp_main_title } = cmsUsp ?? {};
  const { email, given_name, family_name } =
    useSelector((state: RootState) => state.auth.user) ?? {};
  const imagesUrl = body
    ?.find((slice): slice is BodyImagesEl => slice?.slice_type === 'images_with_animation')
    ?.items.map((el) => el.image_animated);
  const testimonials = body?.find(
    (slice): slice is BodyAffiliateTestimonialEl => slice.slice_type === 'testimonials'
  )?.items;

  return (
    <StyledAffiliateContainer id="affiliate-block">
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <DescriptionBlock image={first_image} video={first_video} />
      <AffiliateVideoBlock />
      {imagesUrl ? <ImagesBlock images={imagesUrl} /> : null}
      <ConditionsBlock />
      {testimonials ? <TestimonialsBlock testimonials={testimonials} /> : null}
      {pdf_affiliation ? (
        <AffiliateForm
          pdf_link={pdf_affiliation.url}
          email={email}
          firstName={given_name}
          lastName={family_name}
        />
      ) : null}
      {usp?.length ? <UspSection content={usp} title={usp_main_title ?? ''} /> : null}
      <SeoDescription seo_description={seo_description} alignment="left" />
    </StyledAffiliateContainer>
  );
};
