import styled from '@emotion/styled';

import { breakpoints, colors } from '../../../design-system';
import { zIndex } from '../../common/constants';

export const SearchAutocompleteWrapper = styled.div<{ isSuggestionsDisplayed: boolean }>`
  .form__wrapper {
    position: relative;
    padding: 6px 14px;
    color: ${colors.BLACK};
    border: 1px solid ${colors.BLACK};
    border-radius: 15px;
    width: 100%;
    transition: all 0.4s;
    form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      div {
        width: 100%;
        display: flex;
        align-items: center;
      }
      input {
        border: none;
        background: inherit;
        color: ${colors.BLACK};
        font-size: 1.6rem;
        line-height: 1.8rem;
        padding: 0;
        flex: 1 1 auto;
        :focus {
          outline: none;
        }
        ::placeholder {
          font-size: 1.6rem;
          color: ${colors.BLACK};
        }
      }

      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }

      .clear-input-button {
        display: flex;
        border: none;
        height: 20px;
        width: 20px;
        padding-right: 16px;
        justify-content: center;
        align-items: center;
        background: transparent;
        cursor: pointer;
      }
      .clear-input__icon,
      .input-search__icon {
        filter: unset;
      }

      .input-search-button {
        border: none;
        background: none;
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        cursor: pointer;
        filter: unset;
      }
    }
  }

  .autocomplete-panel {
    position: absolute;
    top: 110px;
    left: 16px;
    width: calc(100% - 32px);
    background: ${colors.BACKGROUND};
    box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 15%);
    border-radius: 8px;
    overflow-y: auto;
    z-index: ${zIndex.MODAL};
    @media (min-width: ${breakpoints.M - 1}px) {
      left: 0;
      background: ${colors.WHITE};
      box-shadow: none;
      border-radius: unset;
      position: initial;
      display: flex;
      gap: 24px;
      padding: ${(props) => (props.isSuggestionsDisplayed ? '14px 16px 0 16px' : 0)};
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0 16px;
      position: relative;
      @media (min-width: ${breakpoints.M - 1}px) {
        display: flex;
        gap: 24px;
        padding: 0;
      }
      li {
        font-size: 1.4rem;
        line-height: 1.8rem;
        cursor: pointer;
        margin: 8px 0;
        @media (min-width: ${breakpoints.M - 1}px) {
          margin: 0;
        }
        &:hover,
        &:focus {
          span {
            color: ${colors.BLACK};
          }
        }

        div {
          border: 0;
          display: flex;
          align-items: center;
          width: 100%;
          gap: 4px;
          justify-content: space-between;
          @media (min-width: ${breakpoints.M - 1}px) {
            justify-content: unset;
          }
        }

        .icon-custom-highlight {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        button {
          display: flex;
          border: none;
          height: 16px;
          width: 16px;
          justify-content: center;
          align-items: center;
          background: transparent;
          cursor: pointer;

          :hover,
          :focus {
            filter: unset;
          }
        }
      }

      li[aria-selected='true'] {
        span {
          color: ${colors.BLACK};
        }
      }
    }
  }
`;
