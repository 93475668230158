import { css } from '@emotion/core';

export const rheostatStyles = css`
  .rheostat {
    touch-action: none;
    pointer-events: none;
  }

  .rheostat-progress {
    position: absolute;
    border: 1px solid #000;
    top: -1px;
  }

  .rheostat-handle {
    background-color: #000;
    border: none;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    margin-left: -7px;
    z-index: 2;
    top: -7px;
    touch-action: pan-x;
    pointer-events: auto;
    cursor: pointer;
  }

  .rheostat-background {
    position: relative;
    border: 1px solid#e6e6e6;
  }
`;
