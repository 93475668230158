import * as React from 'react';

import { Box, Text, Divider } from '../../../design-system';

type Props = {
  nbResults: number;
  lastSearch?: string;
  isPositioned?: boolean;
  isPickupDelivery?: boolean;
  isEresaModal?: boolean;
};

const ResultsTitle = ({
  nbResults,
  lastSearch,
  isPositioned,
  isPickupDelivery,
  isEresaModal,
}: Props) => (
  <>
    <Box display="flex" alignItems="center" justifyContent="space-between" my="xs">
      {!isEresaModal && !isPickupDelivery && (
        <>
          <Text
            id={`${isPickupDelivery ? 'pickup-station' : 'store'}-results-title`}
            preset="caption"
            color="GREY"
          >{`${isPickupDelivery ? 'Point relais' : 'Boutiques'} près de ”${
            isPositioned ? 'Votre position' : lastSearch ? lastSearch : 'France'
          }”`}</Text>
          <Text
            id={`${isPickupDelivery ? 'pickup-station' : 'store'}-results-count`}
            preset="caption"
            color="GREY"
            justifySelf="right"
          >{`${nbResults} Résultat${nbResults < 2 ? '' : 's'}`}</Text>
        </>
      )}
      {(isEresaModal || isPickupDelivery) && (
        <>
          <Box id="eresa-modal-store-results-title" fontSize="14px" color="#000" lineHeight="110%">
            {`Boutiques près de ”${
              isPositioned ? 'Votre position' : lastSearch ? lastSearch : 'France'
            }”`}
          </Box>
          <Box
            id="eresa-modal-store-results-count"
            ml="xs"
            textAlign="right"
            minWidth="80px"
            fontSize="14px"
            color="#000"
            lineHeight="110%"
          >
            {`${nbResults} Résultat${nbResults < 2 ? '' : 's'}`}
          </Box>
        </>
      )}
    </Box>
    <Divider color="BLACK" my="-1px" />
  </>
);

export default ResultsTitle;
