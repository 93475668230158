import React, { useState, useEffect, useCallback, RefObject } from 'react';
import styled from '@emotion/styled';

import { breakpoints, colors, durations, Icon, opacities } from '../../../design-system';
import { Product } from '../../product/types';
import { COMPLETE_WITH, YOU_WILL_LOVE } from '../../ecart/locale';
import { RecoBlockIdTypes } from '../types';

const StyledRecommendationHeader = styled.div<{
  isPrevButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isFeedBack: boolean;
  isTabsRecommendation: boolean;
  isCartRecommendation: boolean;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.isFeedBack || props.isCartRecommendation ? 'left' : 'center'};
  align-items: center;
  position: relative;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.L}px) {
    margin-bottom: ${(props) => (props.isFeedBack ? '16px' : '24px')};
  }

  .recommendations-title {
    margin: 0;
    font-size: ${(props) => (props.isFeedBack ? '1.6rem' : '2rem')};
    font-weight: 700;
    font-family: ${(props) => (props.isFeedBack ? 'MaisonNeue' : 'Ogg-text')};

    @media (min-width: ${breakpoints.M}px) {
      font-size: ${(props) => (props.isFeedBack ? '1.6rem' : '2.4rem')};
    }
  }

  .recommendations-arrow-block {
    position: absolute;
    top: ${(props) => (props.isFeedBack ? '-16px' : props.isTabsRecommendation ? '52px' : '-8px')};
    right: 0;
    display: flex;

    @media (min-width: ${breakpoints.M}px) {
      top: ${(props) =>
        props.isFeedBack ? '-16px' : props.isTabsRecommendation ? '56px' : '-6px'};
    }
  }

  .recommandations-arrow {
    display: none;

    @media (min-width: ${breakpoints.S}px) {
      display: block;
      border: none;
      background-color: unset;
      padding: 12px;
      color: ${colors.BLACK};
      cursor: pointer;
      transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
    }

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .prev-arrow {
    opacity: ${(props) => (props.isPrevButtonDisabled ? opacities.DISABLED : 1)};
    pointer-events: ${(props) => (props.isPrevButtonDisabled ? 'none' : 'initial')};
  }

  .next-arrow {
    opacity: ${(props) => (props.isNextButtonDisabled ? opacities.DISABLED : 1)};
    pointer-events: ${(props) => (props.isNextButtonDisabled ? 'none' : 'initial')};
  }
`;

type Props = {
  id: RecoBlockIdTypes;
  title?: string;
  hits: Product[];
  containerRef: RefObject<HTMLDivElement>;
};

export const RecommendationsHeader = ({ id, title, hits, containerRef }: Props) => {
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [isAtStart, setIsAtStart] = useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);
  const [isArrowBlockVisible, setIsArrowBlockVisible] = useState<boolean>(false);

  const updateItemWidth = useCallback(() => {
    if (containerRef?.current) {
      const firstItem = containerRef.current.querySelector('.recommendations-product-wrapper');
      if (firstItem) {
        setItemWidth((firstItem as HTMLElement).offsetWidth);
      }
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsArrowBlockVisible(scrollWidth > clientWidth);
    }
  }, [hits]);

  const updateButtonStates = () => {
    if (containerRef?.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      setIsArrowBlockVisible(scrollWidth > clientWidth);
    }
  };

  const scrollToPrev = () => {
    if (containerRef?.current) {
      containerRef.current.scrollBy({
        left: -itemWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollToNext = () => {
    if (containerRef?.current) {
      containerRef.current.scrollBy({
        left: itemWidth,
        behavior: 'smooth',
      });
    }
  };

  const getRecommendationsTitle = () => {
    const isFeedBack = id === 'cross-sell';
    if (!title) {
      return isFeedBack ? COMPLETE_WITH : YOU_WILL_LOVE;
    }
    return title;
  };

  useEffect(() => {
    updateItemWidth();
    window.addEventListener('resize', updateItemWidth);

    return () => window.removeEventListener('resize', updateItemWidth);
  }, [updateItemWidth]);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener('scroll', updateButtonStates);
    }

    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener('scroll', updateButtonStates);
      }
    };
  }, [itemWidth]);

  return (
    <StyledRecommendationHeader
      id={`${id}-recommendations-block`}
      isPrevButtonDisabled={isAtStart}
      isNextButtonDisabled={isAtEnd}
      isFeedBack={id === 'cross-sell'}
      isTabsRecommendation={id === 'home-page-tabs'}
      isCartRecommendation={id === 'cart-page'}
    >
      <h2 className="recommendations-title">{getRecommendationsTitle()}</h2>
      {isArrowBlockVisible && (
        <div className="recommendations-arrow-block">
          <button
            type="button"
            className="recommandations-arrow prev-arrow"
            data-testid={`${id}-recommendations-navigation-prev-button-${
              isAtStart ? 'disabled' : 'enabled'
            }`}
            onClick={scrollToPrev}
          >
            <Icon name="arrowLeft" size={20} />
          </button>
          <button
            type="button"
            className="recommandations-arrow next-arrow"
            data-testid={`${id}-recommendations-navigation-next-button-${
              isAtEnd ? 'disabled' : 'enabled'
            }`}
            onClick={scrollToNext}
          >
            <Icon name="arrowRight" size={20} />
          </button>
        </div>
      )}
    </StyledRecommendationHeader>
  );
};
