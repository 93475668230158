export default {
  REQUEST_LOGIN: 'AUTH / REQUEST LOGIN',
  ERROR_LOGIN: 'AUTH / ERROR LOGIN',
  SUCCESS_LOGIN: 'AUTH / SUCCESS LOGIN',
  SUCCESS_LOGOUT: 'AUTH / SUCCESS LOGOUT',
  REQUEST_SIGNUP: 'AUTH / REQUEST SIGNUP',
  ERROR_SIGNUP: 'AUTH / ERROR SIGNUP',
  SUCCESS_SIGNUP: 'AUTH / SUCCESS SIGNUP',
  ERROR_SOCIAL: 'AUTH / ERROR SOCIAL',
  SET_REDIRECT_TO: 'SEARCH / SET REDIRECT TO',
  SET_USER_EXIST: 'AUTH / SET USER EXIST',
  SET_HAS_FORGOT_PASSWORD: 'AUTH / SET HAS FORGOT PASSWORD',
} as const;
