import React from 'react';
import styled from '@emotion/styled';

import { CmsSearch } from '../../cms/types';
import { SearchLinks } from './SearchLinks';
import { breakpoints } from '../../../design-system';
import { SearchMostWanted } from './SearchMostWanted';
import { SearchBannerTiles } from './SearchBannerTiles';

type Props = {
  cmsSearch: CmsSearch;
  isHidden: boolean;
};

const StyledContainer = styled.div<{ isHidden: boolean }>`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  width: calc(100% - 32px);
  margin: 8px 16px;

  @media (min-width: ${breakpoints.L}px) {
    width: 100%;
    margin: 0;
  }

  .link-wrapper {
    display: flex;
  }

  .product-image-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.L}px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
    }
  }
`;

export const CmsSearchBlock = ({ cmsSearch, isHidden }: Props) => {
  const {
    body: cmsLink,
    body1: cmsHelpSection,
    body2: cmsMostWanted,
    body3: cmsBannerTiles,
  } = cmsSearch ?? [];

  return (
    <StyledContainer id="search-cms-block" isHidden={isHidden}>
      <div className="link-wrapper">
        <SearchLinks data={cmsLink?.[0]} />
        <SearchLinks data={cmsHelpSection?.[0]} />
      </div>
      <div className="product-image-wrapper">
        <SearchMostWanted data={cmsMostWanted?.[0]} />
        <SearchBannerTiles data={cmsBannerTiles?.[0]} />
      </div>
    </StyledContainer>
  );
};
