import { DynamicButtonStatus } from '../../design-system';
import { Cart, NotificationType, State, CartStep } from './types';

export const emptyCart: Cart = {
  cartId: '',
  items: [],
  couponCode: '',
  totalCouponAmount: 0,
  couponAccepted: false,
  shippingType: undefined,
  storeId: '',
  pickupStation: undefined,
  shippingAmount: 0,
  freeShipping: false,
  rejectCouponMessage: '',
  totalDiscount: 0,
  notifications: [
    {
      type: NotificationType.Offer,
      title: '',
      message: '',
    },
  ],
  originalBaseTotal: 0,
  total: 0,
  isGiftEnabled: false,
  isValidated: false,
  isDigital: false,
};

export const cartState: State = {
  cart: { ...emptyCart },
  errMsg: '',
  isFetching: true,
  couponCodeIsLoading: false,
  step: CartStep.LISTING,
  ctaState: DynamicButtonStatus.Default,
  shippingTypes: [],
  paypalECS: {
    email: '',
    firstName: '',
    orderNumber: '',
  },
  showFeedback: false,
};
