import { cartState } from './state';
import { State } from './types';
import types from './actionTypes';
import { CartActions } from './actions';

export default function cart(state = cartState, action: CartActions): State {
  switch (action.type) {
    case types.REQUEST_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        isFetching: false,
      };
    case types.REQUEST_CART_FAILURE:
      return {
        ...state,
        errMsg: action.payload,
        isFetching: false,
      };
    case types.REQUEST_SHIPPING_TYPES_SUCCESS:
      return {
        ...state,
        shippingTypes: action.payload,
        isFetching: false,
      };
    case types.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case types.SET_STORE_ID:
      return {
        ...state,
        cart: {
          ...state.cart,
          storeId: action.payload,
        },
      };
    case types.SET_PICKUP_STATION_ID:
      return {
        ...state,
        cart: {
          ...state.cart,
          pickupStation: action.payload,
        },
      };
    case types.REMOVE_PICKUP_STATION_INFO:
      return {
        ...state,
        cart: {
          ...state.cart,
          pickupStation: action.payload,
        },
      };
    case types.CHANGE_CTA_STATE:
      return { ...state, ctaState: action.payload };
    case types.SET_SHOW_FEEDBACK:
      return { ...state, showFeedback: action.payload };
    case types.SET_PAYPAL_ECS:
      return {
        ...state,
        paypalECS: {
          ...state.paypalECS,
          email: action.payload.email,
          firstName: action.payload.firstName,
          orderNumber: action.payload.orderNumber,
        },
      };
    case types.SET_COUPON_LOADING_STATE:
      return {
        ...state,
        couponCodeIsLoading: action.payload,
      };
    case types.RESET_STORE_ID:
      return {
        ...state,
        cart: { ...state.cart, storeId: '' },
      };
    case types.RESET_PICKUP_STATION_ID:
      return {
        ...state,
        cart: { ...state.cart, pickupStation: undefined },
      };
    default:
      return state;
  }
}
