import * as React from 'react';

import { Box, Stars } from '../../../design-system';

type Props = {
  ratingAverage: number;
  size?: number;
  withSubHeadline?: boolean;
  identifier: string;
};
const RatingScale = ({ ratingAverage, size, withSubHeadline, identifier }: Props) => {
  const isStarsOnReviewsCard = identifier.includes('reviews-cards-stars');
  return (
    <Box display="flex" alignItems="center" ml={isStarsOnReviewsCard ? '-4px' : 'na'}>
      <Stars rating={ratingAverage} size={size} identifier={identifier} />
      <Box pl="s" mt="xs">
        <Box fontSize={withSubHeadline ? '3rem' : '1.2rem'}>{ratingAverage}/5</Box>
      </Box>
    </Box>
  );
};
export default RatingScale;
