import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';

import useScript from '../../common/hooks/useScript';
import {
  APPLE_LOGIN_APP_ID,
  FACEBOOK_LOGIN_APP_ID,
  GOOGLE_LOGIN_APP_ID,
} from '../../api/constants';
import { socialLogin } from '../actions';
import { SocialProviders } from '../types';
declare global {
  interface Window {
    FB?: {
      init: (params: {
        appId: string;
        version: string;
        cookie: boolean;
        xfbml: boolean;
        status: boolean;
      }) => void;
      login: (
        callback: (response: { status: string; authResponse: { accessToken: string } }) => void,
        { scope }
      ) => void;
      getLoginStatus: (
        callback: (response: { status: string; authResponse: { accessToken: string } }) => void
      ) => void;
      logout: () => void;
    };
    google?: {
      accounts: {
        id: {
          initialize: ({ client_id, callback }: { client_id: string; callback: any }) => void;
          renderButton: (
            element: HTMLElement | null,
            options: {
              type: string;
              theme: string;
              size: string;
              shape: string;
              locale: string;
              width: number;
              height: number;
            }
          ) => void;
        };
      };
    };
    AppleID: {
      auth: {
        init: ({
          clientId,
          scope,
          redirectURI,
          usePopup,
        }: {
          clientId: string;
          scope: string;
          redirectURI: string;
          usePopup?: boolean;
        }) => void;
        signIn: () => Promise<{
          authorization: { id_token: string };
          user?: {
            email: string;
            name: {
              lastName: string;
              firstName: string;
              middleName: string;
            };
          };
        }>;
      };
    };
  }
}

export default function useSocial() {
  const dispatch = useDispatch();

  const [hasFacebookInitialized, setHasFacebookInitialized] = React.useState(false);

  const [hasAppleInitizalied, setHasAppleInitizalied] = React.useState(false);

  const hasFacebookScriptLoaded = useScript({
    id: 'facebook-jssdk',
    src: 'https://connect.facebook.net/en_US/sdk.js',
    position: 'head',
  });

  const hasGoogleScriptLoaded = useScript({
    id: 'google-api',
    src: 'https://accounts.google.com/gsi/client',
    position: 'head',
  });

  const hasAppleScriptLoaded = useScript({
    id: 'apple-js',
    src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  });

  const handleGoogleCredentialResponse = (response) =>
    dispatch(
      socialLogin({
        provider: SocialProviders.google,
        credential: response.credential,
      }) as unknown as UnknownAction
    );

  React.useEffect(() => {
    if (hasFacebookScriptLoaded && window.FB) {
      window.FB.init({
        appId: FACEBOOK_LOGIN_APP_ID,
        version: 'v8.0',
        status: true,
        cookie: true,
        xfbml: true,
      });
      setHasFacebookInitialized(true);
    }
  }, [hasFacebookScriptLoaded]);

  React.useEffect(() => {
    if (hasGoogleScriptLoaded && window.google) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_LOGIN_APP_ID,
        callback: handleGoogleCredentialResponse,
      });

      window.google.accounts.id.renderButton(document.getElementById('div-google-login'), {
        type: 'icon',
        theme: 'filled_black',
        size: 'large',
        shape: 'rectangular',
        locale: 'fr-FR',
        width: 70,
        height: 60,
      });

      if (process.env.DEPLOYMENT_ENV === 'development') {
        const button = document.querySelector('[aria-labelledby=button-label]') as HTMLElement;
        if (button) {
          button.style.backgroundColor = '#F2F2F2';
          button.style.width = '70px';
          button.style.height = '60px';
          button.style.borderRadius = '8px';

          const collectionOfChilds = button.childNodes;
          const container = collectionOfChilds[1] as HTMLElement;
          button.onmouseenter = () => {
            container.style.backgroundColor = '#F2F2F2';
          };
          container.style.justifyContent = 'center';
          container.style.borderRadius = '8px';
          const target = collectionOfChilds[1].childNodes[0] as HTMLElement;
          target.style.backgroundColor = '#F2F2F2';
        }
      }
      if (process.env.DEPLOYMENT_ENV !== 'development') {
        const iframe = document.getElementById('div-google-login')?.childNodes[0]
          .childNodes[1] as HTMLIFrameElement;
        if (iframe) {
          iframe.style.opacity = '0';
        }
      }
    }
  }, [hasGoogleScriptLoaded]);

  React.useEffect(() => {
    if (hasAppleScriptLoaded && window.AppleID) {
      window.AppleID.auth.init({
        clientId: APPLE_LOGIN_APP_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}/`,
        usePopup: true,
      });

      setHasAppleInitizalied(true);
    }
  }, [hasAppleScriptLoaded]);

  return { hasFacebookInitialized, hasAppleInitizalied };
}
