import { OrderStatus, Tracking, OrderDetail, PaymentInfo } from './types';
import { formatPrice } from '../common/utils';

const countryLabels = {
  AF: 'Afghanistan',
  ZA: 'Afrique du Sud',
  AX: '\u00celes \u00c5land',
  AL: 'Albanie',
  DZ: 'Alg\u00e9rie',
  DE: 'Allemagne',
  AD: 'Andorre',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctique',
  AG: 'Antigua-et-Barbuda',
  SA: 'Arabie saoudite',
  AR: 'Argentine',
  AM: 'Arm\u00e9nie',
  AW: 'Aruba',
  AU: 'Australie',
  AT: 'Autriche',
  AZ: 'Azerba\u00efdjan',
  BS: 'Bahamas',
  BH: 'Bahre\u00efn',
  BD: 'Bangladesh',
  BB: 'Barbade',
  BY: 'Bi\u00e9lorussie',
  BE: 'Belgique',
  BZ: 'Belize',
  BJ: 'B\u00e9nin',
  BM: 'Bermudes',
  BT: 'Bhoutan',
  BO: 'Bolivie',
  BQ: 'Pays-Bas carib\u00e9ens',
  BA: 'Bosnie-Herz\u00e9govine',
  BW: 'Botswana',
  BV: '\u00cele Bouvet',
  BR: 'Br\u00e9sil',
  BN: 'Brunei',
  BG: 'Bulgarie',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KY: '\u00celes Ca\u00efmans',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  CV: 'Cap-Vert',
  CF: 'R\u00e9publique centrafricaine',
  CL: 'Chili',
  CN: 'Chine',
  CX: '\u00cele Christmas',
  CY: 'Chypre (pays)',
  CC: '\u00celes Cocos',
  CO: 'Colombie',
  KM: 'Comores (pays)',
  CG: 'R\u00e9publique du Congo',
  CD: 'R\u00e9publique d\u00e9mocratique du Congo',
  CK: '\u00celes Cook',
  KR: 'Cor\u00e9e du Sud',
  KP: 'Cor\u00e9e du Nord',
  CR: 'Costa Rica',
  CI: "C\u00f4te d'Ivoire",
  HR: 'Croatie',
  CU: 'Cuba',
  CW: 'Cura\u00e7ao',
  DK: 'Danemark',
  DJ: 'Djibouti',
  DO: 'R\u00e9publique dominicaine',
  DM: 'Dominique',
  EG: '\u00c9gypte',
  SV: 'Salvador',
  AE: '\u00c9mirats arabes unis',
  EC: '\u00c9quateur (pays)',
  ER: '\u00c9rythr\u00e9e',
  ES: 'Espagne',
  EE: 'Estonie',
  US: '\u00c9tats-Unis',
  ET: '\u00c9thiopie',
  FK: 'Malouines',
  FO: '\u00celes F\u00e9ro\u00e9',
  FJ: 'Fidji',
  FI: 'Finlande',
  FR: 'France',
  GA: 'Gabon',
  GM: 'Gambie',
  GE: 'G\u00e9orgie (pays)',
  GS: 'G\u00e9orgie du Sud-et-les \u00eeles Sandwich du Sud',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Gr\u00e8ce',
  GD: 'Grenade (pays)',
  GL: 'Groenland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GN: 'Guin\u00e9e',
  GW: 'Guin\u00e9e-Bissau',
  GQ: 'Guin\u00e9e \u00e9quatoriale',
  GY: 'Guyana',
  GF: 'Guyane',
  HT: 'Ha\u00efti',
  HM: '\u00celes Heard-et-MacDonald',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hongrie',
  IM: '\u00cele de Man',
  UM: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
  VG: '\u00celes Vierges britanniques',
  VI: '\u00celes Vierges des \u00c9tats-Unis',
  IN: 'Inde',
  ID: 'Indon\u00e9sie',
  IR: 'Iran',
  IQ: 'Irak',
  IE: 'Irlande (pays)',
  IS: 'Islande',
  IL: 'Isra\u00ebl',
  IT: 'Italie',
  JM: 'Jama\u00efque',
  JP: 'Japon',
  JE: 'Jersey',
  JO: 'Jordanie',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KI: 'Kiribati',
  KW: 'Kowe\u00eft',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettonie',
  LB: 'Liban',
  LR: 'Liberia',
  LY: 'Libye',
  LI: 'Liechtenstein',
  LT: 'Lituanie',
  LU: 'Luxembourg (pays)',
  MO: 'Macao',
  MK: 'Mac\u00e9doine du Nord',
  MG: 'Madagascar',
  MY: 'Malaisie',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malte',
  MP: '\u00celes Mariannes du Nord',
  MA: 'Maroc',
  MH: '\u00celes Marshall (pays)',
  MQ: 'Martinique',
  MU: 'Maurice (pays)',
  MR: 'Mauritanie',
  YT: 'Mayotte',
  MX: 'Mexique',
  FM: '\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie (pays)',
  MD: 'Moldavie',
  MC: 'Monaco',
  MN: 'Mongolie',
  ME: 'Mont\u00e9n\u00e9gro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Birmanie',
  NA: 'Namibie',
  NR: 'Nauru',
  NP: 'N\u00e9pal',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: '\u00cele Norfolk',
  NO: 'Norv\u00e8ge',
  NC: 'Nouvelle-Cal\u00e9donie',
  NZ: 'Nouvelle-Z\u00e9lande',
  IO: "Territoire britannique de l'oc\u00e9an Indien",
  OM: 'Oman',
  UG: 'Ouganda',
  UZ: 'Ouzb\u00e9kistan',
  PK: 'Pakistan',
  PW: 'Palaos',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papouasie-Nouvelle-Guin\u00e9e',
  PY: 'Paraguay',
  NL: 'Pays-Bas',
  PE: 'P\u00e9rou',
  PH: 'Philippines',
  PN: '\u00celes Pitcairn',
  PL: 'Pologne',
  PF: 'Polyn\u00e9sie fran\u00e7aise',
  PR: 'Porto Rico',
  PT: 'Portugal',
  QA: 'Qatar',
  RE: 'La R\u00e9union',
  RO: 'Roumanie',
  GB: 'Royaume-Uni',
  RU: 'Russie',
  RW: 'Rwanda',
  EH: 'R\u00e9publique arabe sahraouie d\u00e9mocratique',
  BL: 'Saint-Barth\u00e9lemy',
  KN: 'Saint-Christophe-et-Ni\u00e9v\u00e8s',
  SM: 'Saint-Marin',
  MF: 'Saint-Martin',
  SX: 'Saint-Martin',
  PM: 'Saint-Pierre-et-Miquelon',
  VA: 'Saint-Si\u00e8ge (\u00c9tat de la Cit\u00e9 du Vatican)',
  VC: 'Saint-Vincent-et-les-Grenadines',
  SH: 'Sainte-H\u00e9l\u00e8ne, Ascension et Tristan da Cunha',
  LC: 'Sainte-Lucie',
  SB: 'Salomon',
  WS: 'Samoa',
  AS: 'Samoa am\u00e9ricaines',
  ST: 'Sao Tom\u00e9-et-Principe',
  SN: 'S\u00e9n\u00e9gal',
  RS: 'Serbie',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapour',
  SK: 'Slovaquie',
  SI: 'Slov\u00e9nie',
  SO: 'Somalie',
  SD: 'Soudan',
  SS: 'Soudan du Sud',
  LK: 'Sri Lanka',
  SE: 'Su\u00e8de',
  CH: 'Suisse',
  SR: 'Suriname',
  SJ: 'Svalbard et ile Jan Mayen',
  SZ: 'Swaziland',
  SY: 'Syrie',
  TJ: 'Tadjikistan',
  TW: 'Ta\u00efwan / (R\u00e9publique de Chine (Ta\u00efwan))',
  TZ: 'Tanzanie',
  TD: 'Tchad',
  CZ: 'Tch\u00e9quie',
  TF: 'Terres australes et antarctiques fran\u00e7aises',
  TH: 'Tha\u00eflande',
  TL: 'Timor oriental',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinit\u00e9-et-Tobago',
  TN: 'Tunisie',
  TM: 'Turkm\u00e9nistan',
  TC: '\u00celes Turques-et-Ca\u00efques',
  TR: 'Turquie',
  TV: 'Tuvalu',
  UA: 'Ukraine',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vi\u00eat Nam',
  WF: 'Wallis-et-Futuna',
  YE: 'Y\u00e9men',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
};

export const countryOptions = Object.keys(countryLabels)
  .map((value) => ({ value, label: countryLabels[value] || '' }))
  .sort((a, b) => a.label.localeCompare(b.label, 'fr'));

export function getShowPrintInvoice(status: OrderStatus): boolean {
  return [
    OrderStatus.SHIPPED,
    OrderStatus.PAID,
    OrderStatus.DISPATCHED,
    OrderStatus.RECEIVED,
    OrderStatus.COLLECTED,
  ].includes(status);
}

export function getTrackingNumbers({
  tracking,
  legacyNumber,
}: {
  tracking?: Tracking[];
  legacyNumber?: string;
}): string[] {
  return tracking?.length ?? 0 > 0
    ? (tracking ?? []).map(({ trackingNumber }) => trackingNumber)
    : legacyNumber
    ? [legacyNumber]
    : [];
}

export function getPaymentInfo(order: OrderDetail): PaymentInfo[] {
  return Array.isArray(order.paymentDetails) && order.paymentDetails.length > 0
    ? order.paymentDetails
    : [
        {
          method: order.paymentMethod ?? '',
          cardSummary: order.cardSummary ?? '',
          amount: formatPrice(order.total),
        },
      ];
}
