import * as React from 'react';

import Icon from '../Icon';
import Box from '../../box';

type Props = {
  rating?: number;
  size?: number;
  identifier: string;
};

const Stars = ({ rating = 0, size = 12, identifier }: Props) => {
  const roundedRating = Number.isNaN(rating) ? 0 : rating > 5 ? 5 : Math.round(rating * 2) / 2;
  const stars = [...Array(5).keys()].map((i) => (
    <Box px="xs" key={`${identifier}-${i}`}>
      <Icon
        name={i + 1 <= roundedRating ? 'star' : i + 0.5 <= roundedRating ? 'starHalf' : 'starEmpty'}
        size={size}
      />
    </Box>
  ));
  return <Box display="flex">{stars}</Box>;
};

export default Stars;
