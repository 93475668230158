import types from './actionTypes';
import { filtersState } from './state';
import { FilterActions } from './actions';
import { State } from './types';

export default function filters(state = filtersState, action: FilterActions): State {
  switch (action.type) {
    case types.UPDATE_LIMIT:
      return {
        ...state,
        limits: {
          ...state.limits,
          [action.payload.key]: action.payload.value,
        },
      };
    case types.RESET_LIMITS:
      return {
        ...state,
        limits: {
          ...filtersState.limits,
        },
      };
    case types.UPDATE_CURRENT_REFINEMENTS_GROUP:
      return {
        ...state,
        currentRefinementsGroup: action.payload,
      };
    case types.UPDATE_CURRENT_REFINEMENTS_ITEMS:
      return {
        ...state,
        currentRefinementsItems: action.payload,
      };
    case types.UPDATE_REFINEMENTS_ITEMS:
      return {
        ...state,
        refinementsItems: action.payload,
      };
    case types.UPDATE_STEP:
      return {
        ...state,
        mobile: {
          ...state.mobile,
          step: action.payload,
        },
      };
    case types.UPDATE_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.key]: action.payload.value,
        },
      };
    case types.UPDATE_SELECTED_ITEMS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        filters: {
          ...state.filters,
          isLoading: action.payload,
        },
      };
    default:
      return state;
  }
}
