import React from 'react';
import { useSelector } from 'react-redux';
import { RichText } from 'prismic-reactjs';

import { RootState } from '../../../store/rootReducer';
import { Box, Text } from '../../../design-system';
import { Title } from '../../common/components/Custom';
import { STORE_ASSITANCES } from '../locale';
import { hasValidCmsText } from '../../home/utils';

const StoreAssistances = () => {
  const { assistances } = useSelector((state: RootState) => state.cms.storelocator);

  return (
    <Box textAlign="center">
      <Title>{STORE_ASSITANCES}</Title>
      <Box
        display="grid"
        gridGap="m"
        mx={['m', 'auto']}
        my="m"
        gridAutoFlow={['row', 'column']}
        gridTemplateColumns={['auto', '1fr 1fr']}
        gridTemplateRows={['1fr 1fr', 'auto']}
        maxWidth="mwl"
        width={['auto', 'calc(100vw - 200px)']}
      >
        {assistances
          .slice(0, 2)
          .map(({ assistance_image, assistance_title, assistance_text }, index) => (
            <Box key={index}>
              <Box
                display="grid"
                height={['30vh', '34vw', '34vw', '830px']}
                width={['calc(100vw - 100px)', 'auto']}
                backgroundImage={assistance_image.url ? `url(${assistance_image?.url ?? ''})` : ''}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                backgroundSize="cover"
                m="auto"
              />
              <Box m="s" justifySelf="center" alignSelf="center">
                <Text fontWeight="bold" textTransform="uppercase" my="s">
                  {assistance_title}
                </Text>
                {hasValidCmsText(assistance_text) && (
                  <Box color="GREY">
                    <RichText render={assistance_text} />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default StoreAssistances;
