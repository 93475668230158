import * as React from 'react';

export default function useTimeout(ms: number): boolean {
  const [hasTimedOut, setHasTimedOut] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => setHasTimedOut(true), ms);

    return () => clearTimeout(timeoutId);
  }, []);

  return hasTimedOut;
}
