import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { Tab } from './components/Tab';
import { TABS_CATEGORIE_HEADER_TABS_WIDTH } from '../../modules/OurBrand/utils';

type TabItem = {
  title: string;
  content: React.ReactNode;
  icon?: string;
};

type Props = {
  id: string;
  items: TabItem[];
  defaultTabIndex?: number;
  commonComponent?: React.ReactNode;
  isSizeGuide?: boolean;
  isOurBrandPage?: boolean;
};

type StyledContainerProps = {
  width: string;
  marginBottom: string;
};

const StyledContainer = styled.div<StyledContainerProps>`
  .tabs-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    margin-bottom: ${(props) => props.marginBottom};
    width: ${(props) => props.width};
  }
`;

export const Tabs = ({
  id,
  items = [],
  defaultTabIndex = 0,
  commonComponent,
  isSizeGuide = false,
  isOurBrandPage,
}: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
  const refContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (defaultTabIndex > 0) {
      setActiveTabIndex(defaultTabIndex);
    }
  }, [defaultTabIndex]);

  return (
    <StyledContainer
      width={isOurBrandPage ? `${TABS_CATEGORIE_HEADER_TABS_WIDTH}px` : '100%'}
      marginBottom={isOurBrandPage ? '16px' : isSizeGuide ? '0' : '8px'}
      id={id}
    >
      <div className="tabs-container tabs__wrapper" ref={refContainer}>
        {items.map((item, index) => (
          <Tab
            key={index}
            id={`${id}-tab-${index}`}
            data-testid={`${id}-tab-${index}`}
            icon={item.icon}
            title={item.title}
            onClick={() => setActiveTabIndex(index)}
            isOpen={index === activeTabIndex}
            isSizeGuide={isSizeGuide}
            refContainer={refContainer}
            defaultTabIndex={defaultTabIndex}
            index={index}
          />
        ))}
      </div>
      {commonComponent}
      {items.map((item, index) => (
        <div style={{ display: index === activeTabIndex ? 'block' : 'none' }} key={index}>
          {item.content}
        </div>
      ))}
    </StyledContainer>
  );
};
