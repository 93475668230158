import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Box, Icon, Divider } from '../../../design-system';
import { opacities } from '../../../design-system/systemprovider';
import { durations } from '../../common/constants';

type Props = {
  step: number;
  setStep: (value: number) => void;
  navigationIsLock: boolean;
};

export const EresaBreadcrumb = ({ step, setStep, navigationIsLock }: Props) => {
  const { isMobile } = useMediaQueries();
  const breadcrumbItems = ['Produit', 'Boutique', 'Résumé', 'Confirmation'];
  return (
    <Box id="eresa-modal-breadcrumb" mt="l" width="100%" display="flex">
      {breadcrumbItems.map((item, index) => {
        return (
          <Box key={`breadcrumb-item-${index}`} display="flex">
            <StyledBox
              display="flex"
              pointerEvents={navigationIsLock ? 'none' : index + 1 >= step ? 'none' : 'initial'}
            >
              {!isMobile && (
                <Box
                  ml="s"
                  width="l"
                  height="l"
                  borderRadius="50%"
                  bg={step === index + 1 ? '#36C754' : '#FFF'}
                  border={
                    step === index + 1
                      ? 'na'
                      : step < index + 1
                      ? '1px solid #9B9B9B'
                      : '1px solid #36C754'
                  }
                  fontSize="12px"
                  fontWeight={700}
                  color={step === index + 1 ? '#FFF' : '#9B9B9B'}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  transition={`all ${durations.EXIT} ease-in-out`}
                  data-cy="ereservation-breadcrumb-step-number-link-desktop-tablet"
                  onClick={() =>
                    index + 1 < step && !navigationIsLock ? setStep(index + 1) : null
                  }
                  pointerEvents={navigationIsLock ? 'none' : index + 1 >= step ? 'none' : 'initial'}
                >
                  {step <= index + 1 ? (
                    index + 1
                  ) : (
                    <Icon name="checkmark" size={10} color="#36C754" />
                  )}
                </Box>
              )}
              <Box
                fontSize="14px"
                fontWeight={700}
                width={isMobile ? 'calc((100vw - 32px)/4)' : 'auto'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition={`all ${durations.EXIT} ease-in-out`}
                color={
                  (!isMobile && step === index + 1) || (isMobile && step >= index + 1)
                    ? '#36C754'
                    : !isMobile && index + 1 < step
                    ? 'rgba(0, 0, 0, 0.6)'
                    : '#9B9B9B'
                }
                px={isMobile ? 'na' : 's'}
                pb={isMobile ? '6px' : 'na'}
                borderBottom={
                  isMobile && step === index + 1
                    ? 'solid 1px #36C754'
                    : isMobile && step !== index + 1
                    ? 'solid 1px #E6E6E6'
                    : 'none'
                }
                data-cy="ereservation-breadcrumb-step-text-link"
                onClick={() => (index + 1 < step && !navigationIsLock ? setStep(index + 1) : null)}
                pointerEvents={navigationIsLock ? 'none' : index + 1 >= step ? 'none' : 'initial'}
              >
                {item}
              </Box>
            </StyledBox>
            {index !== 3 && !isMobile && (
              <Box width="l" display="flex" alignItems="center" justifyContent="center">
                <Divider color="#9B9B9B" />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const StyledBox = styled(Box)(
  css({
    ':hover': {
      opacity: opacities.HOVERED,
      transition: 'opacity 0.5s',
    },
  })
);
