import styled from '@emotion/styled';
import {
  compose,
  system,
  space,
  layout,
  color,
  background,
  border,
  flexbox,
  grid,
  position,
  shadow,
  typography,
} from 'styled-system';

type GenericStyle = string | number | (string | number)[];
type StringStyle = string | string[];
type NumericStyle = number | number[];

export type Props = {
  id?: string;
  onClick?: (e?: React.SyntheticEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e?: React.SyntheticEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e?: React.SyntheticEvent<HTMLDivElement>) => void;
  margin?: GenericStyle;
  marginTop?: GenericStyle;
  marginRight?: GenericStyle;
  marginBottom?: GenericStyle;
  marginLeft?: GenericStyle;
  marginX?: GenericStyle;
  marginY?: GenericStyle;
  padding?: GenericStyle;
  paddingTop?: GenericStyle;
  paddingRight?: GenericStyle;
  paddingBottom?: GenericStyle;
  paddingLeft?: GenericStyle;
  paddingX?: GenericStyle;
  paddingY?: GenericStyle;
  m?: GenericStyle;
  mt?: GenericStyle;
  mr?: GenericStyle;
  mb?: GenericStyle;
  ml?: GenericStyle;
  mx?: GenericStyle;
  my?: GenericStyle;
  p?: GenericStyle;
  pt?: GenericStyle;
  pr?: GenericStyle;
  pb?: GenericStyle;
  pl?: GenericStyle;
  px?: GenericStyle;
  py?: GenericStyle;
  color?: string;
  backgroundColor?: StringStyle;
  bg?: StringStyle;
  width?: GenericStyle;
  height?: GenericStyle;
  minWidth?: GenericStyle;
  minHeight?: GenericStyle;
  maxWidth?: GenericStyle;
  maxHeight?: GenericStyle;
  display?: StringStyle;
  verticalAlign?: StringStyle;
  size?: GenericStyle;
  fontFamily?: StringStyle;
  fontSize?: GenericStyle;
  fontWeight?: GenericStyle;
  lineHeight?: GenericStyle;
  letterSpacing?: GenericStyle;
  fontStyle?: StringStyle;
  textAlign?: StringStyle;
  textShadow?: StringStyle;
  alignItems?: StringStyle;
  alignContent?: StringStyle;
  justifyItems?: StringStyle;
  justifyContent?: StringStyle;
  flexWrap?: GenericStyle;
  flexDirection?: StringStyle;
  flex?: GenericStyle;
  flexGrow?: GenericStyle;
  flexShrink?: GenericStyle;
  flexBasis?: GenericStyle;
  justifySelf?: StringStyle;
  alignSelf?: StringStyle;
  order?: GenericStyle;
  border?: StringStyle;
  borderWidth?: StringStyle;
  borderStyle?: GenericStyle;
  borderColor?: StringStyle;
  borderRadius?: GenericStyle;
  borderTop?: GenericStyle;
  borderRight?: GenericStyle;
  borderBottom?: GenericStyle;
  borderLeft?: GenericStyle;
  borderX?: GenericStyle;
  borderY?: GenericStyle;
  background?: StringStyle;
  backgroundImage?: StringStyle;
  backgroundSize?: GenericStyle;
  backgroundPosition?: StringStyle;
  backgroundRepeat?: StringStyle;
  position?: StringStyle;
  zIndex?: GenericStyle;
  top?: GenericStyle;
  right?: GenericStyle;
  bottom?: GenericStyle;
  left?: GenericStyle;
  gridGap?: GenericStyle;
  gridColumnGap?: GenericStyle;
  gridRowGap?: GenericStyle;
  gridColumn?: StringStyle;
  gridRow?: StringStyle;
  gridAutoFlow?: StringStyle;
  gridAutoColumns?: StringStyle;
  gridAutoRows?: StringStyle;
  gridTemplateColumns?: StringStyle;
  gridTemplateRows?: StringStyle;
  gridTemplateAreas?: StringStyle;
  gridArea?: StringStyle;
  overflow?: StringStyle;
  overflowX?: StringStyle;
  overflowY?: StringStyle;
  opacity?: NumericStyle;
  textDecoration?: StringStyle;
  textTransform?: StringStyle;
  textOverflow?: StringStyle;
  wordSpacing?: StringStyle;
  wordBreak?: StringStyle;
  wordWrap?: StringStyle;
  whiteSpace?: StringStyle;
  boxSizing?: StringStyle;
  boxShadow?: StringStyle;
  float?: StringStyle;
  backgroundClip?: StringStyle;
  cursor?: StringStyle;
  pointerEvents?: StringStyle;
  transition?: StringStyle;
  transform?: StringStyle;
  transformOrigin?: StringStyle;
  animation?: StringStyle;
  paddingBlockStart?: StringStyle;
  paddingBlockEnd?: StringStyle;
  marginBlockStart?: StringStyle;
  marginBlockEnd?: StringStyle;
  scrollBehavior?: StringStyle;
  overflowAnchor?: StringStyle;
  mask?: StringStyle;
};

const Box = styled.div<Props>(
  compose(space, layout, color, background, border, flexbox, grid, position, shadow, typography),
  system({
    textDecoration: true,
    textTransform: true,
    textOverflow: true,
    wordSpacing: true,
    wordBreak: true,
    wordWrap: true,
    whiteSpace: true,
    boxSizing: true,
    float: true,
    backgroundClip: true,
    cursor: true,
    pointerEvents: true,
    transition: true,
    transform: true,
    transformOrigin: true,
    animation: true,
    paddingBlockStart: true,
    paddingBlockEnd: true,
    marginBlockStart: true,
    marginBlockEnd: true,
    scrollBehavior: true,
    overflowAnchor: true,
    mask: true,
  }),
  ({ onClick, onMouseEnter }) =>
    (typeof onClick === 'function' || typeof onMouseEnter === 'function') && {
      cursor: 'pointer',
    }
);

export default Box;
