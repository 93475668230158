import { useEffect } from 'react';
import { useClientAsyncInit } from '@statsig/react-bindings';

import { STATSIG_CLIENT_API } from '../../api/constants';
import { persistData } from '../utils';

type Props = {
  cartId: string;
};

export const useAsyncStatsigClient = ({ cartId }: Props) => {
  const { client, isLoading } = useClientAsyncInit(
    STATSIG_CLIENT_API,
    {
      userID: cartId,
    },
    { environment: { tier: process.env.DEPLOYMENT_ENV } }
  );

  useEffect(() => {
    if (!isLoading) {
      const isMapBoxFlagsEnable = client.getFeatureGate('map-box').value;
      persistData('statsig-map-box-flag', [`map-box-${isMapBoxFlagsEnable ? 'on' : 'off'}`]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (cartId && !isLoading) {
      client.updateUserAsync({ userID: cartId });
    }
  }, [cartId, isLoading]);

  return { client, isLoading };
};
