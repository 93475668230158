import * as React from 'react';

import { Text } from '../../../design-system';
import { EXCEPTIONAL_CLOSINGS, WEEK_DAYS } from '../../store-locator/locale';
import { formatDateToLocale, isDateWithinDaysRange } from '../../store-locator/utils';
import { PickupStation } from '../types';
import { mergeStationOpeningHours } from '../utils';

type Props = {
  station: PickupStation;
};

const OpeningHours = ({ station }: Props) => {
  const { hours, exceptionalClosings } = station;
  const closingDatesInNextTenDays = exceptionalClosings.filter((closing) => {
    return isDateWithinDaysRange(closing.end, 10);
  });

  return (
    <>
      {mergeStationOpeningHours(hours).map((value, index) => {
        return (
          <Text key={index} color="GREY">{`${WEEK_DAYS[value.day]} ${
            value.endDay ? `au ${WEEK_DAYS[value.endDay]}` : ''
          } ${value.openingHours}`}</Text>
        );
      })}
      {closingDatesInNextTenDays.length > 0 && (
        <>
          <Text my="xs" color="GREY">
            {EXCEPTIONAL_CLOSINGS}:
          </Text>

          {closingDatesInNextTenDays.map((item, index) => {
            return (
              <Text key={index} color="ERROR">
                {formatDateToLocale(item.start)}
                {item.start !== item.end ? ` jusqu'au ${formatDateToLocale(item.end)}` : ''}
              </Text>
            );
          })}
        </>
      )}
    </>
  );
};

export default OpeningHours;
