import styled from '@emotion/styled';
import React from 'react';
import { ModelPDP } from '../types';
import { findSizeByModel } from '../utils';

type Props = {
  imagePositions: number[];
  model: ModelPDP[] | null;
  index: number;
};

const StyledModelSizes = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  background: #fff;
  opacity: 75%;
  border-radius: 4px;
  pointer-events: none;
  z-index: 10;
  .model-size__description {
    margin: 0;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    padding: 4px 8px;
  }
`;

export const ModelSizes = ({ imagePositions, model, index }: Props) => {
  const matchingModels =
    model &&
    findSizeByModel(model)?.filter(
      (item) => item.position && imagePositions?.includes(item.position)
    );
  return (
    <>
      {matchingModels?.map((matchingModel, modelIndex) => {
        if (imagePositions && matchingModel.position === imagePositions[index]) {
          if (!matchingModel.name || !matchingModel.size) {
            return null;
          }
          return (
            <StyledModelSizes key={modelIndex}>
              <p className="model-size__description">
                {matchingModel.name} porte un {matchingModel.size}
              </p>
            </StyledModelSizes>
          );
        }
        return null;
      })}
    </>
  );
};
