import * as React from 'react';
import {
  useClearRefinements,
  useCurrentRefinements,
  useRange,
  useRefinementList,
} from 'react-instantsearch';

import { Box, Divider, Icon, Text } from '../../../design-system';
import { getRefinementLabel } from '../utils';
import { formatPriceStore } from './Refinements';

type Props = {
  attribute: string;
  index: number;
  title: string;
  onClick: () => void;
};

export const MobileFilterSelector: React.FC<Props> = ({ attribute, index, title, onClick }) => {
  const { items } = useCurrentRefinements({ includedAttributes: [attribute] });
  const { refine, canRefine } = useClearRefinements({ includedAttributes: [attribute] });
  const { items: refinementItems } = useRefinementList({
    attribute: attribute === 'storePrice' ? '_' : attribute,
  });
  const { range } = useRange({ attribute: 'storePrice' });

  return (
    <Box
      display={
        (attribute === 'storePrice' && range.min === range.max) ||
        (!refinementItems.length && attribute !== 'storePrice')
          ? 'none'
          : 'block'
      }
    >
      <Box
        display="flex"
        py="m"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        {canRefine && (
          <Box pr="s">
            <Icon name="delete" onClick={refine} id={`filter-bar-clear-filters-${index}`} />
          </Box>
        )}
        <Box
          onClick={onClick}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text textTransform="uppercase" id={`filter-bar-filter-title-${index}`}>
            {title}
          </Text>
          <Box display="flex" alignItems="center">
            <Box maxWidth="160px" mx="s">
              <Text
                withEllipsis
                preset="caption"
                color="GREY"
                id={`filter-bar-selected-filters-${index}`}
              >
                {items.map((item) => {
                  return item.attribute === 'storePrice'
                    ? formatPriceStore(item.refinements, range)
                    : item.refinements.map((ref) => getRefinementLabel(ref.label)).join(', ');
                })}
              </Text>
            </Box>
            <Icon name="chevronRight" size={14} />
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
