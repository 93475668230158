import { css } from '@emotion/core';

import MaisonNeueBook from './../modules/common/fonts/MaisonNeue/MaisonNeue-Book.woff2';
import MaisonNeueBold from './../modules/common/fonts/MaisonNeue/MaisonNeue-Bold.woff2';
import MaisonNeueLight from './../modules/common/fonts/MaisonNeue/MaisonNeue-Light.woff2';
import MaisonNeueMedium from './../modules/common/fonts/MaisonNeue/MaisonNeue-Medium.woff2';
import OggBoldItalique from './../modules/common/fonts/OggText/Ogg-Bold-Italique.woff2';
import OggBold from './../modules/common/fonts/OggText/Ogg-Bold.woff2';
import OggBookItalique from './../modules/common/fonts/OggText/Ogg-Book-Italique.woff2';
import OggBook from './../modules/common/fonts/OggText/Ogg-Book.woff2';
import OggExtraBold from './../modules/common/fonts/OggText/Ogg-Extra-Bold.woff2';
import OggExtraBoldItalique from './../modules/common/fonts/OggText/Ogg-ExtraBold-Italique.woff2';
import OggLightItalique from './../modules/common/fonts/OggText/Ogg-Light-Italique.woff2';
import OggLight from './../modules/common/fonts/OggText/Ogg-Light.woff2';
import OggMediumItalique from './../modules/common/fonts/OggText/Ogg-Medium-Italique.woff2';
import OggMedium from './../modules/common/fonts/OggText/Ogg-Medium.woff2';

export const fontFamilyStyles = css`
  @font-face {
    font-family: 'MaisonNeue';
    font-weight: 300;
    src: url(${MaisonNeueLight}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'MaisonNeue';
    font-weight: 400;
    src: url(${MaisonNeueBook}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'MaisonNeue';
    font-weight: 500;
    src: url(${MaisonNeueMedium}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'MaisonNeue';
    font-weight: 600;
    src: url(${MaisonNeueBold}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 300;
    src: url(${OggLight}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 400;
    src: url(${OggBook}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 500;
    src: url(${OggMedium}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 700;
    src: url(${OggBold}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 800;
    src: url(${OggExtraBold}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 300;
    font-style: italic;
    src: url(${OggLightItalique}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 400;
    font-style: italic;
    src: url(${OggBookItalique}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 500;
    font-style: italic;
    src: url(${OggMediumItalique}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 700;
    font-style: italic;
    src: url(${OggBoldItalique}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Ogg-text';
    font-weight: 800;
    font-style: italic;
    src: url(${OggExtraBoldItalique}) format('woff2');
    font-display: swap;
  }
`;
