import * as React from 'react';

import { useOutsideClick } from '../../modules/common/hooks/useOutsideClick';
import { DropdownItem, DropdownMenu, DropdownSelect, DropdownBackground } from './dropdown.style';
import Icon from '../icon';
import Box from '../box';

type Option = {
  value: string;
  label: string | JSX.Element;
  hidden?: boolean;
};

type Props = {
  id: string;
  selected: string;
  options: Option[];
  background?: DropdownBackground;
  border?: string;
  onChange: (id: string) => void;
  isPositionAbsolute?: boolean;
  isReviewsBlock?: boolean;
};

const Dropdown = ({
  id,
  onChange,
  options = [],
  selected,
  background,
  isReviewsBlock,
  border = '',
  isPositionAbsolute = true,
}: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  useOutsideClick(ref, () => setIsOpen(false));
  const onClickOption = (value: string) => {
    setIsOpen(false);
    onChange(value);
  };

  const selectedOption = options.find((option) => option.value === selected);

  return (
    <Box ref={ref} position="relative" minWidth="96px" maxWidth="calc(100vw - 16px)" width="100%">
      <DropdownSelect
        id={id}
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        background={background}
        isReviewsBlock={isReviewsBlock}
        border={border}
      >
        <Box
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          pl="s"
          color="BLACK"
          wordBreak="break-word"
          id={`${id}-option-selected`}
        >
          {selectedOption ? selectedOption.label : ''}
        </Box>
        <Box pr={isReviewsBlock ? 'na' : 'm'} pl={isReviewsBlock ? 'l' : 'na'}>
          <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} />
        </Box>
      </DropdownSelect>
      {isOpen && (
        <DropdownMenu isPositionAbsolute={isPositionAbsolute}>
          {options.map((option, index) => {
            if (option.hidden) {
              return null;
            }
            const { value, label } = option;
            const isSelected = value === selected;
            return (
              <DropdownItem
                key={value}
                id={`${id}-option-${index}`}
                isSelected={isSelected}
                onClick={() => onClickOption(value)}
              >
                <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {label}
                </Box>
                <Box pr="m">{isSelected && <Icon name="checkmark" />}</Box>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      )}
    </Box>
  );
};

export default Dropdown;
