import * as React from 'react';

import { Box, Icon, Text } from '../../../design-system';
import { Position } from '../../store-locator/types';
import { getDistanceInKm } from '../../store-locator/utils';
import { PickupStation as PickupStationType } from '../types';
import OpeningHours from './OpeningHours';

export type Props = {
  station: PickupStationType;
  index: number;
  currentPosition?: Position;
};

const StationInfo = ({ station, index, currentPosition }: Props) => {
  const {
    line1,
    line2,
    postalCode,
    city,
    name,
    parking,
    accessForPRM,
    displayCoordinate: { latitude, longitude },
  } = station;
  const distance = getDistanceInKm({
    from: currentPosition,
    to: { lat: latitude, lng: longitude },
  });
  return (
    <Box display="grid" gridGap={['xxs', 'xxs', 'xs']} gridAutoRows="min-content">
      <Box display="flex" alignItems="start" justifyContent="space-between">
        <Text id={`pickup-station-${index}-name`} textTransform="uppercase" fontWeight={700}>
          {name}
        </Text>
        <Text
          id={`pickup-station-${index}-distance`}
          preset="caption"
          color="GREY"
          whiteSpace="nowrap"
        >
          {distance}
        </Text>
      </Box>
      <Text
        id={`pickup-station-${index}-address`}
        py={['na', 'na', 's']}
        preset={['caption', 'caption', 'body']}
      >
        {`${line2 ? `${line2}, ` : ''}${line1 ? `${line1}, ` : ''}${postalCode} ${city}`}
      </Text>
      {(parking || accessForPRM) && (
        <Box display="flex" mb="s">
          {accessForPRM && <Icon name="wheelchair" />}
          {parking && <Icon name="parkingColissimo" />}
        </Box>
      )}
      <OpeningHours station={station} />
    </Box>
  );
};

export default StationInfo;
