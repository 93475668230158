import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { Box } from '../../../design-system';
import { CmsText } from '../types';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  body: CmsText;
  column: string;
  withImage?: boolean;
};

const BrandText = ({ body, column, withImage }: Props) => {
  const { isDesktop, isTablet, isMobile } = useMediaQueries();

  const px = withImage
    ? 'l'
    : !isDesktop
      ? 'm'
      : column === 'full'
        ? 'h'
        : column === 'half'
          ? 'xxxl'
          : 'l';
  const pt = withImage ? 'm' : 'na';

  return (
    <Box textAlign="center" px={px} pt={pt}>
      {isDesktop && !withImage && (
        <Box width="800px">
          <RichText render={body} />
        </Box>
      )}
      {isTablet && !withImage && (
        <Box width="600px">
          <RichText render={body} />
        </Box>
      )}
      {(withImage || (isMobile && !withImage)) && <RichText render={body} />}
    </Box>
  );
};

export default BrandText;
