import React from 'react';
import { css } from '@emotion/core';
import { UseRefinementListProps, useRefinementList } from 'react-instantsearch';

import { Icon, Box, Checkbox, colors, Text, Divider } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  suffix?: string;
};

export const StandardFilter: React.FC<UseRefinementListProps & Props> = ({
  attribute,
  suffix = '',
  ...props
}) => {
  const { isMobile } = useMediaQueries();
  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } = useRefinementList({
    ...props,
    attribute,
    sortBy: ['name:asc'],
    showMore: isMobile ? false : true,
    limit: isMobile ? 100 : 5,
    showMoreLimit: 200,
  });

  return (
    <Box display="flex" flexDirection="column">
      {items.map((item, index) => (
        <Box key={`filter-${item.label}-${index}`} display="block" mt={isMobile ? 's' : 'unset'}>
          <Box
            id={`filter-${attribute}-${item.value}`}
            height="34px"
            css={css`
              :hover {
                background: ${colors.BACKGROUND};
              }
            `}
          >
            <Checkbox
              id={item.label}
              onChange={() => {
                refine(item.value);
              }}
              checked={item.isRefined}
              isFilterCheckBox={true}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <Text fontWeight={item.isRefined ? 'bold' : 'regular'}>
                  {`${item.label} ${suffix}`}
                </Text>
                <Text fontWeight={item.isRefined ? 'bold' : 'regular'}>({item.count})</Text>
              </div>
            </Checkbox>
          </Box>
          {isMobile && <Divider />}
        </Box>
      ))}
      {canToggleShowMore && (
        <Box
          py="s"
          pr="s"
          display="flex"
          alignItems="flex-end"
          onClick={toggleShowMore}
          id={`filter-${attribute}-see-more`}
        >
          {isShowingMore ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}
          <Box pl="s">
            <Text pl="xs">Voir {isShowingMore ? 'moins' : 'plus'}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
