import styled from '@emotion/styled';
import { colors, opacities } from '../systemprovider';

type Props = {
  isSelected: boolean;
};

export const ButtonSize = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  vertical-align: middle;
  padding: 0 16px;
  height: 32px;
  width: 48px;
  font-size: 12px;
  border: none;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  &:disabled {
    opacity: ${opacities.DISABLED};
    pointer-events: none;
    font-weight: 400;
    background: ${colors.LIGHT2};
    cursor: default;
    text-decoration: line-through;
  }
  ${(props) =>
    props.isSelected
      ? `
      font-weight: 700;
      color: ${colors.WHITE};
      background: ${colors.BLACK};
    `
      : `
      color: ${colors.BLACK};
      background: ${colors.LIGHT2};
      font-weight: 400;
      @media(hover: hover) {
        :hover {
          background: ${colors.LIGHT};
          font-weight: 700;
        }
      }
      &:focus-visible {
        background: ${colors.LIGHT};
        font-weight: 700;
      }
    `}
`;
