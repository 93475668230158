import * as CurrencyFormatter from 'currencyformatter.js';
import { Devices } from './types';
import { ORIGIN_URL } from '../api/constants';
import { datadogLogs } from '@datadog/browser-logs';

export const noop = () => {};

export function sortByProperty(property: string) {
  return (a, b) => {
    if (typeof a[property] !== 'undefined' && typeof b[property] !== 'undefined') {
      return a[property] - b[property] || a[property].localeCompare(b[property]);
    }
    return 1;
  };
}

export function sortByCount(a, b): 0 | 1 | -1 {
  if (a.count < b.count) {
    return 1;
  }
  if (a.count > b.count) {
    return -1;
  }
  return 0;
}

export function formatPrice(price: number): string {
  return CurrencyFormatter.format(price, { currency: 'EUR', locale: 'fr' });
}

export function getIsClient(): boolean {
  return Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);
}

export function localStorageTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    const storedValue = localStorage.getItem(test);
    if (storedValue !== test) {
      datadogLogs.logger.debug(
        `DAR-3244 - Problem with local storage - ${storedValue} !== ${test}`
      );
      return false;
    }
    localStorage.removeItem(test);
    return true;
  } catch (error) {
    datadogLogs.logger.debug('DAR-3244 - Problem with local storage', { error });
    return false;
  }
}

export function persistData(key: string, data: any): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch {
    return false;
  }
}

export function getPersistedData(key: string): any | null {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } catch {
    return null;
  }
}

export function findDevice(userAgent: string): Devices {
  if (/Tablet|iPad|Silk/.test(userAgent)) {
    return Devices.tablet;
  } else if (/Mobile|iP(hone|od)|Android/.test(userAgent)) {
    return Devices.mobile;
  }
  return Devices.desktop;
}

function getIsIpadOS(): boolean {
  return (
    getIsClient() &&
    Boolean(navigator.maxTouchPoints) &&
    navigator.maxTouchPoints > 2 &&
    (/MacIntel/.test(navigator.platform) || /iPad/.test(navigator.platform))
  );
}

export function getShouldRotate(): boolean {
  return getIsIpadOS() && (window.orientation === 0 || window.orientation === 180);
}

export function scrollToTop() {
  if (getIsClient()) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}

export function removeTrailingSlash(value: string): string {
  return value.replace(/\/$/, '');
}

export function getCanonicalUrl(pathname: string): string {
  return removeTrailingSlash(`${ORIGIN_URL}${pathname}`);
}

export const isButtonToDisplay = (textRef: React.RefObject<HTMLDivElement> | null) => {
  if (textRef?.current) {
    return textRef.current.offsetHeight < textRef.current.scrollHeight;
  }
  return false;
};
