import * as React from 'react';
import { SizeGuideVisual } from './SizeGuideVisual';
import { SizeGuideTable } from './SizeGuideTable';

import styled from '@emotion/styled';
import { breakpoints } from '../../../design-system';
import { colors } from '../../../design-system';
import { TSizeGuideVisual } from './SizeGuideVisual';

type TSizeGuideData = {
  title?: string;
  sizeGuide: Array<{
    entries: Array<string | string[] | { stepIndicator?: string; label: string }>;
    variant?: string;
  }>;
};
export type TSizeGuideSection = {
  isPopup?: boolean;
  sizeGuideData: TSizeGuideData;
  arrayOfVisual?: TSizeGuideVisual['arrayOfVisual'];
  id: TSizeGuideVisual['id'];
};

const StyledSizeGuideSection = styled.div<{
  arrayOfVisual?: TSizeGuideSection['arrayOfVisual'];
  isPopup?: TSizeGuideSection['isPopup'];
}>`
  padding: 8px 0;
  @media (min-width: ${breakpoints.S - 1}px) {
    background: ${(props) => (!props.isPopup ? colors.BACKGROUND : 'none')};
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: ${(props) => (props.arrayOfVisual && !props.isPopup ? '40px' : '0')};
    justify-content: center;
    align-items: start;
    padding: 24px;
    &:not(:last-child) {
      margin-bottom: ${(props) => (props.isPopup ? 0 : '40px')};
    }
  }
`;

const SizeGuideSection = ({ sizeGuideData, id, arrayOfVisual, isPopup }: TSizeGuideSection) => {
  return (
    <StyledSizeGuideSection arrayOfVisual={arrayOfVisual} isPopup={isPopup}>
      {arrayOfVisual && !isPopup && (
        <SizeGuideVisual arrayOfVisual={arrayOfVisual} id={id} isPopup={isPopup} />
      )}
      <SizeGuideTable
        sizeGuide={sizeGuideData.sizeGuide}
        id={id}
        title={sizeGuideData.title}
        isPopup={isPopup}
      />
    </StyledSizeGuideSection>
  );
};

export { SizeGuideSection };
