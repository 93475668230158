import * as React from 'react';

import { getIconUrl } from './utils';
import { StyledImg } from './icon.style';

type Props = {
  name: string;
  id?: string;
  color?: string;
  size?: number;
  onClick?: () => void;
  className?: string;
};

const Icon = ({ name, color, ...rest }: Props) => (
  <StyledImg
    {...rest}
    color={color}
    src={getIconUrl(name)}
    alt={`${name} icon`}
    suppressHydrationWarning={Boolean(color)}
  />
);

export default Icon;
