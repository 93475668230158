import { State, PickupStation } from './types';

export const PickupState: State = {
  pickupStations: [],
  geoCoordinate: undefined,
  totalCount: 0,
  token: '',
  errMsg: '',
  isFetching: false,
};

export const emptyPickupStation: PickupStation = {
  id: '',
  name: '',
  line1: 'string',
  line2: '',
  line3: '',
  postalCode: '',
  city: '',
  accessForPRM: false,
  parking: false,
  exceptionalClosings: [],
  displayCoordinate: { latitude: 0, longitude: 0 },
  hours: {
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: '',
  },
};
