import * as React from 'react';

import Box from '../../box';
import Text from '../../text';
import Icon from '../../icon';
import { colors } from '../../systemprovider';

type Props = {
  numberOfItems: number;
  activeIndex: number;
  goToIndex: (index: number) => void;
};

const ZoomPagination = ({ numberOfItems, activeIndex, goToIndex }: Props) => {
  const onClickRight = () => {
    if (activeIndex !== numberOfItems - 1) {
      goToIndex(activeIndex + 1);
    }
  };

  const onClickLeft = () => {
    if (activeIndex !== 0) {
      goToIndex(activeIndex - 1);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Icon
        name="arrowLeft"
        color={activeIndex === 0 ? colors.GREY2 : colors.BLACK}
        onClick={onClickLeft}
      />
      <Text display="flex" preset="caption" whiteSpace="pre-wrap">
        {`${activeIndex + 1} `}
        <Text preset="caption" color="GREY">
          {`sur ${numberOfItems}`}
        </Text>
      </Text>
      <Icon
        name="arrowRight"
        color={activeIndex === numberOfItems - 1 ? colors.GREY2 : colors.BLACK}
        onClick={onClickRight}
      />
    </Box>
  );
};

export default ZoomPagination;
