import { PaymentAction, PaymentAmount } from '@adyen/adyen-web';

export enum PaymentResults {
  Authorised = 'Authorised',
  REDACTED = 'REDACTED',
  CANCELLED = 'CANCELLED',
  REFUSED = 'Refused',
}

export type State = {
  cardDeleted: string;
  forterToken: string;
};

export type StoredPayments = {
  holderName: string;
  expiryMonth: string;
  expiryYear: string;
};

export type PaymentMethod = {
  brands?: string[];
  configuration?: {
    merchantId: string;
    gatewayMerchantId?: string;
    intent?: string;
    merchantName?: string;
  };
  details?: {
    key: string;
    optional?: boolean;
    type: string;
  }[];
  name: string;
  type: string;
};

export type StoredPaymentMethod = {
  brand: string;
  expiryMonth: string;
  expiryYear: string;
  holderName: string;
  id: string;
  lastFour: string;
  name: string;
  supportedShopperInteractions: string[];
  type: string;
};

export enum RefusalReason {
  EXPIRED_TOKEN = 'Invalid or expired token',
  DECLINED = 'Declined',
}

export type MakePaymentResponse = {
  resultCode: string;
  orderNumber: string;
  action?: PaymentAction;
  refusalReason?: string;
  error?: {
    message: string;
  };
  order?: { orderData: string; pspReference: string; remainingAmount: PaymentAmount };
};

export type AdditionalDetailsState = {
  details: {
    threeDSResult: string;
    paymentSource: string;
  };
  paymentData?: string;
};

export type PaypalDataEvent = {
  correlationID?: string;
};
