import cartTypes from '../modules/cart/actionTypes';
import wishListTypes from '../modules/wishlist/actionTypes';
import formTypes from '../modules/form/actionTypes';
import pickupTypes from '../modules/pickup/actionTypes';

const {
  REQUEST_CART_SUCCESS,
  REQUEST_SHIPPING_TYPES_SUCCESS,
  SET_STEP,
  SET_STORE_ID,
  SET_PICKUP_STATION_ID,
  SET_PAYPAL_ECS,
  RESET_STORE_ID,
  RESET_PICKUP_STATION_ID,
} = cartTypes;

const {
  REQUEST_PICKUP_STATIONS,
  REQUEST_PICKUP_STATIONS_SUCCESS,
  REQUEST_PICKUP_STATIONS_FAILURE,
} = pickupTypes;

const { REQUEST_WISHLIST_SUCCESS, SET_IS_FETCHING } = wishListTypes;

const FORM_ACTIONS = Object.values(formTypes);

export const STATE_SYNC_CONFIG = {
  channel: 'darjeeling_broadcast_channel',
  whitelist: [
    // cart actions
    REQUEST_CART_SUCCESS,
    REQUEST_SHIPPING_TYPES_SUCCESS,
    SET_STEP,
    SET_STORE_ID,
    SET_PICKUP_STATION_ID,
    SET_PAYPAL_ECS,
    RESET_STORE_ID,
    RESET_PICKUP_STATION_ID,
    REQUEST_PICKUP_STATIONS,
    REQUEST_PICKUP_STATIONS_SUCCESS,
    REQUEST_PICKUP_STATIONS_FAILURE,
    // whishlist action
    REQUEST_WISHLIST_SUCCESS,
    SET_IS_FETCHING,
    // copy all actions from, forms
    ...FORM_ACTIONS,
  ],
};
