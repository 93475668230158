import * as React from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { CmsHomepageCategory, CmsText } from '../../cms/types';
import { CategoryItem } from './CategoryItem';
import { breakpoints, colors } from '../../../design-system';
import { hasValidCmsText } from '../utils';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  content: CmsHomepageCategory[];
  backgroundColorMobile?: string;
  titleMobile: CmsText | undefined;
  titleDesktop: CmsText | undefined;
  titleDesktopAlignment: string | undefined;
  titleMobileAlignment: string | undefined;
  isActiveDesktop: boolean | undefined;
  isActiveMobile: boolean | undefined;
};

type CategorieContainerProps = {
  backgroundColorMobile: string | undefined;
  titleMobileAlignment: string | undefined;
  titleDesktopAlignment: string | undefined;
  isActiveMobile: boolean;
  isActiveDesktop: boolean;
  isScrollable?: boolean;
};

const CategorieContainer = styled.div<CategorieContainerProps>`
  background: ${(props) => props.backgroundColorMobile ?? colors.WHITE};
  padding: 0 0 0 16px;
  display: ${(props) => (props.isActiveMobile ? 'block' : 'none')};
  @media (min-width: ${breakpoints.S}px) {
    display: ${(props) => (props.isActiveDesktop ? 'block' : 'none')};
    background: ${colors.WHITE};
    padding: 0;
  }
  .categorie-home-items-container {
    display: grid;
    overflow-x: auto;
    overflow-y: hidden;
    grid-gap: 16px;
    grid-auto-flow: column;
    width: 100%;
    :last-child {
      padding-right: 16px;
      @media (min-width: ${breakpoints.S}px) {
        padding-right: 0;
      }
    }
  }
  &.categrie-home-banner-column {
    padding: 16px 0;
  }
  .categorie-home-title-wrapper-mobile {
    padding-bottom: 16px;
    padding-left: ${(props) => (props.isScrollable ? 0 : '16px')};
    padding-right: ${(props) => (props.isScrollable ? 0 : '16px')};
    text-align: ${(props) => props.titleMobileAlignment ?? 'left'};
    @media (min-width: ${breakpoints.S}px) {
      display: none;
    }
  }
  .categorie-home-title-wrapper-desktop {
    display: none;
    @media (min-width: ${breakpoints.S}px) {
      display: block;
      padding-bottom: 24px;
      text-align: ${(props) => props.titleDesktopAlignment ?? 'center'};
    }
  }
`;

export const Categories = ({
  content,
  titleMobile,
  titleDesktop,
  titleMobileAlignment,
  titleDesktopAlignment,
  backgroundColorMobile,
  isActiveDesktop,
  isActiveMobile,
}: Props) => {
  const { isMobile } = useMediaQueries();
  const shouldDisplayTitleDesktop = hasValidCmsText(titleDesktop);
  const shouldDisplayTitleMobile = hasValidCmsText(titleMobile);
  const shouldDisplayTitlesBlock = shouldDisplayTitleDesktop || shouldDisplayTitleMobile;

  return content.length < 3 ? null : content.length > 4 || !isMobile ? (
    <CategorieContainer
      backgroundColorMobile={backgroundColorMobile}
      titleDesktopAlignment={titleDesktopAlignment}
      titleMobileAlignment={titleMobileAlignment}
      isActiveMobile={Boolean(isActiveMobile)}
      isActiveDesktop={Boolean(isActiveDesktop)}
      isScrollable
    >
      {shouldDisplayTitlesBlock && (
        <div>
          {shouldDisplayTitleMobile && (
            <div className="categorie-home-title-wrapper-mobile">
              <RichText render={titleMobile} />
            </div>
          )}
          {shouldDisplayTitleDesktop && (
            <div className="categorie-home-title-wrapper-desktop">
              <RichText render={titleDesktop} />
            </div>
          )}
        </div>
      )}
      <div className="categorie-home-items-container">
        {content.map((item, index) => (
          <CategoryItem
            key={index}
            image={isMobile ? item.image_mobile : item.image}
            link={item.link}
            ctaType={item.cta_type}
            ctaText={item.cta_text}
            backgroundColor={item.cta_color}
            textColor={item.text_color}
            index={index}
            isScrollable
          />
        ))}
      </div>
    </CategorieContainer>
  ) : (
    <CategorieContainer
      className="categrie-home-banner-column"
      backgroundColorMobile={backgroundColorMobile}
      titleDesktopAlignment={titleDesktopAlignment}
      titleMobileAlignment={titleMobileAlignment}
      isActiveDesktop={Boolean(isActiveDesktop)}
      isActiveMobile={Boolean(isActiveMobile)}
    >
      {shouldDisplayTitlesBlock && (
        <div>
          {shouldDisplayTitleMobile && (
            <div className="categorie-home-title-wrapper-mobile">
              <RichText render={titleMobile} />
            </div>
          )}
          {shouldDisplayTitleDesktop && (
            <div className="categorie-home-title-wrapper-desktop">
              <RichText render={titleDesktop} />
            </div>
          )}
        </div>
      )}
      {content.map((item, index) => (
        <CategoryItem
          key={index}
          image={isMobile ? item.image_mobile : item.image}
          link={item.link}
          ctaType={item.cta_type}
          ctaText={item.cta_text}
          backgroundColor={item.cta_color}
          textColor={item.text_color}
          index={index}
        />
      ))}
    </CategorieContainer>
  );
};
