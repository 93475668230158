import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Box, Icon } from '../../../design-system';
import { scrollToTop } from '../../common/utils';
import paths from '../../routing/paths';
import { isItGiftCard, getProductIdsFromUrl } from '../../product/utils';

export default function ScrollBackToTopButton() {
  const [isActive, setIsActive] = React.useState(false);
  const { isMobile } = useMediaQueries();
  const { pathname } = useLocation();

  useEffect(() => {
    function scrollListener() {
      setIsActive(window.scrollY > 600);
    }
    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  const stickyCtaIsActive =
    (isMobile && pathname.startsWith(paths.PRODUCT)) ||
    (!isMobile &&
      pathname.startsWith(paths.PRODUCT) &&
      !isItGiftCard(getProductIdsFromUrl(pathname).productRef));

  if (isActive) {
    return (
      <Box
        id="scroll-top-header-button"
        width={['44px', '150px', '150px']}
        height="44px"
        backgroundColor="#000000"
        position="fixed"
        right={['5%', '5%', '3%']}
        bottom={stickyCtaIsActive ? ['66px', '112px', '112px'] : '40px'}
        display="flex"
        borderRadius={['50%', '30px', '30px']}
        alignItems="center"
        justifyContent="center"
        zIndex={89}
        onClick={() => scrollToTop()}
      >
        <Box mb={['na', 'xs', 'xs']}>
          <Icon color="#FFFFFF" name="arrowUp" />
        </Box>
        {!isMobile && (
          <Box fontSize="16px" color="#FFFFFF" ml="xs">
            Haut de page
          </Box>
        )}
      </Box>
    );
  }
  return null;
}
