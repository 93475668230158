import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { WEBSOCKET_URL } from '../../api/constants';
import { WS_EVENTS } from '../types';
import { setIsOutdated } from '../actions';

export function useWebsocket() {
  const dispatch = useDispatch();

  useEffect(() => {
    const ws = new ReconnectingWebSocket(WEBSOCKET_URL);

    ws.onmessage = ({ data }) => {
      switch (data) {
        case WS_EVENTS.MAINTENANCE_SWITCH:
          window.location.reload();
          break;
        case WS_EVENTS.VERSION_UPDATE:
          dispatch(setIsOutdated(true));
          break;
        default:
          break;
      }
    };
  }, []);
}
