import { css } from '@emotion/core';

import { breakpoints } from '../design-system';

export const richTextStyles = css`
  span[class^='MaisonNeue'],
  span[class^='Ogg-text'] {
    line-height: 130%;

    a {
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      color: currentColor;

      :after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform: scaleX(1);
        transform-origin: 0 100%;
        transition: transform 0.4s ease-in-out;
        pointer-events: none;
      }

      @media (hover: hover) {
        :hover {
          :after {
            transform: scaleX(0);
            transform-origin: 100% 0;
          }
        }
      }
    }

    strong,
    strong > * {
      font-weight: 700;
    }

    em {
      font-weight: inherit;
    }

    ul {
      padding-inline-start: 16px;
      margin-right: 8px;
    }

    ol {
      list-style: disc;
      margin-left: -16px;
      padding-block-start: 0;
      padding-block-end: 0;
    }

    li {
      padding-block-start: 0.8rem;
      padding-block-end: 0.8rem;
    }

    &.line-break {
      padding-block-start: 0.7rem;
      padding-block-end: 0.7rem;
    }

    &.MaisonNeue-12pt {
      font-size: 1.2rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';
    }

    &.MaisonNeue-14pt {
      font-size: 1.4rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';
    }

    &.MaisonNeue-16pt {
      font-size: 1.4rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 1.6rem;
      }
    }

    &.MaisonNeue-20pt {
      font-size: 1.6rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 2rem;
      }
    }

    &.MaisonNeue-30pt {
      font-size: 2rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 3rem;
      }
    }

    &.MaisonNeue-40pt {
      font-size: 3rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 4rem;
      }
    }

    &.MaisonNeue-50pt {
      font-size: 4rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 5rem;
      }
    }

    &.MaisonNeue-80pt {
      font-size: 5rem;
      font-weight: inherit;
      font-family: 'MaisonNeue';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 8rem;
      }
    }

    &.Ogg-text-12pt {
      font-size: 1.2rem;
      font-weight: inherit;
      font-family: 'Ogg-text';
    }

    &.Ogg-text-14pt {
      font-size: 1.4rem;
      font-weight: inherit;
      font-family: 'Ogg-text';
    }

    &.Ogg-text-16pt {
      font-size: 1.4rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 1.6rem;
      }
    }

    &.Ogg-text-20pt {
      font-size: 1.6rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 2rem;
      }
    }

    &.Ogg-text-30pt {
      font-size: 2rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 3rem;
      }
    }

    &.Ogg-text-40pt {
      font-size: 3rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 4rem;
      }
    }

    &.Ogg-text-50pt {
      font-size: 4rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 5rem;
      }
    }

    &.Ogg-text-80pt {
      font-size: 5rem;
      font-weight: inherit;
      font-family: 'Ogg-text';

      @media (min-width: ${breakpoints.S}px) {
        font-size: 8rem;
      }
    }
  }
`;
