import { State, Product, Size } from './types';
import { Reviews } from '../reviews/types';

export const emptyProduct: Product = {
  productRef: '',
  productName: '',
  productDescription: '',
  productComposition: '',
  status: false,
  eReservation: false,
  colorRef: '',
  colorLabel: '',
  detailedColorLabel: '',
  realColorRef: '',
  collectionName: '',
  cleaningAdvice: '',
  originalPrice: 0,
  storePrice: 0,
  promotionPercentage: '',
  promotionLabel: '',
  contentRecycledMaterials: 0,
  recyclability: false,
  rejectsMicrofibers: false,
  toxicity: false,
  traceability: '',
  hide_csr_block: false,
  images: [],
  categories: {
    lvl0: '',
    lvl1: '',
    lvl2: '',
    lvl3: '',
  },
  colorVariants: [],
  sizeVariants: [],
  model: [],
};

export const emptyProductModal: Product = {
  productRef: '',
  productName: '',
  productDescription: '',
  productComposition: '',
  status: false,
  eReservation: false,
  colorRef: '',
  colorLabel: '',
  detailedColorLabel: '',
  realColorRef: '',
  collectionName: '',
  cleaningAdvice: '',
  originalPrice: 0,
  storePrice: 0,
  promotionPercentage: '',
  promotionLabel: '',
  contentRecycledMaterials: 0,
  recyclability: false,
  rejectsMicrofibers: false,
  toxicity: false,
  traceability: '',
  hide_csr_block: false,
  images: [],
  categories: {
    lvl0: '',
    lvl1: '',
    lvl2: '',
    lvl3: '',
  },
  colorVariants: [],
  sizeVariants: [],
  model: [],
};

export const emptySize: Size = {
  sku: '',
  originalPrice: 0,
  storePrice: 0,
  promotionPercentage: '',
  stockQuantity: 0,
  cupSize: '',
  bandSize: '',
  size: '',
  storesAvailable: [],
};

export const emptyReviews: Reviews = {
  product_name: '',
  product_code: '',
  product_code_parent: '',
  rating_count: 1,
  rating_average: '',
  rating_scale_min: 1,
  rating_scale_max: 5,
  recommendation_percent: 0,
  sub_questions_global: [
    {
      sub_question_id: 0,
      sub_question_title: {},
      sub_question_rating: 0,
    },
  ],
  feedbacks: [
    {
      feedback_id: 0,
      created_at: new Date(),
      author: '',
      size: '',
      rating: 0,
      recommendation: true,
      verbatim: '',
      sub_answers: [
        {
          sub_question_title: {},
          sub_question_rating: 5,
        },
      ],
    },
  ],
};

export const productState: State = {
  product: { ...emptyProduct },
  productModal: { ...emptyProductModal },
  isFetching: false,
  errMsg: '',
  size: { ...emptySize },
  sizeModal: { ...emptySize },
  productReviews: {
    isFetching: false,
    errMsg: '',
    reviews: { ...emptyReviews },
  },
  sizeErrMsg: '',
  quantity: 1,
  crossSellIsLoading: true,
  upSellIsLoading: true,
};
