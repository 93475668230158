import * as React from 'react';

import { PickupDelivery } from './PickupDelivery';
import { createMap, BASE_LOC } from '../../store-locator/utils';

const PickupMap = () => {
  const [mapState, setMapState] = React.useState<{
    loading: boolean;
    sessionToken?: google.maps.places.AutocompleteSessionToken;
    autocomplete?: google.maps.places.AutocompleteService;
  }>({
    loading: true,
  });

  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let isMounted = true;
    const initMap = async (element: HTMLDivElement) => {
      const { sessionToken, autocomplete } = await createMap({
        center: { ...BASE_LOC },
        zoom: 10,
        element,
      });
      if (isMounted) {
        setMapState({ loading: false, sessionToken, autocomplete });
      }
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
    return () => {
      isMounted = false;
    };
  }, [mapRef]);

  return (
    <div>
      {!mapState.loading && <PickupDelivery {...mapState} />}
      <div ref={mapRef} />
    </div>
  );
};

export default PickupMap;
