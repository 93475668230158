import React from 'react';

import { Box, Button, Icon } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { scrollToFormAffiliate } from '../utils';

const ConditionsBlock = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { isMobile, isTablet } = useMediaQueries();

  return (
    <Box
      id="affiliate-conditions-block"
      mt={['48px', '64px', '88px']}
      width={['343px', '706px', '1158px']}
      backgroundColor="rgba(241, 237, 230, 0.4)"
      display="flex"
      alignItems="center"
      flexDirection="column"
      py={['48px', '32px', '64px']}
      px={['24px', 'na', 'na']}
      border="1px solid #DAB09E"
    >
      <Box
        fontFamily="Ogg-text"
        fontWeight="700"
        fontSize={['20px', '20px', '30px']}
        lineHeight="130%"
      >
        Les conditions d’accès
      </Box>
      {!isMobile && (
        <Box
          width="80px"
          height="1px"
          border="solid 1px black"
          my="16px"
          alignSelf={['flex-start', 'inherit', 'inherit']}
        />
      )}
      <Box
        fontSize={['14px', '20px', '20px']}
        textAlign="center"
        width={['auto', '600px', '800px']}
        mt={['8px', 'na', 'na']}
        mb={['32px', '32px', '64px']}
        lineHeight="130%"
      >
        Pour assurer votre succès dans le temps, nous nous impliquons durablement à vos côtés : de
        la création de votre point de vente jusqu’au renouvellement des vitrines.
      </Box>
      <Box
        display="flex"
        flexDirection={['column', 'row', 'row']}
        flexWrap={['na', 'wrap', 'wrap']}
        alignItems={['center', 'na', 'na']}
        justifyContent={['na', 'space-between', 'space-evenly']}
        width={['100%', '700px', '1000px']}
        pb={['initial', '64px', 'initial']}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width={['inherit', '200px', '300px']}
          height={['inherit', '140px', '140px']}
          mb={['32px', 'na', 'na']}
        >
          <Icon name="contractAffiliate" size={56} />
          <Box fontWeight="700" fontSize="16px" my="8px" lineHeight="130%">
            Type de contrat
          </Box>
          <Box fontWeight="400" fontSize="16px" lineHeight="130%">
            Affiliation
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width={['inherit', '200px', '300px']}
          height={['inherit', '140px', '140px']}
          mb={['32px', 'na', 'na']}
          mx={['na', '30px', '50px']}
        >
          <Icon name="commissionAffiliate" size={56} />
          <Box fontWeight="700" fontSize="16px" my="8px" lineHeight="130%">
            Taux de commission
          </Box>
          <Box
            fontWeight="400"
            fontSize="16px"
            textAlign={['left', 'left', 'center']}
            lineHeight="130%"
            width={['60%', '200px', '250px']}
          >
            - Des taux par palier de CA retail HT, avec une marge brute de 50% pour le premier
            palier.
            <br />
            {isMobile && <br />} - Des points supplémentaires de marge sur les 2 premières années de
            vos ventes en boutique.
            <br />
            {isMobile && <br />} - Des commissions complémentaires pour rémunérer la contribution
            aux ventes omnicanales.
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width={['inherit', '200px', '300px']}
          height={['inherit', '140px', '140px']}
          mb={['32px', 'na', 'na']}
        >
          <Icon name="contractPenAffiliate" size={56} />
          <Box fontWeight="700" fontSize="16px" my="8px" lineHeight="130%">
            Contrat
          </Box>
          <Box fontWeight="400" fontSize="16px" lineHeight="130%">
            7 ans
          </Box>
        </Box>
        {(isOpen || !isMobile) && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width={['inherit', '200px', '300px']}
              height={['inherit', '140px', '140px']}
              mt={['na', '48px', '48px']}
              mb={['32px', 'na', 'na']}
              mr={['na', '15px', '50px']}
            >
              <Icon name="personalContributionAffiliate" size={56} />
              <Box fontWeight="700" fontSize="16px" my="8px" lineHeight="130%">
                Apport personnel
              </Box>
              <Box fontWeight="400" fontSize="16px" textAlign="center" lineHeight="130%">
                40 000€ HT minimum
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width={['inherit', '200px', '300px']}
              height={['inherit', '140px', '140px']}
              mt={['na', '48px', '48px']}
              mb={['32px', 'na', 'na']}
              ml={['na', '15px', '50px']}
            >
              <Icon name="entranceFeeAffiliate" size={56} />
              <Box fontWeight="700" fontSize="16px" my="8px" lineHeight="130%">
                Droit d’entrée
              </Box>
              <Box fontWeight="400" fontSize="16px" lineHeight="130%">
                13 000€ HT
              </Box>
            </Box>
          </>
        )}
        {isMobile && (
          <Box
            display="flex"
            width="65%"
            justifyContent="center"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Box mr="4px" fontSize="14px" alignSelf="center" lineHeight="110%">
              Voir {isOpen ? 'moins' : 'plus'} de conditions
            </Box>
            <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size={16} />
          </Box>
        )}
      </Box>
      <Box
        width={['295px', '173px', '188px']}
        mt={['32px', 'na', '24px']}
        mb={['na', 'na', 'na']}
        onClick={() => scrollToFormAffiliate({ isMobile, isTablet })}
      >
        <Button id="affiliate-conditions-button" fontWeight="700">
          Devenir affilié
        </Button>
      </Box>
    </Box>
  );
};

export default ConditionsBlock;
