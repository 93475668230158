import { State } from './types';

export const trackingState: State = {
  dataLayer: {
    env_work: undefined,
    env_device: undefined,
    env_language: undefined,
    env_country: undefined,
    env_currency: undefined,
    page_id: undefined,
    page_name: undefined,
    page_template: undefined,
    page_error: undefined,
    page_category1: undefined,
    page_category2: undefined,
    page_category3: undefined,
    page_query: undefined,
    page_filters: undefined,
    page_products: [],
    user: undefined,
    cart: undefined,
    order: undefined,
  },
};
