import React, { useEffect, useRef } from 'react';
import Box from '../box';
import Icon from '../icon';
import Text from '../text';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Transition } from 'react-transition-group';
import { zIndex } from '../../modules/common/constants';
import { CloseButton } from '../button';

type Props = {
  icon?: string;
  iconColor?: string;
  children?: React.ReactNode;
  placement?: 'top' | 'bottom';
  offset?: number;
  noTimeout?: boolean;
  timeout?: number;
};

export const Snackbar: React.FC<Props> = ({
  icon = 'checkmarkCircleBold',
  iconColor,
  children,
  placement = 'top',
  offset = 24,
  noTimeout,
  timeout = 5000,
}) => {
  const nodeRef = useRef(null);
  const [isShown, setIsShown] = React.useState(true);

  const duration = {
    enter: 200,
    exit: 500,
  };

  const defaultStyle = {
    transition: `opacity ${duration.enter}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0, transition: `opacity ${duration.exit}ms ease-in-out` },
    exited: { opacity: 0 },
  };

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout;
    if (!noTimeout) {
      timeoutFunction = setTimeout(() => {
        setIsShown(false);
        clearTimeout(timeoutFunction);
      }, timeout);
    }
    return () => {
      clearTimeout(timeoutFunction);
    };
  }, []);

  return (
    <Transition
      nodeRef={nodeRef}
      in={isShown}
      timeout={duration}
      mountOnEnter
      unmountOnExit
      appear={true}
      enter={true}
    >
      {(state) => (
        <Box
          id={'snackbar'}
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          position="fixed"
          zIndex={zIndex.SNACKBAR}
          bottom={placement === 'bottom' ? `${offset}px` : 'auto'}
          top={placement === 'top' ? `${offset}px` : 'auto'}
          right={16}
          padding={16}
          display={'grid'}
          gridTemplateColumns={'min-content 1fr min-content'}
          alignItems={'center'}
          width={['calc(100% - 32px)', 'auto']}
          maxWidth={'calc(100% - 32px)'}
          height={'auto'}
          borderColor={'#E6E6E6'}
          borderStyle={'solid'}
          borderWidth={'1px'}
          backgroundColor={'#FFFFFF'}
          boxShadow={'-4px 4px 8px rgba(0, 0, 0, 0.15)'}
        >
          <Icon name={icon} size={20} color={iconColor} />
          <SnackbarText>{children}</SnackbarText>
          <CloseButton
            id="close-snackbar-button"
            iconName="closeSmall"
            onClick={() => setIsShown(false)}
          />
        </Box>
      )}
    </Transition>
  );
};

const SnackbarText = styled(Text)(
  css({
    fontWeight: 700,
    fontSize: '16px',
    fontStyle: 'normal',
    paddingRight: ['16px', '72px'],
    paddingLeft: '16px',
  })
);
