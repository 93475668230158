export const sizingGuideBraData = {
  sizeGuide: [
    {
      entries: [
        'Taille universelle',
        'Taille FR',
        { stepIndicator: '1', label: 'Tour de poitrine' },
        { stepIndicator: '2', label: 'Tour de dos' },
      ],
    },
    { entries: ['XS', '80A', '76 - 78 cm', '63 - 67 cm'] },
    { entries: ['XS', '80B', '79 - 81 cm', '63 - 67 cm'] },
    { entries: ['S', '80C', '82 - 84 cm', '63 - 67 cm'] },
    { entries: ['S', '80D', '85 - 87 cm', '63 - 67 cm'] },
    { entries: ['S/M', '80E', '88 - 90 cm', '63 - 67 cm'] },
    { entries: ['tooltip', '80F', '91 - 93 cm', '63 - 67 cm'] },
    { entries: ['tooltip', '80G', '94 - 96 cm', '63 - 67 cm'] },
    { entries: ['tooltip', '80H', '97 - 99 cm', '63 - 67 cm'] },

    { entries: ['XS', '85A', '81 - 83 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['S', '85B', '84 - 86 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['S', '85C', '87 - 89 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['M', '85D', '90 - 92 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['M', '85E', '93 - 95 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['M/L', '85F', '96 - 98 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['tooltip', '85G', '99 - 101 cm', '68 - 72 cm'], variant: 'gray' },
    { entries: ['tooltip', '85H', '102 - 104 cm', '68 - 72 cm'], variant: 'gray' },

    { entries: ['S', '90A', '86 - 88 cm', '73 - 77 cm'] },
    { entries: ['S', '90B', '89 - 91 cm', '73 - 77 cm'] },
    { entries: ['M', '90C', '92 - 94 cm', '73 - 77 cm'] },
    { entries: ['M', '90D', '95 - 97 cm', '73 - 77 cm'] },
    { entries: ['L', '90E', '98 - 100 cm', '73 - 77 cm'] },
    { entries: ['L', '90F', '101 - 103 cm', '73 - 77 cm'] },
    { entries: ['XL', '90G', '104 - 106 cm', '73 - 77 cm'] },
    { entries: ['tooltip', '90H', '107 - 109 cm', '73 - 77 cm'] },

    { entries: ['S/M', '95A', '91 - 93 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['M', '95B', '94 - 96 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['M', '95C', '97 - 99 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['L', '95D', '100 - 102 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['L/XL', '95E', '104 - 105 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['XL', '95F', '106 - 108 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['XXL', '95G', '109 - 111 cm', '78- 82 cm'], variant: 'gray' },
    { entries: ['tooltip', '95H', '112 - 114 cm', '78- 82 cm'], variant: 'gray' },

    { entries: ['M/L', '100B', '99 - 101 cm', '83 - 87 cm'] },
    { entries: ['L', '100C', '102 - 104 cm', '83 - 87 cm'] },
    { entries: ['L', '100D', '105 - 107 cm', '83 - 87 cm'] },
    { entries: ['XL', '100E', '108 - 110 cm', '83 - 87 cm'] },
    { entries: ['XXL', '100F', '111 - 113 cm', '83 - 87 cm'] },
    { entries: ['XXL', '100G', '114 - 116 cm', '83 - 87 cm'] },
    { entries: ['tooltip', '100H', '117 - 119 cm', '83 - 87 cm'] },

    { entries: ['tooltip', '105A', '101 - 103 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105B', '104 - 106 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105C', '107 - 109 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105D', '110 - 112 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105E', '113 - 115 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105F', '116 - 118 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105G', '119 - 121 cm', '88 - 92 cm'], variant: 'gray' },
    { entries: ['tooltip', '105H', '122 - 124 cm', '88 - 92 cm'], variant: 'gray' },

    { entries: ['tooltip', '110A', '106 - 108 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110B', '109 - 111 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110C', '112 - 114 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110D', '115 - 117 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110E', '118 - 120 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110F', '121 - 123 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110G', '124 - 126 cm', '93 - 97 cm'] },
    { entries: ['tooltip', '110H', '127 - 129 cm', '93 - 97 cm'] },
  ],
};

export const sizingGuidePantyData = {
  sizeGuide: [
    { entries: ['Taille universelle', 'Taille FR', 'Tour de hanches'] },
    { entries: ['XS', '34', '71 - 75 cm'] },
    { entries: ['XS/S', '36', '76 - 80 cm'] },
    { entries: ['S', '38', '81 - 85 cm'] },
    { entries: ['M', '40', '86 - 90 cm'] },
    { entries: ['M', '42', '91 - 95 cm'] },
    { entries: ['L', '44', '96 - 100 cm'] },
    { entries: ['L', '46', '101 - 105 cm'] },
    { entries: ['XL', '48', '106 - 110 cm'] },
  ],
};

export const sizingGuidePyjamaData = {
  title: 'Bas de pyjamas',
  sizeGuide: [
    { entries: ['Taille universelle', 'Taille FR', 'Tour de hanches', 'Tour de taille'] },
    { entries: ['0 / XS', '34 / 36', '80 - 88 cm', '52 - 60 cm'] },
    { entries: ['1 / S', '36 / 38', '82 - 96 cm', '60 - 68 cm'] },
    { entries: ['2 / M', '40 / 42', '96 - 104 cm', '68 - 76 cm'] },
    { entries: ['3 / L', '44 / 46', '104 - 112 cm', '76 - 84 cm'] },
    { entries: ['4 / XL', '46 / 48', '112 - 120 cm', '84 - 92 cm'] },
  ],
};

export const sizingGuideBabyDollData = {
  title: 'Hauts de pyjamas & nuisettes',
  sizeGuide: [
    {
      entries: [
        'Taille FR',
        'Tour de sous poitrine',
        'Tour de poitrine',
        'Tour de hanches',
        'Tour de taille',
      ],
    },
    { entries: ['36', '63 - 67 cm', '79 - 81 cm', '88 - 92 cm', '60 - 64 cm'] },
    { entries: ['38', '68 - 72 cm', '84 - 86 cm', '92 - 96 cm', '64 - 68 cm'] },
    { entries: ['40', '73 - 77 cm', '89 - 91 cm', '96 - 100 cm', '68 - 72 cm'] },
    { entries: ['42', '78 - 82 cm', '94 - 96 cm', '100 - 104 cm', '72 - 76 cm'] },
    { entries: ['44', '83 - 87 cm', '99 - 101 cm', '104 - 108 cm', '76 - 80 cm'] },
    { entries: ['46', '88 - 92 cm', '104 - 106 cm', '108 - 112 cm', '80 - 84 cm'] },
    { entries: ['48', '93 - 97 cm', '109 - 111 cm', '112 - 116 cm', '84 - 88 cm'] },
  ],
};

export const sizingGuideSwimwearTopData = {
  title: 'Haut de maillots de bain - triangles et bandeaux',
  sizeGuide: [
    { entries: ['Taille FR', 'Tour de sous poitrine', 'Tour de poitrine', 'Correspondances'] },
    { entries: ['36', '63 - 67 cm', '76 - 83 cm', ['1', '80/85A']] },
    { entries: ['38', '68 - 72 cm', '84 - 86 cm', ['tooltip', '85B']] },
    { entries: ['40', '73 - 77 cm', '87 - 91 cm', ['2', '90B/85C']] },
    { entries: ['42', '78 - 82 cm', '92 - 96 cm', ['tooltip', '90C/85D']] },
    { entries: ['44', '83 - 87 cm', '97 - 99 cm', ['3', '85E/90D/95C']] },
  ],
};

export const sizingGuideSwimwearBottomData = {
  title: 'Bas de maillots de bain',
  sizeGuide: [
    { entries: ['Taille FR', 'Tour de hanches', 'Tour de taille'] },
    { entries: ['36', '88 - 92 cm', '60 - 64 cm'] },
    { entries: ['38', '92 - 96 cm', '64 - 68 cm'] },
    { entries: ['40', '96 - 100 cm', '68 - 72 cm'] },
    { entries: ['42', '100 - 104 cm', '72 - 76 cm'] },
    { entries: ['44', '104 - 108 cm', '76 - 80 cm'] },
    { entries: ['46', '108 - 112 cm', '80 - 84 cm'] },
  ],
};

export const sizingGuideOnePieceSwimwearData = {
  title: 'Maillots de bain 1 pièce + beachwear',
  sizeGuide: [
    {
      entries: [
        'Taille FR',
        'Tour de sous poitrine',
        'Tour de poitrine',
        'Tour de hanches',
        'Tour de taille',
      ],
    },
    { entries: ['36', '63 - 67 cm', '79 - 81 cm', '88 - 92 cm', '60 - 64 cm'] },
    { entries: ['38', '68 - 72 cm', '84 - 86 cm', '92 - 96 cm', '64 - 68 cm'] },
    { entries: ['40', '73 - 77 cm', '89 - 91 cm', '96 - 100 cm', '68 - 72 cm'] },
    { entries: ['42', '78 - 82 cm', '94 - 96 cm', '100 - 104 cm', '72 - 76 cm'] },
    { entries: ['44', '83 - 87 cm', '99 - 101 cm', '104 - 108 cm', '76 - 80 cm'] },
    { entries: ['46', '88 - 92 cm', '104 - 106 cm', '108 - 112 cm', '80 - 84 cm'] },
    { entries: ['48', '93 - 97 cm', '109 - 111 cm', '112 - 116 cm', '84 - 88 cm'] },
  ],
};

export const sizingGuideSoftstretchData = {
  sizeGuide: [
    { entries: ['Taille universelle', 'Taille FR'] },
    { entries: ['XS', '80A'] },
    { entries: ['XS', '80B'] },
    { entries: ['S', '80C'] },
    { entries: ['S', '80D'] },
    { entries: ['S / M', '80E'] },
    { entries: ['tooltip', '80F'] },
    { entries: ['tooltip', '80G'] },
    { entries: ['tooltip', '80H'] },

    { entries: ['XS', '85A'], variant: 'gray' },
    { entries: ['S', '85B'], variant: 'gray' },
    { entries: ['S', '85C'], variant: 'gray' },
    { entries: ['M', '85D'], variant: 'gray' },
    { entries: ['M', '85E'], variant: 'gray' },
    { entries: ['M / L', '85F'], variant: 'gray' },
    { entries: ['tooltip', '85G'], variant: 'gray' },
    { entries: ['tooltip', '85H'], variant: 'gray' },

    { entries: ['S', '90A'] },
    { entries: ['S', '90B'] },
    { entries: ['M', '90C'] },
    { entries: ['M', '90D'] },
    { entries: ['M / L', '90E'] },
    { entries: ['L', '90F'] },
    { entries: ['XL / XXL', '90G'] },
    { entries: ['XL / XXL', '90H'] },

    { entries: ['S / M', '95A'], variant: 'gray' },
    { entries: ['M', '95B'], variant: 'gray' },
    { entries: ['M', '95C'], variant: 'gray' },
    { entries: ['L', '95D'], variant: 'gray' },
    { entries: ['L', '95E'], variant: 'gray' },
    { entries: ['L / XL', '95F'], variant: 'gray' },
    { entries: ['XL', '95G'], variant: 'gray' },
    { entries: ['XXL', '95H'], variant: 'gray' },

    { entries: ['tooltip', '100A'] },
    { entries: ['M / L', '100B'] },
    { entries: ['L', '100C'] },
    { entries: ['L', '100D'] },
    { entries: ['XL', '100E'] },
    { entries: ['XL', '100F'] },
    { entries: ['XXL', '100G'] },
    { entries: ['XXL', '100H'] },

    { entries: ['tooltip', '105A'], variant: 'gray' },
    { entries: ['tooltip', '105B'], variant: 'gray' },
    { entries: ['tooltip', '105C'], variant: 'gray' },
    { entries: ['XL', '105D'], variant: 'gray' },
    { entries: ['XL', '105E'], variant: 'gray' },
    { entries: ['XXL', '105F'], variant: 'gray' },
    { entries: ['XXL', '105G'], variant: 'gray' },
    { entries: ['tooltip', '105H'], variant: 'gray' },
  ],
};
