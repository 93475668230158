import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { breakpoints } from '../../../design-system';
import { CmsMenuItem } from '../../cms/types';
import { ThirdLevel } from './ThirdLevel';
import { ImageBanner } from './ImageBanner';

export const MAX_NUMBER_OF_THIRD_LEVEL = 10;

type Props = {
  banner?: CmsMenuItem;
  columns: (CmsMenuItem & { rows: CmsMenuItem[] })[];
};
const StyledSecondLevel = styled.div`
  --gap: 24px;
  @media (min-width: ${breakpoints.L}px) {
    --gap: 80px;
  }
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  .submenu__links-container {
    display: flex;
    gap: var(--gap);
  }
  .submenu__column-container {
    padding: 16px;
    @media (min-width: ${breakpoints.L}px) {
      padding: 16px 24px 24px 24px;
    }
  }
  .submenu__image-container {
    padding: 0 16px 16px 16px;
    @media (min-width: ${breakpoints.L}px) {
      padding: 0 24px 24px 24px;
    }
  }
`;

export const SecondLevel = ({ banner, columns }: Props) => {
  return (
    <StyledSecondLevel>
      <div className="submenu__links-container">
        {columns.map((column, index) => {
          const { rows = [] } = column;
          const limitedRows = rows.slice(0, MAX_NUMBER_OF_THIRD_LEVEL);
          return (
            <div key={`desktop-second-level-${column.name}`} className="submenu__column-container">
              <Link
                id={`link-submenu-column-${index}`}
                className="link-menu link-menu--second-level"
                data-testid={`link-submenu-column-${index}`}
                aria-label={`Lien vers la page ${column.name}`}
                to={column.link ?? '#'}
              >
                {column.name}
              </Link>
              {rows.length > 0 && <ThirdLevel rows={limitedRows} columnIndex={index} />}
            </div>
          );
        })}
      </div>
      {banner?.image?.url && (
        <div className="submenu__image-container">
          <ImageBanner banner={banner} />
        </div>
      )}
    </StyledSecondLevel>
  );
};
