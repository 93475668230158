import * as React from 'react';

import { Box } from '../../../design-system';
import { SHIPUP_KEY } from '../../api/constants';
import useScript from '../../common/hooks/useScript';
import BackButton from '../../common/components/BackButton';
import { useNavigate, useParams } from 'react-router-dom';

declare global {
  interface Window {
    ShipupJS: any;
  }
}

const OrderTracking = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const hasScriptLoaded = useScript({
    id: 'shipup-js',
    src: 'https://cdn.shipup.co/latest/shipup-js.js',
    position: 'head',
  });

  const hasStylesLoaded = useScript({
    id: 'shipup-jcss',
    src: 'https://cdn.shipup.co/latest/shipup.css',
    position: 'head',
  });

  React.useEffect(() => {
    if (hasScriptLoaded && hasStylesLoaded && window.ShipupJS) {
      const shipup = new window.ShipupJS.default(SHIPUP_KEY);
      const element = document.getElementById('shipup-container');
      const args = {
        trackingNumber: uid,
        searchEnabled: false,
      };
      shipup.render(element, args);
    }
  }, [hasScriptLoaded, hasStylesLoaded]);

  return (
    <div>
      <Box display={['none', 'block']}>
        <BackButton id="back-btn-desktop" onClick={() => navigate(-1)} preset="darkTheme" />
      </Box>
      <div id="shipup-container" />
      <Box display={['block', 'none']}>
        <BackButton id="back-btn-mobile" onClick={() => navigate(-1)} preset="darkTheme" />
      </Box>
    </div>
  );
};
export default OrderTracking;
