import types from './actionTypes';
import { RecommendationsActions } from './actions';

import { recommendationsState } from './state';
import { State } from './types';

export default function product(
  state = recommendationsState,
  action: RecommendationsActions
): State {
  switch (action.type) {
    case types.REQUEST_RECOMMENDATIONS_LOADING:
      return {
        ...state,
        isFetching: true,
        errMsg: '',
      };
    case types.REQUEST_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errMsg: action.payload,
      };
    case types.REQUEST_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        blocks: { ...state.blocks, ...action.payload },
        isFetching: false,
      };
    default:
      return state;
  }
}
