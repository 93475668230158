import React, { useEffect } from 'react';
import { UnknownAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from '../../../store/rootReducer';
import { loadRecommendations } from '../../recommendations/actions';
import { RecommendationTypes } from '../../recommendations/types';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { breakpoints } from '../../../design-system';
import { RecommendationsBlock } from '../../recommendations/components/RecommendationsBlock';

const StyledHomeRecommendationsContainer = styled.div`
  margin: 0 16px;

  @media (min-width: ${breakpoints.S}px) {
    margin: 0;
  }
`;

export const HomeRecommendations = () => {
  const dispatch = useDispatch();
  const { recommendedProducts, tracking } = useSelector(
    (state: RootState) => state.recommendations.blocks.home
  );
  const { recommendations_t2s_title: title } = useSelector(
    (state: RootState) => state.cms.homepage
  );
  const { hits } = useProductsInfos(recommendedProducts);

  useEffect(() => {
    if (!recommendedProducts || recommendedProducts.length === 0) {
      dispatch(loadRecommendations(RecommendationTypes.home) as unknown as UnknownAction);
    }
  }, []);

  if (recommendedProducts.length === 0) {
    return null;
  }

  return (
    <StyledHomeRecommendationsContainer>
      <RecommendationsBlock id="home-page" hits={hits} title={title} tracking={tracking} />
    </StyledHomeRecommendationsContainer>
  );
};
