import React from 'react';

import { Box, ProductName } from '../../../design-system';
import { getProductPrice } from '../utils';
import ProductPrice from './ProductPrice';
import { Rating } from './Rating';
import { heights, zIndex } from '../../common/constants';
import { RecycleLabel } from '../../recycle';
import { H1 } from '../../common/components/Custom';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  productName: string;
  storePrice: number;
  originalPrice: number;
  ratingAverage: string;
  ratingCount: string;
  showReviews: boolean;
  reviewsRef: React.RefObject<HTMLDivElement>;
  isRecyclable?: boolean;
};

export const InfoPanel = ({
  productName,
  storePrice,
  originalPrice,
  ratingAverage,
  ratingCount,
  reviewsRef,
  showReviews,
  isRecyclable,
}: Props) => {
  const { isMobile } = useMediaQueries();

  const price = getProductPrice({ storePrice, originalPrice });

  return (
    <>
      {isMobile && isRecyclable && <RecycleLabel />}
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap="s"
        alignItems="start"
        justifyContent="space-between"
        bg="WHITE"
        zIndex={isMobile ? zIndex.STICKY : 'initial'}
        mb={isMobile ? 'na' : '-8px'}
        position={isMobile ? 'sticky' : 'initial'}
        bottom={isMobile ? `${heights.MOBILE_SELECTOR}px` : 'initial'}
        mx={isMobile ? '-16px' : 'na'}
        p={isMobile ? '16px 16px 0 16px' : 'na'}
      >
        <H1 id="pdp-product-name" preset={['body', 'subheading']}>
          <ProductName productName={productName} />
        </H1>
        <ProductPrice price={price} />
      </Box>
      <Box display="grid" gridAutoFlow="column" justifyContent="space-between">
        <Box ml="-4px">
          {showReviews && (
            <Rating
              ratingAverage={ratingAverage}
              ratingCount={ratingCount}
              reviewsRef={reviewsRef}
            />
          )}
        </Box>
        {!isMobile && isRecyclable && <RecycleLabel />}
      </Box>
    </>
  );
};
