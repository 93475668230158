export const PAYPAL_EXPRESS_TITLE = 'Paiement express avec';
export const PAYPAL_EXPRESS_SUBTITLE = 'Darjeeling souhaite utiliser PayPal pour se connecter';
export const PAYPAL_EXPRESS_DESCRIPTION = `Cela permet aux deux sites d'échanger des informations vous concernant. En passant une commande chez Darjeeling.fr, vous acceptez la création d'un compte client Darjeeling.`;
export const PAYPAL_EXPRESS_FREE_DELIVERY = 'Livraison à domicile exceptionnellement offerte.';
export const PAYPAL_EXPRESS_OPT_IN =
  'Je souhaite recevoir par e-mail les actualités et les offres personnalisées Darjeeling.';
export const PAYPAL_EXPRESS_THRESHOLD_DELIVERY = `Livraison à domicile à l'adresse renseignée dans votre compte PayPal`;
export const PAYPAL_EXPRESS_PRICE_DELIVERY_DESCRIPTION = (
  homeShippingPrice: string,
  requiredAmountHome: number
): string =>
  `Gratuite dès ${requiredAmountHome}€ d'achat (${homeShippingPrice}€ pour toute commande inférieure à ${requiredAmountHome}€)`;
export const PAYPAL_EXPRESS_PRIVACY_POLICY = 'Politique de confidentialité';
export const PAYPAL_EXPRESS_CTA = 'Poursuivre avec PayPal';
