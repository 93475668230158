import * as React from 'react';

import { StyledField, StyledInput, StyledLabel } from './multilinefield.style';
import Text from '../text';
import Box from '../box';
import Icon from '../icon';
import { Handles } from '../field/Field';
import { durations } from '../systemprovider/constants';

export type Props = {
  id: string;
  value: string;
  label: string;
  errMsg: string;
  onChange: (value: string) => void;
  placeholder?: string;
  note?: string;
  maxLength?: number;
  onBlur?: (value: string) => void;
  areaHeight?: string;
  isAffiliatePage?: boolean;
};

const MultilineField: React.RefForwardingComponent<Handles, Props> = (
  { id, onChange, onBlur, label, errMsg, note, areaHeight, isAffiliatePage, ...rest }: Props,
  ref
) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setTimeout(
        () => (inputRef.current ? inputRef.current.focus() : undefined),
        durations.FOCUS_DELAY
      );
    },
  }));

  return (
    <div>
      <StyledField areaHeight={areaHeight} isAffiliatePage={isAffiliatePage}>
        <StyledInput
          id={id}
          onChange={(event) => onChange(event.target.value)}
          onBlur={(event) => (onBlur ? onBlur(event.target.value) : undefined)}
          ref={inputRef}
          hasError={Boolean(errMsg)}
          isAffiliatePage={isAffiliatePage}
          {...rest}
        />
        <StyledLabel id={`label-for-${id}`} htmlFor={id}>
          {label}
        </StyledLabel>
      </StyledField>
      {(errMsg || note) && (
        <Box my="16px">
          {errMsg ? (
            <Text preset="caption" color="ERROR" id={`error-message-for-${id}`}>
              {errMsg}
            </Text>
          ) : (
            note && (
              <Box
                display="grid"
                gridGap="xs"
                gridAutoFlow="column"
                alignItems="start"
                color="GREY"
                justifyContent="left"
              >
                <Icon id={`info-icon-for-${id}`} name="infoCircle" size={15} />
                <Text preset="caption" color="GREY" id={`note-for-${id}`}>
                  {note}
                </Text>
              </Box>
            )
          )}
        </Box>
      )}
    </div>
  );
};

export default React.forwardRef(MultilineField);
