import { getPersistedData, persistData } from '../common/utils';
import { getIconUrl } from '../../design-system/icon/utils';
import { LABEL_DISABLE_METHOD_ERROR } from './locale';
import { StoredPayments } from './types';
import { PaymentWarning } from '../cms/types';

export const getOrderNumber = (token: string): string => {
  try {
    const paymentMap = getPersistedData('paymentMap');
    return paymentMap[token];
  } catch {
    return '';
  }
};

export const removeToken = (token: string): boolean => {
  try {
    const paymentMap = getPersistedData('paymentMap');
    delete paymentMap[token];
    persistData('paymentMap', paymentMap);
    return true;
  } catch {
    return false;
  }
};

const giftCards = {
  svs: {
    brand: 'svs',
    name: {
      original: 'SVS',
      custom: 'Carte cadeau Darjeeling',
    },
    logo: {
      url: getIconUrl('darjeelingCard'),
    },
  },
  illicado: {
    brand: 'prosodie_illicado',
    name: {
      original: 'Illicado Gift Card',
      custom: 'Carte cadeau Illicado',
    },
  },
};

export const customizeGiftCardNames = (paymentMethodsResponse: string) =>
  JSON.parse(
    paymentMethodsResponse
      .replace(giftCards.svs.name.original, giftCards.svs.name.custom)
      .replace(giftCards.illicado.name.original, giftCards.illicado.name.custom)
  );

const findGiftCardImg = () => {
  // Get all images across the page where src contains 'svs'
  const svsImages = Array.from(document.querySelectorAll<HTMLImageElement>('img')).filter((img) =>
    img.src.includes('svs')
  );

  return svsImages;
};

export const insertPaymentMethodWarning = (warnings?: PaymentWarning[]): void => {
  if (warnings?.length) {
    warnings.forEach(
      ({ warning_method_type, warning_title, warning_description, warning_method_name }) => {
        const methodContainer = warning_method_name
          ? (
              ([].slice.call(
                document.getElementsByClassName('adyen-checkout__payment-method__header')
              ) ?? []) as HTMLImageElement[]
            ).find(({ innerText }) => innerText.includes(warning_method_name))
          : null; // not possible to find by selector, so looking by the content
        const warningContainer = methodContainer?.querySelector(
          '.payment-method__warning-container'
        );
        if (methodContainer && !warningContainer) {
          const warningContainer = document.createElement('div');
          warningContainer.classList.add(
            'payment-method__warning-container',
            `payment-method__warning-container--${warning_method_type}`
          );
          warningContainer.innerHTML = `
          <strong>${warning_title}</strong>
          <p>${warning_description}</p>
        `;
          const detailsContainer = methodContainer.querySelector(
            '.adyen-checkout-pm-details-wrapper'
          );
          methodContainer?.insertBefore(warningContainer, detailsContainer);
        }
      }
    );
  }
};

export const customizeGiftCardLogo = () =>
  findGiftCardImg().map((logo) => {
    logo.src = giftCards.svs.logo.url;
    logo.srcset = giftCards.svs.logo.url;
    logo.alt = 'Carte cadeau Darjeeling image';
    return logo;
  });

export const giftCardLogoExists = () => {
  return findGiftCardImg().length > 0;
};

export const oneClickPaymentDomModifications = (
  isMobile: boolean,
  storedPaymentMethods: StoredPayments[],
  selectedTarget?: string
): null => {
  if (!selectedTarget) {
    storedPaymentMethods &&
      storedPaymentMethods.map((method, i) => {
        const container = document.createElement('div');
        container.setAttribute('id', `container-adyen-custum-${i}`);
        container.classList.add(
          i === 0 && isMobile
            ? 'selected-card-container-mobile'
            : i === 0 && !isMobile
              ? 'selected-card-container'
              : i !== 0 && isMobile
                ? 'stored-card-container-mobile'
                : 'stored-card-container'
        );
        const holderNameDiv = document.createElement('div');
        holderNameDiv.setAttribute('id', `holder-name-custum-${i}`);
        holderNameDiv.classList.add(
          isMobile ? 'stored-card-holder-name-mobile' : 'stored-card-holder-name'
        );
        const holderNameContent = document.createTextNode(method.holderName.toUpperCase());
        holderNameDiv.appendChild(holderNameContent);
        const expiryDateDiv = document.createElement('div');
        expiryDateDiv.setAttribute('id', `expiry-date-custum-${i}`);
        expiryDateDiv.classList.add(
          isMobile ? 'stored-card-expiry-date-mobile' : 'stored-card-expiry-date'
        );
        const expiryDateContent = document.createTextNode(
          `Exp: ${method.expiryMonth}/${method.expiryYear}`
        );
        expiryDateDiv.appendChild(expiryDateContent);
        container.appendChild(expiryDateDiv);
        container.appendChild(holderNameDiv);
        const dropInDiv = document.getElementById('dropin-container');
        const firstDropinUl = dropInDiv
          ?.getElementsByClassName('adyen-checkout__payment-methods-list')
          .item(0);
        const storedPaymentMethodLi = firstDropinUl
          ?.getElementsByClassName('adyen-checkout__payment-method')
          ?.item(i);
        const targetToInsert = firstDropinUl
          ?.getElementsByClassName('adyen-checkout-pm-details-wrapper')
          ?.item(i);
        targetToInsert &&
          document
            .getElementsByClassName(
              isMobile ? 'stored-card-holder-name-mobile' : 'stored-card-holder-name'
            )
            ?.item(i) === null &&
          storedPaymentMethodLi?.insertBefore(container, targetToInsert);
        const titleField = firstDropinUl
          ?.getElementsByClassName('adyen-checkout__payment-method__name')
          ?.item(i);
        titleField?.setAttribute('id', `title__field-custum-${i}`);
        const lastFour = document.getElementById(`title__field-custum-${i}`);
        if (lastFour) {
          lastFour.style.fontWeight = '700';
        }
        return null;
      });
  }
  if (selectedTarget) {
    const dropInDiv = document.getElementById('dropin-container');
    const firstDropinUl = dropInDiv
      ?.getElementsByClassName('adyen-checkout__payment-methods-list')
      ?.item(0);
    const elClassToModify = firstDropinUl
      ?.getElementsByClassName(
        isMobile ? 'selected-card-container-mobile' : 'selected-card-container'
      )
      ?.item(0);
    elClassToModify?.classList.remove(
      isMobile ? 'selected-card-container-mobile' : 'selected-card-container'
    );
    elClassToModify?.classList.add(
      isMobile ? 'stored-card-container-mobile' : 'stored-card-container'
    );
    const selectedLi = document.getElementsByClassName(selectedTarget).item(0);
    const selectedContainer = selectedLi
      ?.getElementsByClassName(isMobile ? 'stored-card-container-mobile' : 'stored-card-container')
      ?.item(0);
    selectedContainer?.classList.remove(
      isMobile ? 'stored-card-container-mobile' : 'stored-card-container'
    );
    selectedContainer?.classList.add(
      isMobile ? 'selected-card-container-mobile' : 'selected-card-container'
    );
    return null;
  }
  return null;
};

const eventListnerOnClick = () => {
  const dropInDiv = document.getElementById('dropin-container');
  const parent =
    dropInDiv && dropInDiv.getElementsByClassName('adyen-checkout__payment-methods-list').item(0);
  const divToRemove = document.getElementsByClassName('error-disable-method').item(0);
  divToRemove && parent?.removeChild(divToRemove);
  document.removeEventListener('click', eventListnerOnClick);
};

export const createErrorDiv = () => {
  const errorDiv = document.createElement('div');
  errorDiv.classList.add('error-disable-method');
  const errorDivContent = document.createTextNode(LABEL_DISABLE_METHOD_ERROR);
  errorDiv.appendChild(errorDivContent);
  const target = document
    .getElementsByClassName('adyen-checkout__payment-method--selected')
    .item(0);
  const dropInDiv = document.getElementById('dropin-container');
  const parent =
    dropInDiv && dropInDiv.getElementsByClassName('adyen-checkout__payment-methods-list').item(0);
  parent?.insertBefore(errorDiv, target);
  document.addEventListener('click', eventListnerOnClick);
};
