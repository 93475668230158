import * as React from 'react';

import { Radio } from './components/Radio';
import { Handles } from '../field/Field';
import { durations, colors } from '../systemprovider';
import styled from '@emotion/styled';

type Props = StyledProps & {
  id: string;
  options: Option[];
  onChange?: (id: string) => void;
  checked?: string;
  errMsg?: string;
  disabled?: boolean;
};

type StyledProps = {
  inline?: boolean;
};

type Option = {
  id: string;
  value: React.ReactNode;
  content?: React.ReactNode;
};

const StyledRadioGroup = styled.div<StyledProps>`
  display: ${(props) => (props.inline ? 'flex' : 'block')};
  gap: ${(props) => (props.inline ? '24px' : '0')};
  .radio-group__description {
    margin: 0;
  }
  .radio-group__description--checked {
    margin: 0 0 16px 0;
  }
  .field-error__container {
    padding-top: 4px;
    height: 8px;
  }
  .field-error__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${colors.ERROR};
    border-color: ${colors.ERROR};
    margin: 0;
  }
`;

const RadioGroup: React.ForwardRefRenderFunction<Handles, Props> = (
  { id, checked, onChange, options = [], inline, errMsg, disabled }: Props,
  ref
) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setTimeout(
        () =>
          divRef.current
            ? divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            : undefined,
        durations.FOCUS_DELAY
      );
    },
  }));

  return (
    <StyledRadioGroup id={id} ref={divRef} inline={inline}>
      {options.map((option) => (
        <div key={option.id}>
          <Radio
            id={option.id}
            onChange={onChange}
            checked={option.id === checked}
            disabled={disabled}
            errMsg={errMsg}
          >
            <p className="radio-group__description">{option.value}</p>
          </Radio>
          {option.id === checked && option.content && (
            <p className="radio-group__description--checked">{option.content}</p>
          )}
        </div>
      ))}
      <div className="field-error__container">
        {errMsg && <p className="field-error__description">{errMsg}</p>}
      </div>
    </StyledRadioGroup>
  );
};

export default React.forwardRef(RadioGroup);
