import * as React from 'react';
import Slider from 'react-slick';

import { CmsHomepageBanner } from '../../../../cms/types';
import { Box } from '../../../../../design-system';
import { HomeBanner } from '../HomeBanner';
import { SliderArrow } from './SliderArrow';
import { SliderDots } from './SliderDots';
import { useMediaQueries } from '../../../../common/hooks/useMediaQuery';

type Props = {
  banners: CmsHomepageBanner[];
  index: string;
};

export const SliderBanner = ({ banners, index: homeIndex }: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const sliderRef = React.useRef<{ slickGoTo: (index: number) => void }>(null);

  const { isDesktop } = useMediaQueries();

  const settings = {
    dots: false,
    fade: isDesktop,
    infinite: true,
    speed: isDesktop ? 1000 : 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: isDesktop ? <SliderArrow position="Right" isSliderHovered={isHovered} /> : undefined,
    prevArrow: isDesktop ? <SliderArrow position="Left" isSliderHovered={isHovered} /> : undefined,
    beforeChange: (_current: number, next: number) => setActiveIndex(next),
  };

  const onDotClick = (index: number) => {
    sliderRef?.current?.slickGoTo(index);
  };

  const ctaLink = banners.map((banner) => banner.cta_link)?.[activeIndex] ?? '';

  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      overflow="hidden"
    >
      <Slider {...settings} ref={sliderRef}>
        {banners.map((banner, index) => (
          <div key={`${homeIndex}-${index}`}>
            <HomeBanner
              banner={banner}
              index={`${homeIndex}-${index}`}
              ctaLink={ctaLink}
              isSlider
            />
          </div>
        ))}
      </Slider>
      <SliderDots length={banners.length} activeIndex={activeIndex} onClick={onDotClick} />
    </Box>
  );
};
