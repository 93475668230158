import * as React from 'react';

import { Box, Divider, Text } from '../../../design-system';

type Props = {
  ratingAverage: number;
  width: number;
  maximumValue: number;
  labelLeft: string;
  labelRight: string;
};

const HorizontalScale = ({ ratingAverage, width, labelLeft, labelRight, maximumValue }: Props) => {
  const minimumValue = 1;
  const position =
    !maximumValue ||
    maximumValue === 0 ||
    typeof maximumValue !== 'number' ||
    !ratingAverage ||
    ratingAverage === 0 ||
    typeof ratingAverage !== 'number'
      ? width / 2
      : ((ratingAverage - minimumValue) / (maximumValue - minimumValue)) * width;
  const cursorSize = 8;

  return (
    <Box display="flex" width={`${width}px`} position="relative">
      <Divider />
      <Box
        position="absolute"
        size={`${cursorSize}px`}
        bg="BLACK"
        top={`-${cursorSize / 2}px`}
        zIndex="absolute"
        left={`${position - cursorSize / 2}px`}
      />
      <Box position="absolute" top="s" left={0} zIndex="absolute">
        <Text preset="caption" color="GREY">
          {labelLeft}
        </Text>
      </Box>
      <Box position="absolute" top="s" zIndex="absolute" left={`${width - 32}px`}>
        <Text preset="caption" color="GREY">
          {labelRight}
        </Text>
      </Box>
    </Box>
  );
};

export default HorizontalScale;
