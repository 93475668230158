import * as React from 'react';
import { css } from '@emotion/core';
import { breakpoints, theme } from '../../../design-system/systemprovider/constants';

import { Box } from '../../../design-system';
import { CmsUspContent } from '../../cms/types';
import { Title } from '../../common/components/Custom';
import UspItem from './UspItem';

type Props = {
  content: CmsUspContent[];
  title: string;
  isProductPage?: boolean;
};

const UspSection = ({ title, content }: Props) => {
  return (
    <Box
      display="grid"
      mx={['na', 'na', 'l']}
      mt={['xl', 'xl', 'xxxl']}
      mb={['xl', 'xl', 'xxxl']}
      gridGap={['l', 'l', 'xxl']}
      textAlign="center"
    >
      <Title>{title}</Title>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: initial;
          grid-auto-columns: initial;

          @media (min-width: ${breakpoints.S}px) {
            justify-content: center;
            align-items: start;
            gap: ${theme.space.l};
          }
          @media (min-width: ${breakpoints.M}px) {
            gap: ${theme.space.xl} ${theme.space.xxxl};
          }
        `}
      >
        {content.map((item, index) => (
          <UspItem key={index} item={item} />
        ))}
      </div>
    </Box>
  );
};

export default UspSection;
