import React, { useEffect, useRef, useState } from 'react';

type Props = {
  url: string;
  isActive: boolean;
  isScriptLoaded: boolean;
};

type CloudflareStream = (videoElement: HTMLIFrameElement) => {
  play: () => Promise<void>;
  pause: () => void;
  muted: boolean;
};

declare const Stream: CloudflareStream;

export const FullSliderVideo = ({ url, isActive, isScriptLoaded }: Props) => {
  const videoRef = useRef<HTMLIFrameElement | null>(null);
  const [videoPlayer, setVideoPlayer] = useState<any>(null);

  useEffect(() => {
    if (isScriptLoaded && videoRef.current) {
      const video = videoRef.current;
      setVideoPlayer(Stream(video));
    }
  }, [isScriptLoaded]);

  useEffect(() => {
    if (videoPlayer) {
      if (isActive) {
        videoPlayer.play().catch(() => {
          videoPlayer.muted = true;
          videoPlayer.play();
        });
      } else {
        videoPlayer.pause();
      }
    }
  }, [isActive, videoPlayer]);

  return (
    <iframe
      ref={videoRef}
      src={url}
      style={{
        border: 'none',
        borderRadius: '8px',
        height: '100%',
        width: '100%',
      }}
      frameBorder="0"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
      allowFullScreen
    />
  );
};
