import * as React from 'react';
import styled from '@emotion/styled';

import { ComponentPropsWithRef } from 'react';
import { colors, opacities } from '../../systemprovider';

type RadioProps = StyledInputContainerProps & {
  id: string;
  onChange?: (id: string) => void;
  errMsg?: string;
  children?: React.ReactNode;
};

type StyledInputContainerProps = {
  inline?: boolean;
  checked: boolean;
  disabled?: boolean;
  isCartDeliveryMethod?: boolean;
};

const StyledInputContainer = styled.label<StyledInputContainerProps>`
  display: flex;
  opacity: ${(props) => (props.disabled ? opacities.DISABLED : 1)};
  gap: 8px;
  align-items: center;
  cursor: ${(props) =>
    props.isCartDeliveryMethod && (props.checked || props.disabled) ? 'initial' : 'pointer'};
  .radio__description {
    color: #000;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

const StyledInput = styled.input<ComponentPropsWithRef<'input'> & RadioProps>`
  min-width: 17px;
  min-height: 17px;
  border-radius: 50%;
  margin: 0;
  accent-color: ${colors.BLACK};

  &:not(:checked):not(:disabled) {
    appearance: none;
    border: ${(props) =>
      props.errMsg ? `1px solid ${colors.ERROR}` : `1px solid ${colors.BLACK}`};
    cursor: pointer;
  }
`;

export const Radio = ({
  id,
  checked = false,
  onChange = () => null,
  inline = false,
  disabled = false,
  isCartDeliveryMethod = false,
  children,
  errMsg,
}: RadioProps) => {
  const handleChange = (event) => {
    if (event.target.type !== 'input' && !disabled && !isCartDeliveryMethod) {
      onChange(id);
    }
  };

  return (
    <StyledInputContainer
      onClick={(event) => handleChange(event)}
      isCartDeliveryMethod={isCartDeliveryMethod}
      inline={inline}
      checked={checked}
      disabled={disabled}
    >
      <StyledInput
        id={id}
        type="radio"
        name={checked ? 'radioOn' : 'radioOff'}
        checked={checked}
        disabled={disabled}
        onChange={(event) => handleChange(event)}
        errMsg={errMsg}
      />
      <div className="radio-description__container">
        <div className="radio__description">{children}</div>
      </div>
    </StyledInputContainer>
  );
};
