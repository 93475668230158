import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { breakpoints } from '../../../design-system';
import { formatPrice } from '../../common/utils';
import { RootState } from '../../../store/rootReducer';
import { NEW_LBL_TOTAL } from '../locale';
import { CartStep } from '../types';

type Props = {
  step?: CartStep;
};

export const TotalAmount = ({ step }: Props) => {
  const { total, shippingAmount } = useSelector((state: RootState) => state.cart.cart);
  return (
    <div
      id="cart-summary-total-block"
      css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        @media (min-width: ${breakpoints.M}px) {
          margin-bottom: 0;
        }
      `}
    >
      <p
        css={css`
          font-size: 1.4rem;
          line-height: 110%;
          font-weight: 700;
          margin-block-start: 0;
          margin-block-end: 0;
        `}
      >
        {NEW_LBL_TOTAL}
      </p>

      <p
        css={css`
          font-size: 1.4rem;
          line-height: 110%;
          font-weight: 700;
          margin-block-start: 0;
          margin-block-end: 0;
        `}
      >
        {formatPrice(step === CartStep.LISTING ? total - shippingAmount : total)}
      </p>
    </div>
  );
};
