import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

import { RootState } from '../../../store/rootReducer';
import { isSelectionOrCatalogPage } from '../../search/utils';
import { setLastPositionId } from '../../catalog/actions';

export function useScrollToTop() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  const { lastPositionId } = useSelector((state: RootState) => state.catalog);

  useEffect(() => {
    if (
      navigationType === NavigationType.Pop &&
      Boolean(lastPositionId) &&
      isSelectionOrCatalogPage(pathname)
    ) {
      const lastProductCard = document.getElementById(lastPositionId);
      if (lastProductCard) {
        setTimeout(() => {
          const y =
            lastProductCard.getBoundingClientRect().top +
            window.pageYOffset -
            window.innerHeight / 4;

          window.scrollTo({ top: y, behavior: 'smooth' });
          dispatch(setLastPositionId(''));
        }, 0);
      }
    } else {
      setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'auto' }), 0);
    }
  }, [pathname]);
}
