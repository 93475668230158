import * as React from 'react';

import { Box, Checkbox, MultilineField, Icon, breakpoints } from '../../../design-system';
import { LBL_IS_GIFT, PERSONAL_MESSAGE, PLACEHOLDER_GIFT_MESSAGE } from '../locale';
import { Forms, FormFieldCallback, FormDelivery } from '../../form/types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

type Props = {
  form: FormDelivery;
  onShowGiftMessageClick: () => void;
  onFieldBlur: ({ form, key, value }: FormFieldCallback) => void;
  onFieldChange: ({ form, key, value }: FormFieldCallback) => void;
};
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  .checkbox-content__container {
    display: flex;
    gap: 8px;
  }
  .checkbox-content__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-block-start: 0;
    margin-block-end: 0;
    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`;

const GiftMessage = ({ form, onFieldChange, onFieldBlur, onShowGiftMessageClick }: Props) => {
  const { focus, validation, values } = form;
  const fieldRef = React.useRef<HTMLTextAreaElement>(null);
  const fieldKey = 'giftMessage';

  React.useEffect(() => {
    if (focus === fieldKey) {
      const node = fieldRef.current;
      if (node) {
        node.focus();
      }
    }
  }, [focus]);

  const MAX_LENGTH_GIFT_MESSAGE = 240;

  return (
    <>
      <StyledDiv>
        <Box fontSize="1.6rem" fontWeight={700} my="m">
          {PERSONAL_MESSAGE}
        </Box>
        <div
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Checkbox
            id="checkbox-checkout-show-gift-message"
            checked={values.showGiftMessage}
            onChange={onShowGiftMessageClick}
          >
            <div className="checkbox-content__container">
              <p className="checkbox-content__description">{LBL_IS_GIFT}</p>
              <Icon name="gift" />
            </div>
          </Checkbox>
        </div>
      </StyledDiv>
      {values.showGiftMessage && (
        <MultilineField
          id="multifield-checkout-gift-message"
          onChange={(value: string) =>
            onFieldChange({ form: Forms.delivery, key: fieldKey, value })
          }
          onBlur={(value: string) => onFieldBlur({ form: Forms.delivery, key: fieldKey, value })}
          value={values[fieldKey]}
          label={PLACEHOLDER_GIFT_MESSAGE}
          errMsg={validation[fieldKey]}
          maxLength={MAX_LENGTH_GIFT_MESSAGE}
          note={`${values[fieldKey].length}/${MAX_LENGTH_GIFT_MESSAGE} caractères maximum`}
          ref={fieldRef}
        />
      )}
    </>
  );
};

export default GiftMessage;
