import { useEffect, useState } from 'react';
import { fetchProductFromBackend } from '../../api/bff';
import { Product } from '../../product/types';

export function useProductsInfos(productsRefArray?: string[]) {
  const [hits, setHits] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null | unknown>(null);
  const [productsRef, setProductsRef] = useState<string[]>([]);

  // Deep compare productsRefArray with productsRef
  const shouldUpdate = productsRefArray?.sort().reduce((acc, value, index) => {
    if (value !== productsRef[index]) {
      return true;
    }
    return acc;
  }, false);

  if (shouldUpdate && productsRefArray) {
    setProductsRef(productsRefArray.sort());
  }

  useEffect(() => {
    if (!productsRef || !productsRef.length) {
      return () => {};
    }
    let ignore = false;
    setIsLoading(true);
    const fetchProduct = async () => {
      try {
        const res = await fetchProductFromBackend(productsRef);
        if (res.ok === true) {
          if (!ignore) {
            const products = res.data as Product[];
            const filteredProducts = products.map((product) => ({
              ...product,
              colorVariants: product.colorVariants.filter((color) => color.status),
            }));
            setHits(filteredProducts);
          }
        } else {
          setError(res.error);
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchProduct();
    return () => {
      ignore = true;
    };
  }, [productsRef]);

  return { hits, isLoading, error };
}
