import * as React from 'react';
import { css } from '@emotion/core';

import { breakpoints, colors, Icon } from '../../../design-system';
import { getColorFilterImageLink, getIsColorFilterWhitish } from '../utils';

type Props = {
  id: string;
  label: string;
  isRefined: boolean;
};

export const Color: React.FC<Props> = ({ id, label, isRefined }) => {
  return (
    <div
      id={id}
      css={css`
        width: 30px;
        height: 30px;
        background-image: url(${getColorFilterImageLink(label)});
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        border: ${isRefined
          ? `3px solid ${label === 'Blanc' ? '#e6e6e6' : colors.WHITE}`
          : 'initial'};
        box-shadow: ${isRefined
          ? `0px 0px 0px 1px ${colors.BLACK}`
          : getIsColorFilterWhitish(label)
          ? `0px 0px 0px 1px ${colors.GREY2}`
          : 'initial'};
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: ${breakpoints.S - 1}px) {
          width: 37px;
          height: 37px;
          margin-bottom: 8px;
          border: ${isRefined
            ? `2px solid ${label === 'Blanc' ? '#e6e6e6' : colors.WHITE}`
            : 'initial'};
        }
      `}
    >
      {isRefined && (
        <Icon color={label === 'Noir' ? colors.WHITE : colors.BLACK} name="checkmark" />
      )}
    </div>
  );
};
