import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import types from './actionTypes';
import { Wishlist, WishlistItem } from './types';
import { Mutations, getBFFData } from '../api';
import { pushToGTM, getTrackingProduct } from '../tracking';
import { Events } from '../tracking/types';
import { Product } from '../product/types';
import { CatalogProduct } from '../catalog/types';

export const requestWishlistSuccess = (payload: Wishlist) =>
  action(types.REQUEST_WISHLIST_SUCCESS, payload);

export const requestWishlistFailure = (payload: string) =>
  action(types.REQUEST_WISHLIST_FAILURE, payload);

export const resetErrorMessage = () => action(types.RESET_ERROR_MESSAGE);

export const setIsFetching = () => action(types.SET_IS_FETCHING);

type AsyncWishlistActions = ActionType<
  | typeof requestWishlistSuccess
  | typeof requestWishlistFailure
  | typeof resetErrorMessage
  | typeof setIsFetching
>;

export type WishlistActions = AsyncWishlistActions;

export type WishlistProductInput = {
  productRef: string;
  colorRef: string;
  sku?: string;
};

export function addToWishlist(
  product: WishlistProductInput,
  trackingProduct: Product | CatalogProduct | WishlistItem
) {
  return async (dispatch: Dispatch<AsyncWishlistActions>) => {
    dispatch(setIsFetching());
    const response = await getBFFData(Mutations.addToWishlist, { product });
    if (response.ok) {
      dispatch(requestWishlistSuccess(response.data.addToFavourites));
      pushToGTM(Events.addToWishlist, {
        product: getTrackingProduct({ product: trackingProduct }),
      });
    } else {
      dispatch(requestWishlistFailure(response.data));
    }
  };
}

export function removeFromWishlist(
  product: WishlistProductInput,
  trackingProduct: Product | CatalogProduct | WishlistItem
) {
  return async (dispatch: Dispatch<AsyncWishlistActions>) => {
    dispatch(setIsFetching());
    const response = await getBFFData(Mutations.removeFromWishlist, { product });
    if (response.ok) {
      dispatch(requestWishlistSuccess(response.data.deleteFromFavourites));
      pushToGTM(Events.removeFromWishlist, {
        product: getTrackingProduct({ product: trackingProduct }),
      });
    }
  };
}
