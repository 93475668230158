import * as React from 'react';

import { Box, Text } from '../../../design-system';
import { StoreAddress as StoreAddressType } from '../types';
import { SubTitle } from '../../common/components/Custom';

type Props = {
  name: string;
  address: StoreAddressType;
};

const StoreAddress = ({ name, address }: Props) => {
  const { line1, line2, postalCode, city } = address;
  return (
    <Box textAlign="center">
      <SubTitle>{name}</SubTitle>
      {line2 && <Text mt="s">{line2},</Text>}
      <Text>{`${line1}, ${postalCode} ${city}`}</Text>
    </Box>
  );
};

export default StoreAddress;
