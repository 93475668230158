import React from 'react';
import { Icon, breakpoints, opacities } from '../../../../design-system';
import { CmsCatalogHighlight, CmsCatalogHighlightItem } from '../../../cms/types';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';
import { emptyText } from '../../../cms/state';
import { BannerItem } from './BannerItem';

type Props = {
  banner: CmsCatalogHighlight;
  setProductRefWithColorSelected: (value: string) => void;
  setOriginalColorRef: (value: string) => void;
};
type StyledHighlightedBannerProps = {
  bannerBackgroundColor?: string;
  bannerTextColor?: string;
  bannerItems?: CmsCatalogHighlightItem[];
};

const CARD_WIDTH = 260;

const StyledHighlightedBanner = styled.div<StyledHighlightedBannerProps>`
  background-color: ${(props) => props.bannerBackgroundColor ?? '#FFF'};
  padding: 32px 0;

  @media (min-width: ${breakpoints.S}px) {
    padding: 40px 0 16px 0;
  }

  @media (min-width: ${breakpoints.L}px) {
    padding: 40px 16px 16px;
  }

  .slider__wrapper {
    position: relative;
    padding: 0 8px;

    > button {
      all: unset;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      margin: 0 8px;
      position: absolute;
      top: 40%;
      opacity: ${opacities.HOVERED};
      overflow: hidden;
      z-index: 11;
      transition: 0.2s all ease-in-out;

      :first-of-type {
        left: 8px;
      }
      :last-of-type {
        right: 8px;
      }

      :hover {
        opacity: 1;
      }
    }

    > div {
      scroll-snap-type: x mandatory;
      box-sizing: border-box;
      display: flex;
      overflow-x: auto;
      width: 100%;
      scroll-padding: 16px;
      scroll-behavior: smooth;
      padding: 16px;

      > div {
        scroll-snap-align: start;
        margin: 8px;
        width: ${CARD_WIDTH}px;
        flex: 0 0 ${CARD_WIDTH}px;

        @media (min-width: ${breakpoints.S}px) {
          width: calc(50% - 16px);
          flex: 0 0 calc(50% - 16px);
        }

        @media (min-width: ${breakpoints.M}px) {
          width: calc(33% - 16px);
          flex: 0 0 calc(33% - 16px);
        }

        @media (min-width: ${breakpoints.L}px) {
          width: calc(25% - 16px);
          flex: 0 0 calc(25% - 16px);
        }
      }
    }
  }

  .titles__wrapper {
    color: ${(props) => props.bannerTextColor ?? '#000'};
    padding: 0 16px;
    margin-bottom: 24px;

    @media (min-width: ${breakpoints.S - 1}px) {
      padding: 0 24px;
    }

    h3 {
      margin-bottom: 8px;
    }
  }
  .item-visual__image {
    width: 100%;
  }
`;

const HighlightedBanner = ({
  banner,
  setProductRefWithColorSelected,
  setOriginalColorRef,
}: Props) => {
  const { title1, subtitle } = banner?.primary ?? {};
  const scrollerRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  return (
    <StyledHighlightedBanner
      bannerBackgroundColor={banner.primary?.banner_background_color}
      bannerTextColor={banner.primary?.banner_text_color}
      bannerItems={banner.items}
    >
      <>
        <div className="titles__wrapper">
          <RichText render={title1 ?? [...emptyText]} />
          <RichText render={subtitle ?? [...emptyText]} />
        </div>
        <div className="slider__wrapper">
          <button
            type="button"
            onClick={() => {
              scrollerRef.current?.scrollTo({
                left: scrollPosition - CARD_WIDTH,
                behavior: 'smooth',
              });
            }}
            style={scrollPosition < 16 ? { transform: 'scale(0)' } : { transform: 'scale(1)' }}
          >
            <Icon name="chevronLeft" />
          </button>
          <div
            onScroll={() => {
              setScrollPosition(scrollerRef.current?.scrollLeft ?? 0);
            }}
            ref={scrollerRef}
          >
            {banner?.items?.map((item, index) => {
              return (
                <BannerItem
                  key={`banner_slider_item_${banner.id}_${index}`}
                  item={item}
                  setProductRefWithColorSelected={setProductRefWithColorSelected}
                  setOriginalColorRef={setOriginalColorRef}
                />
              );
            })}
          </div>
          <button
            type="button"
            onClick={() => {
              scrollerRef.current?.scrollTo({
                left: scrollPosition + CARD_WIDTH,
                behavior: 'smooth',
              });
            }}
            style={
              Math.abs(
                scrollPosition -
                  ((scrollerRef.current?.scrollWidth ?? 0) -
                    (scrollerRef.current?.clientWidth ?? 0))
              ) < 20
                ? { transform: 'scale(0)' }
                : { transform: 'scale(1)' }
            }
          >
            <Icon name="chevronRight" />
          </button>
        </div>
      </>
    </StyledHighlightedBanner>
  );
};

export { HighlightedBanner };
