import * as React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { RootState } from 'src/shared/store/rootReducer';
import { breakpoints, colors } from '../../../design-system';
import { CmsCatalogFaqItem, CmsFaqCatalog } from '../../cms/types';
import { CatalogFaqItem } from './CatalogFaqItem';

export const CatalogFaq = () => {
  const { catalog } = useSelector((state: RootState) => state.cms);
  const {
    catalog_faq_is_active_desktop,
    catalog_faq_is_active_mobile,
    body,
    catalog_testimonial_is_active_desktop,
    catalog_testimonial_is_active_mobile,
  } = catalog ?? {};
  const { items, primary } =
    body?.find((el: CmsFaqCatalog) => el.slice_type === 'catalogue_faq') ?? {};
  const {
    catalog_faq_title,
    catalog_faq_title_font_family,
    catalog_faq_title_font_size_desktop,
    catalog_faq_title_font_size_mobile,
    catalog_faq_title_color,
    catalog_faq_background,
    catalog_faq_divider_color,
    catalog_faq_question_color,
    catalog_faq_question_font_family,
    catalog_faq_answer_color,
    catalog_faq_answer_font_family,
  } = primary ?? {};

  if (!catalog_faq_is_active_desktop && !catalog_faq_is_active_mobile) {
    return null;
  }

  return (
    <div
      id="catalog-faq-block"
      css={css`
        width: 100%;
        padding: 80px 24px;
        scroll-margin-top: 80px;
        background-color: ${catalog_faq_background ?? colors.WHITE};
        display: ${catalog_faq_is_active_desktop ? 'flex' : 'none'};
        flex-direction: column;
        margin-top: ${catalog_testimonial_is_active_desktop ? 0 : '24px'};
        @media (max-width: ${breakpoints.S - 1}px) {
          display: ${catalog_faq_is_active_mobile ? 'flex' : 'none'};
          scroll-margin-top: 116px;
          padding: 40px 16px;
          margin-top: ${catalog_testimonial_is_active_mobile ? 0 : '16px'};
        }
        @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.M}px) {
          scroll-margin-top: 104px;
          padding: 24px 16px;
        }
      `}
    >
      {catalog_faq_title ? (
        <h2
          id="catalog-faq-title"
          css={css`
            font-weight: 700;
            font-size: ${catalog_faq_title_font_size_desktop ?? '3rem'};
            font-family: ${catalog_faq_title_font_family ?? 'Ogg-text'};
            line-height: 110%;
            width: 100%;
            text-align: center;
            margin-block-start: 0;
            margin-block-end: 0;
            color: ${catalog_faq_title_color ?? colors.BLACK};
            margin-bottom: 40px;
            @media (max-width: ${breakpoints.S - 1}px) {
              font-size: ${catalog_faq_title_font_size_mobile ?? '3rem'};
            }
          `}
        >
          {catalog_faq_title}
        </h2>
      ) : null}
      {items?.map((item: CmsCatalogFaqItem, index: number) => {
        if (
          !item.catalog_faq_answer_text.length ||
          item.catalog_faq_answer_text?.[0].text === '' ||
          !item.catalog_faq_question_text
        ) {
          return null;
        }
        return (
          <CatalogFaqItem
            key={index}
            index={index}
            question={item.catalog_faq_question_text}
            answer={item.catalog_faq_answer_text}
            questionColor={catalog_faq_question_color}
            questionFontFamily={catalog_faq_question_font_family}
            answerColor={catalog_faq_answer_color}
            answerFontFamily={catalog_faq_answer_font_family}
            dividerColor={catalog_faq_divider_color}
          />
        );
      })}
    </div>
  );
};
