import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { CmsImage, CmsPicturesSlider } from '../../cms/types';
import { breakpoints } from '../../../design-system';
import {
  PICTURES_SLIDER_IMAGE_HEIGHT,
  PICTURES_SLIDER_IMAGE_WIDTH,
  PICTURES_SLIDER_TRACK_GAP,
  getOurBrandBannerPosition,
} from '../utils';

type Props = {
  cmsData: CmsPicturesSlider | null;
  index: number;
};

type StyledContainerProps = {
  pictures_slider_position_on_page: number;
  pictures_slider_animation_active: boolean;
  pictures_slider_animation_speed: number;
  pictures_slider_margin_bottom: number;
  pictures_slider_margin_bottom_small_screen: number;
  numberOfImages: number;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  order: ${(props) => props.pictures_slider_position_on_page};
  margin-bottom: ${(props) => props.pictures_slider_margin_bottom_small_screen}px;
  width: 100%;
  position: relative;

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.pictures_slider_margin_bottom}px;
  }

  .pictures-slider-image {
    width: ${PICTURES_SLIDER_IMAGE_WIDTH}px;
    height: ${PICTURES_SLIDER_IMAGE_HEIGHT}px;
    border-radius: 8px;
    display: block;
    object-fit: cover;
    will-change: transform;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: ${(props) =>
        props.pictures_slider_animation_active
          ? `translateX(calc(-${PICTURES_SLIDER_IMAGE_WIDTH + PICTURES_SLIDER_TRACK_GAP}px * ${
              props.numberOfImages
            }))`
          : 'none'};
    }
  }

  .slider {
    height: ${PICTURES_SLIDER_IMAGE_HEIGHT}px;
    margin: auto;
    overflow-x: ${(props) => (props.pictures_slider_animation_active ? 'hidden' : 'auto')};
    overflow-y: hidden;
    position: relative;
    width: 100%;

    .slide-track {
      animation: ${(props) => `scroll ${props.pictures_slider_animation_speed}s linear infinite`};
      display: flex;
      width: ${(props) =>
        `calc(${PICTURES_SLIDER_IMAGE_WIDTH}px * ${
          props.numberOfImages * (props.pictures_slider_animation_active ? 2 : 1)
        } + ${PICTURES_SLIDER_TRACK_GAP}px * ${
          (props.numberOfImages - 1) * (props.pictures_slider_animation_active ? 2 : 1)
        })`};
      margin: ${(props) => (props.pictures_slider_animation_active ? 'none' : 'auto')};
    }

    .slide {
      height: ${PICTURES_SLIDER_IMAGE_HEIGHT}px;
      width: ${PICTURES_SLIDER_IMAGE_WIDTH}px;
      margin-right: ${PICTURES_SLIDER_TRACK_GAP}px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const PicturesSlider = ({ cmsData, index }: Props) => {
  const [content, setContent] = useState<
    Array<{ pictures_slider_image: CmsImage; pictures_slider_image_link: string }>
  >([]);

  const {
    pictures_slider_position_on_page,
    pictures_slider_animation_active,
    pictures_slider_animation_speed,
    pictures_slider_margin_bottom,
    pictures_slider_margin_bottom_small_screen,
  } = cmsData?.primary ?? {};

  const items = (cmsData?.items || []) as {
    pictures_slider_image: CmsImage;
    pictures_slider_image_link: string;
  }[];

  useEffect(() => {
    if (pictures_slider_animation_active) {
      if (items.length === 2) {
        // min of 12 items to fit the max-width case
        setContent([...items, ...items, ...items, ...items, ...items, ...items]);
      } else if (items.length === 3) {
        setContent([...items, ...items, ...items, ...items]);
      } else if (items.length === 4) {
        setContent([...items, ...items, ...items]);
      } else if (items.length === 5) {
        setContent([...items, ...items, items[0], items[1]]);
      } else {
        setContent([...items, ...items]);
      }
    } else {
      setContent([...items]);
    }
  }, []);

  if ((items?.length ?? 0) < 2) {
    return null;
  }

  return (
    <StyledContainer
      id={`pictures-slider-banner-block-${index}`}
      pictures_slider_position_on_page={getOurBrandBannerPosition(
        pictures_slider_position_on_page ?? 'Not activated'
      )}
      pictures_slider_animation_active={pictures_slider_animation_active ?? false}
      pictures_slider_animation_speed={pictures_slider_animation_speed ?? 40}
      pictures_slider_margin_bottom={pictures_slider_margin_bottom ?? 0}
      pictures_slider_margin_bottom_small_screen={pictures_slider_margin_bottom_small_screen ?? 0}
      numberOfImages={items?.length ?? 0}
    >
      <div className="slider">
        <div className="slide-track">
          {content?.map((item, index) => (
            <Link
              key={`pictures-slider-image-${index}`}
              className="slide"
              to={item.pictures_slider_image_link ?? '#'}
              data-testid={`pictures-slider-image-anchor-wrapper-${index}`}
              aria-label={`Lien vers la page ${item.pictures_slider_image_link}`}
            >
              <img
                className="pictures-slider-image"
                src={item.pictures_slider_image.url ?? ''}
                alt={item.pictures_slider_image.alt ?? ''}
              />
            </Link>
          ))}
        </div>
      </div>
    </StyledContainer>
  );
};
