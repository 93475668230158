import React from 'react';
import styled from '@emotion/styled';

import { breakpoints } from '../../../design-system';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/store/rootReducer';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

const StyledAffiliateVideoContainer = styled.div`
  width: 90%;
  margin-top: 48px;
  aspect-ratio: 65/74;

  @media (min-width: ${breakpoints.S}px) {
    aspect-ratio: 16/9;
    margin-top: 64px;
  }

  @media (min-width: ${breakpoints.M}px) {
    width: 80%;
    margin-top: 88px;
  }
`;

export const AffiliateVideoBlock = () => {
  const { second_video_mobile, second_video_desktop } =
    useSelector((state: RootState) => state.cms.affiliation) ?? {};
  const { isMobile } = useMediaQueries();

  if ((!second_video_desktop && !isMobile) || (!second_video_mobile && isMobile)) {
    return null;
  }

  return (
    <StyledAffiliateVideoContainer>
      <iframe
        title={`Affilate second video for ${isMobile ? 'mobile' : 'desktop'}`}
        src={isMobile ? second_video_mobile : second_video_desktop}
        height="100%"
        width="100%"
        frameBorder="0"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
        allowFullScreen
      />
    </StyledAffiliateVideoContainer>
  );
};
