import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Text } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { wishlistItemsSelector } from '../selectors';
import { WishlistItem } from './WishlistItem';
import locale from '../locale';

export const WishlistItemList = () => {
  const items = useSelector((state: RootState) => wishlistItemsSelector(state));

  if (items.length < 1) {
    return <Text my="m">{locale.EMPTY_WISHLIST_TITLE}</Text>;
  }

  return (
    <Box id="box-wishlist-item-list">
      {items.map((item) => {
        const id = `${item.productRef}${item.colorRef}${item.sku ?? ''}`;
        return <WishlistItem key={id} {...{ id, item }} />;
      })}
    </Box>
  );
};
