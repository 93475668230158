import React, { Fragment, FC } from 'react';
import { useSortBy } from 'react-instantsearch';
import { css } from '@emotion/core';

import { Box, Text, Divider, colors } from '../../../design-system';

type Props = {
  items: {
    value: string;
    label: string;
  }[];
  onClose: () => void;
};

export const MobileSortBar: FC<Props> = ({ items, onClose }) => {
  const { refine, currentRefinement } = useSortBy({ items });
  return (
    <Box>
      {items.map((item, index) => (
        <Fragment key={item.value}>
          <Box p="m">
            <button
              id={`sort-drawer-link-${index}`}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
                onClose();
              }}
              css={css`
                border: none;
                background: unset;
                padding: 0;
                color: ${colors.BLACK};
              `}
            >
              <Text
                id={`sort-drawer-text-${index}`}
                fontWeight={item.value === currentRefinement ? 'bold' : 'regular'}
                textTransform="uppercase"
              >
                {item.label}
              </Text>
            </button>
          </Box>
          <Divider />
        </Fragment>
      ))}
    </Box>
  );
};
