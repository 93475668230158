import React, { useEffect, useState } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';

import { Box, DynamicButton, Text, ProductName } from '../../../design-system';
import { ButtonLink } from '../../../design-system/button';

import {
  getProductPrice,
  sortSizes,
  getProductLink,
  getIsSizeAvailable,
  getSizeMessage,
  isItGiftCard,
  sizeNoLongerAvailable,
} from '../../product/utils';
import { RootState } from '../../../store/rootReducer';
import { TO_PDP } from '../locale';
import { CTA_CONTENT, ERR_CHOOSE_SIZE } from '../../product/locale';
import { ModalImages } from './ModalImages';
import { ERR_GENERIC } from '../../common/locale';
import { addToCart } from '../../cart/actions';
import { Colors } from '../../product/components/Colors';
import { Sizes } from '../../product/components/Sizes';
import ProductPrice from '../../product/components/ProductPrice';
import { setLastPositionId } from '../../catalog/actions';
import { Feedback } from '../../cart/components/Feedback';
import WishlistCta from '../../wishlist/components/WishlistCta';
import { requestProductSuccessModal, changeSizeModal } from '../../product/actions';
import { getBFFData, Queries } from '../../api';
import { Product } from '../../product/types';
import { LoadingScreen } from './LoadingScreen';
import { H1 } from '../../common/components/Custom';
import paths from '../../routing/paths';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type Props = {
  hits: Product[];
  selectedColorRef: string;
  selectedImagePosition: number;
  setSelectedImagePosition: (value: number) => void;

  originalSelectedColor?: string;
  onColorClick: (colorRef: string) => void;
  onClose?: () => void;
};

export const ProductModal = ({
  hits,
  selectedColorRef,
  selectedImagePosition,
  setSelectedImagePosition,
  originalSelectedColor,
  onColorClick,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const product = hits?.[0];

  const [selectedSku, setSelectedSku] = useState('');
  const [hoveredSku, setHoveredSku] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const {
    productRef,
    productName,
    colorLabel,
    detailedColorLabel,
    colorVariants,
    colorRef,
    sizeVariants,
    images,
    originalPrice,
    storePrice,
    promotionPercentage,
    promotionLabel,
    promoBColor: promotionBackgroundColor,
    promoTColor: promotionTextColor,
    isOutlet,
  } = product ?? {};
  const objectID = productRef + '-' + colorRef;
  const sortedSizes = sortSizes(sizeVariants ?? []);

  const { ctaState, cart } = useSelector((state: RootState) => state.cart);

  const isNoLongerAvailable = sizeNoLongerAvailable(cart, productRef, colorRef, sizeVariants);
  const { isMobile } = useMediaQueries();

  useEffect(() => {
    setSelectedSku('');
  }, [selectedColorRef]);

  useEffect(() => {
    if (sortedSizes.length === 1) {
      const [uniqueSize] = sortedSizes;
      if (getIsSizeAvailable(uniqueSize)) {
        setSelectedSku(uniqueSize.sku);
      }
    }
  }, [selectedColorRef, sizeVariants]);

  useEffect(() => {
    setErrMsg('');
  }, [selectedSku, hoveredSku]);

  const origninalColor = colorVariants.find((color) => color.colorRef === originalSelectedColor);

  const sortedColor = origninalColor
    ? [origninalColor, ...colorVariants.filter((color) => color.colorRef !== originalSelectedColor)]
    : colorVariants;

  const selectedColor = (colorVariants ?? []).find((color) => color.colorRef === selectedColorRef);
  const selectedSize = sortedSizes.find((size) => size.sku === selectedSku);
  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);

  const handleCtaClick = async () => {
    if (selectedSize) {
      if (getIsSizeAvailable(selectedSize)) {
        const response = await getBFFData(Queries.getProduct, { productRef, colorRef });
        if (response.ok) {
          const selectedProduct = response.data.product ?? {};
          dispatch(requestProductSuccessModal(selectedProduct));
          dispatch(changeSizeModal(selectedSize));
        }
        dispatch(
          addToCart(
            {
              productRef,
              colorRef,
              sku: selectedSku,
              quantity: 1,
            },
            product
          ) as unknown as UnknownAction
        );
      } else {
        setErrMsg(ERR_GENERIC);
      }
    } else {
      setErrMsg(ERR_CHOOSE_SIZE);
    }
  };

  const productLink = getProductLink({
    productRef,
    colorLabel: selectedColor?.colorLabel ?? '',
    colorRef,
    productName,
  });

  const setReduxLastPositionId = () => {
    const elementID = `product-card-${objectID}`;
    dispatch(setLastPositionId(elementID));
  };

  const price = getProductPrice({
    storePrice: selectedSize ? selectedSize.storePrice : storePrice,
    originalPrice: selectedSize ? selectedSize.originalPrice : originalPrice,
  });

  const sizeMessage = getSizeMessage(hoveredSize);

  const isGiftCard = isItGiftCard(productRef);

  const fragments = {
    sizes: (
      <Sizes
        objectID={objectID}
        errMsg={errMsg}
        sizeMessage={sizeMessage}
        sortedSizes={sortedSizes}
        selectedSku={selectedSku}
        setHoveredSku={setHoveredSku}
        setSelectedSku={setSelectedSku}
        isNoLongerAvailable={isNoLongerAvailable}
        isQuickAdd
      />
    ),
    images: (
      <ModalImages
        promotionLabel={promotionLabel}
        promotionPercentage={promotionPercentage}
        promotionBackgroundColor={promotionBackgroundColor}
        promotionTextColor={promotionTextColor}
        selectedImagePosition={selectedImagePosition}
        setSelectedImagePosition={setSelectedImagePosition}
        isOutlet={isOutlet}
        objectID={objectID}
        images={images}
        productRef={productRef}
        productName={productName}
        colorLabel={colorLabel}
        colorRef={colorRef}
      />
    ),
    wishlist: (
      <WishlistCta
        productRef={productRef}
        colorRef={colorRef}
        objectID={objectID}
        product={product}
        isQuickAddMobile
      />
    ),
    productNameAndPrice: (
      <Box display="flex" justifyContent="space-between">
        <Text>{productName}</Text>
        <ProductPrice price={price} />
      </Box>
    ),
  };

  return (
    <>
      {!pathname.startsWith(paths.PRODUCT) && <Feedback onClose={onClose} />}
      {isMobile ? (
        <Box id={`product-modal-mobile-${objectID}`}>
          <Box mx="m" mt="m" display="grid" gridGap="m">
            {!isGiftCard && fragments.wishlist}
            {fragments.images}
            {fragments.productNameAndPrice}
            {fragments.sizes}
          </Box>
          <Box display="grid" gridAutoFlow="column" gridAutoColumns="1fr">
            <ButtonLink
              to={productLink}
              data-testid={`product-modal-mobile-link-to-${productRef}-${colorRef}`}
              id={`product-modal-mobile-link-to-${productRef}-${colorRef}`}
              onClick={setReduxLastPositionId}
              preset={'subtle'}
            >
              {TO_PDP(true)}
            </ButtonLink>
            <DynamicButton
              id="product-modal-mobile-cta"
              onClick={handleCtaClick}
              data={CTA_CONTENT(true)}
              feedback={ctaState}
            />
          </Box>
        </Box>
      ) : (
        <Box id={`product-modal-${objectID}`}>
          {colorRef !== selectedColorRef && <LoadingScreen />}
          <Box display="grid" p="xxl" gridGap="xl" gridTemplateColumns="325px auto">
            {fragments.images}
            <Box display="grid" gridGap="l" mt="l" gridAutoRows="min-content">
              <Box
                display="grid"
                gridAutoFlow="column"
                gridGap="s"
                alignItems="center"
                justifyContent="space-between"
              >
                <H1 id="pdp-product-name" preset={['body', 'subheading']}>
                  <ProductName productName={productName} />
                </H1>
                <ProductPrice price={price} />
              </Box>
              <Colors
                objectID={objectID}
                productRef={productRef}
                detailedColorLabel={detailedColorLabel}
                colorVariants={sortedColor}
                onColorClick={onColorClick}
                colorRef={selectedColorRef}
              />
              <Box display="grid" gridGap="m" gridAutoRows="min-content">
                {fragments.sizes}
                <Box>
                  <DynamicButton
                    id="product-modal-cta"
                    onClick={handleCtaClick}
                    data={CTA_CONTENT()}
                    feedback={ctaState}
                  />
                </Box>
                <Box textAlign="center">
                  <Link
                    type="button"
                    to={productLink}
                    className="anchor-animated"
                    data-testid={`product-modal-link-to-${productRef}-${colorRef}`}
                    id={`product-modal-link-to-${productRef}-${colorRef}`}
                    onClick={setReduxLastPositionId}
                    css={css`
                      font-size: 1.6rem;
                    `}
                  >
                    {TO_PDP()}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
