import { MenuTree, MobileMenuTree, MobileMenuTreeItem } from './types';
import { CmsMenuItem } from '../cms/types';
import { LINK_SEE_ALL } from './locale';

export function makeMenuTree(items: CmsMenuItem[]): MenuTree {
  const tree: MenuTree = [];
  for (const item of items) {
    switch (item.type) {
      case 'First Level':
        tree.push({
          ...item,
          banner: undefined,
          columns: [],
          imageBlock: [],
        });
        break;
      case 'Second Level Banner Image':
        if (tree.length > 0) {
          const lastItem = tree[tree.length - 1];
          if (!lastItem.banner) {
            lastItem.banner = item;
          }
        }
        break;
      case 'Second Level Text Column':
        if (tree.length > 0) {
          const lastItem = tree[tree.length - 1];
          if (lastItem.imageBlock.length < 1) {
            lastItem.columns.push({ ...item, rows: [] });
          }
        }
        break;
      case 'Third Level Text Row':
        if (tree.length > 0) {
          const lastItem = tree[tree.length - 1];
          if (lastItem.columns.length > 0) {
            const lastSubItem = lastItem.columns[lastItem.columns.length - 1];
            lastSubItem.rows.push(item);
          }
        }
        break;
      default:
        break;
    }
  }
  return tree;
}

export function makeMenuTreeMobile(items: CmsMenuItem[]): MobileMenuTree {
  return (
    items?.reduce((acc, item) => {
      const level = getLevelFromItemType(item.type);
      const newItem = transformItemForMobile(item, level);
      switch (newItem.level) {
        case 1:
          acc.push({ ...newItem, subLevel: [] });
          break;
        case 2:
          acc[acc.length - 1].subLevel.push({ ...newItem, subLevel: [] });
          break;
        case 3:
          acc[acc.length - 1].subLevel[
            (acc[acc.length - 1].subLevel?.length ?? 1) - 1
          ].subLevel.push({
            ...newItem,
            subLevel: [],
          });
          break;
        default:
          break;
      }
      return acc;
    }, [] as MobileMenuTreeItem[]) || []
  );
}

function getLevelFromItemType(type: string): number {
  switch (type) {
    case 'First Level':
      return 1;
    case 'Second Level Text Column':
      return 2;
    case 'Third Level Text Row':
      return 3;
    default:
      return 0;
  }
}
function transformItemForMobile(item: CmsMenuItem, level: number): MobileMenuTreeItem {
  return {
    name: item.name,
    picto_for_mobile: item.picto_for_mobile,
    link: item.link ?? '#',
    level: level,
    subLevel: [],
  };
}

export function addViewAllItemForMobile(
  items: MobileMenuTreeItem[],
  parentName: string,
  parentLink: string | null
): MobileMenuTreeItem[] {
  if (items.length === 0) {
    return items;
  }
  const viewAllItem: MobileMenuTreeItem = {
    name: `${LINK_SEE_ALL} ${parentName}`,
    link: parentLink,
    subLevel: [],
    level: items[0]?.level,
    isPrimaryLink: true,
  };

  return [viewAllItem, ...items];
}

export function transformMenuItemsForMobile(items: MobileMenuTreeItem[]): MobileMenuTreeItem[] {
  return items.map((item) => ({
    ...item,
    subLevel:
      item.subLevel && item.subLevel.length > 0
        ? addViewAllItemForMobile(item.subLevel, item.name, item.link)
        : item.subLevel,
  }));
}
