import * as React from 'react';

import { Box } from '../../../design-system';
import { COUPON_ACCEPTED } from '../locale';
import { CartNotification, NotificationType, CartStep } from '../types';
import NotificationItem from './NotificationItem';

type Props = {
  notifications: CartNotification[];
  couponAccepted: boolean;
  step: CartStep;
};

const couponType = {
  type: NotificationType.Info,
  title: 'coupon',
  message: COUPON_ACCEPTED,
};

export const Notifications = ({ notifications, couponAccepted, step }: Props) => {
  const [showCouponMessage, setShowCouponMessage] = React.useState(
    couponAccepted && step === CartStep.LISTING
  );
  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    if (couponAccepted) {
      setShowCouponMessage(true);
    } else {
      setShowCouponMessage(false);
    }
  }, [couponAccepted]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setShowCouponMessage(false);
    }
  }, [step]);

  return (
    <Box mx="auto" my={['m', 'l']} textAlign="center" id="box-cart-notifications">
      <Box
        display={
          (step === CartStep.LISTING && couponAccepted) || showCouponMessage ? 'block' : 'none'
        }
      >
        <NotificationItem type={couponType.type} message={couponType.message} />
      </Box>
      <Box display={step === CartStep.LISTING ? 'block' : 'none'}>
        {(notifications ?? []).map((item, index) => (
          <NotificationItem key={index} index={index} type={item.type} message={item.message} />
        ))}
      </Box>
    </Box>
  );
};
