export default {
  REQUEST_CART_SUCCESS: 'CART / REQUEST CART / SUCCESS',
  REQUEST_CART_FAILURE: 'CART / REQUEST CART / FAILURE',
  REQUEST_SHIPPING_TYPES_SUCCESS: 'CART / REQUEST SHIPPING TYPES / SUCCESS',
  SET_STEP: 'CART / SET STEP',
  SET_STORE_ID: 'CART / SET STORED ID',
  SET_PICKUP_STATION_ID: 'CART / SET PICKUP STATION ID',
  REMOVE_PICKUP_STATION_INFO: 'CART / REMOVE PICKUP STATION INFO',
  CHANGE_CTA_STATE: 'CART / CHANGE CTA STATE',
  SET_SHOW_FEEDBACK: 'CART / SET SHOW FEEDBACK',
  SET_PAYPAL_ECS: 'CART / SET PAYPAL ECS',
  SET_COUPON_LOADING_STATE: 'CART / SET COUPON LOADING STATE',
  RESET_STORE_ID: 'CART / RESET STORE ID',
  RESET_PICKUP_STATION_ID: 'CART / RESET PICKUP STATION ID',
} as const;
