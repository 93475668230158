import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { SignInWithForm } from './SignIn';
import { SignUpWithForm } from './SignUp';
import withAuth, { WithAuthProps } from '../hocs/withAuth';
import withSidebarNew, { WithSidebarNewActions } from '../../common/hocs/withSidebarNew';
import { WithCmsActions } from '../../cms/hocs/withCms';
import { CheckEmailExistWithForm } from './CheckEmailExist';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { ForgotPassword } from './ForgotPassword';
import { checkUserExist, setHasForgotPassword } from '../actions';
import { Snackbar } from '../../../../shared/design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import paths from '../../routing/paths';
import useQuery from '../../common/hooks/useQuery';

type Props = { defaultTabIndex?: number } & WithSidebarNewActions &
  WithAuthProps &
  WithCmsActions & {
    onLogin?: () => void;
  };

const AuthBar = ({
  closeSidebarNew,
  onLogin,
  checkEmail,
  login,
  signup,
  isLoggedIn,
  errLogin,
  errSignup,
  errSocial,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const query = useQuery();

  const { isMobile } = useMediaQueries();
  const [email, setEmail] = useState('');
  const { userExist, hasForgotPassword } = useSelector((state: RootState) => state.auth);
  const [comeFromResetPassword, setComeFromResetPassword] = useState(false);

  useEffect(() => {
    dispatch(setHasForgotPassword(false));
    dispatch(checkUserExist(null));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      closeSidebarNew();
      if (onLogin) {
        onLogin();
      }
      ReactDOM.render(
        <Snackbar placement="top" offset={isMobile ? 95 : 140}>
          Vous êtes bien connecté.e !
        </Snackbar>,
        document.getElementById('snackbars')
      );
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (pathname.startsWith(paths.RESET_PASSWORD)) {
      const queryEmail = query.get('email') || '';
      checkEmail(queryEmail);
      setEmail(queryEmail);
      setComeFromResetPassword(true);
      window.history.replaceState(null, document.title, paths.HOME);
    }
  }, [pathname]);

  if (hasForgotPassword) {
    return <ForgotPassword email={email} />;
  }
  if (userExist === false) {
    return (
      <SignUpWithForm signup={signup} errSignup={errSignup} email={email} errSocial={errSocial} />
    );
  }
  if (userExist === true) {
    return (
      <SignInWithForm
        login={login}
        errLogin={errLogin}
        email={email}
        comeFromResetPassword={comeFromResetPassword}
      />
    );
  }
  return (
    <CheckEmailExistWithForm
      checkEmail={checkEmail}
      setEmail={setEmail}
      errLogin={errLogin}
      errSocial={errSocial}
    />
  );
};

export const AuthWithSideBar = withSidebarNew(withAuth(AuthBar));
