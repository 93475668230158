import { useEffect } from 'react';

import { heights } from '../constants';

export const useStickySliderObserver = ({
  sliderElement,
  isDataLoaded,
  isMobile,
  slider_with_number_is_active_mobile,
  setStickySliderIsActive,
}: {
  sliderElement: HTMLDivElement | HTMLElement | null;
  isDataLoaded: boolean;
  isMobile: boolean;
  slider_with_number_is_active_mobile: boolean | undefined;
  setStickySliderIsActive: (value: boolean) => void;
}): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setStickySliderIsActive(!entry.isIntersecting);
        });
      },
      { threshold: 0, rootMargin: `-${heights.HEADER_MOBILE}px 0px 0px 0px` }
    );

    const needToObserveSliderComponent = Boolean(
      isDataLoaded && isMobile && slider_with_number_is_active_mobile
    );

    if (needToObserveSliderComponent && sliderElement) {
      observer.observe(sliderElement);
    }

    return () => {
      if (needToObserveSliderComponent && sliderElement) {
        observer.unobserve(sliderElement);
      }
    };
  }, [sliderElement, isDataLoaded, isMobile, slider_with_number_is_active_mobile]);
};
