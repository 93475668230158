import styled from '@emotion/styled';
import css from '@styled-system/css';

export type DropdownBackground = 'BACKGROUND' | 'WHITE';

type DropdownSelectProps = {
  id: string;
  isOpen?: boolean;
  background?: DropdownBackground;
  isReviewsBlock?: boolean;
  border?: string;
};

export const DropdownSelect = styled.div<DropdownSelectProps>(
  {
    ':focus': {
      cursor: 'pointer',
    },
    ':hover': {
      cursor: 'pointer',
      transition: 'background-color 0.5s',
    },
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    width: 'inherit',
    height: '50px',
  },
  ({ background = 'BACKGROUND' }) =>
    css({
      backgroundColor: background,
    }),
  ({ isReviewsBlock }) =>
    isReviewsBlock &&
    css({
      justifyContent: 'flex-end',
    }),
  ({ border }) =>
    border &&
    css({
      border: border,
    }),
  ({ isOpen, border }) =>
    isOpen &&
    border &&
    css({
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: border,
    })
);

type DropdownMenuProps = {
  isPositionAbsolute: boolean;
};

export const DropdownMenu = styled.div<DropdownMenuProps>(
  css({
    zIndex: 'dropdown',
    backgroundColor: 'BACKGROUND',
    backgroundClip: 'padding-box',
    float: 'left',
    left: 0,
    listStyle: 'none',
    position: 'absolute',
    textAlign: 'left',
    top: '100%',
    width: 'inherit',
  }),
  ({ isPositionAbsolute }) =>
    !isPositionAbsolute &&
    css({
      position: 'initial',
      marginBottom: 'm',
    })
);

type DropdownItemProps = {
  isSelected?: boolean;
};

export const DropdownItem = styled.div<DropdownItemProps>(
  css({
    ':focus': {
      textDecoration: 'none',
      backgroundColor: 'WHITE',
    },
    '@media(hover: hover)': {
      ':hover': {
        textDecoration: 'none',
        backgroundColor: 'WHITE',
      },
    },
    clear: 'both',
    color: 'GREY',
    cursor: 'pointer',
    fontSize: '14px',
    height: '46px',
    paddingLeft: '8px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  ({ isSelected }) =>
    isSelected &&
    css({
      color: 'BLACK',
    })
);
