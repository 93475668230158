import * as React from 'react';

type Props = {
  video: string;
  banner_type?: string;
};

export const BannerVideo = ({ video, banner_type }: Props) => {
  return (
    <iframe
      src={video}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={banner_type ? { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 } : {}}
    />
  );
};
