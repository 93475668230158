import React from 'react';
import styled from '@emotion/styled';

import { colors } from './../../../design-system';

type Props = {
  productName: string;
  typology?: string;
  collectionName: string;
  objectID: string;
};

const StyledHighlightedName = styled.div`
  width: 100%;
  text-align: left;

  .category-name,
  .product-name {
    margin: 0;
    font-size: 1.4rem;
    line-height: 21px;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .category-name {
    font-weight: 700;
    color: ${colors.BLACK};
  }

  .product-name {
    font-weight: 400;
    color: ${colors.MAIN_GREY};
    text-transform: capitalize;
  }
`;

export const HighlightedName = ({ objectID, productName, typology, collectionName }: Props) => {
  const [nameWithoutCategory, category] = productName?.split(' - ') ?? '';
  return (
    <StyledHighlightedName id={`product-name-block-${objectID}`}>
      <p className="category-name">
        {(category ?? collectionName ?? 'categorie').toLocaleUpperCase()}
      </p>
      <p className="product-name">{nameWithoutCategory ?? typology ?? 'nom du produit'}</p>
    </StyledHighlightedName>
  );
};
