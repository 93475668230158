import * as React from 'react';
import AlgoliaAnalytics from 'search-insights';

import { SEARCH_APP_ID, SEARCH_API_KEY } from '../../api/constants';
import { getUserTokenForAlgoliaAnalytics } from '../utils';

export default function useAnalytics() {
  React.useEffect(() => {
    AlgoliaAnalytics('init', {
      appId: SEARCH_APP_ID,
      apiKey: SEARCH_API_KEY,
    });

    AlgoliaAnalytics('setUserToken', getUserTokenForAlgoliaAnalytics());
  }, []);
}
