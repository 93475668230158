import React from 'react';

import { ImageContainer, Picture } from '../../../design-system';
import { ProductImage } from '../../product/utils';

type Props = {
  objectID: string;
  image: ProductImage;
};

export const ModalImage = ({ image, objectID }: Props) => (
  <ImageContainer>
    <Picture>
      <source srcSet={image.webp} type="image/webp" />
      <source srcSet={image.jpg} type="image/jpeg" />
      <img id={`product-modal-${objectID}-image`} src={image.jpg} alt={image.alt} />
    </Picture>
  </ImageContainer>
);
