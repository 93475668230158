import * as React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Transition from 'react-transition-group/Transition';

import { colors, Box, Text, CloseButton, breakpoints } from '../../../design-system';
import useScrollLock from '../hooks/useScrollLock';
import withSidebar from '../hocs/withSidebar';
import { zIndex, durations, transitions, widths } from '../constants';
import { getPersistedData } from '../utils';

export const transitionStylesBackground = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const transitionStylesContent = {
  entering: { right: '-100vw' },
  entered: { right: 0 },
  exiting: { right: '-100vw' },
  exited: { right: '-100vw' },
};

export const transitionStylesContentLeft = {
  entering: { left: '-100vw' },
  entered: { left: 0 },
  exiting: { left: '-100vw' },
  exited: { left: '-100vw' },
};

export type SidebarType = {
  isOpen: boolean;
  content: React.ReactNode;
  title?: string;
};

type Props = {
  closeSidebar: () => void;
  location: {
    pathname: string;
  };
} & SidebarType;

export const Sidebar = ({ isOpen, title, content, closeSidebar }: Props) => {
  const [ref, setRef] = React.useState<HTMLDivElement>();
  const onRefChange = React.useCallback((node) => {
    setRef(node);
  }, []);
  useScrollLock({ ref, isActive: isOpen });

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (isOpen) {
      closeSidebar();
    }
  }, [pathname]);

  const styledContent = (
    <Box my={title ? 'xl' : 'na'} mx={['m', 'l']}>
      {title && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="xl">
          <Text preset="heading" fontWeight={'600'}>
            {title}
          </Text>
          <Box paddingLeft={16}>
            <CloseButton id="close-drawer" onClick={closeSidebar} />
          </Box>
        </Box>
      )}
      {content}
    </Box>
  );

  return (
    <>
      <Transition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Background
            onClick={closeSidebar}
            style={{
              ...transitionStylesBackground[state],
            }}
            parentZIndex={zIndex.SIDEBAR}
          />
        )}
      </Transition>
      <Transition
        in={isOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <SidebarContent
            ref={onRefChange}
            style={
              getPersistedData('fromLeft')
                ? { ...transitionStylesContentLeft[state] }
                : {
                    ...transitionStylesContent[state],
                  }
            }
          >
            {styledContent}
          </SidebarContent>
        )}
      </Transition>
    </>
  );
};

type BackgroundProps = {
  parentZIndex?: number;
};

export const Background = styled.div<BackgroundProps>(
  {
    top: 0,
    right: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',

    background: 'rgba(0, 0, 0, 0.3)',
    transition: transitions.GENERIC,
  },
  ({ parentZIndex }) =>
    parentZIndex
      ? {
          zIndex: parentZIndex - 1,
        }
      : {
          zIndex: zIndex.MODAL - 1,
        }
);

export const SidebarContent = styled.div`
  top: 0;
  height: 100%;
  position: fixed;
  overflow: auto;
  z-index: ${zIndex.SIDEBAR};
  background-color: ${colors.WHITE};
  transition: ${transitions.GENERIC};
  width: 100vw;
  @media (min-width: ${breakpoints.S}px) {
    width: ${widths.SIDEBAR}px;
  }
`;

export default withSidebar(Sidebar);
