import GoogleMapsApiLoader from 'google-maps-api-loader';

import { GOOGLE_MAP_API_KEY } from '../api/constants';
import {
  Range,
  StoreHours,
  StoreHolidayHoursDescription,
  OpeningHours,
  ZoomLevels,
  Position,
  Store,
  StoreList,
  StoreCoordinate,
  StoreService,
} from './types';
import { PickupStation } from '../pickup/types';
import { getIsClient } from '../common/utils';
import { fetchIcon } from '../../design-system';
import { WEEK_DAYS } from './locale';

export function isFrenchZipCode(value: string): boolean {
  return /\b\d{5}\b/g.test(value);
}

export function isValidStoreId(value: string): boolean {
  return /\b\d{4}\b/g.test(value);
}

export function removeDiacritics(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function isSameDay(day: string): boolean {
  return Object.keys(WEEK_DAYS)[new Date().getDay()] === day;
}

export function isWorkingHours({ startTime, endTime }: { startTime: string; endTime: string }): {
  isOpenNow: boolean;
  isClosingSoon: boolean;
} {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getTime());
  startDate.setHours(Number(startTime.split(':')[0]));
  startDate.setMinutes(Number(startTime.split(':')[1]));

  const endDate = new Date(currentDate.getTime());
  endDate.setHours(Number(endTime.split(':')[0]));
  endDate.setMinutes(Number(endTime.split(':')[1]));

  const ONE_HOUR = 60 * 60 * 1000;

  return {
    isOpenNow: startDate < currentDate && endDate > currentDate,
    isClosingSoon:
      endDate.getTime() - currentDate.getTime() < ONE_HOUR &&
      endDate.getTime() - currentDate.getTime() > 0,
  };
}

export function getOpeningHours(hours: StoreHours) {
  return Object.keys(hours)
    .filter((key) => !['holidayHours', 'reopenDate'].includes(key))
    .reduce<OpeningHours[]>((acc, value) => {
      const flatObj: OpeningHours = { day: '', open: [], close: [] };

      if (!hours[value].isClosed) {
        flatObj.day = value;
        hours[value].openIntervals.forEach((item, index) => {
          flatObj.open[index] = item.start;
          flatObj.close[index] = item.end;
        });
      }

      return !hours[value].isClosed ? [...acc, flatObj] : acc;
    }, []);
}

export function getFormattedOpeningBounds(value: OpeningHours): {
  openings: string;
  startTime: string;
  endTime: string;
} {
  const openings = value?.open?.reduce((acc, cVal, idx) => {
    return (
      acc +
      `${cVal} - ${value?.close?.[idx]}${idx === (value?.open?.length ?? 0) - 1 ? '' : ' -- '}`
    );
  }, '');

  const startTime = openings?.split('-')[0];
  const endTime = openings?.split('-')[openings?.split('-').length - 1];

  return { openings, startTime, endTime };
}

export function mergeOpeningHours(hours: StoreHours) {
  return getOpeningHours(hours).reduce<OpeningHours[]>((preVal, curVal) => {
    const last = preVal[preVal.length - 1];
    if (
      last &&
      JSON.stringify(last.open) === JSON.stringify(curVal.open) &&
      JSON.stringify(last.close) === JSON.stringify(curVal.close)
    ) {
      last.endDay = curVal.day;
    } else {
      preVal.push(curVal);
    }
    return preVal;
  }, []);
}

export function mergeClosingDays(closingHours: StoreHolidayHoursDescription[]): Range[] {
  let range: Range = { from: '', to: '' };
  let index = -1;
  return closingHours
    .reduce<Range[]>((preVal, curVal) => {
      const last = preVal[index];
      if (!last) {
        range.from = curVal?.date ?? '';
        range.to = curVal?.date ?? '';
        preVal.push(range);
        index++;
      } else if (Date.parse(curVal?.date ?? '') - Date.parse(last?.to ?? '') === 86400000) {
        preVal[index].to = curVal?.date ?? '';
      } else {
        range = { from: curVal?.date ?? '', to: curVal?.date ?? '' };
        preVal.push(range);
        index++;
      }
      return preVal;
    }, [])
    .map((item) => {
      if (item.from === item.to) {
        item.to = '';
      }
      return item;
    });
}

export function formatDateToLocale(date: string | number): string {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString('fr-FR', options);
}

export function isDateWithinDaysRange(date: string | number, daysRange: number): boolean {
  const dateNow: any = new Date();
  const dateFuture: any = new Date(date);
  return (
    Math.floor((dateFuture - dateNow) / 86400000) >= 0 &&
    Math.floor((dateFuture - dateNow) / 86400000) <= daysRange
  );
}

export function getExceptionalOpenings(
  holidayHours: StoreHolidayHoursDescription[]
): StoreHolidayHoursDescription[] {
  return holidayHours.filter(
    (item) => !item.isClosed && isDateWithinDaysRange(item?.date ?? '', 30)
  );
}

export function getExceptionalClosings(
  holidayHours: StoreHolidayHoursDescription[]
): StoreHolidayHoursDescription[] {
  return holidayHours.filter(
    (item) => item.isClosed && isDateWithinDaysRange(item?.date ?? '', 15)
  );
}

export const BASE_LOC = { lat: 46.955535, lng: 1.979282 };

export const getRadiusInMiles = (zoom?: number): number =>
  !zoom || zoom <= ZoomLevels.COUNTRY ? 450 : zoom >= ZoomLevels.CITY ? 50 : 250;

export async function createPositionMarker({
  map,
  position,
}: {
  map: google.maps.Map;
  position: {
    lat: number;
    lng: number;
  };
}): Promise<google.maps.Marker> {
  const response = await fetchIcon({ name: 'position' });
  const url = getIsClient()
    ? `data:image/svg+xml;charset=UTF-8;base64, ${window.btoa(response || '')}`
    : '';

  const icon = {
    url,
    scaledSize: new google.maps.Size(21, 21),
  };

  return new google.maps.Marker({
    position,
    map,
    icon,
  });
}

export async function createMap({
  zoom = ZoomLevels.COUNTRY,
  center,
  element,
}: {
  center: { lat: number; lng: number };
  zoom?: ZoomLevels;
  element: HTMLDivElement;
}): Promise<{
  map: google.maps.Map;
  maps: typeof google.maps;
  autocomplete: google.maps.places.AutocompleteService;
  sessionToken: google.maps.places.AutocompleteSessionToken;
  bounds: google.maps.LatLngBounds;
}> {
  const googleApi = await GoogleMapsApiLoader({
    apiKey: GOOGLE_MAP_API_KEY,
    libraries: ['geometry', 'places'],
  });
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const autocomplete = new google.maps.places.AutocompleteService();
  const bounds = new google.maps.LatLngBounds();

  const map: google.maps.Map = new googleApi.maps.Map(element, {
    zoom,
    center,
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: 'cooperative',
  });
  return { map, maps: google.maps, autocomplete, sessionToken, bounds };
}

export function formatPhone(tel: string | undefined | null): string {
  return !tel
    ? ''
    : tel.length === 12
    ? `${tel[0] + tel[1] + tel[2]} ${tel[3] + tel[4] + tel[5]} ${tel[6] + tel[7]} ${
        tel[8] + tel[9]
      } ${tel[10] + tel[11]}`
    : tel;
}

function getDistance({ from, to }: { from?: Position; to?: Position }): number | undefined {
  return from && to
    ? google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(from.lat, from.lng),
        new google.maps.LatLng(to.lat, to.lng)
      )
    : undefined;
}

export function getDistanceInKm({ from, to }: { from?: Position; to?: Position }): string {
  const distanceM = getDistance({ from, to });

  if (!distanceM) {
    return '';
  }

  const distanceKm = distanceM / 1000;
  const fixedDistanceKm = Number.parseFloat(distanceKm.toFixed(1));
  const truncatedDistanceKm =
    (fixedDistanceKm * 10) % 10 === 0 ? Math.trunc(fixedDistanceKm) : fixedDistanceKm;

  return `${truncatedDistanceKm.toString().replace('.', ',')} km`;
}

export function searchStores({
  allStores,
  storesId,
  geoCoordinate,
  isClickAndCollect,
  isReservation,
}: {
  allStores: Store[];
  storesId: string[];
  geoCoordinate: StoreCoordinate;
  isClickAndCollect?: boolean;
  isReservation?: boolean;
}): StoreList {
  let stores: Store[] = storesId
    .map((store) => allStores.filter((item) => item.id === store)[0])
    .filter(Boolean);
  if (isClickAndCollect) {
    stores = stores.filter((store) => store?.services.includes(StoreService.clickCollect));
  } else if (isReservation) {
    stores = stores.filter((store) => store?.services.includes(StoreService.eReservation));
  }
  return {
    geoCoordinate,
    stores,
    totalCount: stores.length,
  };
}

export function sortByDistance({
  listToOrder,
  currentPosition,
}: {
  listToOrder: Store[] | PickupStation[];
  currentPosition: Position;
}): Store[] | PickupStation[] {
  const orderedList = listToOrder
    .slice()
    .sort((a: Store | PickupStation, b: Store | PickupStation) => {
      const distanceA = getDistance({
        from: currentPosition,
        to: { lat: a.displayCoordinate.latitude, lng: a.displayCoordinate.longitude },
      });

      const distanceB = getDistance({
        from: currentPosition,
        to: { lat: b.displayCoordinate.latitude, lng: b.displayCoordinate.longitude },
      });
      if (typeof distanceA === 'number' && typeof distanceB === 'number') {
        return distanceA - distanceB;
      }
      return 0;
    });
  return orderedList;
}

export const GEO_LOCATE_TIMEOUT_MS = 5000;
