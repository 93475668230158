import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { CmsFontFamily, CmsFullTitle, CmsHtmlTag } from '../../cms/types';
import { breakpoints, colors } from '../../../design-system';
import { useTextReveal } from '../../common/hooks/useTextReveal';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { getOurBrandBannerPosition } from '../utils';
import { heights } from '../../common/constants';

type Props = {
  cmsData: CmsFullTitle | null;
  index: number;
};

type StyledContainerProps = {
  full_title_position_on_page: number;
  full_title_margin_bottom: number;
  full_title_margin_bottom_small_screen: number;
  full_title_position_on_image: number;
  full_title_position_on_image_small_screen: number;
  full_title_font_family: CmsFontFamily;
  full_title_html_tag: CmsHtmlTag;
  full_title_text_color: string;
  full_title_text_font_size: number;
  full_title_text_font_size_small_screen: number;
  full_title_text_font_weight: number;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: block;
  width: 100%;
  max-height: calc(100vh - ${heights.HEADER_MOBILE}px);
  overflow: hidden;
  position: relative;
  order: ${(props) => props.full_title_position_on_page};
  margin-bottom: ${(props) => props.full_title_margin_bottom_small_screen}px;
  margin-top: ${(props) =>
    props.full_title_position_on_page === 1 ? `-${heights.HEADER_MOBILE}px` : 0};

  @media (min-width: ${breakpoints.M}px) {
    margin-bottom: ${(props) => props.full_title_margin_bottom}px;
    max-height: calc(100vh - ${heights.HEADER_TABLET}px);
    margin-top: ${(props) =>
      props.full_title_position_on_page === 1 ? `-${heights.HEADER}px` : 0};
  }

  @media (min-width: ${breakpoints.L}px) {
    max-height: calc(100vh - ${heights.HEADER}px);
  }

  .full-title-image {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  .full-title-banner-title {
    position: absolute;
    text-align: center;
    top: ${(props) => props.full_title_position_on_image_small_screen}%;
    left: 5%;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 90%;

    @media (min-width: ${breakpoints.S}px) {
      width: 80%;
      left: 10%;
      top: ${(props) => props.full_title_position_on_image}%;
    }

    span {
      font-size: ${(props) => props.full_title_text_font_size_small_screen}px;
      font-family: ${(props) => props.full_title_font_family};
      font-weight: ${(props) => props.full_title_text_font_weight};
      color: ${(props) => props.full_title_text_color};

      @media (min-width: ${breakpoints.M}px) {
        font-size: ${(props) => props.full_title_text_font_size}px;
      }
    }
  }
`;

export const FullTitleBanner = ({ cmsData, index }: Props) => {
  const {
    full_title_position_on_page,
    full_title_animation_active,
    full_title_margin_bottom,
    full_title_margin_bottom_small_screen,
    full_title_position_on_image,
    full_title_position_on_image_small_screen,
    full_title_font_family,
    full_title_image,
    full_title_image_small_screen,
    full_title_text,
    full_title_text_color,
    full_title_html_tag,
    full_title_text_font_size,
    full_title_text_font_size_small_screen,
    full_title_text_font_weight,
  } = cmsData?.primary ?? {};

  const containerRef = useRef<HTMLHeadingElement | HTMLParagraphElement | null>(null);
  const { data } = useTextReveal(
    full_title_text,
    full_title_animation_active ?? false,
    containerRef
  );
  const { isMobile } = useMediaQueries();
  const HtmlTag = full_title_html_tag ?? 'p';

  return (
    <StyledContainer
      id={`full-title-banner-block-${index}`}
      full_title_position_on_page={getOurBrandBannerPosition(
        full_title_position_on_page ?? 'Not activated'
      )}
      full_title_margin_bottom={full_title_margin_bottom ?? 0}
      full_title_margin_bottom_small_screen={full_title_margin_bottom_small_screen ?? 0}
      full_title_position_on_image={full_title_position_on_image ?? 0}
      full_title_position_on_image_small_screen={full_title_position_on_image_small_screen ?? 0}
      full_title_font_family={full_title_font_family ?? 'Ogg-text'}
      full_title_text_color={full_title_text_color ?? colors.WHITE}
      full_title_text_font_size={full_title_text_font_size ?? 30}
      full_title_text_font_size_small_screen={full_title_text_font_size_small_screen ?? 20}
      full_title_text_font_weight={full_title_text_font_weight ?? 700}
      full_title_html_tag={full_title_html_tag ?? 'p'}
    >
      <img
        className="full-title-image"
        src={isMobile ? full_title_image_small_screen?.url : full_title_image?.url}
        alt={isMobile ? full_title_image_small_screen?.alt ?? '' : full_title_image?.alt ?? ''}
      />
      {full_title_text && (
        <HtmlTag ref={containerRef} className="full-title-banner-title">
          {data}
        </HtmlTag>
      )}
    </StyledContainer>
  );
};
