import { colors } from '../../design-system';
import { opacities } from '../common/constants';
import { Product, Size } from '../product/types';
import { getIsSizeAvailable } from '../product/utils';
import { ECartItem } from './types';

export function checkECartItemAvailability(item: ECartItem): boolean {
  return item.stockQuantity > 0 && Boolean(item.status) && Boolean(item.eReservation);
}

export function checkECartItemValidity(item: ECartItem): boolean {
  return checkECartItemAvailability(item) && item.quantity <= item.stockQuantity;
}

export function checkECartValidity(items: ECartItem[]): boolean {
  return items.every((item) => checkECartItemValidity(item));
}

export const isValidProduct = (product: Product) => {
  if (
    product &&
    product.productName &&
    product.categories &&
    product.productRef &&
    product.colorRef
  ) {
    return true;
  }
  return false;
};

export enum EresaStep {
  PRODUCT = 1,
  STORES = 2,
  SUMMARY = 3,
  FORM = 4,
}

export const crossSellProductIsToDisplay = ({
  additionnals_products,
  hit,
  main_product,
}: {
  additionnals_products: Product[];
  hit: Product;
  main_product: Product;
}): boolean => {
  if (
    (additionnals_products.length > 0 &&
      additionnals_products.find(
        (item: Product) => item.productRef === hit.productRef && item.colorRef === hit.colorRef
      )) ||
    (main_product.productRef === hit.productRef && main_product.colorRef === hit.colorRef)
  ) {
    return false;
  }
  return true;
};

export const capitalize = (str: string): string => {
  if (typeof str !== 'string' || str === '') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getColorForLeftArrow = (activeIndex: number): string => {
  return activeIndex > 0 ? colors.BLACK : colors.GREY2;
};

export const getColorForRightArrow = (activeIndex: number, length: number): string => {
  return activeIndex + 1 < length ? colors.BLACK : colors.GREY2;
};

export const onClickLeft = (
  activeIndex: number,
  setActiveIndex: (value: number) => void
): void | null => {
  return activeIndex > 0 ? setActiveIndex(activeIndex - 1) : null;
};

export const onClickRight = (
  activeIndex: number,
  length: number,
  setActiveIndex: (value: number) => void
): void | null => {
  return activeIndex + 1 < length ? setActiveIndex(activeIndex + 1) : null;
};

export const getSizeForTracking = (originalSize: Size | undefined): string | undefined => {
  return !originalSize
    ? undefined
    : originalSize?.size || `${originalSize?.bandSize}-${originalSize?.cupSize}`;
};

export const setSkuAndSizeForEresaMainProduct = ({
  sortedSizes,
  sku,
  setSelectedSku,
  setSelectedSize,
}: {
  sortedSizes: Size[];
  sku: string;
  setSelectedSku: (value: string) => void;
  setSelectedSize: (value: Size | undefined) => void;
}): void => {
  if (sortedSizes.length === 1) {
    const [uniqueSize] = sortedSizes;
    if (getIsSizeAvailable(uniqueSize)) {
      setSelectedSku(uniqueSize.sku);
      setSelectedSize(sortedSizes.find((size) => size.sku === uniqueSize.sku));
    }
  } else {
    setSelectedSku(sku);
    setSelectedSize(sortedSizes.find((size) => size.sku === sku));
  }
};

export const getStoreLocatorPlaceHolder = (placeHolder: string | undefined | null): string => {
  return placeHolder ?? '';
};

export const getStoreLocatorOpacity = ({
  isFetching,
  isLocating,
}: {
  isFetching: boolean;
  isLocating: boolean;
}): number => {
  return isFetching || isLocating ? opacities.LOADING : 1;
};
