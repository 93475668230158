export default {
  RESET_TOKEN: 'PICKUP / RESET_TOKEN',
  SET_GEO_LOCATION_POSITION: 'PICKUP / SET_GEO_LOCATION_POSITION',
  REQUEST_TOKEN: 'PICKUP / REQUEST_TOKEN',
  REQUEST_TOKEN_SUCCESS: 'PICKUP / REQUEST_TOKEN_SUCCESS',
  REQUEST_TOKEN_FAILURE: 'PICKUP / REQUEST_TOKEN_FAILURE',
  REQUEST_PICKUP_STATIONS: 'PICKUP / REQUEST_PICKUP_STATIONS',
  REQUEST_PICKUP_STATIONS_SUCCESS: 'PICKUP / REQUEST_PICKUP_STATIONS_SUCCESS',
  REQUEST_PICKUP_STATIONS_FAILURE: 'PICKUP / REQUEST_PICKUP_STATIONS_FAILURE',
} as const;
