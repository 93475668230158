import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { breakpoints } from '../../../design-system';

type Props = {
  feedId: string;
};

const StyledDiv = styled.div`
  margin: 0 6px;

  @media (min-width: ${breakpoints.M}px) {
    margin: 0 14px;
  }

  .crt-panel-arrow {
    z-index: 1;
  }

  .instafeed-title {
    font-size: 2rem;
    font-weight: 700;
    font-family: 'Ogg-text';

    @media (min-width: ${breakpoints.M}px) {
      font-size: 3rem;
    }
  }
`;

export const InstagramFeed = ({ feedId }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const feedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (feedRef.current) {
            observer.unobserve(feedRef.current);
          }
        }
      });
    }, options);

    if (feedRef.current) {
      observer.observe(feedRef.current);
    }

    return () => {
      if (feedRef.current) {
        observer.unobserve(feedRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const loadScript = (src: string, libraryId: string, async: boolean) => {
        return new Promise((resolve) => {
          const existingScript = document.getElementById(libraryId);
          if (existingScript) {
            resolve(true);
          } else {
            const script = document.createElement('script');
            script.src = src;
            script.id = libraryId;
            script.async = async;
            script.defer = async;
            document.body.appendChild(script);

            script.onload = () => {
              resolve(true);
            };
          }
        });
      };

      loadScript(`https://cdn.curator.io/published/${feedId}.js`, feedId, true);
    }
  }, [isVisible, feedId]);

  useEffect(() => {
    return () => {
      const scriptElement = document.getElementById(feedId);
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [feedId]);

  return (
    <StyledDiv id="instagram-block">
      <h2 className="instafeed-title">Suivez-nous sur Instagram</h2>
      <div ref={feedRef} data-crt-feed-id={feedId} />
    </StyledDiv>
  );
};
