import React, { ComponentProps, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInstantSearch } from 'react-instantsearch';
import { css } from '@emotion/core';

import { breakpoints, colors, durations, opacities } from '../../../design-system';
import { CmsCatalogBanner, CmsCatalogHighlight } from '../../cms/types';
import { BannerVideo } from '../components/BannerVideo';
import { Timer } from '../../cms';
import { sortIndices } from '../../sorting/constants';
import { useProductsInfos } from '../../common/hooks/useProductsInfos';
import { Product } from '../../product/types';
import { getProductLink } from '../../product/utils';
import { HighlightedName } from '../../catalog/components/HighlightedName';
import { Price } from '../../catalog/components/Price';
import { HighlightedBanner } from './HighlightedBanner/HighlightedBanner';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { cdnBaseImageUrl } from '../../common/constants';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';

const getImageSource = (banner: CmsCatalogBanner, isMobile: boolean) => {
  const images = isMobile
    ? (banner.banner_image_mobile ?? banner.banner_image)
    : banner.banner_image;
  return images;
};

const noBannerWhenFilter = ['full column', 'half column'];

type BannerProduct = Pick<
  Product,
  | 'productRef'
  | 'productName'
  | 'colorRef'
  | 'colorLabel'
  | 'storePrice'
  | 'originalPrice'
  | 'typology'
  | 'collectionName'
>;

const BannerProduct: React.FC<ComponentProps<'li'> & { banner: CmsCatalogBanner }> = ({
  banner,
  ...props
}) => {
  const { hits } = useProductsInfos([`${banner.banner_product_ref}-${banner.banner_color_ref}`]);

  if (!hits?.[0]) {
    return <li {...props}>Loading...</li>;
  }

  const {
    productRef,
    colorRef,
    colorLabel,
    productName,
    storePrice,
    originalPrice,
    collectionName,
    typology,
  } = hits[0] ?? {};

  const productLink = getProductLink({
    productRef,
    colorLabel,
    colorRef,
    productName,
  });

  const objectID = `${productRef}-${colorRef}`;

  return (
    <li {...props}>
      <Link
        to={productLink}
        css={css`
          transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
          text-decoration: none;
          cursor: pointer;

          @media (hover: hover) {
            &:hover {
              opacity: ${opacities.HOVERED};
            }
          }
        `}
      >
        <picture>
          <source
            media={`(min-width: ${breakpoints.L}px)`}
            srcSet={getImageSource(banner, false)?.url} // Desktop
          />
          <source
            media={`(max-width: ${breakpoints.S}px)`}
            srcSet={getImageSource(banner, true)?.url} // Mobile
          />
          <img
            src={getImageSource(banner, false)?.url}
            alt={getImageSource(banner, false)?.alt ?? ''}
            css={css`
              width: 100%;
              object-fit: cover;
              border-radius: ${banner.banner_type === 'one tile' ? '8px' : 'none'};
              aspect-ratio: ${banner.banner_type === 'one tile' ? '3 / 4' : 'initial'};

              @media (min-width: ${breakpoints.S}px) {
                border-radius: 8px;
                aspect-ratio: ${banner.banner_type === 'half column'
                  ? '1.55'
                  : banner.banner_type === 'one tile'
                    ? '3 / 4'
                    : 'initial'};
              }

              @media (min-width: 1650px) {
                aspect-ratio: ${banner.banner_type === 'half column'
                  ? '1.54'
                  : banner.banner_type === 'one tile'
                    ? '3 / 4'
                    : 'initial'};
              }

              @media (min-width: 1750px) {
                aspect-ratio: ${banner.banner_type === 'half column'
                  ? '1.53'
                  : banner.banner_type === 'one tile'
                    ? '3 / 4'
                    : 'initial'};
              }
            `}
          />
        </picture>
        <div
          css={css`
            display: grid;
            gap: 8px;
            padding: 8px 8px 0 8px;

            @media (min-width: ${breakpoints.S}px) {
              padding: 8px 0 0 0;
            }
          `}
        >
          <HighlightedName
            objectID={objectID}
            productName={productName}
            typology={typology}
            collectionName={collectionName}
          />
          <Price originalPrice={originalPrice} storePrice={storePrice} />
        </div>
      </Link>
    </li>
  );
};

export const Banner: React.FC<
  ComponentProps<'li'> & { banner: CmsCatalogBanner; index: number }
> = ({ banner, index, ...props }) => {
  const { indexUiState } = useInstantSearch();
  const [showQuickAddMobile, setShowQuickAddMobile] = useState(false);
  const [productRefWithColorSelected, setProductRefWithColorSelected] = useState('');
  const [originalColorRef, setOriginalColorRef] = useState('');

  useEffect(() => {
    if (productRefWithColorSelected) {
      setShowQuickAddMobile(true);
    }
  }, [productRefWithColorSelected]);

  const handleCloseQuickAdd = () => {
    setShowQuickAddMobile(false);
    setProductRefWithColorSelected('');
  };

  // We don't display half and full column tiles when some filters are applied
  if (
    (indexUiState.refinementList ||
      indexUiState.range ||
      (indexUiState.sortBy && indexUiState.sortBy !== sortIndices[0].value)) &&
    noBannerWhenFilter.includes(banner.banner_type ?? 'full column')
  ) {
    return null;
  }
  if (banner.is_banner_active === false) {
    return null;
  }

  if (banner.banner_video) {
    return (
      <li
        {...props}
        css={css`
          display: ${banner.is_banner_hidden_on_mobile ? 'none' : 'block'};

          @media (min-width: ${breakpoints.L}px) {
            display: block;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            padding-top: ${banner.banner_type === 'one tile' ? '133.33%' : '56.25%'};
            border-radius: ${banner.banner_type === 'one tile' ? '8px' : 'none'};
            overflow: hidden;

            @media (min-width: ${breakpoints.S}px) {
              border-radius: 8px;
            }
          `}
        >
          <BannerVideo video={banner.banner_video} banner_type={banner.banner_type} />
        </div>
      </li>
    );
  }

  if (banner.banner_product_ref && banner.banner_color_ref) {
    return (
      <BannerProduct
        banner={banner}
        {...props}
        css={css`
          display: ${banner.is_banner_hidden_on_mobile ? 'none' : 'block'};

          @media (min-width: ${breakpoints.L}px) {
            display: block;
          }
        `}
      />
    );
  }

  if (banner.banner_gif?.name && banner.banner_gif.id) {
    return (
      <li
        {...props}
        css={css`
          display: ${banner.is_banner_hidden_on_mobile ? 'none' : 'block'};

          @media (min-width: ${breakpoints.L}px) {
            display: block;
          }
        `}
      >
        <Link
          data-testid={`plp-banner-gif-${banner.banner_gif?.name}-link-${index}`}
          to={getFormatedLinkForReactRouterLink(banner.banner_link)}
          css={css`
            cursor: ${banner.banner_link ? 'pointer' : 'initial'};
            transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

            @media (hover: hover) {
              &:hover {
                opacity: ${banner.banner_link ? opacities.HOVERED : 1};
              }
            }
          `}
        >
          <img
            src={`${
              process.env.DEPLOYMENT_ENV === 'production'
                ? cdnBaseImageUrl.production
                : cdnBaseImageUrl.development
            }${banner.banner_gif.id}_${banner.banner_gif.name}`}
            alt={banner.banner_gif.name}
            css={css`
              width: 100%;
              aspect-ratio: 3/4;
              border-radius: 8px;
            `}
          />
        </Link>
      </li>
    );
  }

  if (banner.banner_type === 'highlighted' && banner.items?.length) {
    return (
      <li
        {...props}
        css={css`
          display: ${banner.is_banner_hidden_on_mobile ? 'none' : 'block'};

          @media (min-width: ${breakpoints.L}px) {
            display: block;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            overflow: hidden;

            @media (min-width: ${breakpoints.S}px) {
              border-radius: 8px;
            }
          `}
        >
          <HighlightedBanner
            banner={banner as unknown as CmsCatalogHighlight}
            setProductRefWithColorSelected={setProductRefWithColorSelected}
            setOriginalColorRef={setOriginalColorRef}
          />
        </div>

        <QuickAddMobile
          productRef={productRefWithColorSelected.split('-')?.[0]}
          colorRef={productRefWithColorSelected.split('-')?.[1]}
          originalSelectedColor={originalColorRef}
          isActive={showQuickAddMobile}
          onClose={handleCloseQuickAdd}
          css={css`
            display: block;

            @media (min-width: ${breakpoints.S}px) {
              display: none;
            }
          `}
        />
      </li>
    );
  }

  return (
    <li
      {...props}
      css={css`
        display: ${banner.is_banner_hidden_on_mobile ? 'none' : 'block'};

        @media (min-width: ${breakpoints.L}px) {
          display: block;
        }
      `}
    >
      <Link
        data-testid={`plp-banner-image-${banner.banner_type}-link-${index}`}
        to={getFormatedLinkForReactRouterLink(banner.banner_link)}
        css={css`
          cursor: ${banner.banner_link ? 'pointer' : 'initial'};
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;
            aspect-ratio: ${banner.banner_type === 'one tile' ? '3 / 4' : 'initial'};

            @media (min-width: ${breakpoints.S}px) {
              aspect-ratio: ${banner.banner_type === 'half column'
                ? '1.55'
                : banner.banner_type === 'one tile'
                  ? '3 / 4'
                  : 'initial'};
            }

            @media (min-width: 1650px) {
              aspect-ratio: ${banner.banner_type === 'half column'
                ? '1.54'
                : banner.banner_type === 'one tile'
                  ? '3 / 4'
                  : 'initial'};
            }

            @media (min-width: 1750px) {
              aspect-ratio: ${banner.banner_type === 'half column'
                ? '1.53'
                : banner.banner_type === 'one tile'
                  ? '3 / 4'
                  : 'initial'};
            }

            @media (hover: hover) {
              &:hover {
                opacity: ${banner.banner_link ? opacities.HOVERED : 1};
              }
            }
          `}
        >
          <picture>
            <source
              media={`(min-width: ${breakpoints.L}px)`}
              srcSet={getImageSource(banner, false)?.url} // Desktop
            />
            <source
              media={`(max-width: ${breakpoints.S}px)`}
              srcSet={getImageSource(banner, true)?.url} // Mobile
            />
            <img
              src={getImageSource(banner, false)?.url}
              alt={getImageSource(banner, false)?.alt ?? ''}
              css={css`
                width: 100%;
                height: 100%;
                border-radius: ${banner.banner_type === 'one tile' ? '8px' : 'none'};
                object-fit: cover;

                @media (min-width: ${breakpoints.S}px) {
                  border-radius: 8px;
                }
              `}
            />
          </picture>
          {banner.banner_text || banner.banner_promotion_end_date_time ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: ${banner.banner_text_position === 'left'
                  ? 'flex-start'
                  : banner.banner_text_position === 'right'
                    ? 'flex-end'
                    : 'center'};
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                flex-direction: column;
                padding: ${banner.banner_type === 'one tile' ? '0 8px' : '0 16px'};
                gap: 16px;
              `}
            >
              {banner.banner_text && (
                <p
                  css={css`
                    color: ${banner.banner_text_color ?? colors.WHITE};
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    font-weight: 700;
                  `}
                >
                  {banner.banner_text}
                </p>
              )}
              {banner.banner_promotion_end_date_time && (
                <Timer
                  textColor={banner.banner_text_color || 'WHITE'}
                  timestamp={banner.banner_promotion_end_date_time}
                  preset="heading"
                  isWrapped={banner.banner_type === 'one tile'}
                />
              )}
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};
